import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import icons from "utils/constants/icons";
import { useDispatch } from "react-redux";
import {
  fetchTestimonials,
  setIsVideo,
  setVideoURL,
} from "../../../store/globalSlice";
import { useEffect, useState } from "react";
import "swiper/css/effect-coverflow";
import "swiper/css";
import "./Testimonials.scss";

function Testimonials() {
  const dispatch = useDispatch();
  const [testimonialsData, setTestimonialsData] = useState([]);

  const getTestimonial = async () => {
    const response = await dispatch(fetchTestimonials());
    if (response?.status === 200) {
      let resData = response?.data || [];
      if (resData?.length === 3) {
        resData = [...resData, ...resData];
      }
      setTestimonialsData(resData);
    }
  };

  useEffect(() => {
    getTestimonial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = () => {
    dispatch(setIsVideo(true));
  };

  return (
    <div className="container testimonial-section">
      <h4 className="title">TESTIMONIALS</h4>
      <h3 className="sub-title">Their Taste-Statements Make Us Proud</h3>

      <div id="left-slider-block" className="testimonial-slider">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          loop={true}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper">
          {testimonialsData?.map((data, index) => {
            if (!data || typeof data !== "object") {
              return null;
            }
            const ratingValue = (data?.rating || "").toString();
            const startValue = ratingValue?.split(".");
            const integerPart = parseInt(startValue[0], 10);
            const decimalPart = parseInt(startValue[1], 10);
            const integerPartStart = [];

            for (let i = 0; i < integerPart; i++) {
              integerPartStart.push(i + 1);
            }
            return (
              <SwiperSlide key={index}>
                <div className="testimonial-img">
                  <img
                    src={data?.image}
                    alt="slide2"
                    className="testimonial-img"
                  />
                </div>
                <div className="testimonial-name">
                  <h3>{data?.project_title}</h3>
                  <h4>{data?.name}</h4>
                </div>
                <div className="testimonial-detail">
                  <div className="ratting-stars">
                    {integerPartStart?.map((e, i) => (
                      <img src={icons.FillStar} alt="Ratting" key={i} />
                    ))}
                    {decimalPart !== "NaN" && decimalPart > 0 && (
                      <img src={icons.HalfStar} alt="Ratting" />
                    )}
                  </div>

                  <h5 className="testimonial-title">{data?.title}</h5>
                  <p className="testimonial-text">{data?.description}</p>
                  {data.video && (
                    <div className="d-flex justify-content-between testimonial-video">
                      <button
                        className="btn testimonial-btn"
                        onClick={() => {
                          dispatch(setVideoURL(data));
                          handleOpen();
                        }}>
                        <img src={icons.PlayBtn} alt="Play Button" />
                      </button>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default Testimonials;
