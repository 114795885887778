import "./Page3Service.scss";
import service from "assets/images/businessmodel/page3/service.png";

const Page3Service = () => {
  return (
    <section className="page3-service ">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-6 order-2 order-lg-1 ">
            <h2 className="mb-4 service-heading pt-4 pt-lg-0">
              Share your photos with friends easily
            </h2>
            <ul>
              <li className="service-list py-2">
                <div className="service-list-icon pe-4">
                  <span>
                    <svg
                      class="svg-inline--fa fa-buffer fa-w-14"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="buffer"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M427.84 380.67l-196.5 97.82a18.6 18.6 0 0 1-14.67 0L20.16 380.67c-4-2-4-5.28 0-7.29L67.22 350a18.65 18.65 0 0 1 14.69 0l134.76 67a18.51 18.51 0 0 0 14.67 0l134.76-67a18.62 18.62 0 0 1 14.68 0l47.06 23.43c4.05 1.96 4.05 5.24 0 7.24zm0-136.53l-47.06-23.43a18.62 18.62 0 0 0-14.68 0l-134.76 67.08a18.68 18.68 0 0 1-14.67 0L81.91 220.71a18.65 18.65 0 0 0-14.69 0l-47.06 23.43c-4 2-4 5.29 0 7.31l196.51 97.8a18.6 18.6 0 0 0 14.67 0l196.5-97.8c4.05-2.02 4.05-5.3 0-7.31zM20.16 130.42l196.5 90.29a20.08 20.08 0 0 0 14.67 0l196.51-90.29c4-1.86 4-4.89 0-6.74L231.33 33.4a19.88 19.88 0 0 0-14.67 0l-196.5 90.28c-4.05 1.85-4.05 4.88 0 6.74z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="service-list-body">
                  <p className="mb-0">
                    Fully layered dolor sit amet, consectetur adipisicing elit.
                    Facere, nobis, id expedita dolores officiis laboriosam.
                  </p>
                </div>
              </li>
              <li className="service-list py-2">
                <div className="service-list-icon pe-4">
                  <span>
                    <svg
                      class="svg-inline--fa fa-brush fa-w-12"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="brush"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M352 0H32C14.33 0 0 14.33 0 32v224h384V32c0-17.67-14.33-32-32-32zM0 320c0 35.35 28.66 64 64 64h64v64c0 35.35 28.66 64 64 64s64-28.65 64-64v-64h64c35.34 0 64-28.65 64-64v-32H0v32zm192 104c13.25 0 24 10.74 24 24 0 13.25-10.75 24-24 24s-24-10.75-24-24c0-13.26 10.75-24 24-24z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="service-list-body pe-4">
                  <p className="mb-0">
                    Customizable design dolor sit amet, consectetur adipisicing
                    elit. Facere, nobis, id expedita dolores officiis
                    laboriosam..
                  </p>
                </div>
              </li>
              <li className="service-list py-2">
                <div className="service-list-icon pe-4">
                  <span>
                    <svg
                      class="svg-inline--fa fa-burn fa-w-12"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="burn"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M192 0C79.7 101.3 0 220.9 0 300.5 0 425 79 512 192 512s192-87 192-211.5c0-79.9-80.2-199.6-192-300.5zm0 448c-56.5 0-96-39-96-94.8 0-13.5 4.6-61.5 96-161.2 91.4 99.7 96 147.7 96 161.2 0 55.8-39.5 94.8-96 94.8z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="service-list-body">
                  <p className="mb-0">
                    Drop ipsum dolor sit amet, consectetur adipisicing elit.
                    Facere, nobis, id expedita dolores officiis laboriosam.
                  </p>
                </div>
              </li>
              <li className="service-list py-2">
                <div className="service-list-icon pe-4">
                  <span>
                    <svg
                      class="svg-inline--fa fa-cart-arrow-down fa-w-18"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="cart-arrow-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M504.717 320H211.572l6.545 32h268.418c15.401 0 26.816 14.301 23.403 29.319l-5.517 24.276C523.112 414.668 536 433.828 536 456c0 31.202-25.519 56.444-56.824 55.994-29.823-.429-54.35-24.631-55.155-54.447-.44-16.287 6.085-31.049 16.803-41.548H231.176C241.553 426.165 248 440.326 248 456c0 31.813-26.528 57.431-58.67 55.938-28.54-1.325-51.751-24.385-53.251-52.917-1.158-22.034 10.436-41.455 28.051-51.586L93.883 64H24C10.745 64 0 53.255 0 40V24C0 10.745 10.745 0 24 0h102.529c11.401 0 21.228 8.021 23.513 19.19L159.208 64H551.99c15.401 0 26.816 14.301 23.403 29.319l-47.273 208C525.637 312.246 515.923 320 504.717 320zM403.029 192H360v-60c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v60h-43.029c-10.691 0-16.045 12.926-8.485 20.485l67.029 67.029c4.686 4.686 12.284 4.686 16.971 0l67.029-67.029c7.559-7.559 2.205-20.485-8.486-20.485z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="service-list-body">
                  <p className="mb-0">
                    Marketing chart dolor sit amet, consectetur adipisicing
                    elit. Facere, nobis, id expedita dolores officiis
                    laboriosam.
                  </p>
                </div>
              </li>
            </ul>
            <a href="#/" className="btn">
              Learn More
            </a>
          </div>

          <div className="col-12 col-lg-5 order-1 order-lg-2 d-none d-md-block">
            <img src={service} alt="service-thumb" className="fit-img" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Page3Service;
