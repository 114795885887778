import React from "react";
import "./HeroSection.scss";
import TouchBtn from "components/common/TouchBtn";
import icons from "utils/constants/icons";
const HeroSection = ({ Data }) => {
  return (
    <div id="Hero-Section-id">
      {Data?.map((ele, index) => {
        const { mainTitle, subTitle, tags, images } = ele;
        return (
          <section className="hireresources-hero-section" key={index}>
            <div className="hireresources-hero-content">
              <div className="container">
                <h1 className="hero-title">{mainTitle}</h1>
                <p className="hero-paragraph">{subTitle}</p>
                {tags && (
                  <div className="hireresources-tags">
                    {tags?.map((tag, Tindex) => {
                      const { name } = tag;
                      return (
                        <div className="tag" key={Tindex}>
                          <div className="checked-icon">
                            <img src={icons.checkedIcon} alt="Checked Icon" />
                          </div>
                          <p>{name}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
                <TouchBtn title="Get in Touch" />
              </div>
            </div>
            <div className="container">
              <div className="hireresources-text">
                <img
                  src={images}
                  alt="Hire Resources Text"
                  className="hireresources-text"
                />
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default HeroSection;
