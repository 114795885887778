import React, { useState } from 'react'
import icons from 'utils/constants/icons';
import "./Fun.scss";

const Fun = () => {
    const [showMore, setShowMore] = useState(false);

    const bigImages = [
        {
            id: 1,
            image: icons.st1,
        },
        {
            id: 2,
            image: icons.st2,
        },
        {
            id: 3,
            image: icons.st3,
        },
        {
            id: 4,
            image: icons.st4,
        }
    ];

    const smallImagesSection1 = [
        {
            id: 1,
            smImage: icons.st5,
        },
        {
            id: 2,
            smImage: icons.st6,
        },
        {
            id: 3,
            smImage: icons.st7,
        },
        {
            id: 4,
            smImage: icons.st8,
        }
    ];

    const smallImagesSection2 = [
        {
            id: 1,
            smImage: icons.st9,
        },
        {
            id: 2,
            smImage: icons.st10,
        },
        {
            id: 3,
            smImage: icons.st11,
        },
        {
            id: 4,
            smImage: icons.st12,
        }
    ];

    const smallImagesSection3 = [
        {
            id: 1,
            smImage: icons.st13,
        },
        {
            id: 2,
            smImage: icons.st14,
        },
        {
            id: 3,
            smImage: icons.st15,
        },
        {
            id: 4,
            smImage: icons.st16,
        }
    ];

    const smallImagesSection4 = [
        {
            id: 1,
            smImage: icons.st17,
        },
        {
            id: 2,
            smImage: icons.st18,
        },
        {
            id: 3,
            smImage: icons.st19,
        },
        {
            id: 4,
            smImage: icons.st20,
        }
    ];

  return (
    <div id="fun-images" className="container">
        <h4 className="title">Memories</h4>
        <h3 className="sub-title">Our Gallery</h3>
        <div className="row">
            <div className="mb-4">
                <div className="w-100 main-image"><img src={icons.st22} alt="img" className="w-100 h-100 fun-image" /></div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="mb-4">
                    <div className="w-100 big-image"><img src={bigImages[0]?.image} alt="img" className="w-100 h-100 fun-image" /></div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="row d-flex">
                    {smallImagesSection1?.map((el, index) => {
                        return (
                            <div className="col-md-6" key={index}>
                                <div className="mb-4">
                                    <div className="w-100 small-image"><img src={el?.smImage} alt="img" className="w-100 h-100 fun-image" /></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6">
                <div className="row d-flex">
                    {smallImagesSection2?.map((el, index) => {
                        return (
                            <div className="col-md-6" key={index}>
                                <div className="mb-4">
                                    <div className="w-100 small-image"><img src={el?.smImage} alt="img" className="w-100 h-100 fun-image" /></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-4">
                    <div className="w-100 big-image"><img src={bigImages[1]?.image} alt="img" className="w-100 h-100 fun-image" /></div>
                </div>
            </div>
        </div>

        <div className={`row ${showMore ? "show" : "hide"}`}>
            <div className="col-md-6">
                <div className="mb-4">
                    <div className="w-100 big-image" ><img src={bigImages[2]?.image} alt="img" className="w-100 h-100 fun-image" /></div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="row">
                    {smallImagesSection3?.map((el, index) => {
                        return (
                            <div className="col-md-6" key={index}>
                                <div className="mb-4">
                                    <div className="w-100 small-image"><img src={el?.smImage} alt="img" className="w-100 h-100 fun-image" /></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>       
        </div>

        <div className={`row ${showMore ? "show" : "hide"}`}>
            <div className="col-md-6">
                <div className="row d-flex">
                    {smallImagesSection4?.map((el, index) => {
                        return (
                            <div className="col-md-6" key={index}>
                                <div className="mb-4">
                                    <div className="w-100 small-image"><img src={el?.smImage} alt="img" className="w-100 h-100 fun-image" /></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-4">
                    <div className="w-100 big-image"><img src={bigImages[3]?.image} alt="img" className="w-100 h-100 fun-image" /></div>
                </div>
            </div>
        </div>

        {!showMore &&
            <div className="row m-4">
                <div className="d-flex justify-content-center">
                    <button
                        className="btn btn-primary"
                        onClick={() => setShowMore(true)}
                    >
                        Show More
                    </button>
                </div>
            </div>
        }
    </div>
  )
}

export default Fun;