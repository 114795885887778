import "./Page3Screenshot.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icons from "utils/constants/icons";
import left from "assets/images/businessmodel/page3/left.png";
import right from "assets/images/businessmodel/page3/right.png";

const Page3Screenshot = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    nextArrow: <img src={right} alt="right" />,
    prevArrow: <img src={left} alt="left" />,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 598,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  return (
    <section className="page3-screenshot">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h2>Simple & Beautiful Interface</h2>
              <p className="d-none d-sm-block mt-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Laborum obcaecati dignissimos quae quo ad iste ipsum officiis
                deleniti asperiores sit.
              </p>
              <p className="d-block d-sm-none mt-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Laborum obcaecati.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 text-center slider-container py-3 align-items-center overflow-hidden">
            <Slider {...settings} className="mx-auto ">
              <div className="text-center slide pb-4">
                <img
                  src={icons.page3Screenshot1}
                  alt="Image-1"
                  className="img-fluid"
                />
              </div>
              <div className="text-center slide pb-4">
                <img
                  src={icons.page3Screenshot2}
                  alt="Image-2"
                  className="img-fluid"
                />
              </div>
              <div className="text-center slide pb-4">
                <img
                  src={icons.page3Screenshot3}
                  alt="Image-3"
                  className="img-fluid"
                />
              </div>
              <div className="text-center slide pb-4">
                <img
                  src={icons.page3Screenshot4}
                  alt="Image-4"
                  className="img-fluid"
                />
              </div>
              <div className="text-center slide pb-4">
                <img
                  src={icons.page3Screenshot5}
                  alt="Image-5"
                  className="img-fluid"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Page3Screenshot;
