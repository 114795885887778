import "./HealthcareHeroSection.scss";
import ComputerImg from "assets/images/businessmodel/healthcare/MacBook.png";

import Button from "components/Button";
// import "animate.css";
const HealthcareHeroSection = () => {
    return (
        <div className="healthcare-hero">
            <div className="container">
                <div className="hero-inner-text-area">
                    <div className="container">
                        <div className="row gy-lg-0 gy-4">
                            <div className="col-lg-6">
                                <div className="hero-inner-text">
                                    {/* <div className="text-subtitle">
                                        <span>FIRST EVER AMAZING THING</span>
                                    </div> */}
                                    <h1 className="text-header">
                                        The app that builds for your management
                                    </h1>
                                    <p className="text-content">
                                        You zealously departure had procuring
                                        suspicion. Books whose front would purse
                                        if be do decay. Quitting you way
                                        formerly disposed perceived are. Common
                                        turned boy direct and yet sociable feli
                                    </p>
                                    <div className="text-action-btn">
                                        <a
                                            href="#"
                                            className="btnText text-decoration-none"></a>
                                    </div>
                                    <div>
                                        <Button
                                            btnStyle="btn-light-green"
                                            btnText="Get Started For Free"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="healcare-hero-div">
                                    <div className="healtcare-computer-image">
                                        <img
                                            src={ComputerImg}
                                            className=""
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HealthcareHeroSection;
