import icons from "utils/constants/icons";
import "./Solution.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
const Solution = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <div className="container-fluid Solutionmain-container">
        <div className="container Solution-container">
          <div className="row text-center  mb-5 justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 mx-auto ">
              <div className="Solution-header">
                <h1 className="display-4 mb-3 text-center  mx-auto">
                  Key Features of Our Real Estate Solution
                </h1>
                <p className="lead text-muted">
                  Our real estate platform is designed to provide a seamless
                  experience across mobile, desktop, and administrative tools.
                  Explore the key features that make our solution a game-changer
                  in real estate managemen
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center justify-content-center align-items-center">
            <Slider {...settings}>
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className="col-xl-4 col-md-6 col-12  shadow p-3 rounded"
                >
                  <div className="Solution-card mx-auto d-flex flex-column justify-content-center align-items-start">
                    <div className="img">
                      <img
                        src={icons.Solutioncard}
                        alt="simple"
                        className="mb-3"
                      />
                    </div>

                    <h1 className="card-title">Seamless Management</h1>

                    <p className="text-start">
                      Get full control on your business and manage users, staff,
                      services, etc. from a single window Get full control on
                      your business and manage users, staff, services, etc. from
                      a single window Get full control on your business and
                      manage users, staff, services, etc. from a single window
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Solution;
