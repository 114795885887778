import "./HealthCard.scss";
import Icon1 from "assets/images/businessmodel/healthcare/home.png";
import Icon2 from "assets/images/businessmodel/healthcare/bullseye.png";
import Icon3 from "assets/images/businessmodel/healthcare/maintenance.png";
import icons from "utils/constants/icons";
import Button from "components/Button";
const HealthCard = () => {
    const helthList = [
        {
            image: icons?.hospitalIcons,
            title: "Fast & Optimized",
            description:
                "Life lain held calm and true neat she. Much feet each so went no from. Truth began.",
        },
        {
            image: icons?.heartIcons,
            title: "Clean Code",
            description:
                "Life lain held calm and true neat she. Much feet each so went no from. Truth began.",
        },
        {
            image: icons?.tapIcons,
            title: "Fast & Optimized",
            description:
                "Life lain held calm and true neat she. Much feet each so went no from. Truth began.",
        },
        {
            image: icons?.healthInsuranceIcons,
            title: "Fast & Optimized",
            description:
                "Life lain held calm and true neat she. Much feet each so went no from. Truth began.",
        },
        {
            image: icons?.healthIcons,
            title: "Fast & Optimized",
            description:
                "Life lain held calm and true neat she. Much feet each so went no from. Truth began.",
        },
        {
            image: icons?.stethoscopeIcons,
            title: "Fast & Optimized",
            description:
                "Life lain held calm and true neat she. Much feet each so went no from. Truth began.",
        },
    ];

    return (
        <div className="health-card-container">
            <div className="container">
                <div className="row gx-md-5 gy-md-5 gy-3 gx-0">
                    {helthList?.map((ele, index) => {
                        const { image, title, description } = ele;
                        return (
                            <div
                                className="col-lg-4 col-md-6"
                                data-aos="zoom-in"
                                data-aos-duration="800"
                                data-aos-mirror="false"
                                data-aos-once="true"
                                key={index}>
                                <div className="card-item">
                                    <div className="icon">
                                        <img src={image} alt="icon" />
                                    </div>
                                    <h4 className="card-title">{title}</h4>
                                    <p className="card-text-content">
                                        {description}
                                    </p>
                                    <Button
                                        btnText="Read More"
                                        btnStyle="btn-green-s"
                                    />
                                </div>
                            </div>
                        );
                    })}

                    {/* <div
                            className="col-lg-4 col-md-6 "
                            data-aos="zoom-in"
                            data-aos-duration="800"
                            data-aos-mirror="false"
                            data-aos-once="true">
                            <div className="card-item text-center">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <img
                                        src={Icon2}
                                        alt="icon"
                                        className="icon-bullseye"
                                    />
                                </div>
                                <div className="content">
                                    <h4 className="card-title">Clean Code</h4>
                                    <p className="card-text-content">
                                        Life lain held calm and true neat she.
                                        Much feet each so went no from. Truth
                                        began.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6"
                            data-aos="zoom-in"
                            data-aos-duration="800"
                            data-aos-mirror="false"
                            data-aos-once="true">
                            <div className="card-item ">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <img
                                        src={Icon3}
                                        alt="icon"
                                        className="icon-repair"
                                    />
                                </div>
                                <div className="content">
                                    <h4 className="card-title">
                                        Easy Optimsation
                                    </h4>
                                    <p className="card-text-content text-center">
                                        Life lain held calm and true neat she.
                                        Much feet each so went no from. Truth
                                        began.
                                    </p>
                                </div>
                            </div>
                        </div> */}
                </div>
            </div>
        </div>
    );
};

export default HealthCard;
