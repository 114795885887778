import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import Button from "components/Button";
import icons from "utils/constants/icons";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "./BusinessContent.scss";

const BusinessContent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef = useRef(null);

  const handleNext = () => {
    if (swiperRef?.current) {
      swiperRef?.current?.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef?.current) {
      swiperRef?.current?.slidePrev();
    }
  };

  const businessList = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
  ];

  return (
    <>
      <section className="business-content-container">
        <div className="container">
          <h3 className="business-title">
            Looking for Informative Business Content?
          </h3>
          <p className="business-sub-title">
            Look no further! Our Blogs & videos will keep you up-to-date with
            the market.
          </p>
        </div>
        <div className="container">
          <div className="business-div">
            <div className="row">
              <Swiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
                cssMode={true}
                pagination={{
                  clickable: true,
                }}
                mousewheel={true}
                keyboard={true}
                breakpoints={{
                  0: {
                    spaceBetwee: 20,
                    slidesPerView: 1,
                  },
                  768: {
                    spaceBetwee: 34,
                    slidesPerView: 2,
                  },
                  1440: {
                    spaceBetwee: 54,
                    slidesPerView: 3,
                  },
                }}
                spaceBetween={54}
                slidesPerView={3}
                onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
                modules={[Pagination]}
                className="mySwiper"
              >
                {businessList?.map((ele, index) => {
                  return (
                    <SwiperSlide
                      className="col-lg-4 col-md-6 col-12"
                      key={index}
                    >
                      <div className="business-card">
                        <div className="btn-div">
                          <Button btnStyle="btn-purple" btnText="Get Started" />
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
                {/* <SwiperSlide className="col-md-4">
                            <div className="business-card">
                                <div className="btn-div">
                                    <Button
                                        btnStyle="btn-purple"
                                        btnText="Get Started"
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="col-md-4">
                            <div className="business-card">
                                <div className="btn-div">
                                    <Button
                                        btnStyle="btn-purple"
                                        btnText="Get Started"
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="col-md-4">
                            <div className="business-card">
                                <div className="btn-div">
                                    <Button
                                        btnStyle="btn-purple"
                                        btnText="Get Started"
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="col-md-4">
                            <div className="business-card">
                                <div className="btn-div">
                                    <Button
                                        btnStyle="btn-purple"
                                        btnText="Get Started"
                                    />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="col-md-4">
                            <div className="business-card">
                                <div className="btn-div">
                                    <Button
                                        btnStyle="btn-purple"
                                        btnText="Get Started"
                                    />
                                </div>
                            </div>
                        </SwiperSlide> */}
              </Swiper>
            </div>
            <div className="custom-navigation-1">
              <button
                onClick={handlePrev}
                className={`btn-prev-1 ${
                  currentSlide === 0 ? "first-slide" : ""
                }`}
              >
                <img
                  src={icons?.roundLeftIcons}
                  alt="left-img"
                  loading="lazy"
                />
              </button>
              <button
                onClick={handleNext}
                className={`btn-next-1 ${
                  currentSlide === businessList?.length - 1 ? "last-slide" : ""
                }`}
              >
                <img
                  src={icons?.roundRightIcons}
                  alt="right-img"
                  loading="lazy"
                />
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="business-last-container">
        <h4 className="last-title">Join, Sell & Earn</h4>
        <h2 className="last-sub-title">
          Explore Our Partnership Program to Sell Our Fully Customized Tech
          Solution To Your Clients.
        </h2>
        <div className="btn-div">
          <Button btnStyle="btn-purple-s" btnText="Partner with us" />
        </div>
      </section>
    </>
  );
};

export default BusinessContent;
