import { createSlice } from "@reduxjs/toolkit";
import { api } from "../services";
// import { storeLocalStorageData } from "../utils/helpers";

const initialState = {
    apiError: {},
    isOpen: false,
    isVacancy: false,
    isVideo: false,
    singleJobData: [],
    videoURL: [],
};

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setApiError(state, action) {
            state.apiError = action.payload;
        },
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        setIsVacancy: (state, action) => {
            state.isVacancy = action.payload;
        },
        setIsVideo: (state, action) => {
            state.isVideo = action.payload;
        },
        setSingleJobData: (state, action) => {
            state.singleJobData = action.payload;
        },
        setVideoURL: (state, action) => {
            state.videoURL = action.payload;
        },
        reset: () => initialState,
    },
});

export const sendMail = (formData) => async (dispatch) => {
    try {
        // const res = await api.post("/save-business-request", formData, {
        const res = await api.post("/inquiry", formData, {
            "Content-Type": "multipart/form-data",
        });
        return await dispatch(handelResponse(res));
    } catch (err) {
        return await dispatch(handelCatch(err));
    }
};

export const sendEmail = (payload) => async (dispatch) => {
    try {
        const res = await api.post("/inquiry/mail", payload);
        return await dispatch(handelResponse(res));
    } catch (err) {
        return await dispatch(handelCatch(err));
    }
};

//fresher & experienced vacancy
export const fetchJobs = () => async (dispatch) => {
    try {
        const res = await api.get("/job");
        return dispatch(handelResponse(res));
    } catch (error) {
        return await dispatch(handelCatch(error));
    }
};

//job details
export const fetchJobDetail = (queryParams) => async (dispatch) => {
    try {
        const res = await api.get(`/job/job-details?${queryParams}`);
        return dispatch(handelResponse(res));
    } catch (error) {
        return await dispatch(handelCatch(error));
    }
};

//apply job
export const applyJob = (formData) => async (dispatch) => {
    try {
        const res = await api.post("/job/apply-job", formData, {
            "Content-Type": "multipart/form-data",
        });
        return dispatch(handelResponse(res));
    } catch (error) {
        return await dispatch(handelCatch(error));
    }
};

//testimonial
export const fetchTestimonials = () => async (dispatch) => {
    try {
        const res = await api.get("/testimonials");
        return dispatch(handelResponse(res));
    } catch (error) {
        return await dispatch(handelCatch(error));
    }
};

// inquiry
export const addInquiry = (formData) => async (dispatch) => {
    try {
        const res = await api.post("/start-project-inquiry", formData, {
            "Content-Type": "multipart/form-data",
        });
        return await dispatch(handelResponse(res));
    } catch (err) {
        return await dispatch(handelCatch(err));
    }
};

export const throwSuccess = (msg) => async (dispatch) => {
    let message = msg || "Operation Done Successfully.";
    dispatch(
        setApiError({
            show: true,
            message: message,
            type: "success",
        })
    );
};

export const throwError = (res) => async (dispatch) => {
    let message = res?.message;
    message = message || "Something went wrong!";
    dispatch(
        setApiError({
            show: true,
            message: message,
            type: "danger",
        })
    );
};

export const handelResponse = (res) => async (dispatch) => {
    let returnValue = null;
    const status = res?.status;
    switch (status) {
        case 200:
            returnValue = res;
            break;
        case 201:
            returnValue = res;
            break;
        case 400:
            dispatch(throwError(res));
            returnValue = res;
            break;
        case 500:
            dispatch(throwError(res));
            returnValue = res;
            break;
        default:
            throwError({ message: "Something went wrong!" });
            returnValue = null;
            break;
    }
    return returnValue;
};

export const handelCatch = (error) => async (dispatch) => {
    let status = error?.response?.status;
    let message = error?.response?.data?.message;
    if (status === 401 || status === 403) {
        // dispatch(handelLogout());
    } else {
        message = message || "Something went wrong!";
        dispatch(
            setApiError({
                show: true,
                message: message,
                type: "danger",
            })
        );
    }
    return null;
};

export const {
    setApiError,
    reset,
    setIsOpen,
    setIsVacancy,
    setIsVideo,
    setSingleJobData,
    setVideoURL,
} = globalSlice.actions;

export default globalSlice.reducer;
