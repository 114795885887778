import React from "react";
import { NavLink } from "react-router-dom";
import icons from "utils/constants/icons";
import "swiper/css";
import "./ContactUs.scss";

const ContactUs = () => {
    return (
        <section className="section-line contect-us" id="contact-us">
            <div className="contact-image-container container-main m-auto">
                <div className="container">
                    <h4 className="title mt-0">CONTACT US</h4>
                    <h3 className="sub-title mb-1">
                        Unlock Potential with Sourrcecube : Let's Ignite Ideas.
                    </h3>
                    <div className="">
                        <div className="sub-heading pb-4">
                            Team Up With Us Today For An Unforgettable Service
                            Experience
                        </div>
                        <div className="d-flex contact-sosial">
                            <NavLink
                                to={
                                    "https://www.google.com/search?q=sourcecube+technologies&oq=sourcecube+technologies&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBwgCEAAYgAQyBggDEEUYQDIGCAQQRRg8MgYIBRBFGDwyBggGEEUYPdIBCDM5MTlqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8"
                                }
                                target="_blank">
                                <img src={icons.googleIcon} alt="google" />
                            </NavLink>
                            <NavLink
                                to={
                                    "https://clutch.co/profile/sourcecube-technologies#highlights"
                                }
                                target="_blank">
                                <img src={icons.clucthIcon} alt="clutch" />
                            </NavLink>
                            <NavLink
                                to={
                                    "https://www.glassdoor.co.in/Reviews/Sourcecube-Technologies-Reviews-E2562857.htm"
                                }
                                target="_blank">
                                <img src={icons.Glassdoor} alt="goodfirms" />
                            </NavLink>
                        </div>

                        <div className="orgName">India</div>
                        <div className="orgAdd">
                            322-329, Atlanta Shopping, Althan Bhimrad Road,Near
                            Metro, Vesu, Surat.
                        </div>
                        <NavLink
                            to="mailto:hr@sourcecubeindia.com"
                            className="orgEmail d-flex gap-2">
                            <img src={icons.emailIcon} alt="email" />{" "}
                            hr@sourcecubeindia.com
                        </NavLink>
                        <NavLink
                            to="mailto:Info@sourcecubeindia.com"
                            className="orgEmail d-flex gap-2">
                            <img src={icons.emailIcon} alt="email" />{" "}
                            Info@sourcecubeindia.com ( Sales )
                        </NavLink>
                        <NavLink
                            to="tel:9727171113"
                            className="orgPhone text-decoration-none d-flex gap-2 mb-3">
                            <img src={icons.PhoneIcon} alt="phone" />
                            +91 97271 71113 ( For Sales )
                        </NavLink>
                        <NavLink
                            to="tel:7862091636"
                            className="orgPhone text-decoration-none d-flex gap-2">
                            <img src={icons.PhoneIcon} alt="phone" />
                            +91 78620 91636 ( For Candidates )
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
