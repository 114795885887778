import React from "react";
import "./HireReactJSDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireReactJSDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Elevate Your Digital Presence with Expert ReactJS Developers",
      subTitle:
        "Are you in need of experienced, skilled, and dedicated ReactJS developers? Look no further than our esteemed organization. Our team of ReactJS developers is ready to tackle your front-end application development needs with dynamic, interactive, and feature-rich solutions. Leverage their expertise, experience, and skillset to bring your ideas to life.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Unlock Exceptional ReactJS Solutions with Us",
      title: "Engage Our Dedicated Developers",
      text: "Crafting impressive user interfaces powered by ReactJS is effortless with our expertise. As a leading ReactJS development company in India, we provide top-notch ReactJS developers. Our experience spans various front-end and backend technologies, including React + Php, React + Node, and React + Python, enabling us to develop scalable web architectures.Our ReactJS developers harness cutting-edge tools like Single Page Apps and PWA to deliver unparalleled experiences for your end-users.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire ReactJS Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our ReactJS Developers",
      subTitle: "Unlocking the Potential Expertise of Our ReactJS Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Custom React Development",
          icon: icons.learningIcon,
          benefitdescription:
            "Our developers are highly skilled, trained, and experienced in building custom React-based websites that are scalable, secure, and feature-rich.",
        },
        {
          id: 2,
          benefitTitle: "Cross-platform Application Development",
          icon: icons.motovationalIcon,
          benefitdescription:
            "We have a team of top-notch ReactJS developers who possess the adept skillset and expertise to develop A-grade cross-platform mobile applications that are easy-to-access and robust.",
        },
        {
          id: 3,
          benefitTitle: "ReactJS UI Development",
          icon: icons.holidayIcon,
          benefitdescription:
            "Our experienced and creative designers will create interactive and intuitive designs of the highest aesthetic value for your ReactJS UI development needs.",
        },
        {
          id: 4,
          benefitTitle: "Enterprise React Web Apps",
          icon: icons.celebrationIcon,
          benefitdescription:
            "We offer top-quality ReactJS developers who develop web applications for big-scale enterprises, ensuring reliability, scalability, and security.",
        },
        {
          id: 5,
          benefitTitle: "ReactJS eCommerce Development",
          icon: icons.salaryIcon,
          benefitdescription:
            "Achieve all your aspirations of having a successful eCommerce business with our ReactJS eCommerce development solutions. Our developers will create solutions to increase your eCommerce revenue and help your business scale rapidly.",
        },
        {
          id: 6,
          benefitTitle: "ReactJS Migration Service",
          icon: icons.environmentIcon,
          benefitdescription:
            "Our ReactJS developers possess top-notch expertise in ReactJS migration service, ensuring seamless migration of your existing application’s frontend to the latest version of React.",
        },
        {
          id: 7,
          benefitTitle: "Progressive Web App (PWA) Development",
          icon: icons.professionalIcon,
          benefitdescription:
            "Our ReactJS developers specialize in building Progressive Web Applications (PWAs) that offer a seamless user experience across various devices and platforms. Leveraging the latest web technologies and best practices, we ensure your PWA is fast, reliable, and engaging, driving higher user engagement and retention.",
        },
        {
          id: 8,
          benefitTitle: "Real-time Data Visualization",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Harnessing the power of ReactJS and advanced data visualization libraries, our developers create stunning real-time data visualization solutions tailored to your specific business needs. Whether you require interactive dashboards, dynamic charts, or immersive graphs, our team delivers visually appealing and actionable insights that empower your decision-making process.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of ReactJS Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our ReactJS developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "Tailored Solutions for Your Business Needs",
          description:
            "Looking for innovative and dynamic solutions? Partner with our ReactJS developers equipped with a high level of expertise to bring your digital ideas to life. Choose from a variety of services tailored to meet your specific requirements",
          number: "01",
        },
        {
          id: 2,
          title: "ReactJS Application Development",
          description:
            "Embark on a journey of innovation with our ReactJS application development services. Our skilled developers craft dynamic and responsive web applications that cater to your unique business needs, ensuring seamless functionality and user experience.",
          number: "02",
        },
        {
          id: 3,
          title: "ReactJS Single-Page Application (SPA) Development",
          description:
            "Discover the power of ReactJS for building robust single-page applications. Whether you need scalable and interactive SPAs or custom web solutions, our top-notch developers have the skills and experience to meet your ReactJS development needs.",
          number: "03",
        },
        {
          id: 4,
          title: "ReactJS Integration & Upgradation",
          description:
            "Stay ahead of the curve with seamless integrations and upgrades. Our experienced ReactJS developers specialize in integrating and upgrading existing applications with ReactJS, ensuring compatibility, security, and optimal performance.",
          number: "04",
        },
        {
          id: 5,
          title: "Enterprise Web Solution",
          description:
            "Empower your enterprise with scalable and technically sound solutions. Hire our dedicated ReactJS developers to build economically viable and technically scalable enterprise solutions, designed to meet the demands of a growing business environment.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Streamlined ReactJS Development Process",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "We embark on a journey of exploration, delving deep into your needs and aspirations. Through thorough research and analysis, we uncover insights to shape a roadmap that aligns perfectly with your vision.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "Creativity knows no bounds as we design solutions that push the boundaries. Our design philosophy is to blend creativity with usability, crafting interfaces that are both innovative and intuitive for your end-users.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            " Harnessing modern technologies and cutting-edge tools, we bring your ideas to life. Our development process focuses on building customized solutions that are not only reliable and scalable but also of the highest quality, ensuring your success in the digital realm.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            " Excellence is our hallmark as we follow an iterative approach to our work. With a commitment to continuous improvement, we strive to deliver excellence with every project, ensuring your satisfaction and success.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "What experience do your React.js developers have in building single-page applications (SPAs) and reusable UI components?",
          subtitle:
            "Our React.js developers have extensive experience in developing SPAs and reusable UI components using React.js and related libraries such as Redux, React Router, and Material-UI. They specialize in architecting scalable and maintainable front-end solutions that deliver exceptional user experiences.",
        },
        {
          id: 2,
          title:
            "Can you describe a complex React.js project your developers have successfully delivered?",
          subtitle:
            "Our React.js developers have successfully delivered various complex projects, including building interactive dashboards, e-commerce platforms, and real-time data visualization applications. They excel in leveraging React.js's component-based architecture, state management, and virtual DOM to create performant and responsive applications.",
        },
        {
          id: 3,
          title:
            "How do your React.js developers handle state management in large-scale applications?",
          subtitle:
            "Our React.js developers employ state management solutions such as Redux, MobX, or React Context API to manage complex application states in large-scale applications. They follow best practices for state normalization, separation of concerns, and asynchronous data fetching to ensure maintainability and scalability.",
        },
        {
          id: 4,
          title:
            "What strategies do your React.js developers employ for optimizing performance in React applications?",
          subtitle:
            "Our React.js developers utilize various performance optimization techniques, including code splitting, lazy loading, memoization, virtualization, and server-side rendering (SSR) to improve React application performance and reduce time-to-interactivity. They also leverage performance monitoring tools to identify and address performance bottlenecks.",
        },
        {
          id: 5,
          title:
            "How do your React.js developers handle client-side routing and navigation in React applications?",
          subtitle:
            "Our React.js developers use React Router for client-side routing and navigation in React applications. They design efficient route configurations, implement nested routes, and utilize route guards and navigation hooks to manage navigation flows and ensure a smooth user experience across different views and components.",
        },
        {
          id: 6,
          title:
            "What testing methodologies and tools do your React.js developers use to ensure the quality of React applications?",
          subtitle:
            "Our React.js developers follow a comprehensive testing approach, including unit testing, integration testing, and end-to-end testing, to ensure the quality and reliability of React applications. They utilize testing frameworks like Jest, React Testing Library, and Cypress for automated testing and conduct manual testing to validate UI/UX and functionality.",
        },
        {
          id: 7,
          title:
            "How do your React.js developers stay updated with the latest React.js releases, libraries, and best practices?",
          subtitle:
            "Our React.js developers are passionate about continuous learning and professional development. They stay updated with the latest React.js releases, libraries, and best practices through attending conferences, workshops, online courses, and participating in online communities and forums. They also actively contribute to open-source projects and experiment with new tools and libraries to enhance their skills and expertise in React.js development.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-ReactJS-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireReactJSDeveloper;
