import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "./Progress.scss";
const Progress = ({ now, text, label }) => {
    return (
        <div className="progress-container">
            <div className="top-div">
                <h2 className="progress-title">{text}</h2>
                <label className="progress-title">{label}</label>
            </div>
            <ProgressBar
                completed={now}
                maxCompleted={100}
                bgColor="#18306D"
                borderRadius="100px"
                labelColor="#18306D"
                height="24px"
                barContainerClassName="div-border"
                baseBgColor="#ffffff"
            />
        </div>
    );
};

export default Progress;
