import React from "react";
import "./HireLaravelDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireLaravelDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Elevate Your Web Development Projects with Expert Laravel Developers",
      subTitle:
        "Experience exceptional web solutions with our seasoned Laravel developers. Collaborate with us to leverage the expertise of our Laravel professionals and elevate your digital projects. Whether it's crafting robust backend systems, seamless integrations, or visually stunning designs, our Laravel developers are ready to deliver excellence.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Unlock Outstanding Laravel Solutions with Us",
      title: "Engage Our Dedicated Developers",
      text: "Welcome to our platform, your ultimate destination for top-tier Laravel development services. As a distinguished Laravel development entity, we provide access to a talented pool of dedicated Laravel developers meticulously selected for their qualifications, experience, and mastery in web development principles.Our Laravel developers excel in creating scalable and secure solutions tailored precisely to your project requisites. Whether you require part-time assistance, full-time collaboration, or specialized ad-hoc support, our adaptable team seamlessly integrates into your workflow, ensuring smooth collaboration and superior outcomes.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire Laravel Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our Laravel Developers",
      subTitle: "Unlocking the Potential Expertise of Our Laravel Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Backend Development with Laravel",
          icon: icons.learningIcon,
          benefitdescription:
            "Partner with us to harness the expertise of our Laravel developers in building robust backend systems that power your web applications. Our developers possess the requisite skills and experience to deliver top-notch quality in Laravel development, tailored to your specific requirements.",
        },
        {
          id: 2,
          benefitTitle: "Seamless Integration & Enhancement",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Stay ahead of the curve with seamless integration and enhancement of your existing web applications. Our experienced Laravel developers specialize in integrating and enhancing backend systems, ensuring scalability, usability, and optimal performance.",
        },
        {
          id: 3,
          benefitTitle: "Laravel Maintenance & Support",
          icon: icons.holidayIcon,
          benefitdescription:
            "Ensure the continued success of your Laravel solutions with our comprehensive maintenance and support services. Our dedicated Laravel developers are committed to providing hassle-free maintenance and support, ensuring smooth operation and uninterrupted business continuity.",
        },
        {
          id: 4,
          benefitTitle: "Custom API Development",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Leverage the expertise of our Laravel developers to create custom APIs that facilitate smooth communication between your web applications and external systems. Whether it's RESTful APIs or GraphQL endpoints, our team ensures secure and efficient data exchange.",
        },
        {
          id: 5,
          benefitTitle: "Performance Optimization",
          icon: icons.salaryIcon,
          benefitdescription:
            "Maximize the performance of your Laravel applications with our developers' proficiency in performance optimization techniques. From database query optimization to code caching and beyond, we fine-tune every aspect to deliver fast and responsive user experiences.",
        },
        {
          id: 6,
          benefitTitle: "Security Implementation",
          icon: icons.environmentIcon,
          benefitdescription:
            "Trust our Laravel developers to prioritize security at every stage of development. With in-depth knowledge of Laravel's security features and best practices, we implement robust security measures to safeguard your application against threats like SQL injection, XSS attacks, and more.",
        },
        {
          id: 7,
          benefitTitle: "Migration Services",
          icon: icons.professionalIcon,
          benefitdescription:
            "Seamlessly migrate your existing applications to Laravel with our expert migration services. Our developers ensure a smooth transition, minimizing downtime and preserving data integrity while leveraging Laravel's advanced features to enhance your application's capabilities.",
        },
        {
          id: 8,
          benefitTitle: "Continuous Improvement",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Partner with us for ongoing enhancements and optimizations to keep your Laravel applications competitive and up-to-date. Our developers stay abreast of the latest Laravel releases and industry trends, proactively implementing improvements to meet evolving business needs.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of Laravel Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our Laravel developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "Custom Module Development",
          description:
            "Our Laravel developers excel in creating custom modules tailored to your specific business needs. Whether it's user authentication, payment processing, or content management, we design and implement modular solutions that seamlessly integrate with your existing Laravel application.",
          number: "01",
        },
        {
          id: 2,
          title: "E-commerce Development",
          description:
            "Leverage the power of Laravel for building robust and scalable e-commerce platforms. Our developers specialize in crafting feature-rich online stores with Laravel, incorporating essential functionalities such as product catalog management, shopping cart systems, secure payment gateways, and order processing.",
          number: "02",
        },
        {
          id: 3,
          title: "API Development and Integration",
          description:
            "Trust our Laravel experts to design and develop RESTful APIs or GraphQL endpoints to facilitate communication between different components of your application ecosystem. We ensure seamless integration with third-party services, mobile applications, and other systems, enabling efficient data exchange and interoperability.",
          number: "03",
        },
        {
          id: 4,
          title: "Real-time Application Development",
          description:
            "Harness the capabilities of Laravel for building real-time web applications with features like live chat, notifications, collaborative editing, and more. Our developers utilize technologies such as WebSockets and event broadcasting to create interactive and engaging user experiences in real-time.",
          number: "04",
        },
        {
          id: 5,
          title: "Performance Auditing and Optimization",
          description:
            "Improve the speed and efficiency of your Laravel applications with our performance auditing and optimization services. Our developers conduct thorough performance audits to identify bottlenecks and areas for improvement, implementing optimizations such as code refactoring, database indexing, and server configuration tuning to enhance overall performance.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for Laravel Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Embark on a journey of discovery as we delve into your business objectives and technical requirements. Through in-depth analysis and planning, we gain valuable insights to inform the development process.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            " Unleash creativity as we craft robust backend solutions with Laravel. Our development philosophy blends functionality with scalability, ensuring reliable performance and seamless integration with frontend interfaces.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Harnessing the power of Laravel, we bring your vision to life. Our development process focuses on creating scalable and secure backend systems that meet the highest standards of quality and reliability.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "Excellence is our standard as we follow an iterative approach to development. With a commitment to continuous improvement, we strive to deliver Laravel solutions that exceed expectations and drive business success.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "How do you prioritize user security and privacy in your web development projects?",
          subtitle:
            "Security is a top priority; we implement robust encryption and adhere to best practices for data privacy.",
        },
        {
          id: 2,
          title:
            "How do you prioritize user security and privacy in your web development projects?",
          subtitle:
            "Security is a top priority; we implement robust encryption and adhere to best practices for data privacy.",
        },
        {
          id: 3,
          title:
            "Can you provide examples of successful collaborations with clients in the finance sector?",
          subtitle:
            "Security is a top priority; we implement robust encryption and adhere to best practices for data privacy.",
        },
        {
          id: 4,
          title:
            "How does your team approach the customization of IT solutions for specific industries?",
          subtitle:
            "Security is a top priority; we implement robust encryption and adhere to best practices for data privacy.",
        },
        {
          id: 5,
          title:
            "Can you share insights into the technologies and frameworks utilized for recent e-commerce projects?",
          subtitle:
            "Security is a top priority; we implement robust encryption and adhere to best practices for data privacy.",
        },
        {
          id: 6,
          title:
            "How does your team adapt to changing technology trends in the ever-evolving IT landscape?",
          subtitle:
            "Security is a top priority; we implement robust encryption and adhere to best practices for data privacy.",
        },
        {
          id: 7,
          title:
            "Can you elaborate on your team's agile project management approach and its benefits?",
          subtitle:
            "Security is a top priority; we implement robust encryption and adhere to best practices for data privacy.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-Laravel-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireLaravelDeveloper;
