import "./Healthcare.scss";
import HealthcareFeatures from "./HealthcareFeatures";

import HealthcareHeroSection from "./HealthcareHeroSection";
import "@flaticon/flaticon-uicons/css/all/all.css";
import HealthcareTestimonial from "./HealthcareTestimonial";
import HealthcarePlayer from "./HealthcarePlayer";
import ContactUs from "components/ContactUs";
import { useEffect } from "react";
import HealthCard from "./HealthCard";
const Healthcare = () => {
    return (
        <div className="healthcare-container">
            <HealthcareHeroSection />
            <HealthCard />
            <HealthcareFeatures />
            {/* <HealthcareTestimonial /> */}
            <HealthcarePlayer />
            <ContactUs />
        </div>
    );
};

export default Healthcare;
