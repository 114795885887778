import "bootstrap/dist/css/bootstrap.min.css";
import icons from "utils/constants/icons";
import "./OurMission.scss";

function OurMission() {
    return (
        <section className="section-line">
            <div className="container">
                <h4 className="title">OUR MISSION & VISION</h4>
                <h3 className="sub-title mb-1">
                    Empowering Progress, Shaping Futures Together.
                </h3>
                <div className="mission-perent">
                    <div className="mission-part d-flex">
                        <div className="mission-content">
                            <div className="mission-heading">
                                <img
                                    src={icons.MissionIcon}
                                    alt="Mission-Icon"
                                    className="mission-icon"
                                />
                                <p className="mission-well-text mb-0">
                                    Welcome to Sourrcecube Technologies,
                                </p>
                            </div>
                            <div className="mission-text-pera">
                                <p className="mission-text">
                                    We firmly believe that progress is born out
                                    of pushing boundaries, and that's precisely
                                    what we are here to do – push, pioneer, and
                                    propel. Our mission is to be at the
                                    forefront of transformative change, creating
                                    an impact that resonates across industries.
                                </p>

                                <p className="mission-text">
                                    To each one of you who has joined us on this
                                    remarkable journey, I extend my heartfelt
                                    gratitude. Together, we are not merely
                                    building a business; we are crafting a
                                    legacy of innovation and impact that goes
                                    beyond the ordinary.
                                </p>

                                <p className="mission-text">
                                    Thank you for your trust, your
                                    collaboration, and your shared commitment to
                                    excellence. Here's to pushing the boundaries
                                    of what's possible.
                                </p>

                                <p className="mission-text mb-md-0">
                                    Warm regards,
                                </p>
                                <p className="mission-text mb-md-0">
                                    N.P Gupta
                                </p>
                            </div>
                            <div className="mission-ceo-name">
                                <h3>CEO Founder,</h3>
                                <p>Sourrcecube Technologies Pvt Ltd</p>
                            </div>
                        </div>
                        <div className="mission-img">
                            <img src={icons.MissionBorder} alt="" />
                            <div className="mission-ceo-bg">
                                <img
                                    src={icons.MissionCeo}
                                    alt="Mission CEO"
                                    className="mission-ceo"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurMission;
