import NumberCounter from "number-counter";
import Carousel from "react-bootstrap/Carousel";
import ContactBtns from "components/ContactBtns";
import Technologies from "./Technologies";
import TechnologieAd from "./TechnologieAd";
import Testimonials from "./Testimonials";
import Service from "./Service";
import Portfolio from "./Portfolio";
import ContactUs from "components/ContactUs";
import icons from "utils/constants/icons";
import { useDispatch } from "react-redux";
import { setIsOpen } from "store/globalSlice";
import { Helmet } from "react-helmet";
import "./Home.scss";
// import ClientSlider from "components/ClientSlider";

function Home() {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setIsOpen(true));
  };

  return (
    <>
      <Helmet>
        <title>
          Agile Software development company USA | Top 2% developers
        </title>
        <meta
          name="description"
          content="INDIA-based software development company. 3264+ Successful projects 75+ IT professionals  Your Trusted Partner for Custom Web & Mobile application development"
        />
        <meta
          property="og:description"
          content="INDIA-based software development company. 3264+ Successful projects 75+ IT professionals  Your Trusted Partner for Custom Web & Mobile application development"
        />
        <meta property="og:url" content="https://www.sourcecubeindia.com/" />
        <meta property="og:site_name" content="Sourrcecube Techtechnologies" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Home" />

        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link rel="canonical" href="https:www.sourcecubeindia.com/" />
      </Helmet>

      <div className="container-fluid px-0 container-main hero-section">
        <div className="row gx-0 hero-child">
          <div className="col-xxl-5 col-xl-6">
            <div className="mac-pera">
              <img
                src={icons.MacbookImg}
                alt="Macbook"
                className="macbookimg"
              />
              <div className="mac-carousel">
                <Carousel fade touch={false} pause={false}>
                  <Carousel.Item>
                    <img src={icons.heroBanner1} alt="hero-banner-1" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={icons.heroBanner2} alt="hero-banner-2" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={icons.heroBanner3} alt="hero-banner-3" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={icons.heroBanner4} alt="hero-banner-4" />
                  </Carousel.Item>

                  {/* <Carousel.Item>
                    <h3 className="mac-font">FOOD</h3>
                    <div className="mac-shadow phn-shadow"></div>
                    <img src={icons.MacImg1} alt="Ring" />
                  </Carousel.Item> */}
                </Carousel>
              </div>

              <div className="iphone-slider">
                <div className="iphone-slider-main">
                  <img
                    src={icons.IphoneImg}
                    alt="Iphone"
                    className="iphone-img"
                  />
                  <div className="iphone-imgs">
                    <Carousel fade touch={false} pause={false}>
                      <Carousel.Item>
                        <img src={icons.heroBannerM1} alt="hero-banner-m-1" />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img src={icons.heroBannerM2} alt="hero-banner-m-2" />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img src={icons.heroBannerM3} alt="hero-banner-m-3" />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img src={icons.heroBannerM4} alt="hero-banner-m-4" />
                      </Carousel.Item>
                      {/* <Carousel.Item>
                        <h3 className="iphone-font">FOOD</h3>
                        <div className="phn-shadow"></div>
                        <img src={icons.PhiImg1} alt="Ring" />
                      </Carousel.Item> */}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-7 col-xl-6 hero-right-part">
            <div className="hero-content sub-container">
              <div className="hero-design-imgs">
                <img
                  src={icons.GetRingArrow}
                  className="get-ring-arrow"
                  alt="Ring Arrow"
                />
                <button className="get-start-ring" onClick={handleClick}>
                  <div className="get-start-bg">
                    <img
                      src={icons.GetStartRing}
                      className="get-start-ring-img"
                      alt="Ring"
                    />
                    <img
                      src={icons.GetRingText}
                      className="get-ring-text"
                      alt="Ring Text"
                    />
                  </div>
                </button>
              </div>
              <h1 className="hero-heading">
                Empowering <br className="hero-phn-br" /> Business With{" "}
                <br className="hero-lap-br" />{" "}
                <span className="heading-first-span">
                  <span className="heading-last-span">
                    Technology Innovations
                  </span>
                </span>{" "}
                That Shape The Future
              </h1>
              <p className="hero-text">
                Empowering businesses through innovative technology solutions,
                where possibilities are limitless, and success is the only
                destination.
              </p>
              <div className="hero-counter">
                <div className="total-count ps-sm-0">
                  <p>Successful Projects</p>
                  <div className="d-flex">
                    <NumberCounter end={3264} delay={1} />
                    <span>+</span>
                  </div>
                </div>
                <div className="total-count">
                  <p>IT professionals</p>
                  <div className="d-flex">
                    <NumberCounter end={75} delay={1} />
                    <span>+</span>
                  </div>
                </div>
                <div className="total-count">
                  <p>Countries Served</p>
                  <div className="d-flex">
                    <NumberCounter end={13} delay={1} />
                    <span>+</span>
                  </div>
                </div>
                <div className="total-count last-count">
                  <p>years of experience</p>
                  <div className="d-flex">
                    <NumberCounter end={5} delay={1} />
                    <span>+</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ClientSlider /> */}
      <Service />
      <Portfolio />
      <div className="home-technologies">
        <Technologies />
      </div>
      <TechnologieAd />
      <Testimonials />
      <ContactUs />
      <ContactBtns />
    </>
  );
}

export default Home;
