import icons from "utils/constants/icons";
import "./Discover.scss"
const Discover =() =>{
    return(
        <div className="container-fluid Discovermain-container">
        <div className="container Discover-container">
          <div className="row text-center  mb-5 justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 mx-auto ">
              <div className="Discover-header">
              <h1 className="display-4 mb-3 text-center  mx-auto">
              Discover the Features of Our 
              Innovative Mobile App
              </h1>
              <p className="lead text-muted">
              Our real estate app is designed to be the ultimate tool for both clients and professionals, offering a seamless
              and dynamic experience from the palm of your hand. Here’s what makes our app an essential part of your real estate toolkit
              </p>
              </div>
            </div>
            <div className='col-lg-12  Discover-header d-flex '>
              <img src={icons.Discoverimg} alt="Background Illustration" className="img-fluid img" />
            </div>
          </div>
  
        </div>
      </div>
    )
}
export default Discover;