import React from "react";
import { Spinner } from "react-bootstrap";
import "./ExploreBtn.scss";

const ExploreBtn = ({ title, className, disabled, isLoading, onClick }) => {
  return (
    <button
      type="button"
      className={className ? className : "btn btn-explore-white"}
      disabled={disabled}
      onClick={onClick}
    >
      {title}
      {isLoading && <Spinner size="sm" className="ms-2" />}
    </button>
  );
};

export default ExploreBtn;
