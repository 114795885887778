import icons from "utils/constants/icons";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { sendEmail, throwSuccess, setIsOpen } from "store/globalSlice";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import { useRef } from "react";
import "./Footer.scss";

const Footer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef();
    const dispatch = useDispatch();
    const initialValues = {
        email: "",
    };
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Email must be a valid email")
            .required("Email is required."),
    });
    const handleSave = async (values) => {
        setIsLoading(true);
        const response = await dispatch(sendEmail(values));
        if (response?.status === 200) {
            dispatch(throwSuccess("Mail send successfully"));
            if (formRef.current) {
                formRef.current.resetForm();
            }
        }
        setIsLoading(false);
    };

    const dispatch2 = useDispatch();
    const handleClick = () => {
        dispatch2(setIsOpen(true));
    };
    return (
        <section className="section-line">
            <div className="container footer-container">
                <div className="footer-details">
                    <div className="org-detail">
                        <div className="logo">
                            <img src={icons.logo} alt="logo" />
                        </div>
                        <NavLink
                            className="location-text"
                            to="https://maps.app.goo.gl/tRdZEs8Zvt9jSwxr8"
                            target="_blank">
                            Get Location
                        </NavLink>
                        <NavLink
                            to="tel:7862091636"
                            className="d-flex text-decoration-none gap-2 mobile">
                            <div>
                                <img src={icons.india} alt="india" />
                            </div>
                            +91 97271 71113
                        </NavLink>
                        <div className="address">
                            322-329, Atlanta Shopping, Althan Bhimrad Road,Near
                            Metro, Vesu, Surat.
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSave}
                            innerRef={formRef}>
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    handleChange,
                                    handleSubmit,
                                } = props;
                                const { email } = values;
                                return (
                                    <form>
                                        <div className="contact-email-container">
                                            <input
                                                type="text"
                                                className="email_input"
                                                placeholder="Enter Your Email..."
                                                id="email"
                                                value={email}
                                                onChange={handleChange}
                                                error={errors?.email}
                                            />

                                            <div
                                                className="arrow-right"
                                                onClick={handleSubmit}>
                                                <div>
                                                    <img
                                                        src={
                                                            icons.arrowRightIcon
                                                        }
                                                        alt="right"
                                                        className="icon-right-block"
                                                    />
                                                    {isLoading && (
                                                        <Spinner
                                                            animation="border"
                                                            size="sm"
                                                            className="ms-2 text-light"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                    <div className="service">
                        <div className="footer-section-title">Services</div>
                        <div className="footer-sub-title">Web Development</div>
                        <div className="footer-sub-title">
                            Mobile App Solutions
                        </div>
                        <div className="footer-sub-title">
                            JS / Cloud Solutions
                        </div>
                        <div className="footer-sub-title">
                            E-commerce Solutions
                        </div>
                        <div className="footer-sub-title">Devops Solutions</div>
                    </div>
                    <div className="company">
                        <div className="footer-section-title">Company</div>
                        <NavLink to="/about" className="footer-sub-title">
                            About Us
                        </NavLink>
                        <NavLink to="/career" className="footer-sub-title">
                            Careers
                        </NavLink>
                    </div>
                    <div className="support">
                        <div className="footer-section-title">Support</div>
                        <NavLink
                            to="/termsOfService"
                            className="footer-sub-title">
                            Terms of Service
                        </NavLink>
                        <NavLink
                            to="/privacyPolicy"
                            className="footer-sub-title">
                            Privacy Policy
                        </NavLink>
                        <a href="#contact-us" className="footer-sub-title">
                            Contact Us
                        </a>
                        <div className="footer-sub-title" onClick={handleClick}>
                            Join Us
                        </div>
                    </div>
                </div>
            </div>
            <div className="social-media-section">
                <div className="container">
                    <div className="copy-right-section">
                        Copyright © 2023 Sourrcecube Technologies PVT LTD | All
                        rights reserved.
                    </div>
                    <div className="d-flex gap-4 footer-social-icon">
                        <NavLink
                            to="https://www.facebook.com/sourcecubetechnologies/"
                            className="pointer"
                            target="_blank">
                            <img src={icons.facebookIcon} alt="facebook" />
                        </NavLink>
                        <NavLink
                            to="https://www.instagram.com/sourrcecubetechnologiespvt.ltd/"
                            className="pointer"
                            target="_blank">
                            <img src={icons.instagramIcon} alt="instagram" />
                        </NavLink>
                        <NavLink
                            to="https://in.linkedin.com/company/sourcecube-technologies"
                            className="pointer"
                            target="_blank">
                            <img src={icons.linkedInIcon} alt="linkedin" />
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
