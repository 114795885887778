import React from "react";
import "./HireFrontendDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireFrontendDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Empower Your Digital Initiatives with Skilled Frontend developer",
      subTitle:
        "Elevate your digital projects with skilled frontend developers from our esteemed development team. Our frontend development company harnesses top talents and cutting-edge technologies such as React JS, Angular, Vue JS, JQuery, EmberJs, and more to craft tailored solutions for diverse business needs. With our dedicated frontend developers, expect quick, robust, and agile digital solutions that excel in performance.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Frontend Development Company for Reliable Digital Solutions",
      title: "Engage Our Dedicated Developers",
      text: "Partner with our leading frontend development company to access dedicated developers who excel in building user-friendly web and mobile applications. Our experienced team has successfully delivered numerous digital apps tailored to various market verticals.We ensure to provide you with digital solutions that are unique and can captivate enormous users with the apps stylish and responsive designs. Our dedicated frontend developers excel at creating single-page, cross-platform, and custom apps that are intuitive, engaging, and extremely user-friendly.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire Frontend Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our Frontend Developers",
      subTitle: "Unlocking the Potential Expertise of Our Frontend Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Front End Design & Architecture",
          icon: icons.learningIcon,
          benefitdescription:
            "Bring your project idea to life with our dedicated frontend developers who incorporate the best architectural designs to ensure the smooth functioning of your application.",
        },
        {
          id: 2,
          benefitTitle: "Front End User Interface Development",
          icon: icons.motovationalIcon,
          benefitdescription:
            "We develop interfaces that are highly intuitive and responsive, simplifying the user search process and providing the best results.",
        },
        {
          id: 3,
          benefitTitle: "Custom Front End Development",
          icon: icons.holidayIcon,
          benefitdescription:
            "Our top-notch frontend development services cater to digital solutions that require complex technical understanding and programming experience.",
        },
        {
          id: 4,
          benefitTitle: "Front End Development for Web Apps and Portals",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Our frontend developers excel in building highly efficient and functional frontend web apps and portals leveraging cutting-edge technologies and advanced frameworks.",
        },
        {
          id: 5,
          benefitTitle: "Single Page Front End App Development",
          icon: icons.salaryIcon,
          benefitdescription:
            "Build single-page frontend applications that offer improved stability, dynamic loading, and higher speed performance with our frontend development services.",
        },
        {
          id: 6,
          benefitTitle: "Front End Development for Cross-platform Apps",
          icon: icons.environmentIcon,
          benefitdescription:
            "Our frontend development company and its coders are dedicated to building apps that offer native experiences to users across multiple platforms such as Android and iOS.",
        },
        {
          id: 7,
          benefitTitle: "Front End Performance Optimization",
          icon: icons.professionalIcon,
          benefitdescription:
            "Our frontend developers specialize in optimizing the performance of your web applications, ensuring faster loading times, smoother interactions, and enhanced user experience.",
        },
        {
          id: 8,
          benefitTitle: "Responsive Web Design Implementation",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Our frontend developers are proficient in implementing responsive web design principles to ensure that your applications are accessible and user-friendly across various devices and screen sizes.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Exceptional Front-End Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our Frontend developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "Front End Accessibility Enhancement",
          description:
            "Our front-end developers prioritize accessibility to ensure that your applications are usable by individuals with disabilities. We implement accessibility best practices and standards, such as WCAG compliance, keyboard navigation, and screen reader compatibility, to make your interfaces accessible to all users, fostering inclusivity and compliance with regulatory requirements.",
          number: "01",
        },
        {
          id: 2,
          title: "Front End Testing and Quality Assurance",
          description:
            "We conduct rigorous testing and quality assurance processes to ensure the reliability, performance, and security of your front-end applications. Our skilled QA engineers perform comprehensive testing, including functional testing, usability testing, compatibility testing, and security testing, to identify and address any issues or defects, ensuring the delivery of high-quality and error-free applications.",
          number: "02",
        },
        {
          id: 3,
          title: "Progressive Web App (PWA) Development",
          description:
            "Our front-end developers specialize in building Progressive Web Applications (PWAs) that combine the best features of web and mobile applications. We leverage modern web technologies, such as service workers and web app manifests, to create PWAs that offer offline capabilities, fast loading times, and app-like experiences, enabling seamless engagement and user retention.",
          number: "03",
        },
        {
          id: 4,
          title: "Front End Animation and Interaction Design",
          description:
            "Enhance user engagement and delight with captivating animations and interactive elements crafted by our front-end developers. We leverage CSS animations, JavaScript libraries, and modern web animation techniques to create visually stunning and engaging interfaces that captivate users' attention, elevate brand perception, and improve user satisfaction",
          number: "04",
        },
        {
          id: 5,
          title: "Front End Performance Monitoring and Optimization",
          description:
            "Our front-end developers continuously monitor and optimize the performance of your applications to ensure fast loading times, smooth interactions, and optimal user experience. We utilize performance monitoring tools, such as Lighthouse and Google PageSpeed Insights, to identify performance bottlenecks and implement optimizations, such as code splitting, bundle optimization, and resource caching, to enhance performance and usability.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for Frontend Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Uncover and shape brands that stand the test of time. We conduct extensive research and analysis to provide a roadmap for your solution that ensures success.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "We excel in creating innovative designs that are both creative and user-friendly. Our design style is imaginative, intricate, yet intuitive for end-users.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Utilizing modern technologies and advanced tools, we construct customized solutions that are not only reliable and scalable but also of the highest quality.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "We uphold excellence by employing an iterative approach to our work. Continuously striving for improvement, we ensure the delivery of exceptional results with every project.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "What experience do your frontend developers have with modern web development technologies and frameworks?",
          subtitle:
            "Our frontend developers possess extensive experience with a wide range of modern web development technologies and frameworks, including HTML5, CSS3, JavaScript, TypeScript, React.js, Vue.js, and Angular. They stay updated with the latest trends and best practices in frontend development to deliver high-quality and innovative solutions.",
        },
        {
          id: 2,
          title:
            "Can you describe a challenging frontend project your developers have successfully completed?",
          subtitle:
            "Our frontend developers have successfully completed various challenging projects, such as building interactive web applications, dynamic single-page applications (SPAs), and responsive websites for clients across diverse industries. They excel in tackling complex UI/UX challenges, optimizing performance, and ensuring cross-browser compatibility.",
        },
        {
          id: 3,
          title:
            "How do your frontend developers ensure accessibility and usability in web applications?",
          subtitle:
            "Our frontend developers prioritize accessibility and usability in web application development by adhering to WCAG (Web Content Accessibility Guidelines) standards, implementing keyboard navigation, semantic HTML, ARIA roles, and conducting usability testing to ensure an inclusive and user-friendly experience for all users.",
        },
        {
          id: 4,
          title:
            "What strategies do your frontend developers employ for responsive web design and cross-browser compatibility?",
          subtitle:
            "Our frontend developers utilize responsive design principles, including flexible layouts, fluid grids, and media queries, to ensure optimal viewing experiences across devices and screen sizes. They also perform extensive testing and utilize CSS pre-processors and browser compatibility tools to ensure consistent rendering across different browsers and devices.",
        },
        {
          id: 5,
          title:
            "How do your frontend developers approach performance optimization in web applications?",
          subtitle:
            "Our frontend developers employ various performance optimization techniques, including code splitting, lazy loading, image optimization, minification, and caching strategies, to improve page load times and enhance the overall performance of web applications. They utilize performance profiling tools and browser developer tools to identify and address performance bottlenecks.",
        },
        {
          id: 6,
          title:
            "What testing methodologies and tools do your frontend developers use to ensure the quality of web applications?",
          subtitle:
            "Our frontend developers follow a comprehensive testing approach, including unit testing, integration testing, end-to-end testing, and UI testing, to ensure the quality and reliability of web applications. They utilize testing frameworks like Jest, Enzyme, Cypress, and Selenium for automated testing and conduct manual testing to validate UI/UX and functionality.",
        },
        {
          id: 7,
          title:
            "How do your frontend developers stay updated with emerging frontend technologies and design trends?",
          subtitle:
            "Our frontend developers are passionate about continuous learning and professional development. They stay updated with emerging frontend technologies, design trends, and best practices through attending conferences, workshops, online courses, and participating in online communities and forums. They also actively contribute to open-source projects and experiment with new tools and frameworks to enhance their skills and expertise.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-Frontend-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireFrontendDeveloper;
