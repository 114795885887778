import React from "react";
import "./TermsOfService.scss";
import ContactUs from "components/ContactUs";
import { Helmet } from "react-helmet";

const TermsOfService = () => {
    return (
        <>
            <Helmet>
                <title>Terms of Service - Sourrcecube Technologies</title>
                <meta
                    name="description"
                    content="Review the Terms of Service for Sourrcecube Technologies. These terms outline the rules, responsibilities, and guidelines governing the use of our website and services. By accessing our site, you agree to abide by these terms."
                />
                <meta
                    property="og:title"
                    content="Terms of Service - Sourrcecube Technologies"
                />
                <meta
                    property="og:description"
                    content="Review the Terms of Service for Sourrcecube Technologies. These terms outline the rules, responsibilities, and guidelines governing the use of our website and services. By accessing our site, you agree to abide by these terms."
                />
            </Helmet>

            <section className="terms-header">
                <div className="container">
                    <h1>Terms of Services</h1>
                    <p>
                        Here are the following terms of a legal agreement
                        between you and Sourrcecube Techology. Here, we clearly
                        define the aspects of our business relationship with
                        you.
                    </p>
                </div>
            </section>
            <section className="termsof-service">
                <div className="container">
                    <h4>Acceptance of Terms</h4>
                    <p>
                        By accessing and using sourcecubeindia.com (hereinafter
                        referred to as "the website"), you agree to comply with
                        and be bound by the following Terms of Service. If you
                        do not agree to these terms, please refrain from using
                        the website.
                    </p>
                    <h4>Changes to Terms</h4>
                    <p>
                        SourceCube India reserves the right to modify or revise
                        these Terms of Service at any time. Any changes will be
                        effective immediately upon posting on the website.
                        Continued use of the website constitutes acceptance of
                        the modified terms.
                    </p>
                    <h4>User Conduct</h4>
                    <p>
                        You agree to use the website in accordance with all
                        applicable laws and regulations. You shall not engage in
                        any conduct that may hinder the use and enjoyment of the
                        website by others.
                    </p>
                    <h4>Intellectual Property</h4>
                    <p>
                        All content, trademarks, logos, and intellectual
                        property on the website are the property of SourceCube
                        India or its licensors. You may not use, reproduce, or
                        distribute any content from the website without explicit
                        permission.
                    </p>
                    <h4>Privacy Policy</h4>
                    <p>
                        Your use of the website is also governed by our Privacy
                        Policy, which outlines how we collect, use, and protect
                        your personal information. Please review our Privacy
                        Policy [link to privacy policy] for more details.
                    </p>
                    <h4>Limitation of Liability</h4>
                    <p>
                        SourceCube India is not liable for any direct, indirect,
                        incidental, special, or consequential damages arising
                        out of or in any way connected with the use of the
                        website. This includes but is not limited to damages for
                        loss of profits, goodwill, data, or other intangible
                        losses.
                    </p>
                    <h4>Termination of Services</h4>
                    <p>
                        SourceCube India reserves the right to terminate or
                        suspend access to the website for any reason, including
                        violation of these Terms of Service.
                    </p>
                    <h4>Governing Law</h4>
                    <p>
                        These Terms of Service are governed by and constructed
                        in accordance with the laws of Surat Gujarat India . Any
                        disputes arising out of or in connection with these
                        terms shall be subject to the exclusive jurisdiction of
                        the courts in [Your Jurisdiction].
                    </p>
                    <h4>Contact Information</h4>
                    <p>
                        If you have any questions or concerns regarding these
                        Terms of Service, please contact us at
                        info@sourcecubeindia.com.
                    </p>
                </div>
            </section>
            <div className="container"></div>
            <ContactUs />
        </>
    );
};

export default TermsOfService;
