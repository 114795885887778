import Aos from "aos";
import { useEffect } from "react";
import Page3HeroSection from "./Page3HeroSection";
import Page3Features from "./Page3Features";
import Page3Discover from "./Page3Discover";
import Page3Service from "./Page3Service";
import Page3Work from "./Page3Work";
import Page3Screenshot from "./Page3Screenshot";
import DigitizeYourBusiness from "./DigitizeYourBusiness";
import ContactUs from "components/ContactUs";
import "aos/dist/aos.css";
import "./OnDemandApp.scss";

const OnDemandApp = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="on-demand-app-container">
      <Page3HeroSection />
      <Page3Features />
      <Page3Discover />
      <Page3Service />
      <DigitizeYourBusiness />
      <Page3Screenshot />
      <Page3Work />
      <ContactUs />
    </div>
  );
};

export default OnDemandApp;
