import "./Page3Discover.scss";
import discoverImg from "assets/images/businessmodel/page3/discover.png";

const Page3Discover = () => {
  let listData = [
    {
      title:
        "Combined with a handful of model sentence structures looks reasonable.",
    },
    {
      title:
        "Contrary to popular belief, Lorem Ipsum is not simply random text.",
    },
    {
      title:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.",
    },
    {
      title:
        "Natus error sit voluptatem unde omnis iste natus error sit voluptatem accusantium.",
    },
    {
      title:
        "All the Lorem Ipsum generators on the Internet tend to repeat necessary.",
    },
  ];
  return (
    <section className="page3-discover">
      <div className="container">
        <div className="row justify-content-between aline align-items-center">
          <div className="col-12 col-md-6 text-center mx-auto order-2 order-md-1 ">
            <img
              src={discoverImg}
              alt="discover-thumbnail"
              className="pt-5 pt-lg-0 fit-img"
            />
          </div>
          <div className="col-12 col-md-6 text-center order-1 order-md-2">
            <div className="discover-heading text-center pt-lg-0 pt-4 px-0 px-lg-4">
              <h2 className="pb-4 text-start">
                Work faster with powerful tools.
              </h2>
              <ul>
                {listData.length &&
                  listData.map((val, index) => (
                    <li className="py-1" key={index}>
                      <div className="list-box media">
                        <span className="list-icon">
                          <svg
                            className="svg-inline--fa fa-check fa-w-16"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="check"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            ></path>
                          </svg>
                        </span>
                        <span className="list-body">{val.title}</span>
                      </div>
                    </li>
                  ))}
              </ul>

              <div className="icon-box d-flex mt-3">
                <div className="service-icon ">
                  <span>
                    <svg
                      className="svg-inline--fa fa-bell fa-w-14"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="bell"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="service-icon px-3">
                  <span>
                    <svg
                      className="svg-inline--fa fa-envelope-open fa-w-16"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="envelope-open"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M512 464c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V200.724a48 48 0 0 1 18.387-37.776c24.913-19.529 45.501-35.365 164.2-121.511C199.412 29.17 232.797-.347 256 .003c23.198-.354 56.596 29.172 73.413 41.433 118.687 86.137 139.303 101.995 164.2 121.512A48 48 0 0 1 512 200.724V464zm-65.666-196.605c-2.563-3.728-7.7-4.595-11.339-1.907-22.845 16.873-55.462 40.705-105.582 77.079-16.825 12.266-50.21 41.781-73.413 41.43-23.211.344-56.559-29.143-73.413-41.43-50.114-36.37-82.734-60.204-105.582-77.079-3.639-2.688-8.776-1.821-11.339 1.907l-9.072 13.196a7.998 7.998 0 0 0 1.839 10.967c22.887 16.899 55.454 40.69 105.303 76.868 20.274 14.781 56.524 47.813 92.264 47.573 35.724.242 71.961-32.771 92.263-47.573 49.85-36.179 82.418-59.97 105.303-76.868a7.998 7.998 0 0 0 1.839-10.967l-9.071-13.196z"
                      ></path>
                    </svg>
                  </span>
                </div>
                <div className="service-icon">
                  <span>
                    <svg
                      className="svg-inline--fa fa-video fa-w-18"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="video"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Page3Discover;
