import { useDispatch } from 'react-redux';
import { setIsOpen } from '../../../store/globalSlice';
import './TouchLightBtn.scss';

function TouchLightBtn({ title }) {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(setIsOpen(true));
    };
    return (
        <button className="btn btn-light" onClick={handleClick}>
            {title}
        </button>
    );
}

export default TouchLightBtn;
