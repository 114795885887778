import icons from "utils/constants/icons";
import "./Choose.scss"
const Choose =() =>{
  
    return (
        <>
             <div className="container-fluid Choosemain-container">
      <div className="container Choose-container">
        <div className="row text-center  mb-5">
          <div className="col-12 col-md-10 col-lg-8 mx-auto Chooseheader-container">
            <h1 className="display-4 mb-3">
            Why Choose Us?
            </h1>
            <p className="lead text-muted">
              In today's fast-paced real estate market, having a dedicated
              mobile app isn't just a luxury—it's a necessity. A well-designed
              real estate app can transform how you operate, engage with
              clients, and stay ahead of the competition. Here’s why launching a
              real estate app is crucial for your success
            </p>
          </div>
        </div>

        <div className="row text-center  justify-content-center" style={{gap:"24px" ,paddingBottom :"80px"}}>
        {Array.from({ length: 6 }).map((_, index) => (  
  <div
  className="col-xl-3 col-lg-4 col-12"

>
  <div className="Choosecard-container d-flex  align-items-center">
    <div className=" d-flex align-items-center justify-content-center">
    <img
      src={icons.chooseimg}
      alt="simple"
      className="mb-3"
    
    />

  </div>
  
    <div className="">
        <h1 className="card-title">Seamless Management</h1>
    <p className="text-start w-100">Get full control on your business and manage users, staff, services, etc. from a single window</p>
    </div>
  </div>
</div>
          ))}
          
        </div>

     
      </div>
    </div>
        </>
    )
}
export default Choose;