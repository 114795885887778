import FeaturesBox from "./FeaturesBox";
import "./Page3Features.scss";


const Page3Features = () => {
    return (
        <section className="page3-features ">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <div className="section-heading text-center">
                            <h2>Explore Premium Features</h2>
                            <p className="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                            <p className="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <FeaturesBox />
                </div>
            </div>
        </section>
    );
};

export default Page3Features;
