import { useState } from "react";
import "./WorkSection.scss";
import icons from "utils/constants/icons";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaRegCheckCircle } from "react-icons/fa";
const WorkSection = ({}) => {
    const workButton = [
        {
            icon: <FaRegCheckCircle size={30} className="icon" />,
            name: "Login Account",
            description:
                '   <p className="mt-3"> Innovative solutions with the best. Incididunt dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p><p>    Adipiscing elit, sed do eiusmod tempor incididunt ut    labore et dolor tempor incididunt ut labore et    dolore. Innovative solutions with the best.    Incididunt dolor sit amet, consectetur adipiscing     elit, sed do eiusmod tempor incididunt ut labore et     dolor tempor incididunt ut labore et dolore. </p>   labore et dolor tempor incididunt ut labore et     dolore. Innovative solutions with the best.    Incididunt dolor sit amet, consectetur adipiscing    elit, sed do eiusmod tempor incididunt ut labore et    dolor tempor incididunt ut labore et dolore.</p>',
            images: icons?.loginImg,
        },
        {
            icon: <FaRegCheckCircle size={30} className="icon" />,
            name: "Open Setting",
            description:
                '   <p className="mt-3"> Innovative solutions with the best. Incididunt dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p><p>    Adipiscing elit, sed do eiusmod tempor incididunt ut    labore et dolor tempor incididunt ut labore et    dolore. Innovative solutions with the best.    Incididunt dolor sit amet, consectetur adipiscing     elit, sed do eiusmod tempor incididunt ut labore et     dolor tempor incididunt ut labore et dolore. </p>   labore et dolor tempor incididunt ut labore et     dolore. Innovative solutions with the best.    Incididunt dolor sit amet, consectetur adipiscing    elit, sed do eiusmod tempor incididunt ut labore et    dolor tempor incididunt ut labore et dolore.</p>',
            images: icons?.settingImg,
        },
        {
            icon: <FaRegCheckCircle size={30} className="icon" />,
            name: "Start your Chat",
            description:
                '   <p className="mt-3">Innovative solutions with the best. Incididunt dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. </p><p>    Adipiscing elit, sed do eiusmod tempor incididunt ut    labore et dolor tempor incididunt ut labore et    dolore. Innovative solutions with the best.    Incididunt dolor sit amet, consectetur adipiscing     elit, sed do eiusmod tempor incididunt ut labore et     dolor tempor incididunt ut labore et dolore. </p>   labore et dolor tempor incididunt ut labore et     dolore. Innovative solutions with the best.    Incididunt dolor sit amet, consectetur adipiscing    elit, sed do eiusmod tempor incididunt ut labore et    dolor tempor incididunt ut labore et dolore.</p>',
            images: icons?.chatImg,
        },
    ];
    const [activeButton, setActiveButton] = useState(0);

    const handleButtonClick = (index) => {
        setActiveButton(index);
    };
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);
    return (
        <div className=" work-section">
            <div className="container">
                {/* <div className="images-container w-25">
                    <img
                        src={icons.ring1}
                        alt="Work illustration"
                        className="img-fluid ring-img1 w-25"
                    />
                    <img
                        src={icons.ring2}
                        alt="Work illustration"
                        className="img-fluid ring-img2 w-25"
                    />
                </div> */}

                <h4 className="section-title">WORKING PROCESS</h4>
                <h1 className="section-sub-title">How it works?</h1>
                <p className="section-pra">
                    Consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                </p>

                <div className="btn-main-div">
                    {workButton?.map((item, index) => (
                        <>
                            <div
                                key={index}
                                className={`btn-container ${
                                    activeButton === index ? "active" : ""
                                }`}
                                onClick={() => handleButtonClick(index)}>
                                <div className="d-flex align-items-center justify-content-center w-100">
                                    <p>{item.name}</p>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
                <div className="top-div">
                    <div
                        className="row"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        <div className="col-lg-6 col-12 mb-4 mb-lg-0">
                            <div className="sub-section-container">
                                <h1>{workButton[activeButton]?.name}</h1>
                                <p
                                    className="mt-3"
                                    dangerouslySetInnerHTML={{
                                        __html: workButton[activeButton]
                                            ?.description,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="image-div">
                                <img
                                    src={workButton[activeButton]?.images}
                                    alt="Work illustration"
                                    className=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="images-container w-25">
                    <img
                        src={icons.ring1}
                        alt="Work illustration"
                        className="img-fluid ring-img1 w-25"
                    />
                    <img
                        src={icons.ring2}
                        alt="Work illustration"
                        className="img-fluid ring-img2 w-25"
                    />
                </div> */}
            </div>
        </div>
    );
};

export default WorkSection;
