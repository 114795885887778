import icons from "utils/constants/icons";
import "./AdvanceTech.scss";
import React from "react";
import Button from "components/Button";

const AdvanceTech = () => {
    const jobSeekerList = [
        {
            title: "Easy Registration",
            description:
                "Simply register to start looking for the most suitable freelance projects",
            images: icons?.numberOneImg,
        },
        {
            title: "Advance Search",
            description:
                "Opportunity to search & filter from a wide range of job opportunities",
            images: icons?.numberTwoImg,
        },
        {
            title: "Seamless Payment",
            description:
                "Facilitate your job seekers with real time, in-app commission settlement",
            images: icons?.numberThreeImg,
        },
        {
            title: "Real Time Chat",
            description:
                "Chat in real-time with recruiters before heading off with their assignments",
            images: icons?.numberFourImg,
        },
    ];
    const jobProviderList = [
        {
            title: "Recruiter Panel",
            description:
                "Job providers can add a wide number jobs as per their needs",
            images: icons?.numberOneImg,
        },
        {
            title: "Catalog Manager",
            description:
                "Add and update all the details of the project like timeline, pricing, etc.",
            images: icons?.numberTwoImg,
        },
        {
            title: "Manage Availability",
            description:
                "Job providers can set and update their availability & timings",
            images: icons?.numberThreeImg,
        },
        {
            title: "Reporting",
            description:
                "Empower your providers through reports of sales, transactions & more",
            images: icons?.numberFourImg,
        },
    ];
    const adminList = [
        {
            title: "Active Themes",
            description:
                "Choose inviting themes for your marketplace & customize it as per your requirement",
            images: icons?.numberOneImg,
        },
        {
            title: "Seller Management",
            description:
                "Manage your job providers, their payouts, & more with a robust admin panel",
            images: icons?.numberTwoImg,
        },
        {
            title: "Project Categorization",
            description:
                "Get a filtered view of the projects posted by the customers according to your needs",
            images: icons?.numberThreeImg,
        },
        {
            title: "Business Analytics",
            description:
                "Boost Your ROI by understanding your customers’ behaviour & better targeting",
            images: icons?.numberFourImg,
        },
    ];

    return (
        <React.Fragment>
            <section className="advance-tech-container">
                <h4 className="advance-title">Advance Tech Stack </h4>
                <p className="advance-pra">
                    Explore what you get with your unique freelance services
                    marketplace
                </p>

                <div className="container">
                    <div className="row gy-4">
                        <div className="col-md-6">
                            <div className="left-first-div">
                                <img
                                    src={icons?.firstImg}
                                    alt="first-img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 col-md-6">
                            <div className="right-div">
                                <h3 className="card-title">
                                    Job Seeker Website & App
                                </h3>
                                {jobSeekerList?.map((ele, index) => {
                                    const { description, title, images } = ele;
                                    return (
                                        <div
                                            className="right-first-div"
                                            key={images}>
                                            <div className="image-div">
                                                <img
                                                    src={images}
                                                    alt="number-icons"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <div className="text-div">
                                                <h5 className="right-title">
                                                    {title}
                                                </h5>
                                                <p className="right-pra">
                                                    {description}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="job-provider">
                        <div className="row gy-5">
                            <div className="col-lg-5 col-md-6 order-md-0 order-2">
                                <div className="right-div">
                                    <h3 className="card-title">
                                        Job Provider Mode
                                    </h3>
                                    {jobProviderList?.map((ele, index) => {
                                        const { description, title, images } =
                                            ele;
                                        return (
                                            <div
                                                className="right-first-div"
                                                key={index}>
                                                <div className="image-div">
                                                    <img
                                                        src={images}
                                                        alt="number-icons"
                                                        loading="lazy"
                                                    />
                                                </div>
                                                <div className="text-div">
                                                    <h5 className="right-title">
                                                        {title}
                                                    </h5>
                                                    <p className="right-pra">
                                                        {description}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="col-md-6 offset-lg-1 order-md-0 order-1">
                                <div className="left-first-div">
                                    <img
                                        src={icons?.centerImg}
                                        alt="center-img"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-5">
                        <div className="col-lg-7 col-md-6">
                            <div className="left-last-div">
                                <img
                                    src={icons?.lastImg}
                                    alt="first-img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-5  col-md-6">
                            <div className="right-div">
                                <h3 className="card-title">Admin Dashboard</h3>
                                {adminList?.map((ele, index) => {
                                    const { description, title, images } = ele;
                                    return (
                                        <div
                                            className="right-first-div"
                                            key={index}>
                                            <div className="image-div">
                                                <img
                                                    src={images}
                                                    alt="number-icons"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <div className="text-div">
                                                <h5 className="right-title">
                                                    {title}
                                                </h5>
                                                <p className="right-pra">
                                                    {description}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="customize-container">
                <div className="container">
                    <div className="customize-card">
                        <Button
                            btnStyle="btn-black"
                            btnText="Get Started"
                            rightIcon={icons?.rightNavigateIcons}
                        />
                        <div>
                            <p className="customize-sub-title">
                                Do You Know Your Recruiters Can Turn Into
                                Freelancers & Vice-Versa, Anytime?
                            </p>
                            <h3 className="customize-title">
                                We Help You Build A Customized Marketplace Like
                                Upwork
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default AdvanceTech;
