import { useDispatch } from "react-redux";
import { setIsOpen } from "../../../store/globalSlice";
import "./TouchBtn.scss";

function TouchBtn({ title, className }) {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setIsOpen(true));
  };
  return (
    <button
      className={className ? className : "btn btn-primary"}
      onClick={handleClick}>
      {title}
    </button>
  );
}

export default TouchBtn;
