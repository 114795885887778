import ContactUs from "components/ContactUs";
import { Helmet } from "react-helmet";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy - Sourrcecube Technologies</title>
                <meta
                    name="description"
                    content="Explore Sourrcecube Technologies' Privacy Policy to understand how we handle and protect your personal information. Your privacy is our priority, and we are committed to maintaining the highest standards in data security. Learn more about our practices and your rights."
                />
                <meta
                    property="og:title"
                    content="Privacy Policy - Sourrcecube Technologies"
                />
                <meta
                    property="og:description"
                    content="Explore Sourrcecube Technologies' Privacy Policy to understand how we handle and protect your personal information. Your privacy is our priority, and we are committed to maintaining the highest standards in data security. Learn more about our practices and your rights."
                />
            </Helmet>

            <section className="policy-header">
                <div className="container">
                    <h1>Privacy Policy</h1>
                    <p>
                        Creating a privacy policy requires careful consideration
                        of the information your website collects, how it is
                        used, and how it is protected. It's important to note
                        that privacy policies are often subject to legal
                        requirements and may vary based on jurisdiction. Below
                        is a basic template that outlines general points
                        typically included in privacy policies. Consult with
                        legal professionals to customize it to your specific
                        needs and ensure compliance with applicable laws.
                    </p>
                </div>
            </section>
            <section className="privacy-policy">
                <div className="container">
                    <h4 className="title">
                        Privacy Policy for sourrcecubeindia.com
                    </h4>
                    <h4>Information We Collect</h4>
                    <p>
                        We may collect personal information, including but not
                        limited to names, email addresses, and contact details,
                        when voluntarily submitted by users through forms on the
                        website.
                    </p>
                    <h4>How We Use Information</h4>
                    <p>
                        The information collected is used to provide and improve
                        our services, respond to user inquiries, process
                        transactions, and send periodic emails. We may also use
                        non-personal information for statistical analysis.
                    </p>
                    <h4>Protection of Information</h4>
                    <p>
                        We implement a variety of security measures to maintain
                        the safety of your personal information. However, no
                        method of transmission over the internet or electronic
                        storage is 100% secure, and we cannot guarantee its
                        absolute security.
                    </p>
                    <h4>Cookies</h4>
                    <p>
                        We use cookies to enhance user experience and collect
                        information about your preferences. You can choose to
                        disable cookies through your browser settings, but this
                        may impact certain features of the website.
                    </p>
                    <h4>Third-Party Disclosure</h4>
                    <p>
                        We do not sell, trade, or otherwise transfer your
                        personally identifiable information to third parties
                        unless we provide users with advance notice. This does
                        not include website hosting partners and other parties
                        who assist us in operating our website, conducting our
                        business, or serving our users.
                    </p>
                    <h4>Links to Third-Party Websites</h4>
                    <p>
                        Our website may contain links to third-party websites.
                        We have no responsibility for the content, privacy
                        policies, or practices of these third-party sites.
                    </p>
                    <h4>Compliance with Laws</h4>
                    <p>
                        We will disclose your information where required to do
                        so by law or subpoena or if we believe that such action
                        is necessary to comply with the law and the reasonable
                        requests of law enforcement.
                    </p>
                    <h4>Changes to this Privacy Policy</h4>
                    <p>
                        We reserve the right to update or change our privacy
                        policy at any time. Any changes will be effective
                        immediately upon posting on the website.
                    </p>
                    <h4>Your Consent</h4>
                    <p>
                        By using our website, you consent to our privacy policy.
                    </p>
                    <h4>Contact Information</h4>
                    <p>
                        If you have any questions regarding this privacy policy,
                        you may contact us at info@sourcecubeindia.com
                    </p>
                </div>
            </section>
            <ContactUs />
        </>
    );
};

export default PrivacyPolicy;
