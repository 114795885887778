import icons from "utils/constants/icons";
import "./CounterUp.scss";
const CounterUp = ({ data }) => {
    const counterList = [
        {
            image: icons?.downloadIcons,
            title: "2050",
            description: "Total Downloads",
        },
        {
            image: icons?.userIcons,
            title: "50",
            description: "Total User",
        },
        {
            image: icons?.reviewIcons,
            title: "2050",
            description: "Total Reviews",
        },
        {
            image: icons?.awardIcons,
            title: "20",
            description: "Best Award",
        },
    ];

    return (
        <div className="counter-up-main-container">
            <div className="main-container">
                <div className="container">
                    <div className="row gy-3">
                        {counterList.map((item, index) => {
                            const { image, description, title } = item;
                            return (
                                <div
                                    key={index}
                                    className="col-xl-3 col-md-6 col-12">
                                    <div className="card-details">
                                        <img
                                            src={image}
                                            alt="icons"
                                            loading="lazy"
                                        />
                                        <h1>{title}</h1>
                                        <p>{description}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CounterUp;
