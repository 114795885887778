import icons from "utils/constants/icons";
import "./LaunchBus.scss";
const LaunchBus = () => {
  const LaunchBusData = [
    {
      img: icons.LaunchBus1,
      title: "Enhanced Accessibility and Convenience",
      desc: "93% of home buyers start their search online. Launching your app means you’re meeting these buyers where they are, capturing their interest right from their smartphones and tablets.",
    },
    {
      img: icons.LaunchBus2,
      title: "Streamline and Shine",
      desc: "Our app automates tedious tasks and keeps everything organized. Imagine cutting down your administrative workload by 30%, freeing up more time to focus on closing deals and connecting with clients.",
    },
    {
      img: icons.LaunchBus3,
      title: " Engage Clients Effortlessly",
      desc: "Keep clients hooked with real-time updates and virtual tours. 70% of users love apps that offer instant notifications and interactive features, making your service more engaging.",
    },
    {
      img: icons.LaunchBus4,
      title: "Unlock Data Insights",
      desc: "Turn data into your superpower. With powerful analytics, you can make smarter decisions. 80% of successful agents use data insights to refine their strategies, boosting lead conversions.",
    },
    {
      img: icons.LaunchBus5,
      title: "Expand Your Reach",
      desc: "Be where your clients are. 75% of people prefer using real estate services with a convenient app, expanding your reach and attracting new leads with just a tap.",
    },
    {
      img: icons.LaunchBus6,
      title: "Stand Out from the Crowd",
      desc: "In a crowded market, be the standout choice. 65% of real estate firms that embrace innovative tech gain a significant edge, making your business more attractive to potential clients.",
    },
  ];

  return (
    <div className="container-fluid LaunchBusmain-container">
      <div className="container LaunchBus-container">
        <div className="row text-center  mb-5">
          <div className="col-12 col-md-10 col-lg-8 mx-auto Lookheader-container">
            <h1 className="display-4 mb-3">
              Why Launching a Real Estate App is Essential for Your Business
            </h1>
            <p className="lead text-muted">
              In today's fast-paced real estate market, having a dedicated
              mobile app isn't just a luxury—it's a necessity. A well-designed
              real estate app can transform how you operate, engage with
              clients, and stay ahead of the competition. Here’s why launching a
              real estate app is crucial for your success
            </p>
          </div>
        </div>

        <div className="row text-center  justify-content-center" style={{gap:"24px"}}>
          {LaunchBusData.map((item, index) => {
            return (
              <>
                <div
                  className="col-xl-4 col-md-6 col-12 d-flex flex-column justify-content-around align-items-center shadow p-3  bg-white rounded"
                  style={{ maxWidth: "350px" ,minHeight : "316px"}}
                >
                  <div className="LaunchBus-container">
                    <div className="" style={{marginBottom : "24px"}}>
                    <img
                      src={item.img}
                      alt="simple"
                      className="mb-3"
                    
                    />

                  </div>
                  
                    <div className="" style={{minHeight : "180px"}}>
                        <h1 className="card-title">{item.title}</h1>
                    <p className="text-center w-100">{item.desc}</p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>

     
      </div>
    </div>
  );
};
export default LaunchBus;
