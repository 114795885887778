import TouchBtn from "components/common/TouchBtn";
import "./VacancyDetails.scss";
import React, { useEffect, useState } from "react";
import icons from "utils/constants/icons";
// import TextInput from "components/common/TextInput";
import Dropdown from "components/common/Dropdown";
import SearchInput from "components/common/SearchInput";
import { fetchJobs, setIsVacancy, setSingleJobData } from "store/globalSlice";
import { useDispatch } from "react-redux";

const VacancyDetails = () => {
  const dispatch = useDispatch();
  const [vacancies, setVacancies] = useState([]);
  const [vacanciesTitleList, setVacanciesTitleList] = useState([]);
  const [vacanciesTitle, setVacanciesTitle] = useState(null);
  const [searchVacancies, setSearchVacancies] = useState("");

  const getVacancies = async () => {
    const response = await dispatch(fetchJobs());
    if (response?.status === 200) {
      setVacancies(response?.data);
    }
  };

  const handleClick = () => {
    dispatch(setIsVacancy(true));
  };

  const handleChangeJob = (e) => {
    setVacanciesTitle(e ? e?.target?.data?.label : null);
  };

  useEffect(() => {
    getVacancies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vacanciesList = vacancies?.filter((val) => {
    if (!searchVacancies && !vacanciesTitle) return true;

    const searchText = searchVacancies?.toLowerCase();
    const selectedJobType = vacanciesTitle?.toLowerCase();
    const matchesSearchText =
      searchText && val?.title?.toLowerCase()?.includes(searchText);
    const matchesJobType =
      selectedJobType && val?.title?.toLowerCase()?.includes(selectedJobType);

    return matchesSearchText || matchesJobType;
  });

  useEffect(() => {}, [vacanciesTitle, vacanciesTitle]);

  useEffect(() => {
    if (vacancies) {
      const uniqueTitles = new Set();
      const titles = vacancies
        ?.filter((ele) => {
          if (!uniqueTitles.has(ele?.title)) {
            uniqueTitles.add(ele?.title);
            return true;
          }
          return false;
        })
        ?.map((ele) => ({
          id: ele?.id,
          label: ele?.title,
          value: ele?.title,
        }));

      setVacanciesTitleList(titles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancies]);

  return (
    <React.Fragment>
      <div className="container">
        <h4 className="title">VACANCY FOR JOBS</h4>
        <h3 className="sub-title mb-4 pb-2">
          Join a Company on the Rise: Your Gateway to Continuous Growth and
          Opportunities
        </h3>
      </div>
      <section className="vacancy-container">
        <div className="container vacancy-details-box">
          <h2 className="vacancy-details-title">
            Exciting Opportunities Await Fresh Talent!
          </h2>
          <p className="vacancy-details-sub-title">
            Are you a fresh graduate ready to kickstart your career in a dynamic
            and innovative environment? Look no further! We invite you to join
            our team, where your skills and ideas are not just welcomed but
            cherished. Take the next step in your career and be part of a
            journey filled with growth, learning, and endless possibilities.
          </p>
          <TouchBtn title="Get in Touch" />

          <div className="vacancy-details">
            <div>
              <h4 className="vacancy-title">
                {vacancies?.length} Open Positions
              </h4>
              <p className="vacancy-pra">
                Thanks for checking out our job openings. See something that
                interests you ? Apply here
              </p>
            </div>
            <div className="search-div">
              <div className="div-w">
                <SearchInput
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchVacancies(e?.target?.value);
                  }}
                />
              </div>
              <div className="div-w">
                <Dropdown
                  placeholder="Job Type"
                  options={vacanciesTitleList}
                  optionKey="label"
                  optionValue="label"
                  value={vacanciesTitle}
                  onChange={handleChangeJob}
                  isClearable
                />
              </div>
            </div>
          </div>
          {vacanciesList?.length > 0 ? (
            <div className="row mt-5 gy-md-3 gy-2">
              {vacanciesList?.map((ele, index) => {
                return (
                  <div className="col-md-6" key={index}>
                    <div className="vacancy-card">
                      <div className="vacancy-main-div">
                        <div className="vacancy-name-div">
                          <h3 className="job-title">{ele?.title}</h3>
                          <div className="location-div">
                            <img
                              src={icons?.locationIcons}
                              alt="map-icons"
                              className="icon-w-h"
                            />
                            <p className="location-text">
                              Althan ,Surat,Gujarat
                            </p>
                          </div>
                        </div>

                        <button
                          className="btn apply-btn"
                          onClick={() => {
                            dispatch(setSingleJobData(ele));
                            handleClick();
                          }}
                        >
                          Apply
                          <img src={icons.upRightArrow} alt="Call Icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="mt-5 not-found-text">Vacancy Not Found</div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default VacancyDetails;
