import React from "react";
import "./HireBackendDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireBackendDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Empower Your Digital Initiatives with Skilled Backend developer",
      subTitle:
        "Elevate your digital solutions with our skilled backend developers, adept at creating robust and efficient business applications with rapid time-to-market. Our premium backend development services are meticulously crafted with agile methodologies to address the diverse needs of various industries. Leveraging cutting-edge technologies, our proficient backend developers design highly secure applications that deliver superior performance and user satisfaction.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Backend Development Excellence for Robust Applications",
      title: "Engage Our Dedicated Developers",
      text: "The backbone of any application lies in its backend, facilitating seamless transactions between the user interface and functionality. It's crucial to hire backend developers who understand the intricacies of backend development and possess expertise in developing high-quality codes ensuring smooth application operations.Acquire a team of dedicated backend developers to build a powerful and reliable backbone structure for your enterprise applications. Our team comprises top-tier developers with extensive experience in developing secure backend applications tailored to various business challenges.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire Backend Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our Backend Developers",
      subTitle: "Unlocking the Potential Expertise of Our Backend Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Backend API Management & Integrations",
          icon: icons.learningIcon,
          benefitdescription:
            "Our developers excel in creating powerful APIs and seamlessly integrating third-party services to enhance the functionality and performance of your applications.",
        },
        {
          id: 2,
          benefitTitle: "Backend Enterprise Solutions",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Leveraging advanced frameworks and technologies, our team specializes in building distributed service architectures and complex data analysis workflows tailored for enterprise-level solutions.",
        },
        {
          id: 3,
          benefitTitle: "Custom Backend Development",
          icon: icons.holidayIcon,
          benefitdescription:
            "Tailoring solutions to your specific requirements, our developers craft intelligent backend solutions for mobile, web, and desktop applications, ensuring they meet your unique business challenges.",
        },
        {
          id: 4,
          benefitTitle: "Cloud Migration Development Solutions",
          icon: icons.celebrationIcon,
          benefitdescription:
            "With an understanding of various cloud platforms, our developers adeptly migrate existing solutions to private, public, and hybrid cloud servers, ensuring scalability and flexibility.",
        },
        {
          id: 5,
          benefitTitle: "Server Scripting Backend Services",
          icon: icons.salaryIcon,
          benefitdescription:
            "Meet your business goals with server-side applications developed by our proficient developers, who possess flawless server scripting skills to address your application's needs.",
        },
        {
          id: 6,
          benefitTitle: "Database Programming",
          icon: icons.environmentIcon,
          benefitdescription:
            "Improve database efficiency and effectiveness with our developers' expertise in modern technologies, ensuring optimal database performance and management.",
        },
        {
          id: 7,
          benefitTitle: "Backend Security Implementation",
          icon: icons.professionalIcon,
          benefitdescription:
            "Our backend developers prioritize security in every aspect of development, implementing robust security measures to protect your data and applications from threats and vulnerabilities.",
        },
        {
          id: 8,
          benefitTitle: "Backend Performance Optimization",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Our backend developers specialize in optimizing the performance of your applications and databases to ensure scalability, responsiveness, and reliability. ",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Exceptional Backend Development Services",
      subTitle:
        "Hire highly skilled backend developers to access services like Backend API Management & Integrations, Custom Backend Development, Backend Enterprise Solutions, Cloud Migration Development Solutions, and more. Our developers are proficient in the latest technologies such as Python, PHP, Ruby on Rails, NodeJS, and Laravel.",
      Services: [
        {
          id: 1,
          title: "Python Backend Development Services",
          description:
            "Build scalable, functional, and robust mobile and web apps with Python backend development services focused on delivering optimal business solutions.",
          number: "01",
        },
        {
          id: 2,
          title: "PHP Backend Development Services",
          description:
            "Our developers specialize in PHP, delivering hundreds of backend applications tailored for small to large-scale enterprises, ensuring seamless performance.",
          number: "02",
        },
        {
          id: 3,
          title: "Ruby on Rails Backend Development Services",
          description:
            "With extensive experience in Ruby on Rails concepts, libraries, and frameworks, our developers craft functional and secure backend applications to meet your needs.",
          number: "03",
        },
        {
          id: 4,
          title: "Node JS Backend Development Services",
          description:
            " Utilize server-side scripting expertise to generate dynamic page content and create applications designed for growth and scalability.",
          number: "04",
        },
        {
          id: 5,
          title: "Laravel Backend Development Services",
          description:
            "Harness specialized skills in Laravel to develop powerful backend structures and architectures, ensuring the security and performance of your business applications.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for Backend Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Uncover and shape brands that stand the test of time. We conduct extensive research and analysis to provide a roadmap for your solution that ensures success.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "We excel in creating innovative designs that are both creative and user-friendly. Our design style is imaginative, intricate, yet intuitive for end-users.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Utilizing modern technologies and advanced tools, we construct customized solutions that are not only reliable and scalable but also of the highest quality.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "We uphold excellence by employing an iterative approach to our work. Continuously striving for improvement, we ensure the delivery of exceptional results with every project.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "How do your backend developers approach database management and optimization?",
          subtitle:
            "Our backend developers are skilled in database design, optimization, and management. They utilize efficient database querying techniques, implement indexing strategies, and optimize database schema to ensure optimal performance and scalability for your application.",
        },
        {
          id: 2,
          title:
            "Can you describe a challenging project your backend developers have successfully tackled in the past?",
          subtitle:
            "Our backend developers have successfully tackled various complex projects, such as building large-scale e-commerce platforms, real-time messaging systems, and data-intensive applications. They excel in solving scalability, performance, and security challenges, delivering robust solutions that meet and exceed client expectations.",
        },
        {
          id: 3,
          title:
            "How do your backend developers approach security in application development?",
          subtitle:
            "Security is paramount in our development process. Our backend developers follow industry best practices for securing applications, including implementing authentication, authorization, encryption, and input validation mechanisms. They conduct thorough security audits and vulnerability assessments to identify and mitigate potential risks proactively.",
        },
        {
          id: 4,
          title:
            "What strategies do your backend developers employ for API development and documentation?",
          subtitle:
            "Our backend developers follow RESTful design principles for API development, ensuring scalability, interoperability, and ease of integration. They provide comprehensive API documentation, including endpoints, request/response formats, authentication methods, and usage examples, to facilitate seamless integration with frontend and third-party systems.",
        },
        {
          id: 5,
          title:
            "How do your backend developers handle concurrency and performance optimization in multi-user applications?",
          subtitle:
            "Our backend developers employ concurrency control techniques, such as locking mechanisms, transaction isolation levels, and optimistic concurrency control, to manage concurrent access to shared resources effectively. They also optimize code execution, database queries, and caching strategies to enhance application performance and responsiveness under load.",
        },
        {
          id: 6,
          title:
            "What level of involvement do your backend developers have in DevOps practices and deployment processes?",
          subtitle:
            "Our backend developers collaborate closely with DevOps teams to automate deployment pipelines, configure infrastructure as code, and implement continuous integration/continuous deployment (CI/CD) processes. They ensure seamless deployment, monitoring, and scaling of applications on cloud platforms and container orchestration systems.",
        },
        {
          id: 7,
          title:
            "How do your backend developers stay updated with emerging technologies and industry trends?",
          subtitle:
            "Our backend developers are passionate about continuous learning and professional development. They regularly attend conferences, workshops, and training programs, participate in online communities, and engage in hands-on experimentation to stay abreast of the latest technologies, frameworks, and best practices in backend development.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-Backend-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireBackendDeveloper;
