import React, { useEffect } from "react";
import icons from "utils/constants/icons";
import "./HeroSection.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import Button from "components/Button";
const HeroSection = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    return (
        <div className="hero-section-container">
            <div className="container ">
                <div className="row gy-3">
                    <div className="col-lg-6" data-aos="fade-up-right">
                        <div className="hero-card">
                            <h1 className="hero-l-title">
                                A better way to connect with your customers
                            </h1>
                            <p className="hero-l-description">
                                To increase sales by skyrocketing communication
                                with all messages in one simple dashboard it now
                                takes seconds.
                            </p>
                            <Button
                                btnStyle="btn-purple"
                                btnText="Get Started"
                            />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="image-phone">
                            <img
                                src={icons?.beautyImg}
                                alt="Background Illustration"
                                className="img-fluid"
                                data-aos="zoom-in"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
