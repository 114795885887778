import NumberCounter from "number-counter";
import icons from "utils/constants/icons";
import TouchBtn from "components/common/TouchBtn";
import OurMission from "./OurMission";
import Portfolio from "pages/Home/Portfolio";
import Technologies from "pages/Home/Technologies";
import ContactUs from "components/ContactUs";
import TeamCard from "./TeamCard/TeamCard";
import OurJourney2 from "./OurJouney2/OurJourney2";
import { Helmet } from "react-helmet";
import "./About.scss";
// import ClientSlider from "components/ClientSlider";

function About() {
  return (
    <>
      <Helmet>
        <title>About Sourrcecube Technologies | N.P Gupta</title>
        <meta
          name="description"
          content="Discover the story of Sourrcecube Technologies, Learn about our mission, values, and commitment to delivering innovative technology solutions. Join us on our journey to empower businesses with cutting-edge development, mobile app solutions, JS/cloud development, e-commerce solutions, and DevOps services."
        />
        <meta
          property="og:title"
          content="About Sourrcecube Technologies | N.P Gupta"
        />
        <meta
          property="og:description"
          content="Discover the story of Sourrcecube Technologies, Learn about our mission, values, and commitment to delivering innovative technology solutions. Join us on our journey to empower businesses with cutting-edge development, mobile app solutions, JS/cloud development, e-commerce solutions, and DevOps services."
        />
      </Helmet>

      <div className="about-us">
        <div className="container-main">
          <div className="about-pera">
            <div className="about-hero-section">
              <img
                src={icons.AboutText}
                alt="About Text"
                className="about-text"
              />
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <h1 className="about-hero-heading">
                      Fueling Business Empowerment Through Cutting-Edge
                      Technological Innovations, Paving the Way for Future
                      Excellence.
                    </h1>
                    <p className="about-hero-text">
                      At the heart of our mission lies a dedication to empower
                      businesses by harnessing the transformative power of
                      technology. We stand as architects of progress, crafting
                      innovative solutions that not only meet current needs but
                      also lay the foundation for a future where businesses
                      thrive on the cusp of technological advancements.
                    </p>
                    <TouchBtn title="Get in Touch" />
                  </div>
                  <div className="col-lg-6">
                    <div className="about-counters">
                      <div className="about-counter delivered">
                        <h3 className="d-flex justify-content-center">
                          <NumberCounter end={75} delay={1} />
                          <span>+</span>
                        </h3>
                        <p>Talented IT professionals</p>
                      </div>
                      <div className="about-counter startups">
                        <h3 className="d-flex justify-content-center">
                          <NumberCounter end={13} delay={1} />
                          <span>+</span>
                        </h3>
                        <p>Countries Served</p>
                      </div>
                      <div className="about-counter empowered">
                        <h3 className="d-flex justify-content-center">
                          <NumberCounter end={3264} delay={1} />
                          <span>+</span>
                        </h3>
                        <p>Successful Projects</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ClientSlider /> */}
        <OurMission />
        <OurJourney2 />
        {/* <HeadStart /> */}
        {/* <OurTeam /> */}
        <TeamCard />
        {/* <OurJourney /> */}
        <Portfolio />
        <Technologies />
        <ContactUs />
      </div>
    </>
  );
}

export default About;
