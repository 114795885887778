import logo from "assets/images/logo/logo.png";
import greenStar from "assets/images/icons/greenStar.webp";
import radioOne from "assets/images/common/radioOne.webp";
import radioTwo from "assets/images/common/radioTwo.webp";
import radioThree from "assets/images/common/radioThree.webp";
import radioFour from "assets/images/common/radioFour.webp";
import radioFive from "assets/images/common/radioFive.webp";
import phpIcon from "assets/images/icons/phpIcon.webp";
import mySqlIcon from "assets/images/icons/mySqlIcon.webp";
import laravelIcon from "assets/images/icons/laravelIcon.webp";
import codeIgnitorIcon from "assets/images/icons/codeIgnitorIcon.webp";
import mongoDBIcon from "assets/images/icons/mongoDBIcon.webp";
import cakePhpIcon from "assets/images/icons/cakePhpIcon.webp";
import goopss from "assets/images/icons/goopss.webp";
import UrgentDeal from "assets/images/icons/urgentdeal.svg";
import iferp from "assets/images/icons/iferp.webp";
import uHello from "assets/images/icons/uHello.webp";
import uStore from "assets/images/icons/uStore.webp";
import uGreen from "assets/images/icons/uGreen.webp";
import citronLogo from "assets/images/icons/citron-logo.webp";
import quickDrive from "assets/images/icons/quick-drive.webp";
import udenzLogo from "assets/images/icons/udenz-logo.webp";
import mohamedLogo from "assets/images/icons/mohamed-logo.webp";
import paydLogo from "assets/images/icons/payd-logo.webp";
import udenzPortfolio from "assets/images/common/udenz-portfolio.webp";
import paydPortfolio from "assets/images/common/payd-portfolio.webp";
import mohamedPortfolio from "assets/images/common/mohamed-portfolio.webp";
import quickDrivePortfolio from "assets/images/common/quick-drive-portfolio.webp";
import citronPortfolio from "assets/images/common/citron-portfolio.webp";
import iferpPortfolio from "assets/images/common/iferp-portfolio.webp";
import macBook from "assets/images/common/macBook.webp";
import www from "assets/images/icons/www.webp";
import playStore from "assets/images/icons/play-store.svg";
import cartIcon from "assets/images/icons/cartIcon.webp";
import googleIcon from "assets/images/common/gooleIcon.webp";
import clucthIcon from "assets/images/common/clucthIcon.webp";
import emailIcon from "assets/images/icons/emailIcon.webp";
import PhoneIcon from "assets/images/icons/PhoneIcon.webp";
import india from "assets/images/icons/india.webp";
import facebookIcon from "assets/images/icons/facebookIcon.webp";
import instagramIcon from "assets/images/icons/instagramIcon.webp";
import linkedInIcon from "assets/images/icons/linkedInIcon.webp";
import arrowRightIcon from "assets/images/icons/arrowRightIcon.webp";
import portDoctor from "assets/images/icons/port-doctor.webp";
import portFood from "assets/images/icons/port-food.webp";
import portMobile from "assets/images/icons/port-mobile.webp";
import portProgres from "assets/images/icons/port-progres.webp";
import MacbookImg from "assets/images/common/hero-macbook.webp";
import IphoneImg from "assets/images/common/hero-iphone.webp";
import GetStartRing from "assets/images/common/get-start-ring.svg";
import GetRingArrow from "assets/images/common/get-ring-arrow.webp";
import GetRingText from "assets/images/common/get-ring-text.webp";
import MacImg1 from "assets/images/common/mac-img-1.webp";
import MacImg2 from "assets/images/common/mac-img-2.webp";
import MacImg3 from "assets/images/common/mac-img-3.webp";
import MacImg4 from "assets/images/common/mac-img-4.webp";
import MacImg5 from "assets/images/common/mac-img-5.webp";
import PhiImg1 from "assets/images/common/phn-img1.webp";
import PhiImg2 from "assets/images/common/phn-img2.webp";
import PhiImg3 from "assets/images/common/phn-img3.webp";
import PhiImg4 from "assets/images/common/phn-img4.webp";
import PhiImg5 from "assets/images/common/phn-img5.webp";
import FlagIcon from "assets/images/icons/flag-icon.svg";
import CompanyAbout from "assets/images/icons/company-about-icon.svg";
import CompanyCareer from "assets/images/icons/company-career-icon.svg";
import CompanyWhy from "assets/images/icons/company-why-icon.svg";
import CompanyWork from "assets/images/icons/company-work-icon.svg";
import Whatsappicon from "assets/images/icons/whatsapp-icon.svg";
import Callicon from "assets/images/icons/call-icon.svg";
import TouchImage from "assets/images/common/touch-modal-img.webp";
import TechnologieImg from "assets/images/common/technologie-ad-img.webp";
import Technology1 from "assets/images/icons/technology1.webp";
import Technology2 from "assets/images/icons/technology2.webp";
import Technology3 from "assets/images/icons/technology3.webp";
import Technology4 from "assets/images/icons/technology4.webp";
import Technology5 from "assets/images/icons/technology5.webp";
import Technology6 from "assets/images/icons/technology6.webp";
import Technology7 from "assets/images/icons/technology7.webp";
import Technology8 from "assets/images/icons/technology8.webp";
import Technology9 from "assets/images/icons/technology9.webp";
import Technology10 from "assets/images/icons/technology10.webp";
import Technology11 from "assets/images/icons/technology11.webp";
import Technology12 from "assets/images/icons/technology12.webp";
import Technology13 from "assets/images/icons/technology13.webp";
import Technology14 from "assets/images/icons/technology4.webp";
import Technology15 from "assets/images/icons/technology15.webp";
import Technology16 from "assets/images/icons/technology16.webp";
import Technology17 from "assets/images/icons/technology17.webp";
import Technology18 from "assets/images/icons/technology18.webp";
import Technology19 from "assets/images/icons/technology19.webp";
import Technology20 from "assets/images/icons/technology20.webp";
import Technology21 from "assets/images/icons/technology21.webp";
import Technology22 from "assets/images/icons/technology22.webp";
import Technology23 from "assets/images/icons/technology23.webp";
import Technology24 from "assets/images/icons/technology24.webp";
import Technology25 from "assets/images/icons/technology25.webp";
import Technology26 from "assets/images/icons/technology26.webp";
import Technology27 from "assets/images/icons/technology27.webp";
import Technology28 from "assets/images/icons/technology28.webp";
import FillStar from "assets/images/icons/star-fill.webp";
import HalfStar from "assets/images/icons/star-half.webp";
import Star from "assets/images/icons/star.webp";
import PlayBtn from "assets/images/icons/play-btn.svg";
import Glassdoor from "assets/images/common/glassdoor.webp";
import AboutText from "assets/images/common/about-text.webp";
import MissionIcon from "assets/images/icons/our-mission-icon.webp";
import MissionCeo from "assets/images/common/our-mission-ceo1.webp";
import MissionBorder from "assets/images/common/our-mission-border.webp";
import TeamMember1 from "assets/images/common/team-member-1.webp";
import TeamMember2 from "assets/images/common/team-member-2.webp";
import TeamMember3 from "assets/images/common/team-member-3.webp";
import TeamMember4 from "assets/images/common/team-member-4.webp";
import TeamMember5 from "assets/images/common/team-member-5.webp";
import TeamMember6 from "assets/images/common/team-member-6.webp";
import TeamImg1 from "assets/images/icons/team-img-1.webp";
import TeamImg2 from "assets/images/icons/team-img-2.webp";
import TeamImg3 from "assets/images/icons/team-img-3.webp";
import TeamImg4 from "assets/images/icons/team-img-4.webp";
import TeamImg5 from "assets/images/icons/team-img-5.webp";
import TeamImg6 from "assets/images/icons/team-img-6.webp";
import TeamImg7 from "assets/images/icons/team-img-7.webp";
import TeamImg8 from "assets/images/icons/team-img-8.webp";
import TeamPlusImg from "assets/images/icons/team-img-plus.svg";
import JourneyRound from "assets/images/common/journey-round.svg";
import Services1 from "assets/images/icons/services-1.svg";
import Services2 from "assets/images/icons/services-2.svg";
import Services3 from "assets/images/icons/services-3.svg";
import Services4 from "assets/images/icons/services-4.svg";
import Services5 from "assets/images/icons/services-5.svg";
import Services21 from "assets/images/icons/services-21.svg";
import Services22 from "assets/images/icons/services-22.svg";
import Services23 from "assets/images/icons/services-23.svg";
import Services24 from "assets/images/icons/services-24.svg";
import Services31 from "assets/images/icons/services-31.svg";
import Services32 from "assets/images/icons/services-32.svg";
import Services33 from "assets/images/icons/services-33.svg";
import Services34 from "assets/images/icons/services-34.svg";
import Services35 from "assets/images/icons/services-35.svg";
import Services36 from "assets/images/icons/services-36.svg";
import Services51 from "assets/images/icons/services-51.svg";
import servicesDevop1 from "assets/images/icons/services-devop1.svg";
import servicesDevop2 from "assets/images/icons/services-devop2.svg";
import servicesDevop3 from "assets/images/icons/services-devop3.svg";
import servicesDevop4 from "assets/images/icons/services-devop4.svg";
import servicesDevop5 from "assets/images/icons/services-devop5.svg";
import gImage1 from "assets/images/common/gImage1.webp";
import gImage2 from "assets/images/common/gImage2.webp";
import gImage3 from "assets/images/common/gImage3.webp";
import gImage4 from "assets/images/common/gImage4.webp";
import gImage5 from "assets/images/common/g1rimage7.webp";
import gImage6 from "assets/images/common/g2rimage7.webp";
import g2Image1 from "assets/images/common/g2Image1.webp";
import g2Image2 from "assets/images/common/g2Image2.webp";
import g2Image3 from "assets/images/common/g2Image3.webp";
import g2Image4 from "assets/images/common/g2Image4.webp";
import g2Image6 from "assets/images/common/g2Image6.webp";
import gallery1 from "assets/images/common/gallery-1.webp";
import gallery2 from "assets/images/common/gallery-2.webp";
import gallery3 from "assets/images/common/gallery-3.webp";
import gallery4 from "assets/images/common/gallery-4.webp";
import gallery5 from "assets/images/common/gallery-5.webp";
import gallery6 from "assets/images/common/gallery-6.webp";
import gallery7 from "assets/images/common/gallery-7.webp";
import gallery8 from "assets/images/common/gallery-8.webp";
import gallery9 from "assets/images/common/gallery-9.webp";
import gallery10 from "assets/images/common/gallery-10.webp";
import gallery11 from "assets/images/common/gallery-11.webp";
import gallery12 from "assets/images/common/gallery-12.webp";
import gallery13 from "assets/images/common/gallery-13.webp";
import gallery14 from "assets/images/common/gallery-14.webp";
import gallery15 from "assets/images/common/gallery-15.webp";
import gallery16 from "assets/images/common/gallery-16.webp";
import learningIcon from "assets/images/icons/learningIcon.webp";
import motovationalIcon from "assets/images/icons/motovationalIcon.webp";
import holidayIcon from "assets/images/icons/holidayIcon.webp";
import celebrationIcon from "assets/images/icons/celebrationIcon.webp";
import salaryIcon from "assets/images/icons/salaryIcon.webp";
import environmentIcon from "assets/images/icons/environmentIcon.webp";
import professionalIcon from "assets/images/icons/professionalIcon.webp";
import loyaltyIcon from "assets/images/icons/loyaltyIcon.webp";
import CareerHeroBg from "assets/images/common/career-hero-bg.webp";
import CareerBigRing from "assets/images/common/career-big-ring.webp";
import CareerSmallRing from "assets/images/common/career-small-ring.webp";
import CareerHeroText from "assets/images/common/career-text.webp";
import VacancyFresh from "assets/images/common/vacancy-freshers-img.webp";
import VacancyExp from "assets/images/common/vacancy-exp-img.webp";
import VacancyArrow from "assets/images/common/vacancy-arrow.svg";
import ServicesWeb from "assets/images/icons/services-web.svg";
import ServicesApp from "assets/images/icons/services-app.svg";
import ServicesJs from "assets/images/icons/services-js.svg";
import ServicesEcommerce from "assets/images/icons/services-ecommerce.svg";
import ServicesDevops from "assets/images/icons/services-devops.svg";
import Business1 from "assets/images/icons/business-icon-1.svg";
import Business2 from "assets/images/icons/business-icon-2.svg";
import Business3 from "assets/images/icons/business-icon-3.svg";
import Business4 from "assets/images/icons/business-icon-4.svg";
import Business5 from "assets/images/icons/business-icon-5.svg";
import Business6 from "assets/images/icons/business-icon-6.svg";
import Business7 from "assets/images/icons/business-icon-7.svg";
import Business8 from "assets/images/icons/business-icon-8.svg";
import Business9 from "assets/images/icons/business-icon-9.svg";
import Business10 from "assets/images/icons/business-icon-10.svg";
import Business11 from "assets/images/icons/business-icon-11.svg";
import Business12 from "assets/images/icons/business-icon-12.svg";
import Business13 from "assets/images/icons/business-icon-13.svg";
import Business14 from "assets/images/icons/business-icon-14.svg";
import Business15 from "assets/images/icons/business-icon-15.svg";
import Business16 from "assets/images/icons/business-icon-16.svg";
import Business17 from "assets/images/icons/dashboard.png";
import detail1 from "assets/images/icons/Upload icon.svg";
import detail2 from "assets/images/icons/company_location.svg";
import detail3 from "assets/images/icons/company_time.svg";
import detail4 from "assets/images/icons/company_workingdays.svg";
import detail5 from "assets/images/icons/graduate.svg";
import detail6 from "assets/images/icons/fulltime_job.svg";
import detail7 from "assets/images/icons/experience.svg";
import detail8 from "assets/images/icons/user-white.svg";
import resumeUpload from "assets/images/icons/resume_upload.svg";
import resumeUploadSuccess from "assets/images/icons/resume_upload_success.svg";
import trashIcon from "assets/images/icons/trash-icon.svg";
import top100Logo from "assets/images/icons/top-100-logo.webp";
import countries_icon1 from "assets/images/icons/countries-icon1.svg";
import countries_icon2 from "assets/images/icons/countries-icon2.svg";
import countries_icon3 from "assets/images/icons/countries-icon3.svg";
import countries_icon4 from "assets/images/icons/countries-icon4.svg";
import project_icon1 from "assets/images/icons/project-icon1.svg";
import project_icon2 from "assets/images/icons/project-icon2.svg";
import project_icon3 from "assets/images/icons/project-icon3.svg";
import project_icon4 from "assets/images/icons/project-icon4.svg";
import country1 from "assets/images/icons/country-icon1.webp";
import country2 from "assets/images/icons/country-icon2.webp";
import country3 from "assets/images/icons/country-icon3.webp";
import country4 from "assets/images/icons/country-icon4.webp";
import country5 from "assets/images/icons/country-icon5.webp";
import country6 from "assets/images/icons/country-icon6.webp";
import country7 from "assets/images/icons/country-icon7.webp";
import country8 from "assets/images/icons/country-icon8.webp";
import country9 from "assets/images/icons/country-icon9.webp";
import checkedIcon from "assets/images/icons/checked-icon.svg";
import hireResourcesFormIcon from "assets/images/icons/hireresources-formicon.svg";
import hireResourcesStap1 from "assets/images/icons/step-1-icon.svg";
import hireResourcesStap2 from "assets/images/icons/hireresources-stap2.svg";
import hireResourcesStap3 from "assets/images/icons/hireresources-stap3.svg";
import hireResourcesStap4 from "assets/images/icons/hireresources-stap4.svg";
import hireResourGreenDot from "assets/images/icons/hireresources-greendot.svg";
import hireResourWhiteDot from "assets/images/icons/hireresources-whitedot.svg";
import hireResourcesText from "assets/images/common/hireresources-text.webp";
import hireResourcesCareIcon from "assets/images/common/hireresources-careicon.webp";
import hireResourcesDeveloperImg from "assets/images/common/hireresources-developerimg.webp";
import hireResourcesFormImg from "assets/images/common/hireresources-formimg.webp";
import hireResourcesSecure from "assets/images/common/hireresources-secureicon.webp";
import ArrowBottom from "assets/images/icons/arrow-bottom.svg";
import stepUpArrow from "assets/images/icons/stepuparrow.svg";
import stepDownArrow from "assets/images/icons/stepdownarrow.svg";
import fullstack from "assets/images/icons/fullstack.png";
import backend from "assets/images/icons/backend.png";
import frontend from "assets/images/icons/frontend.png";
import angular from "assets/images/icons/angular.png";
import reactjs from "assets/images/icons/reactjs.png";
import vuejs from "assets/images/icons/vuejs1.png";
import figma from "assets/images/icons/figma.png";
import dedicated from "assets/images/icons/dedicated.png";
import php from "assets/images/icons/php.png";
import flutter from "assets/images/icons/flutter.png";
import android from "assets/images/icons/android.png";
import laravel from "assets/images/icons/laravel.png";
import nodejs from "assets/images/icons/nodejs.png";
import wordpress from "assets/images/icons/wordpress.png";
import webdesigner from "assets/images/icons/webdesigner.png";
import heroBanner1 from "assets/images/common/hero-banner-1.webp";
import heroBanner2 from "assets/images/common/hero-banner-2.webp";
import heroBanner3 from "assets/images/common/hero-banner-3.webp";
import heroBanner4 from "assets/images/common/hero-banner-4.webp";
import heroBannerM1 from "assets/images/common/hero-banner-m-1.webp";
import heroBannerM2 from "assets/images/common/hero-banner-m-2.webp";
import heroBannerM3 from "assets/images/common/hero-banner-m-3.webp";
import heroBannerM4 from "assets/images/common/hero-banner-m-4.webp";
import st1 from "assets/images/common/st-1.webp";
import st2 from "assets/images/common/st-2.webp";
import st3 from "assets/images/common/st-3.webp";
import st4 from "assets/images/common/st-4.webp";
import st5 from "assets/images/common/st-5.webp";
import st6 from "assets/images/common/st-6.webp";
import st7 from "assets/images/common/st-7.webp";
import st8 from "assets/images/common/st-8.webp";
import st9 from "assets/images/common/st-9.webp";
import st10 from "assets/images/common/st-10.webp";
import st11 from "assets/images/common/st-11.webp";
import st12 from "assets/images/common/st-12.webp";
import st13 from "assets/images/common/st-13.webp";
import st14 from "assets/images/common/st-14.webp";
import st15 from "assets/images/common/st-15.webp";
import st16 from "assets/images/common/st-16.webp";
import st17 from "assets/images/common/st-17.webp";
import st18 from "assets/images/common/st-18.webp";
import st19 from "assets/images/common/st-19.webp";
import st20 from "assets/images/common/st-20.webp";
import st21 from "assets/images/common/st-21.webp";
import st22 from "assets/images/common/st-22.webp";
import st23 from "assets/images/common/st-23.webp";
import st24 from "assets/images/common/st-24.webp";
import communicationImage from "assets/images/icons/communication_image.jpg";
import learning from "assets/images/icons/learning.avif";
import querySession from "assets/images/icons/query-session.jpg";
import blogSlider from "assets/images/icons/blog-slider.png";
import locationIcons from "assets/images/icons/location-pointer.png";
import searchIcons from "assets/images/icons/search.png";
import upRightArrow from "assets/images/icons/up-right.png";
import youtubeIcons from "assets/images/icons/youtube.png";
import phone from "assets/images/businessmodel/phone.png";
import LoveSection from "assets/images/businessmodel/LoveSection.jpg";
// import phone2 from "assets/images/businessmodel/phone2.png"
import work from "assets/images/businessmodel/work.png";
import slider1 from "assets/images/businessmodel/slider1.jpg";
import slider2 from "assets/images/businessmodel/slider2.jpg";
import ring1 from "assets/images/businessmodel/ring1.png";
import ring2 from "assets/images/businessmodel/ring2.png";
import review1 from "assets/images/businessmodel/review.jpg";
import review2 from "assets/images/businessmodel/Review2.jpg";
import background from "assets/images/businessmodel/background.jpg";
import feedback from "assets/images/businessmodel/feedbackicon.png";
import tropy from "assets/images/businessmodel/tropyt_1.png";
import download from "assets/images/businessmodel/download_1.png";
import user from "assets/images/businessmodel/User_1.png";
import page3Screenshot1 from "assets/images/businessmodel/page3/testimonial1.jpg";
import page3Screenshot2 from "assets/images/businessmodel/page3/testimonial2.jpg";
import page3Screenshot3 from "assets/images/businessmodel/page3/testimonial3.jpg";
import page3Screenshot4 from "assets/images/businessmodel/page3/testimonial4.jpg";
import page3Screenshot5 from "assets/images/businessmodel/page3/testimonial5.jpg";
import ratingFillStar from "assets/images/businessmodel/page3/star-fill.svg";
import ratingLineStar from "assets/images/businessmodel/page3/star.svg";
import Page_Hero from "assets/images/businessmodel/Page-04/HeroSection.webp";
import partner1 from "assets/images/businessmodel/Page-04/partner1.svg";
import partner2 from "assets/images/businessmodel/Page-04/partner2.svg";
import partner3 from "assets/images/businessmodel/Page-04/partner3.svg";
import partner4 from "assets/images/businessmodel/Page-04/partner4.svg";
import partner5 from "assets/images/businessmodel/Page-04/partner5.svg";
import Simple1 from "assets/images/businessmodel/Page-04/simple_1.png";
import Simple2 from "assets/images/businessmodel/Page-04/simple_2.png";
import Simple3 from "assets/images/businessmodel/Page-04/simple_3.png";
import Simple4 from "assets/images/businessmodel/Page-04/simple_4.png";
import features_1 from "assets/images/businessmodel/Page-04/features_1.png";
import features_2 from "assets/images/businessmodel/Page-04/features_2.png";
import features_3 from "assets/images/businessmodel/Page-04/features_3.png";
import features_4 from "assets/images/businessmodel/Page-04/features_4.png";
import people1 from "assets/images/businessmodel/Page-04/people1.jpg";
import people2 from "assets/images/businessmodel/Page-04/people2.jpg";
import people3 from "assets/images/businessmodel/Page-04/people3.jpg";
import comapny1 from "assets/images/businessmodel/Page-04/company1 - Copy.svg";
import comapny2 from "assets/images/businessmodel/Page-04/company2.svg";
import comapny3 from "assets/images/businessmodel/Page-04/company3.svg";
import comapny4 from "assets/images/businessmodel/Page-04/comapny4.svg";
import comapny5 from "assets/images/businessmodel/Page-04/comapny5.svg";
import comapny6 from "assets/images/businessmodel/Page-04/company6.svg";
import comapny7 from "assets/images/businessmodel/Page-04/company7.svg";
import comapny8 from "assets/images/businessmodel/Page-04/compnay8.svg";
import comapny9 from "assets/images/businessmodel/Page-04/company9.svg";
import comapny10 from "assets/images/businessmodel/Page-04/cpmpany10.svg";
import comapny11 from "assets/images/businessmodel/Page-04/company11.svg";
import comapny12 from "assets/images/businessmodel/Page-04/compnay12.svg";
import comapny13 from "assets/images/businessmodel/Page-04/company13.svg";
import comapny14 from "assets/images/businessmodel/Page-04/company14.svg";
import comapny15 from "assets/images/businessmodel/Page-04/company15.svg";
import maskimg from "assets/images/businessmodel/Page-04/mask-1.png";
import phonepage2 from "assets/images/businessmodel/page2/phone_page2-1.png";
import businessicon1 from "assets/images/businessmodel/page2/business_ricon.png";
import businessicon2 from "assets/images/businessmodel/page2/business_icon2.png";
import businessicon3 from "assets/images/businessmodel/page2/business_icon3.png";
import businessicon4 from "assets/images/businessmodel/page2/business_icon4.png";
import businessicon5 from "assets/images/businessmodel/page2/business_icon5.png";
import businessicon6 from "assets/images/businessmodel/page2/business_icon6.png";
import worksession1 from "assets/images/businessmodel/page2/worksession1.png";
import worksession2 from "assets/images/businessmodel/page2/worksession2.png";
import worksession3 from "assets/images/businessmodel/page2/worksession3.png";
import Bank_phone from "assets/images/businessmodel/page2/Banking_phone-2.png";
import Productivity_phone from "assets/images/businessmodel/page2/phone2_page2.png";
import Quote from "assets/images/businessmodel/page2/quote.png";
import Avtar1 from "assets/images/businessmodel/page2/avatar-1.png";
import Avtar2 from "assets/images/businessmodel/page2/avatar-2.png";
import Avtar3 from "assets/images/businessmodel/page2/avatar-3.png";
import GooglePlay from "assets/images/businessmodel/page2/google-play.png";
import AppStore from "assets/images/businessmodel/page2/app-store.png";
import page7Heroimg from "assets/images/businessmodel/page7/app-6.webp";
import Qualifucation1 from "assets/images/businessmodel/page7/Qualification1.png";
import Qualifucation2 from "assets/images/businessmodel/page7/Qualification2.png";
import Qualifucation3 from "assets/images/businessmodel/page7/Qualification3.png";
import Qualifucation4 from "assets/images/businessmodel/page7/Qualification4.png";
import Dashboardimg from "assets/images/businessmodel/page7/Dashboard_1.png";
import Grow1 from "assets/images/businessmodel/page7/grow1.svg";
import Grow2 from "assets/images/businessmodel/page7/grow2.svg";
import Grow3 from "assets/images/businessmodel/page7/grow3.svg";
import Coverd1 from "assets/images/businessmodel/page7/Coverd1.svg";
import Coverd2 from "assets/images/businessmodel/page7/Coverd2.svg";
import Coverd3 from "assets/images/businessmodel/page7/Coverd3.svg";
import Coverd4 from "assets/images/businessmodel/page7/Coverd4.svg";
import Coverd5 from "assets/images/businessmodel/page7/Coverd5.svg";
import Process1 from "assets/images/businessmodel/page7/Process1.svg";
import Process2 from "assets/images/businessmodel/page7/Process2.svg";
import Process3 from "assets/images/businessmodel/page7/Process3.svg";
import Process4 from "assets/images/businessmodel/page7/Process4.svg";
import Breeze from "assets/images/businessmodel/page7/Breeze.webp";
import Trustedpeople1 from "assets/images/businessmodel/page7/Trustedpeople1.webp";
import Trustedpeople2 from "assets/images/businessmodel/page7/Trustedpeople2.jpg";
import Trustedpeople3 from "assets/images/businessmodel/page7/Trustedpeople3.jpg";
import Trustedpeople4 from "assets/images/businessmodel/page7/Trustedpeople4.jpg";
import Dive from "assets/images/businessmodel/page7/Dive.jpg";
// import LoveSection from "assets/images/businessmodel/LoveSection.jpg";
// import phone2 from "assets/images/businessmodel/phone2.png";
// import work from "assets/images/businessmodel/work.png";
// import slider1 from "assets/images/businessmodel/slider1.jpg";
// import slider2 from "assets/images/businessmodel/slider2.jpg";
// import ring1 from "assets/images/businessmodel/ring1.png";
// import ring2 from "assets/images/businessmodel/ring2.png";
// import review1 from "assets/images/businessmodel/review.jpg";
// import review2 from "assets/images/businessmodel/Review2.jpg";
// import background from "assets/images/businessmodel/background.jpg";
// import feedback from "assets/images/businessmodel/feedbackicon.png";
// import tropy from "assets/images/businessmodel/tropyt_1.png";
// import download from "assets/images/businessmodel/download_1.png";
// import user from "assets/images/businessmodel/User_1.png";
// import page3Screenshot1 from "assets/images/businessmodel/page3/testimonial1.jpg";
// import page3Screenshot2 from "assets/images/businessmodel/page3/testimonial2.jpg";
// import page3Screenshot3 from "assets/images/businessmodel/page3/testimonial3.jpg";
// import page3Screenshot4 from "assets/images/businessmodel/page3/testimonial4.jpg";
// import page3Screenshot5 from "assets/images/businessmodel/page3/testimonial5.jpg";
// import ratingFillStar from "assets/images/businessmodel/page3/star-fill.svg";
// import ratingLineStar from "assets/images/businessmodel/page3/star.svg";
// import Page_Hero from "assets/images/businessmodel/Page-04/HeroSection.webp";
// import partner1 from "assets/images/businessmodel/Page-04/partner1.svg";
// import partner2 from "assets/images/businessmodel/Page-04/partner2.svg";
// import partner3 from "assets/images/businessmodel/Page-04/partner3.svg";
// import partner4 from "assets/images/businessmodel/Page-04/partner4.svg";
// import partner5 from "assets/images/businessmodel/Page-04/partner5.svg";
// import Simple1 from "assets/images/businessmodel/Page-04/simple_1.png";
// import Simple2 from "assets/images/businessmodel/Page-04/simple_2.png";
// import Simple3 from "assets/images/businessmodel/Page-04/simple_3.png";
// import Simple4 from "assets/images/businessmodel/Page-04/simple_4.png";
// import features_1 from "assets/images/businessmodel/Page-04/features_1.png";
// import features_2 from "assets/images/businessmodel/Page-04/features_2.png";
// import features_3 from "assets/images/businessmodel/Page-04/features_3.png";
// import features_4 from "assets/images/businessmodel/Page-04/features_4.png";
// import people1 from "assets/images/businessmodel/Page-04/people1.jpg";
// import people2 from "assets/images/businessmodel/Page-04/people2.jpg";
// import people3 from "assets/images/businessmodel/Page-04/people3.jpg";
// import comapny1 from "assets/images/businessmodel/Page-04/company1 - Copy.svg";
// import comapny2 from "assets/images/businessmodel/Page-04/company2.svg";
// import comapny3 from "assets/images/businessmodel/Page-04/company3.svg";
// import comapny4 from "assets/images/businessmodel/Page-04/comapny4.svg";
// import comapny5 from "assets/images/businessmodel/Page-04/comapny5.svg";
// import comapny6 from "assets/images/businessmodel/Page-04/company6.svg";
// import comapny7 from "assets/images/businessmodel/Page-04/company7.svg";
// import comapny8 from "assets/images/businessmodel/Page-04/compnay8.svg";
// import comapny9 from "assets/images/businessmodel/Page-04/company9.svg";
// import comapny10 from "assets/images/businessmodel/Page-04/cpmpany10.svg";
// import comapny11 from "assets/images/businessmodel/Page-04/company11.svg";
// import comapny12 from "assets/images/businessmodel/Page-04/compnay12.svg";
// import comapny13 from "assets/images/businessmodel/Page-04/company13.svg";
// import comapny14 from "assets/images/businessmodel/Page-04/company14.svg";
// import comapny15 from "assets/images/businessmodel/Page-04/company15.svg";
// import maskimg from "assets/images/businessmodel/Page-04/mask-1.png";
import socialIcons from "assets/images/businessmodel/page-6/social-hero-img.webp";
import socialIconOne from "assets/images/businessmodel/page-6/systek.svg";
import socialIconTwo from "assets/images/businessmodel/page-6/kromo.svg";
import socialIconThree from "assets/images/businessmodel/page-6/infinite.svg";
import socialIconFour from "assets/images/businessmodel/page-6/covenant.svg";
import grubSpotIcons from "assets/images/businessmodel/page-6/grubspot.svg";
import tribetIcons from "assets/images/businessmodel/page-6/tribe.svg";
import phasekitIcons from "assets/images/businessmodel/page-6/phasekit.svg";
import gutworkIcons from "assets/images/businessmodel/page-6/gutwork.svg";
import proactiveIcons from "assets/images/businessmodel/page-6/proactive.svg";
import saleIconsOne from "assets/images/businessmodel/page-6/bill-icon.svg";
import saleIconsTwo from "assets/images/businessmodel/page-6/download.png";
import saleIconsThree from "assets/images/businessmodel/page-6/credit-card.png";
import saleIconsFour from "assets/images/businessmodel/page-6/cloud.png";
import slantedImg from "assets/images/businessmodel/page-6/start-left-image.webp";
import featuresImgOne from "assets/images/businessmodel/page-6/azur-img.webp";
import featuresImgTwo from "assets/images/businessmodel/page-6/azur-1-img.webp";
import featuresImgThree from "assets/images/businessmodel/page-6/azur-2-img.webp";
import GrowImgOne from "assets/images/businessmodel/page-6/globe-map.svg";
import GrowImgTwo from "assets/images/businessmodel/page-6/cash-img.svg";
import GrowImgThree from "assets/images/businessmodel/page-6/credit-card-img.svg";
import storeImg from "assets/images/businessmodel/page-6/store-img.svg";
import bankImg from "assets/images/businessmodel/page-6/bank-img.svg";
import factoryImg from "assets/images/businessmodel/page-6/factory-img.svg";
import churchImg from "assets/images/businessmodel/page-6/church-img.svg";
import warehouseImg from "assets/images/businessmodel/page-6/warehouse-img.svg";
import trustedUoImg from "assets/images/businessmodel/page-6/trusted-img-one.webp";
import trustedUtImg from "assets/images/businessmodel/page-6/trusted-img-two.webp";
import trustedUthImg from "assets/images/businessmodel/page-6/trusted-img-three.webp";
import trustedUfImg from "assets/images/businessmodel/page-6/trusted-img-four.webp";

import Page8hero from "assets/images/businessmodel/Page-8/page8-hero.png";
import Features1 from "assets/images/businessmodel/Page-8/page8features1.svg";
import Features2 from "assets/images/businessmodel/Page-8/page8features2.svg";
import Features3 from "assets/images/businessmodel/Page-8/page8features3.svg";
import Features4 from "assets/images/businessmodel/Page-8/page8features4.svg";
import Features5 from "assets/images/businessmodel/Page-8/page8features5.svg";
import Features6 from "assets/images/businessmodel/Page-8/page8features6.svg";
import Look1 from "assets/images/businessmodel/Page-8/Look1.png";
import Look2 from "assets/images/businessmodel/Page-8/Look2.png";
import Look3 from "assets/images/businessmodel/Page-8/Look3.png";
import Look4 from "assets/images/businessmodel/Page-8/Look4.png";
import Look5 from "assets/images/businessmodel/Page-8/Look5.png";
import Look6 from "assets/images/businessmodel/Page-8/Look6.png";
import Look7 from "assets/images/businessmodel/Page-8/Look7.png";
import Look8 from "assets/images/businessmodel/Page-8/Look8.png";
import Look9 from "assets/images/businessmodel/Page-8/Look9.png";
import Look10 from "assets/images/businessmodel/Page-8/Look10.png";
import Look11 from "assets/images/businessmodel/Page-8/Look11.png";
import Look12 from "assets/images/businessmodel/Page-8/Look12.png";
import Learn1 from "assets/images/businessmodel/Page-8/learn1.jpeg";
import Learn3 from "assets/images/businessmodel/Page-8/learn3.jpeg";
import Learn2 from "assets/images/businessmodel/Page-8/learn2.jpeg";
import Wrong from "assets/images/businessmodel/Page-8/wrong.png";
import page8_blob from "assets/images/businessmodel/Page-8/blobpage8.svg";
import Learnpeople1 from "assets/images/businessmodel/Page-8/learnpeople1.jpg";
import Learnpeople2 from "assets/images/businessmodel/Page-8/learnpeople2.png";
import Learnpeople3 from "assets/images/businessmodel/Page-8/learnpeople3.jpg";

//page9
import Cardimg1 from "assets/images/businessmodel/Page9/cardimg1.png";
import Cardimg2 from "assets/images/businessmodel/Page9/cardimg2.png";
import Cardimg3 from "assets/images/businessmodel/Page9/cardimg3.png";
import Creative1 from "assets/images/businessmodel/Page9/Creative1.png";
import Creative2 from "assets/images/businessmodel/Page9/Creative2.png";
import InfoSlider1 from "assets/images/businessmodel/Page9/infoslider1.png";
import InfoSlider2 from "assets/images/businessmodel/Page9/infoslider2.png";
import InfoSlider3 from "assets/images/businessmodel/Page9/infoslider3.png";
import InfoSlider4 from "assets/images/businessmodel/Page9/infoslider4.png";
import InfoSlider5 from "assets/images/businessmodel/Page9/infoslider5.png";
import InfoSlider6 from "assets/images/businessmodel/Page9/infoslider6.png";
import InfoSlider7 from "assets/images/businessmodel/Page9/infoslider7.png";
import InfoSlider8 from "assets/images/businessmodel/Page9/infoslider8.png";
import InfoSlider9 from "assets/images/businessmodel/Page9/infoslider9.png";
import InfoSlider10 from "assets/images/businessmodel/Page9/infoslider10.png";
import InfoSlider11 from "assets/images/businessmodel/Page9/infoslider11.png";
import InfoSlider12 from "assets/images/businessmodel/Page9/infoslider12.png";
import Shopping1 from "assets/images/businessmodel/Page9/Shopping1.png";
import Shopping2 from "assets/images/businessmodel/Page9/Shopping2.png";
import Shopping3 from "assets/images/businessmodel/Page9/Shopping3.png";
import Shoping_people from "assets/images/businessmodel/Page9/Shopping_people.png";

import Integration1 from "assets/images/businessmodel/Page9/Intergration1.png";
import Integration2 from "assets/images/businessmodel/Page9/Intergration2.png";
import Integration3 from "assets/images/businessmodel/Page9/Intergration3.png";
import Integration4 from "assets/images/businessmodel/Page9/Intergration4.png";
import Calendar1 from "assets/images/businessmodel/Page9/Calendar1.jpg";
import Calendar2 from "assets/images/businessmodel/Page9/Calenda2.jpg";
import Calendar3 from "assets/images/businessmodel/Page9/Calendar3.jpg";

import LogoCompany1 from "assets/images/businessmodel/Page9/p7-icons-img1.png";
import LogoCompany2 from "assets/images/businessmodel/Page9/p7-icons-img2.png";
import LogoCompany3 from "assets/images/businessmodel/Page9/p7-icons-img3.png";
import LogoCompany4 from "assets/images/businessmodel/Page9/p7-icons-img4.png";
import LogoCompany5 from "assets/images/businessmodel/Page9/p7-icons-img5.png";
import LogoCompany6 from "assets/images/businessmodel/Page9/p7-icons-img6.png";
import LogoCompany7 from "assets/images/businessmodel/Page9/p7-icons-img7.png";
import LogoCompany8 from "assets/images/businessmodel/Page9/p7-icons-img8.png";

import LogoCompanyhover1 from "assets/images/businessmodel/Page9/p7-icons-img1h.png";
import LogoCompanyhover2 from "assets/images/businessmodel/Page9/p7-icons-img2h.png";
import LogoCompanyhover3 from "assets/images/businessmodel/Page9/p7-icons-img3h.png";
import LogoCompanyhover4 from "assets/images/businessmodel/Page9/p7-icons-img4h.png";
import LogoCompanyhover5 from "assets/images/businessmodel/Page9/p7-icons-img5h.png";
import LogoCompanyhover6 from "assets/images/businessmodel/Page9/p7-icons-img6h.png";
import LogoCompanyhover7 from "assets/images/businessmodel/Page9/p7-icons-img7h.png";
import LogoCompanyhover8 from "assets/images/businessmodel/Page9/p7-icons-img8h.png";
import Samplebgimg from "assets/images/businessmodel/Page9/Samplebg-img.png";

import HeroSlider1img1 from "assets/images/businessmodel/Page9/Heroslider1img1.png";
import HeroSlider1img2 from "assets/images/businessmodel/Page9/Heroslider1img2.png";
import HeroSlider1img3 from "assets/images/businessmodel/Page9/Heroslider1img3.png";
import HeroSlider1img4 from "assets/images/businessmodel/Page9/Heroslider1img4.png";
import HeroSlider1img5 from "assets/images/businessmodel/Page9/Heroslider1img5.png";
import HeroSlider1img6 from "assets/images/businessmodel/Page9/Heroslider1img6.png";
import HeroSlider1img7 from "assets/images/businessmodel/Page9/Heroslider1img7.png";
import HeroSlider1img8 from "assets/images/businessmodel/Page9/Heroslider1img8.png";
import HeroSlider1img9 from "assets/images/businessmodel/Page9/Heroslider1img9.png";
import HeroSlider1img10 from "assets/images/businessmodel/Page9/Heroslider1img10.png";

import HeroSlider2img1 from "assets/images/businessmodel/Page9/Heroslider2img1.png";
import HeroSlider2img2 from "assets/images/businessmodel/Page9/Heroslider2img2.png";
import HeroSlider2img3 from "assets/images/businessmodel/Page9/Heroslider2img3.png";
import HeroSlider2img4 from "assets/images/businessmodel/Page9/Heroslider2img4.png";
import HeroSlider2img5 from "assets/images/businessmodel/Page9/Heroslider2img5.png";
import HeroSlider2img6 from "assets/images/businessmodel/Page9/Heroslider2img6.png";
import HeroSlider2img7 from "assets/images/businessmodel/Page9/Heroslider2img7.png";

import HeroSlider3img2 from "assets/images/businessmodel/Page9/HeroSlider3img2.png";
import HeroSlider3img3 from "assets/images/businessmodel/Page9/HeroSlider3img3.png";
import HeroSlider3img4 from "assets/images/businessmodel/Page9/HeroSlider3img4.png";
import HeroSlider3img5 from "assets/images/businessmodel/Page9/HeroSlider3img5.png";
import HeroSlider3img6 from "assets/images/businessmodel/Page9/HeroSlider3img6.png";
import HeroSlider3img7 from "assets/images/businessmodel/Page9/HeroSlider3img7.png";
import HeroSlider3img8 from "assets/images/businessmodel/Page9/HeroSlider3img8.png";
import HeroSlider3img9 from "assets/images/businessmodel/Page9/HeroSlider3img9.png";
import HeroSlider3img10 from "assets/images/businessmodel/Page9/HeroSlider3img10.png";
import HeroSlider3img11 from "assets/images/businessmodel/Page9/HeroSlider3img11.png";
import HeroSlider3img12 from "assets/images/businessmodel/Page9/HeroSlider3img12.png";
import HeroSlider3img13 from "assets/images/businessmodel/Page9/HeroSlider3img13.png";

import Herosliderballs1 from "assets/images/businessmodel/Page9/Herosliderballes1.png";
import Herosliderballs2 from "assets/images/businessmodel/Page9/Herosliderballes2.png";
import Herosliderballs3 from "assets/images/businessmodel/Page9/Herosliderballes3.png";
import Herosliderballs4 from "assets/images/businessmodel/Page9/Herosliderballes4.png";

//page10
import Page10bg1 from "assets/images/businessmodel/Page10/Page10bg.png";
import Page10bg2 from "assets/images/businessmodel/Page10/Page10bg2.png";

//fooddelivery
import layerBgImg from "assets/images/businessmodel/foodDelivery/wavy.svg";
import orderImg from "assets/images/businessmodel/foodDelivery/order-img.webp";
import hamburgerImg from "assets/images/businessmodel/foodDelivery/icons8-hamburger-96.png";
import pizzaImg from "assets/images/businessmodel/foodDelivery/icons8-pizza-100.png";
import bowlImg from "assets/images/businessmodel/foodDelivery/icons8-rice-bowl-96.png";
import brigadeiroImg from "assets/images/businessmodel/foodDelivery/brigadeiro.png";
import scooterImg from "assets/images/businessmodel/foodDelivery/scooter.png";
import mobileImg from "assets/images/businessmodel/foodDelivery/iphone.png";
import mapImg from "assets/images/businessmodel/foodDelivery/location.png";
import giftImg from "assets/images/businessmodel/foodDelivery/gift.png";
import foodOneImg from "assets/images/businessmodel/foodDelivery/food-1.jpg";
import foodTwoImg from "assets/images/businessmodel/foodDelivery/food-2.jpg";
import foodThreeImg from "assets/images/businessmodel/foodDelivery/food-3.jpg";
import foodFourImg from "assets/images/businessmodel/foodDelivery/food-4.jpg";
import foodFiveImg from "assets/images/businessmodel/foodDelivery/food-5.jpg";
import foodSixImg from "assets/images/businessmodel/foodDelivery/food-6.jpg";
import foodSevenImg from "assets/images/businessmodel/foodDelivery/food-7.jpg";
import foodEightImg from "assets/images/businessmodel/foodDelivery/food-8.jpg";
import cashYImg from "assets/images/businessmodel/foodDelivery/cash-yellow.svg";
import cardYImg from "assets/images/businessmodel/foodDelivery/credit-card-yellow.svg";
import globeYImg from "assets/images/businessmodel/foodDelivery/globe-yellow.svg";
import UserOneImg from "assets/images/businessmodel/foodDelivery/user-1.webp";
import UserTwoImg from "assets/images/businessmodel/foodDelivery/user-2.webp";
import UserThreeImg from "assets/images/businessmodel/foodDelivery/user-3.webp";
import UserFourImg from "assets/images/businessmodel/foodDelivery/user-4.webp";
import UserBgOneImg from "assets/images/businessmodel/foodDelivery/bg-1.png";
import UserBgThreeImg from "assets/images/businessmodel/foodDelivery/bg-2.png";
import UserBgTwoImg from "assets/images/businessmodel/foodDelivery/bg-3.webp";
import UserBgFourImg from "assets/images/businessmodel/foodDelivery/bg-4.png";
import quoteImg from "assets/images/businessmodel/foodDelivery/quote.png";

import Protectingmain from "assets/images/businessmodel/Page10/ptotectingmain.png";
import Protectingsmall from "assets/images/businessmodel/Page10/Protectingsmall.png";
import Page10HeroSection1 from "assets/images/businessmodel/Page10/Page10HeroSection1.png";
import Page10HeroSection2 from "assets/images/businessmodel/Page10/Page10HeroSection2.png";
import Page10HeroSection3 from "assets/images/businessmodel/Page10/Page10HeroSection3.png";
import Page10HeroSection4 from "assets/images/businessmodel/Page10/Page10HeroSection4.png";
import Page10HeroSection5 from "assets/images/businessmodel/Page10/Page10HeroSection5.png";
import Page10HeroSection6 from "assets/images/businessmodel/Page10/Page10HeroSection6.png";
import Page10HeroSection7 from "assets/images/businessmodel/Page10/Page10HeroSection7.png";
import Page10HeroSection8 from "assets/images/businessmodel/Page10/Page10HeroSection8.png";
import Page10HeroSection9 from "assets/images/businessmodel/Page10/Page10HeroSection9.png";
import Secure1 from "assets/images/businessmodel/Page10/Secure1.png";
import Secure2 from "assets/images/businessmodel/Page10/Secure2.png";
import Secure3 from "assets/images/businessmodel/Page10/Secure3.png";
import Secure4 from "assets/images/businessmodel/Page10/Secure4.png";
import Securebg from "assets/images/businessmodel/Page10/Securebg.png";
import DAbg from "assets/images/businessmodel/Page10/DAbg.png";
import DA1 from "assets/images/businessmodel/Page10/DA1.png";
import DA2 from "assets/images/businessmodel/Page10/DA2.png";
import DA3 from "assets/images/businessmodel/Page10/DA3.png";
import DA4 from "assets/images/businessmodel/Page10/DA4.png";
import OS_Services1 from "assets/images/businessmodel/Page10/OS_Services1.png";
import OS_Services2 from "assets/images/businessmodel/Page10/OS_Services2.png";
import OS_Services3 from "assets/images/businessmodel/Page10/OS_Services3.png";
import OS_Services4 from "assets/images/businessmodel/Page10/OS_Services4.png";
import OS_Services5 from "assets/images/businessmodel/Page10/OS_Services5.png";
import OS_Services6 from "assets/images/businessmodel/Page10/OS_Services6.png";
import OS_Services7 from "assets/images/businessmodel/Page10/OS_Services7.png";
import OS_Services8 from "assets/images/businessmodel/Page10/OS_Services8.png";
import Unique from "assets/images/businessmodel/Page10/Unique.png";
import Uniquebg from "assets/images/businessmodel/Page10/uniquebg.png";
import Protecting1 from "assets/images/businessmodel/Page10/Protecting1.png";
import Protecting2 from "assets/images/businessmodel/Page10/protecting2.png";
import Protecting3 from "assets/images/businessmodel/Page10/protecting3.png";

// healthcare

import healthInsuranceIcons from "assets/images/businessmodel/healthcare/health-insurance.png";
import healthIcons from "assets/images/businessmodel/healthcare/health.png";
import heartIcons from "assets/images/businessmodel/healthcare/heart.png";
import hospitalIcons from "assets/images/businessmodel/healthcare/hospital.png";
import stethoscopeIcons from "assets/images/businessmodel/healthcare/stethoscope.png";
import tapIcons from "assets/images/businessmodel/healthcare/tap.png";
import PhoneImg from "assets/images/businessmodel/healthcare/phone.png";
import MedicalDashboardImg from "assets/images/businessmodel/healthcare/MedicalDashboard.png";
import groupImg from "assets/images/businessmodel/healthcare/Group.png";
import medicalIcons from "assets/images/businessmodel/healthcare/medical.png";
import medicalBagIcons from "assets/images/businessmodel/healthcare/medical-bag.png";
import secureShieldIcons from "assets/images/businessmodel/healthcare/secure-shield.png";
import fileIcons from "assets/images/businessmodel/healthcare/file.png";
import doctorIcons from "assets/images/businessmodel/healthcare/doctor.png";
import SpaceIcons from "assets/images/businessmodel/healthcare/Space-Black.png";
import thumbImg from "assets/images/businessmodel/healthcare/thumbImg.png";
import pauseImg from "assets/images/businessmodel/healthcare/puse-icons.png";

//Real_estate

import Real_estatehero from "assets/images/businessmodel/Real_estate/Real_estatehero.png";
import Real_estatehero2 from "assets/images/businessmodel/Real_estate/Real_estatehero2.png";
import LaunchBus1 from "assets/images/businessmodel/Real_estate/LaunchBus1.png";
import LaunchBus2 from "assets/images/businessmodel/Real_estate/LaunchBus2.png";
import LaunchBus3 from "assets/images/businessmodel/Real_estate/LaunchBus3.png";
import LaunchBus4 from "assets/images/businessmodel/Real_estate/LaunchBus4.png";
import LaunchBus5 from "assets/images/businessmodel/Real_estate/LaunchBus5.png";
import LaunchBus6 from "assets/images/businessmodel/Real_estate/LaunchBus6.png";
import Comprehensiveimg from "assets/images/businessmodel/Real_estate/Comprehensiveimg.png";
import D_Services from "assets/images/businessmodel/Real_estate/D_services.png";
import Discoverimg from "assets/images/businessmodel/Real_estate/Discoverimg.png";
// beauty

import beautyImg from "assets/images/businessmodel/beauty/beauty-img.png";
import layerIcons from "assets/images/businessmodel/beauty/app-1.png";
import chatIcons from "assets/images/businessmodel/beauty/chat.png";
import secureIcons from "assets/images/businessmodel/beauty/secure.png";
import driveIcons from "assets/images/businessmodel/beauty/drive.png";
import sectionBgImg from "assets/images/businessmodel/beauty/section-bg.png";
import playIcons from "assets/images/businessmodel/beauty/play-icons.png";
import downloadIcons from "assets/images/businessmodel/beauty/download.png";
import userIcons from "assets/images/businessmodel/beauty/user.png";
import awardIcons from "assets/images/businessmodel/beauty/award.png";
import reviewIcons from "assets/images/businessmodel/beauty/review.png";
import settingIcons from "assets/images/businessmodel/beauty/setting.png";
import supportIcons from "assets/images/businessmodel/beauty/support.png";
import bookIcons from "assets/images/businessmodel/beauty/book.png";
import calenderIcons from "assets/images/businessmodel/beauty/calender.png";
import iphoneIcons from "assets/images/businessmodel/beauty/iPhone.png";
import loginImg from "assets/images/businessmodel/beauty/login.png";
import settingImg from "assets/images/businessmodel/beauty/setting-img.png";
import chatImg from "assets/images/businessmodel/beauty/chat-img.png";
import slideImg from "assets/images/businessmodel/beauty/slide-img.png";
import rightImg from "assets/images/businessmodel/beauty/right-arrow.png";
import leftImg from "assets/images/businessmodel/beauty/left-arrow.png";
import manageImg from "assets/images/businessmodel/beauty/manage-img.png";
import realtimeImg from "assets/images/businessmodel/beauty/real-time-img.png";
import discountImg from "assets/images/businessmodel/beauty/discount-img.png";
import referralImg from "assets/images/businessmodel/beauty/referral-img.png";
import performanceImg from "assets/images/businessmodel/beauty/performance-img.png";
import dashbordImg from "assets/images/businessmodel/beauty/dashbord-img.png";

import Solutioncard from "assets/images/businessmodel/Real_estate/solutioncard.png";
import DiscoverMacbook from "assets/images/businessmodel/Real_estate/DiscoverMacbook.png";
import Discovercardimg from "assets/images/businessmodel/Real_estate/Discovercardimg.png";
import chooseimg from "assets/images/businessmodel/Real_estate/chooseimg.png";
//freelancer
import MacBookImg from "assets/images/businessmodel/freelancer/FloatingMacBook.png";
import rightNavigateIcons from "assets/images/businessmodel/freelancer/right-arrow.png";
import userImg from "assets/images/businessmodel/freelancer/user-1.png";
import userTwoImg from "assets/images/businessmodel/freelancer/user-2.png";
import downImg from "assets/images/businessmodel/freelancer/down-arrow.png";
import firstImg from "assets/images/businessmodel/freelancer/first-img.png";
import centerImg from "assets/images/businessmodel/freelancer/center-img.png";
import lastImg from "assets/images/businessmodel/freelancer/last-job.png";
import numberOneImg from "assets/images/businessmodel/freelancer/number-1.png";
import numberTwoImg from "assets/images/businessmodel/freelancer/number-2.png";
import numberThreeImg from "assets/images/businessmodel/freelancer/number-3.png";
import numberFourImg from "assets/images/businessmodel/freelancer/number-4.png";
import profileImg from "assets/images/businessmodel/freelancer/profile.png";
import paymentImg from "assets/images/businessmodel/freelancer/payment.png";
import mobileImgs from "assets/images/businessmodel/freelancer/mobile.png";
import webImg from "assets/images/businessmodel/freelancer/web.png";
import chatImgs from "assets/images/businessmodel/freelancer/chat-1.png";
import customzableImg from "assets/images/businessmodel/freelancer/customzable.png";
import rattingImg from "assets/images/businessmodel/freelancer/ratting.png";
import socialImg from "assets/images/businessmodel/freelancer/social.png";
import sectionImage from "assets/images/businessmodel/freelancer/image.png";
import callImage from "assets/images/businessmodel/freelancer/call.png";
import documentImage from "assets/images/businessmodel/freelancer/document.png";
import settingImage from "assets/images/businessmodel/freelancer/setting-1.png";
import learnImage from "assets/images/businessmodel/freelancer/learn.png";
import ulIcons from "assets/images/businessmodel/freelancer/ul.png";
import roundLeftIcons from "assets/images/businessmodel/freelancer/round-left.png";
import roundRightIcons from "assets/images/businessmodel/freelancer/round-right.png";

// landing-page
import rightIcons from "assets/images/businessmodel/landing-page/right-arrow.png";
import rightOrangeIcons from "assets/images/businessmodel/landing-page/right-arrow-orange.png";
import rightPurpleIcons from "assets/images/businessmodel/landing-page/right-arrow-purpple.png";
import mobileFirstImg from "assets/images/businessmodel/landing-page/mobile.png";
import lightImg from "assets/images/businessmodel/landing-page/light-on.png";
import mobileWhtImg from "assets/images/businessmodel/landing-page/mobile-white.png";
import backlogImg from "assets/images/businessmodel/landing-page/backlog.png";
import bitwardenImg from "assets/images/businessmodel/landing-page/bitwarden.png";
import groupImgs from "assets/images/businessmodel/landing-page/group-mobile.png";
import shopImgs from "assets/images/businessmodel/landing-page/shop-img.png";
import watchGreenImg from "assets/images/businessmodel/landing-page/watch.png";
import mobileBlueImg from "assets/images/businessmodel/landing-page/mobile-blue.png";
import userBlueImg from "assets/images/businessmodel/landing-page/user.png";
import videoImg from "assets/images/businessmodel/landing-page/video-img.png";
import loginImgs from "assets/images/businessmodel/landing-page/login-img.png";
import maleUserImgs from "assets/images/businessmodel/landing-page/male-user.png";
import desktopImg from "assets/images/businessmodel/landing-page/desktop.png";
import listImg from "assets/images/businessmodel/landing-page/list.png";
import lineChartImg from "assets/images/businessmodel/landing-page/line-chart.png";
import downloadImg from "assets/images/businessmodel/landing-page/download.png";
import trophyImg from "assets/images/businessmodel/landing-page/trophy.png";
import businesImg from "assets/images/businessmodel/landing-page/busines.png";
import screenOneImg from "assets/images/businessmodel/landing-page/screen-1.png";
import screenTwoImg from "assets/images/businessmodel/landing-page/screen-2.png";
import screenThreeImg from "assets/images/businessmodel/landing-page/screen-3.png";
import screenFourImg from "assets/images/businessmodel/landing-page/screen-4.png";
import globerImg from "assets/images/businessmodel/landing-page/globe.png";
import userOneImg from "assets/images/businessmodel/landing-page/user-1.png";
import cloudImg from "assets/images/businessmodel/landing-page/cloud.png";
import mainImg from "assets/images/businessmodel/landing-page/main-img.png";
import googlePlayImg from "assets/images/businessmodel/landing-page/google-play.png";
import appStoreImg from "assets/images/businessmodel/landing-page/app-store.png";
import teamOneImg from "assets/images/businessmodel/landing-page/team-1.jpg";
import teamTwoImg from "assets/images/businessmodel/landing-page/team-2.jpg";
import teamThreeImg from "assets/images/businessmodel/landing-page/team-3.jpg";
import teamFourImg from "assets/images/businessmodel/landing-page/team-4.jpg";
import starYellowImg from "assets/images/businessmodel/landing-page/star-yellow.png";
import quoteLightImg from "assets/images/businessmodel/landing-page/quote.png";
import logoOneImg from "assets/images/businessmodel/landing-page/logo-1.png";
import logoTwoImg from "assets/images/businessmodel/landing-page/logo-2.png";
import logoThreeImg from "assets/images/businessmodel/landing-page/logo-3.png";
import logoFourImg from "assets/images/businessmodel/landing-page/logo-4.png";
import logoFiveImg from "assets/images/businessmodel/landing-page/logo-5.png";
import blogOneImg from "assets/images/businessmodel/landing-page/blog-1.jpg";
import blogTwoImg from "assets/images/businessmodel/landing-page/blog-2.jpg";
import blogThreeImg from "assets/images/businessmodel/landing-page/blog-3.jpg";
import blogFourImg from "assets/images/businessmodel/landing-page/blog-4.jpg";
import smartPhoneImg from "assets/images/businessmodel/landing-page/smartphone.png";




//wito

import Witohero from "assets/images/businessmodel/Wito/witohero.jpg"
import TServices1 from "assets/images/businessmodel/Wito/Tservices1.jpg"
import TServices2 from "assets/images/businessmodel/Wito/TServices2.jpg"
import Witobackground from "assets/images/businessmodel/Wito/Witobackground.jpg"
import PServices1 from "assets/images/businessmodel/Wito/PServices1.png"
import PServices2 from "assets/images/businessmodel/Wito/PServices2.png"
import PServices3 from "assets/images/businessmodel/Wito/PServices3.png"
import PServices4 from "assets/images/businessmodel/Wito/PServices4.png"
import PServices5 from "assets/images/businessmodel/Wito/PServices5.png"
import PServices6 from "assets/images/businessmodel/Wito/PServices6.png"
import FProject1 from "assets/images/businessmodel/Wito/FProject1.jpg"
import FProject2 from "assets/images/businessmodel/Wito/FProjec2.jpg"
import FProject3 from "assets/images/businessmodel/Wito/FProject3.jpg"
import Artical1 from "assets/images/businessmodel/Wito/Artical1.jpg"
import Artical2 from "assets/images/businessmodel/Wito/Artical2.jpg"
import Artical3 from "assets/images/businessmodel/Wito/Artical3.jpg"
import Artical4 from "assets/images/businessmodel/Wito/Artical4.jpg"
import Convertion from "assets/images/businessmodel/Wito/Convention.png"
import heroanimation from "assets/images/businessmodel/Wito/heroanimation.gif"
import Tsercicesboth from "assets/images/businessmodel/Wito/Tservicesboth.png"
export const icons = {
    webdesigner,
    wordpress,
    nodejs,
    laravel,
    android,
    backend,
    frontend,
    angular,
    reactjs,
    vuejs,
    fullstack,
    figma,
    php,
    flutter,
    top100Logo,
    dedicated,
    trashIcon,
    gallery16,
    gallery15,
    gallery14,
    gallery13,
    gallery12,
    gallery11,
    gallery10,
    gallery9,
    gallery8,
    gallery7,
    gallery6,
    gallery5,
    gallery4,
    gallery3,
    gallery2,
    gallery1,
    servicesDevop1,
    servicesDevop2,
    servicesDevop3,
    servicesDevop4,
    servicesDevop5,
    resumeUpload,
    resumeUploadSuccess,
    UrgentDeal,
    VacancyFresh,
    CareerHeroText,
    VacancyArrow,
    VacancyExp,
    CareerSmallRing,
    CareerBigRing,
    CareerHeroBg,
    playStore,
    logo,
    Callicon,
    Glassdoor,
    Whatsappicon,
    greenStar,
    radioOne,
    radioTwo,
    radioThree,
    radioFive,
    radioFour,
    phpIcon,
    mySqlIcon,
    laravelIcon,
    codeIgnitorIcon,
    mongoDBIcon,
    cakePhpIcon,
    goopss,
    iferp,
    uHello,
    uStore,
    uGreen,
    citronLogo,
    quickDrive,
    udenzLogo,
    mohamedLogo,
    paydLogo,
    udenzPortfolio,
    paydPortfolio,
    mohamedPortfolio,
    quickDrivePortfolio,
    citronPortfolio,
    iferpPortfolio,
    macBook,
    www,
    cartIcon,
    googleIcon,
    clucthIcon,
    emailIcon,
    PhoneIcon,
    india,
    facebookIcon,
    instagramIcon,
    linkedInIcon,
    arrowRightIcon,
    portDoctor,
    portFood,
    portMobile,
    portProgres,
    MacbookImg,
    IphoneImg,
    GetStartRing,
    GetRingArrow,
    GetRingText,
    MacImg1,
    MacImg2,
    MacImg3,
    MacImg4,
    MacImg5,
    PhiImg1,
    PhiImg2,
    PhiImg3,
    PhiImg4,
    PhiImg5,
    FlagIcon,
    CompanyAbout,
    CompanyCareer,
    CompanyWhy,
    CompanyWork,
    TouchImage,
    TechnologieImg,
    Technology1,
    Technology2,
    Technology3,
    Technology4,
    Technology5,
    Technology6,
    Technology7,
    Technology8,
    Technology9,
    Technology10,
    Technology11,
    Technology12,
    Technology13,
    Technology14,
    Technology15,
    Technology16,
    Technology17,
    Technology18,
    Technology19,
    Technology20,
    Technology21,
    Technology22,
    Technology23,
    Technology24,
    Technology25,
    Technology26,
    Technology27,
    Technology28,
    FillStar,
    HalfStar,
    Star,
    PlayBtn,
    AboutText,
    MissionIcon,
    MissionCeo,
    MissionBorder,
    TeamMember1,
    TeamMember2,
    TeamMember3,
    TeamMember4,
    TeamMember5,
    TeamMember6,
    TeamImg1,
    TeamImg2,
    TeamImg3,
    TeamImg4,
    TeamImg5,
    TeamImg6,
    TeamImg7,
    TeamImg8,
    TeamPlusImg,
    JourneyRound,
    Services1,
    Services2,
    Services3,
    Services4,
    Services5,
    Services21,
    Services22,
    Services23,
    Services24,
    Services31,
    Services32,
    Services33,
    Services34,
    Services35,
    Services36,
    Services51,
    gImage1,
    gImage2,
    gImage3,
    gImage6,
    gImage5,
    gImage4,
    g2Image1,
    g2Image2,
    g2Image3,
    g2Image4,
    g2Image6,
    learningIcon,
    motovationalIcon,
    holidayIcon,
    celebrationIcon,
    salaryIcon,
    environmentIcon,
    professionalIcon,
    loyaltyIcon,
    ServicesWeb,
    ServicesApp,
    ServicesJs,
    ServicesEcommerce,
    ServicesDevops,
    Business1,
    Business2,
    Business3,
    Business4,
    Business5,
    Business6,
    Business7,
    Business8,
    Business9,
    Business10,
    Business11,
    Business12,
    Business13,
    Business14,
    Business15,
    Business16,
    Business17,
    detail1,
    detail2,
    detail3,
    detail4,
    detail5,
    detail6,
    detail7,
    detail8,
    countries_icon1,
    countries_icon2,
    countries_icon3,
    countries_icon4,
    project_icon1,
    project_icon2,
    project_icon3,
    project_icon4,
    country1,
    country2,
    country3,
    country4,
    country5,
    country6,
    country7,
    country8,
    country9,
    checkedIcon,
    hireResourcesFormIcon,
    hireResourGreenDot,
    hireResourWhiteDot,
    hireResourcesStap1,
    hireResourcesStap2,
    hireResourcesStap3,
    hireResourcesStap4,
    hireResourcesText,
    hireResourcesCareIcon,
    hireResourcesDeveloperImg,
    hireResourcesFormImg,
    hireResourcesSecure,
    ArrowBottom,
    stepUpArrow,
    stepDownArrow,
    heroBanner1,
    heroBanner2,
    heroBanner3,
    heroBanner4,
    heroBannerM1,
    heroBannerM2,
    heroBannerM3,
    heroBannerM4,
    st1,
    st2,
    st3,
    st4,
    st5,
    st6,
    st7,
    st8,
    st9,
    st10,
    st11,
    st12,
    st13,
    st14,
    st15,
    st16,
    st17,
    st18,
    st19,
    st20,
    st21,
    st22,
    st23,
    st24,
    communicationImage,
    learning,
    querySession,
    blogSlider,
    locationIcons,
    searchIcons,
    upRightArrow,
    youtubeIcons,
    phone,
    LoveSection,
    // phone2,
    work,
    slider1,
    slider2,
    ring1,
    ring2,
    review1,
    review2,
    background,
    feedback,
    tropy,
    download,
    user,
    page3Screenshot1,
    page3Screenshot2,
    page3Screenshot3,
    page3Screenshot4,
    page3Screenshot5,
    ratingFillStar,
    ratingLineStar,
    Page_Hero,
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    Simple1,
    Simple2,
    Simple3,
    Simple4,
    features_1,
    features_2,
    features_3,
    features_4,
    people1,
    people2,
    people3,
    comapny1,
    comapny2,
    comapny3,
    comapny6,
    comapny7,
    comapny8,
    comapny9,
    comapny10,
    comapny11,
    comapny12,
    comapny13,
    comapny14,
    comapny15,
    comapny4,
    comapny5,
    maskimg,
    phonepage2,
    businessicon1,
    businessicon2,
    businessicon3,
    businessicon4,
    businessicon5,
    businessicon6,
    worksession1,
    worksession2,
    worksession3,
    Bank_phone,
    Productivity_phone,
    Quote,
    Avtar1,
    Avtar2,
    Avtar3,
    GooglePlay,
    AppStore,
    page7Heroimg,
    Qualifucation1,
    Qualifucation2,
    Qualifucation3,
    Qualifucation4,
    Dashboardimg,
    Grow1,
    Grow2,
    Grow3,
    Coverd1,
    Coverd2,
    Coverd3,
    Coverd4,
    Coverd5,
    Process1,
    Process2,
    Process3,
    Process4,
    Breeze,
    Trustedpeople1,
    Trustedpeople2,
    Trustedpeople3,
    Trustedpeople4,
    Dive,
    Page8hero,
    Features1,
    Features2,
    Features3,
    Features4,
    Features5,
    Features6,
    Look1,
    Look2,
    Look3,
    Look4,
    Look5,
    Look6,
    Look7,
    Look8,
    Look9,
    Look10,
    Look11,
    Look12,
    Learn1,
    Learn2,
    Learn3,
    Wrong,
    page8_blob,
    Learnpeople1,
    Learnpeople2,
    Learnpeople3,

    socialIcons,
    socialIconOne,
    socialIconTwo,
    socialIconThree,
    socialIconFour,
    saleIconsOne,
    saleIconsTwo,
    saleIconsThree,
    saleIconsFour,
    slantedImg,
    featuresImgOne,
    featuresImgTwo,
    featuresImgThree,
    GrowImgOne,
    GrowImgTwo,
    GrowImgThree,
    storeImg,
    bankImg,
    factoryImg,
    churchImg,
    warehouseImg,
    trustedUoImg,
    trustedUtImg,
    trustedUthImg,
    trustedUfImg,
    grubSpotIcons,
    tribetIcons,
    phasekitIcons,
    gutworkIcons,
    proactiveIcons,

    //page9
    Cardimg1,
    Cardimg2,
    Cardimg3,
    Creative1,
    Creative2,
    InfoSlider1,
    InfoSlider2,
    InfoSlider3,
    InfoSlider4,
    InfoSlider5,
    InfoSlider6,
    InfoSlider7,
    InfoSlider8,
    InfoSlider9,
    InfoSlider10,
    InfoSlider11,
    InfoSlider12,
    Shopping1,
    Shopping2,
    Shopping3,
    Shoping_people,

    Integration1,
    Integration2,
    Integration3,
    Integration4,
    Calendar1,
    Calendar2,
    Calendar3,
    LogoCompany1,
    LogoCompany2,
    LogoCompany3,
    LogoCompany4,
    LogoCompany5,
    LogoCompany6,
    LogoCompany7,
    LogoCompany8,
    LogoCompanyhover1,
    LogoCompanyhover2,
    LogoCompanyhover3,
    LogoCompanyhover4,
    LogoCompanyhover5,
    LogoCompanyhover6,
    LogoCompanyhover7,
    LogoCompanyhover8,
    Samplebgimg,
    HeroSlider1img1,
    HeroSlider1img2,
    HeroSlider1img3,
    HeroSlider1img4,
    HeroSlider1img5,
    HeroSlider1img6,
    HeroSlider1img7,
    HeroSlider1img8,
    HeroSlider1img9,
    HeroSlider1img10,
    HeroSlider2img1,
    HeroSlider2img2,
    HeroSlider2img3,
    HeroSlider2img4,
    HeroSlider2img5,
    HeroSlider2img6,
    HeroSlider2img7,

    HeroSlider3img2,
    HeroSlider3img3,
    HeroSlider3img4,
    HeroSlider3img5,
    HeroSlider3img6,
    HeroSlider3img7,
    HeroSlider3img8,
    HeroSlider3img9,
    HeroSlider3img10,
    HeroSlider3img11,
    HeroSlider3img12,
    HeroSlider3img13,

    Herosliderballs1,
    Herosliderballs2,
    Herosliderballs3,
    Herosliderballs4,

    //page10
    Page10bg1,
    Page10bg2,
    Page10HeroSection1,
    Page10HeroSection2,
    Page10HeroSection3,
    Page10HeroSection4,
    Page10HeroSection5,
    Page10HeroSection6,
    Page10HeroSection7,
    Page10HeroSection8,
    Page10HeroSection9,
    Secure1,
    Secure2,
    Secure3,
    Secure4,
    Securebg,
    DAbg,
    DA1,
    DA2,
    DA3,
    DA4,
    OS_Services1,
    OS_Services2,
    OS_Services3,
    OS_Services4,
    OS_Services5,
    OS_Services6,
    OS_Services7,
    OS_Services8,
    Unique,
    Uniquebg,
    Protecting1,
    Protecting2,
    Protecting3,
    Protectingmain,
    Protectingsmall,

    //food delivery
    layerBgImg,
    orderImg,
    brigadeiroImg,
    bowlImg,
    pizzaImg,
    hamburgerImg,
    giftImg,
    mapImg,
    mobileImg,
    scooterImg,
    foodOneImg,
    foodTwoImg,
    foodThreeImg,
    foodFourImg,
    foodFiveImg,
    foodSixImg,
    foodSevenImg,
    foodEightImg,
    globeYImg,
    cardYImg,
    cashYImg,
    UserBgFourImg,
    UserBgThreeImg,
    UserBgTwoImg,
    UserBgOneImg,
    UserFourImg,
    UserThreeImg,
    UserTwoImg,
    UserOneImg,
    quoteImg,
    tapIcons,
    stethoscopeIcons,
    hospitalIcons,
    heartIcons,
    healthIcons,
    healthInsuranceIcons,
    PhoneImg,
    MedicalDashboardImg,
    doctorIcons,
    fileIcons,
    secureShieldIcons,
    medicalBagIcons,
    medicalIcons,
    groupImg,
    pauseImg,
    thumbImg,
    SpaceIcons,

    //Real_estate
    Real_estatehero,
    Real_estatehero2,
    LaunchBus1,
    LaunchBus2,
    LaunchBus3,
    LaunchBus4,
    LaunchBus5,
    LaunchBus6,
    Comprehensiveimg,
    D_Services,
    Discoverimg,
    beautyImg,

    driveIcons,
    chatIcons,
    layerIcons,
    secureIcons,
    sectionBgImg,
    playIcons,
    downloadIcons,
    userIcons,
    reviewIcons,
    awardIcons,
    settingIcons,
    supportIcons,
    bookIcons,
    calenderIcons,
    iphoneIcons,
    loginImg,
    settingImg,
    chatImg,
    slideImg,
    leftImg,
    rightImg,
    manageImg,
    performanceImg,
    dashbordImg,
    realtimeImg,
    referralImg,
    discountImg,
    Solutioncard,
    DiscoverMacbook,
    Discovercardimg,
    chooseimg,
    MacBookImg,
    rightNavigateIcons,
    userImg,
    userTwoImg,
    downImg,
    firstImg,
    numberOneImg,
    numberTwoImg,
    numberThreeImg,
    numberFourImg,
    centerImg,
    lastImg,
    socialImg,
    rattingImg,
    customzableImg,
    chatImgs,
    webImg,
    mobileImgs,
    paymentImg,
    profileImg,
    sectionImage,
    callImage,
    documentImage,
    settingImage,
    learnImage,
    ulIcons,
    roundRightIcons,
    roundLeftIcons,
    rightIcons,
    rightOrangeIcons,
    rightPurpleIcons,
    mobileFirstImg,
    lightImg,
    mobileWhtImg,
    backlogImg,
    bitwardenImg,
    groupImgs,
    shopImgs,
    watchGreenImg,
    userBlueImg,
    mobileBlueImg,
    videoImg,
    loginImgs,
    listImg,
    desktopImg,
    maleUserImgs,
    businesImg,
    trophyImg,
    downloadImg,
    lineChartImg,
    screenOneImg,
    screenTwoImg,
    screenThreeImg,
    screenFourImg,
    cloudImg,
    userOneImg,
    globerImg,
    mainImg,
    googlePlayImg,
    appStoreImg,
    teamOneImg,
    teamTwoImg,
    teamThreeImg,
    teamFourImg,
    starYellowImg,
    quoteLightImg,
    logoOneImg,
    logoTwoImg,
    logoThreeImg,
    logoFourImg,
    logoFiveImg,
    blogOneImg,
    blogTwoImg,
    blogThreeImg,
    blogFourImg,
    smartPhoneImg,

    //wito
    Witohero,
    TServices1,
    TServices2,
    Witobackground,
    PServices1,
    PServices2,
    PServices3,
    PServices4,
    PServices5,
    PServices6,
    FProject1,
    FProject2,
    FProject3,
    Artical1,
    Artical2,
    Artical3,
    Artical4,
    Convertion,
    heroanimation,
    Tsercicesboth
};

export default icons;
