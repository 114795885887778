import icons from "utils/constants/icons";
import TouchBtn from "components/common/TouchBtn";
import ExploreBtn from "components/common/ExploreBtn";
import "./BenefitsJoining.scss";

const BenefitsJoining = () => {
    return (
        <div id="benefits-of-joining-container" className="container">
            <h4 className="title">BENEFITES OF JOINING</h4>
            <h3 className="sub-title">
                Chapter in your professional journey, offering not just a
                partnership but a multitude of add-on benefits that set us
                apart. As part of our esteemed partner community, you'll unlock
                a range of advantages designed to enrich your experience and
                maximize your potential.
            </h3>
            <div className="joining-contents">
                <div className="row g-0">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="border-dotted-end border-dotted-bottom box-1 benefit-cards">
                            <img
                                src={icons.learningIcon}
                                alt="learning"
                                className="joining-content-icon"
                            />
                            <h4 className="joining-content-title">
                                Learning Sessions
                            </h4>
                            <p className="joining-content-description">
                                Participate in exclusive learning sessions
                                tailored to enhance your skills and knowledge.
                                Leverage our brand value and trust, cultivated
                                over the last decade of delivering robust app
                                solutions.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="border-dotted-end border-dotted-bottom box-2 benefit-cards">
                            <img
                                src={icons.motovationalIcon}
                                alt="motivational"
                            />
                            <h4 className="joining-content-title">
                                Motivational Sessions
                            </h4>
                            <p className="joining-content-description">
                                We believe in mutual growth. Access motivational
                                sessions and curated content designed to elevate
                                your salesmanship acumen, fostering an
                                environment where partners support each other's
                                success.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="border-dotted-end border-dotted-bottom box-3 benefit-cards">
                            <img src={icons.holidayIcon} alt="holiday" />
                            <h4 className="joining-content-title">
                                Every Saturday Off
                            </h4>
                            <p className="joining-content-description">
                                Enjoy the perk of a rejuvenating break every
                                Saturday. As our partner, you not only
                                contribute to expanding our brand reach but also
                                receive a personalized branded website crafted
                                by our experts.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="border-dotted-bottom box-4 benefit-cards">
                            <img
                                src={icons.celebrationIcon}
                                alt="celebration"
                            />
                            <h4 className="joining-content-title">
                                Festival Celebration
                            </h4>
                            <p className="joining-content-description">
                                Join us in celebrating milestones and festivals.
                                Our collaborative approach extends to fostering
                                a sense of community and shared success, making
                                your partnership experience more enriching.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="border-dotted-end  box-5 benefit-cards">
                            <img src={icons.salaryIcon} alt="salary" />
                            <h4 className="joining-content-title">
                                Punctual on Salary
                            </h4>
                            <p className="joining-content-description">
                                We value your commitment. Enjoy the assurance of
                                timely salary disbursements, reflecting our
                                commitment to transparency and reliability in
                                our professional relationships.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="border-dotted-end box-6 benefit-cards">
                            <img
                                src={icons.environmentIcon}
                                alt="environment"
                            />
                            <h4 className="joining-content-title">
                                Friendly Environment
                            </h4>
                            <p className="joining-content-description">
                                Experience a friendly and inclusive work
                                environment. Your journey as our partner is
                                marked by collaboration and camaraderie,
                                creating a space where every contribution is
                                valued.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="border-dotted-end box-7 benefit-cards">
                            <img
                                src={icons.professionalIcon}
                                alt="professional"
                            />
                            <h4 className="joining-content-title">
                                Co-operative Seniors
                            </h4>
                            <p className="joining-content-description">
                                Benefit from a cooperative and supportive senior
                                leadership team. Our commitment to mutual growth
                                extends to a culture where guidance and
                                collaboration are integral to our collective
                                success.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="box-8 benefit-cards">
                            <img src={icons.loyaltyIcon} alt="loyalty" />
                            <h4 className="joining-content-title">
                                Loyalty Bonus
                            </h4>
                            <p className="joining-content-description">
                                Your dedication deserves recognition. Our
                                loyalty bonus program is a testament to our
                                appreciation for your ongoing commitment, making
                                your partnership with Sourrcecube Technologies
                                even more rewarding.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="joining-bg-image">
                <div>
                    <h4>
                        Let Sourrcecube Technologies be the next chapter in your
                        professional journey, where partnership is not just a
                        label but a doorway to growth, camaraderie, and success.
                        Join us and experience the difference.
                    </h4>
                    <div className="d-flex gap-sm-3 gap-2 flex-wrap">
                        <TouchBtn title="Let's Talk" />
                        <ExploreBtn title="Explore Our Services" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BenefitsJoining;
