import React, { useState } from "react";
import "./FaqSection.scss";
import icons from "utils/constants/icons";
const FaqSection = ({ data }) => {
  const [selectedItem, setSelectedItem] = useState(1);

  const handleItemClick = (index) => {
    setSelectedItem(selectedItem === index ? null : index);
  };
  return (
    <div id="Faq-Section-id">
      <div className="container">
        {data?.map((ele, index) => {
          const { mainTitle, description, question } = ele;
          return (
            <div key={index}>
              <h4 className="title section-line">{mainTitle}</h4>
              <h3 className="sub-title mb-0 pb-1">{description}</h3>
              {question?.map((elm, qindex) => {
                const { id, title, subtitle } = elm;
                return (
                  <div
                    key={qindex}
                    className={`faq-section-div ${
                      id === selectedItem ? "select-faq-section" : ""
                    } `}
                    onClick={() => handleItemClick(id)}>
                    <div className="main-section">
                      <div className="main-faq-section">{title}</div>
                      <div className="faq-img">
                        <img src={icons.ArrowBottom} alt="arrowbottomimg" />
                      </div>
                    </div>

                    {selectedItem === id && (
                      <div className="sub-faq-section">{subtitle}</div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FaqSection;
