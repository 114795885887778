import React from 'react'
import "./Learning.scss";
import icons from 'utils/constants/icons';

const Learning = () => {
  return (
    <div id="learning-section" className="container mt-4">
        <h4 className="title">Learning</h4>
        <h3 className="sub-title">Learning & Training</h3>
        <div className="d-flex image-left">
            <div className="learning-image">
                <img src={icons.communicationImage} className="learning-img" alt="img" />
            </div>
            <div className="learning-content">
                <div className="learning-title mb-2">Communication Skill Sessions</div>
                <p className="learning-desc">Effective communication serves as the cornerstone for building enduring relationships, both personal and professional. It is also paramount in ensuring flawless product or service delivery. Our Communication Skills Sessions empower participants to master the art of articulate expression and information exchange across diverse contexts</p>
            </div>
        </div>
        <div className="d-flex image-right mt-5">
            <div className="learning-image">
                <img src={icons.querySession} className="learning-img" alt="img" />
            </div>
            <div className="learning-content">
                <div className="learning-title mb-2">Townhall Meeting/Sessions</div>
                <p className="learning-desc">Town hall meeting, where open dialogue and collaboration take center stage, driving our collective vision forward. Together, let's engage, inspire, and shape the future of our company.</p>
            </div>
        </div>
        <div className="d-flex image-left mt-5">
            <div className="learning-image">
                <img src={icons.learning} className="learning-img" alt="img" />
            </div>
            <div className="learning-content">
                <div className="learning-title mb-2">Learning Sessions</div>
                <p className="learning-desc">At Sourcecube, we prioritize employee growth and development, offering tailored learning sessions that nurture skills and foster innovation. Join us as we invest in your success, equipping you with the tools and knowledge to thrive in your role and beyond.</p>
            </div>
        </div>
    </div>
  )
}

export default Learning;
