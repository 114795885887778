import React from "react";
import feat1 from "assets/images/businessmodel/page3/feat1.png";
import feat2 from "assets/images/businessmodel/page3/feat2.png";
import feat3 from "assets/images/businessmodel/page3/feat3.png";
import feat4 from "assets/images/businessmodel/page3/feat4.png";
import feat5 from "assets/images/businessmodel/page3/feat5.png";
import feat6 from "assets/images/businessmodel/page3/feat6.png";
import "./DigitizeYourBusiness.scss";

const DigitizeYourBusiness = () => {
  let data = [
    {
      image: feat1,
      title: "Web & Mobile App",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?",
      delay: "0",
    },
    {
      image: feat2,
      title: "Smart Dispatch System",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?",
      delay: "200",
    },
    {
      image: feat3,
      title: "In App Chat",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?",
      delay: "400",
    },
    {
      image: feat4,
      title: "Get Running in No Time",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?",
      delay: "600",
    },
    {
      image: feat5,
      title: "Source Code",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?",
      delay: "800",
    },
    {
      image: feat6,
      title: "Full Customization",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?",
      delay: "1000",
    },
  ];
  return (
    <div className="container digitizeYourBusiness-container">
      <div className="m-title">Digitize Your Business</div>
      <div className="sm-title">Catering 200+ On Demand Businesses</div>
      <div className="row">
        {data.length &&
          data.map((val, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <div
                className="icon-box text-center p-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-mirror="false"
                data-aos-once="true"
                data-aos-delay={val.delay}
              >
                <img
                  src={val.image}
                  alt="feat-1"
                  className="mb-3"
                  height={70}
                />
                <h3 className="mb-2">{val.title}</h3>
                <p>{val.description}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DigitizeYourBusiness;
