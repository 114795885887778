import React from "react";
import "./ServicesSection.scss";
import icons from "utils/constants/icons";
const ServicesSection = ({ data, isTrue }) => {
  return (
    <div id="Services-Section-id">
      {data?.map((ele, index) => {
        const {
          sectionTitle,
          sectionDescription,
          mainTitle,
          subTitle,
          Services,
          sProjects,
          itProfessionals,
          experience,
          sProjectsTitle,
          itProfessionalsTitle,
          experienceTitle,
        } = ele;
        return (
          <div className="container" key={index}>
            <h4 className="title section-line">{sectionTitle}</h4>
            <h3 className="sub-title mb-0 pb-1">{sectionDescription}</h3>
            <div className="development-card">
              <div className="row">
                <div className="col-lg-6">
                  {isTrue ? (
                    <div className="service-div">
                      <div className="service-rel">
                        <div className="service-type">
                          <div className="service-div-1">
                            <h1 className="service-number">
                              {sProjects}
                              <span className="ms-1"> +</span>
                            </h1>
                            <p className="service-title">{sProjectsTitle}</p>
                          </div>
                          <div className="service-div-2">
                            <h1 className="service-number">
                              {itProfessionals} <span className="ms-1">+</span>
                            </h1>
                            <p className="service-title">
                              {itProfessionalsTitle}
                            </p>
                          </div>
                          <div className="service-div-3">
                            <h1 className="service-number">
                              {experience}
                              <span className="ms-1">+</span>
                            </h1>
                            <p className="service-title">{experienceTitle}</p>
                          </div>
                        </div>
                        <div className="service-support">
                          <div className="support-div">
                            <div className="support-image">
                              <img
                                src={icons?.hireResourcesCareIcon}
                                alt="support"
                                className="service-image"
                              />
                            </div>
                            <h5 className="support-text">
                              Care & <br /> Service Support
                            </h5>
                          </div>
                          <div className="support-div">
                            <div className="support-image">
                              <img
                                src={icons?.hireResourcesSecure}
                                alt="support"
                                className="service-image"
                              />
                            </div>
                            <h5 className="support-text">
                              Guaranteed <br /> Secure
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="first-dot">
                        <img src={icons.hireResourGreenDot} alt="GreenDot" />
                      </div>
                      <div className="second-dot">
                        <img src={icons.hireResourWhiteDot} alt="WhiteDot" />
                      </div>
                      <div className="third-dot">
                        <img src={icons.hireResourGreenDot} alt="GreenDot" />
                      </div>
                    </div>
                  ) : (
                    <div className="service-div-text">
                      <div className="service-main-title">{mainTitle}</div>
                      <div className="service-sub-title">{subTitle}</div>
                      <div className="first-dot">
                        <img src={icons.hireResourGreenDot} alt="GreenDot" />
                      </div>
                      <div className="second-dot">
                        <img src={icons.hireResourWhiteDot} alt="WhiteDot" />
                      </div>
                      <div className="third-dot">
                        <img src={icons.hireResourGreenDot} alt="GreenDot" />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  {Services?.map((elm, sindex) => {
                    const { title, description, number } = elm;
                    return (
                      <div className="service-card" key={sindex}>
                        <h3 className="service-card-title">{title}</h3>
                        <span className="service-card-subtitle">
                          {description}
                        </span>
                        <h1 className="number-position">{number}</h1>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ServicesSection;
