import React, { useState } from "react";
import "./TextAreaFile.scss";
import LabelText from "../LabelText";
import icons from "utils/constants/icons";
const TextAreaFile = ({
  label,
  placeholder,
  error,
  onChange,
  id,
  value,
  isRequired,
  onfileChange,
  attachment,
}) => {
  const [lineHide, setLineHide] = useState(false);

  const handleTextAreaFocus = () => {
    setLineHide(true);
  };
  const handleTextAreaBlur = () => {
    setLineHide(false);
  };
  return (
    <div id="text-area-and-file-container">
      {label && <LabelText label={label} required={isRequired} />}

      <textarea
        rows={3}
        className="form-control"
        onChange={onChange}
        id={id}
        onFocus={handleTextAreaFocus}
        onBlur={handleTextAreaBlur}
        value={value}
        placeholder={placeholder}
      />
      <div className="d-flex center-line align-items-center">
        <div className="left-line"></div>
        {!lineHide ? (
          <>
            <div className="d-flex  ms-1 me-1 text-or">Or</div>
          </>
        ) : (
          <div className="div-line"></div>
        )}
        <div className="right-line"></div>
      </div>
      <div className="file-input">
        <input
          type="file"
          title=""
          // multiple
          onChange={onfileChange}
          className="textfile"
          accept=".pdf, .doc, .docx, .jpg, .jpeg"
        />
        <div className="file-div">
          <div className="form-image-div">
            <img
              src={icons.hireResourcesFormIcon}
              alt="hireResourcesFormIcon"
            />
          </div>
          <div className="file-text-div">
            <div className="file-main-title">
              {attachment?.name || "Have something to showcase?"}
            </div>
            <div className="file-sub-title">
              Click here to upload file (.doc, .pdf, .jpg)
            </div>
          </div>
        </div>
      </div>
      {error && (
        <span className="text-13-500 pt-1">
          <span className="dropdown-error-text">{error}</span>
        </span>
      )}
    </div>
  );
};

export default TextAreaFile;
