import "./AboutSection.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import icons from "utils/constants/icons";

const AboutSection = ({ data }) => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);
    const aboutData = [
        {
            icon: icons?.layerIcons,
            title: "FiLayers",
            desc: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolo",
        },
        {
            icon: icons?.chatIcons,
            title: "Live Chat",
            desc: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
        },
        {
            icon: icons?.secureIcons,
            title: "Secure Data",
            desc: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolo",
        },
        {
            icon: icons?.driveIcons,
            title: "Access Drive",
            desc: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor",
        },
    ];

    return (
        <div className="main-about-container">
            <div className="container">
                <h4 className="left-title">ABOUT APPSIDE</h4>
                <h1 className="left-desc">
                    That’s not all Discover <br /> even more
                </h1>
                <p className="left-para">
                    Consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolor tempor incididunt ut labore
                    dolore magna.
                </p>

                <div className="session-container">
                    <div className="row gy-xl-0 gy-4">
                        {aboutData?.map((item, index) => {
                            const { icon, title, desc } = item;
                            return (
                                <div
                                    key={index}
                                    className="col-xl-3 col-lg-4 col-md-6"
                                    data-aos="fade-left">
                                    <div className="bg-container">
                                        <div className="icon-container">
                                            <img
                                                src={icon}
                                                alt="icons"
                                                loading="lazy"
                                            />
                                        </div>
                                        <h4 className="r-title">{title}</h4>
                                        <p className="r-desc">{desc}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutSection;
