import React from "react";
import "./GrowthSection.scss";
const GrowthSection = ({ data }) => {
  return (
    <div id="Growth-Section-id">
      <div className="container">
        {data?.map((ele, index) => {
          const { sectionDescription, sectionTitle, step } = ele;
          return (
            <div key={index}>
              <h4 className="title section-line">{sectionTitle}</h4>
              <h3 className="sub-title mb-0 pb-1">{sectionDescription}</h3>
              <div className="card-top-m">
                <div className="row">
                  {step?.map((elm, sindex) => {
                    const { icon, title, description, number, stepArrow } = elm;
                    return (
                      <div className="col-lg-3 col-md-6" key={sindex}>
                        <div className={`step-card-${sindex + 1}`}>
                          <div className="icon-center">
                            <div className="icon-div">
                              <img
                                src={icon}
                                className="icon-w-h"
                                alt="hireResourcesStepImages"
                              />
                              <div className="icon-number">{number}</div>
                            </div>
                          </div>
                          <h3 className="growth-title">{title}</h3>
                          <p className="growth-subtitle">{description}</p>
                          {stepArrow && (
                            <div className={`stepUpimg-${sindex + 1}`}>
                              <img src={stepArrow} alt="stepUpArrowimg" />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GrowthSection;
