import icons from "utils/constants/icons";
import "./CloneWork.scss";
import React from "react";
import Button from "components/Button";

const CloneWork = () => {
    return (
        <React.Fragment>
            <div className="container">
                <section className="clone-work-container">
                    <h2 className="clone-section-title">
                        How Your Clone Works?
                    </h2>
                    <p className="clone-section-Sub-title">
                        Designed for everyone including professionals,
                        employers, small companies and freelancing groups, the
                        freelance service marketplace model works for two major
                        players.
                    </p>
                    <div className="last-div">
                        <div className="row gy-4">
                            <div className="col-lg-3 offset-lg-1 col-md-6">
                                <div className="left-div">
                                    <img
                                        src={icons?.userImg}
                                        alt="custom-icon"
                                        loading="lazy"
                                    />
                                </div>
                                <h3 className="left-title">CUSTOMER</h3>
                                <h6 className="left-inner-title">
                                    Login & Search
                                </h6>
                                <p className="left-inner-pra">
                                    A simple login and customer can search for
                                    the required service
                                </p>
                                <div className="down-img">
                                    <img
                                        src={icons?.downImg}
                                        alt="down-img"
                                        loading="lazy"
                                    />
                                </div>
                                <h6 className="left-inner-title">
                                    Post Requirements
                                </h6>
                                <p className="left-inner-pra">
                                    Customer specifies the project requirements
                                    & posts it
                                </p>
                                <div className="down-img">
                                    <img
                                        src={icons?.downImg}
                                        alt="down-img"
                                        loading="lazy"
                                    />
                                </div>
                                <h6 className="left-inner-title">
                                    Place the Order
                                </h6>
                                <p className="left-inner-pra">
                                    After selecting one of the posted bids,
                                    customer places the order
                                </p>
                            </div>
                            <div className="col-lg-3 offset-lg-3 col-md-6">
                                <div className="left-div">
                                    <img
                                        src={icons?.userTwoImg}
                                        alt="custom-icon"
                                        loading="lazy"
                                    />
                                </div>
                                <h3 className="left-title">ADMIN</h3>
                                <h6 className="left-inner-title">Onboarding</h6>
                                <p className="left-inner-pra">
                                    Hassle-free onboarding process for
                                    marketplace admin
                                </p>
                                <div className="down-img">
                                    <img
                                        src={icons?.downImg}
                                        alt="down-img"
                                        loading="lazy"
                                    />
                                </div>
                                <h6 className="left-inner-title">
                                    Manage Settings
                                </h6>
                                <p className="left-inner-pra">
                                    Dashboard to update & manage settings
                                </p>
                                <div className="down-img">
                                    <img
                                        src={icons?.downImg}
                                        alt="down-img"
                                        loading="lazy"
                                    />
                                </div>
                                <h6 className="left-inner-title">
                                    Up & Running
                                </h6>
                                <p className="left-inner-pra">
                                    Start providing freelancing services on the
                                    go
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className="help-container">
                <div className="container">
                    <div className="help-card">
                        <h3 className="help-title">
                            We Help You Build Customized Freelancer Marketplace
                            Platform Like Upwork Or Fiverr
                        </h3>
                        <Button
                            btnStyle="btn-black"
                            btnText="Get Started"
                            rightIcon={icons?.rightNavigateIcons}
                        />
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default CloneWork;
