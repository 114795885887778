import icons from "utils/constants/icons";
import "./Real_estateHero.scss"

const RealEstateHero =() =>{
    return (
        <>
         <div className="container-fluid main-real_estate ">
        <div className="container real_estate">
          <div className="row">
            <div className="col-lg-6 col-12 header-container mx-auto d-flex flex-column justify-content-center  justify-content-center ">
              <div className="d-flex text-center text-lg-start align-items-center">
                <div className="mx-auto">
                  <h1 className="hero-title text-center text-lg-start">
                    Transform Your
                    <span> Real Estate</span> Business with Us
                  </h1>
                  <p className="desc text-center text-lg-start" >
                    Empower your team with cutting-edge technology to streamline
                    operations, boost productivity, and maximize sales.
                  </p>
                  <div className="d-flex justify-content-lg-start justify-content-center  btn-container">
                    <button className="btn">Get Started</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className=" real_estate-imgcontainer">
              <img
                src={icons.Real_estatehero}
                alt="Background Illustration"
                className=""
              />
              </div>
            </div>
            <div className="col-lg-6 d-block d-lg-none mt-5">
              <div className=" ">
              <img
                src={icons.Real_estatehero2}
                alt="Background Illustration"
                className="img-fluid"
              />
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default RealEstateHero;