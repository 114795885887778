import icons from "utils/constants/icons";
import TouchLightBtn from "components/common/TouchLightBtn";
import ExploreBtn from "components/common/ExploreBtn";
import "./TechnologieAd.scss";

function TechnologieAd() {
    return (
        <div className="container pb-sm-5">
            <div className="technologie-ad">
                <div className="row gx-0 align-items-center">
                    <div className=" col-md-6">
                        <div className="technologie-ad-left">
                            <h2 className="mb-2">
                                Do you have a Project in Mind?
                            </h2>
                            <h3 className="mb-4">
                                We’ve brought maximum results to early stage
                                startups
                            </h3>
                            <div className="d-flex gap-sm-3 gap-2 flex-wrap">
                                <TouchLightBtn title="Let's Talk" />
                                <ExploreBtn
                                    title="Explore Our Services"
                                    className="black-btn"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="technologie-ad-right">
                            <img src={icons.TechnologieImg} alt="Technologie" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TechnologieAd;
