import React, { useState } from "react";
import icons from "utils/constants/icons";
import { useDispatch } from "react-redux";
import { setIsOpen } from "store/globalSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "./PortfolioSection.scss";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

const PortfolioSection = ({ data, sliderData }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setIsOpen(true));
  };

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <div className="portfolio-container">
      <div className="container">
        <h4 className="title section-line">PORTFOLIO</h4>
        <h3 className="sub-title mb-0 pb-1">
          The Successfully Served Businesses
        </h3>
        <div className="portfolio-sub-heading">
          We take pride in catering their growing business needs.
        </div>
      </div>
      <div className="macbook-container">
        <div className="pilse-pera container-main">
          <div className="pilse-pera-2">
            <div className="pulse-image port-cart-img">
              <img
                src={icons.cartIcon}
                alt="ellipse"
                className="ellipse pulse"
              />
            </div>
            <div className="pulse-image port-doctor-img">
              <img
                src={icons.portDoctor}
                alt="ellipse"
                className="ellipse pulse"
              />
            </div>
            <div className="pulse-image port-food-img">
              <img
                src={icons.portFood}
                alt="ellipse"
                className="ellipse pulse"
              />
            </div>
            <div className="pulse-image port-mobile-img">
              <img
                src={icons.portMobile}
                alt="ellipse"
                className="ellipse pulse"
              />
            </div>
            <div className="pulse-image port-progres-img">
              <img
                src={icons.portProgres}
                alt="ellipse"
                className="ellipse pulse"
              />
            </div>
          </div>
        </div>
        <div className="laptop-container">
          <img
            src={icons.macBook}
            alt="macbook"
            className="responsive portfolio-laptop"
          />
          <div className="portfolio-slider-pera">
            <div className="portfolio-slider-btns">
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={false}
                slidesPerView={"auto"}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper">
                {data?.map((ele, sindex) => {
                  const { images, name } = ele;
                  return (
                    <SwiperSlide key={sindex}>
                      <img src={images} alt={name} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="portfolio-slider-imgs">
              <Swiper
                loop={true}
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                }}
                navigation={true}
                breakpoints={{
                  576: {
                    navigation: false,
                  },
                }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2">
                {sliderData?.map((elm, Dindex) => {
                  const {
                    description,
                    icons,
                    images,
                    ratingName,
                    ratingNumber,
                    userName,
                    usersNumber,
                    visitorsName,
                    visitorsNumber,
                    btnTitle,
                    classNameweb,
                    topLogo,
                    title,
                    multipleImages,
                  } = elm;
                  return (
                    <SwiperSlide key={Dindex}>
                      <div className={`port-main ${classNameweb}`}>
                        {topLogo && (
                          <img
                            className="top-100-logo"
                            src={topLogo}
                            alt="top100Logo"
                          />
                        )}
                        <div className="row gx-0">
                          <div className="col-sm-6">
                            {title && (
                              <div className="port-special-text">
                                <h2>{title}</h2>
                              </div>
                            )}
                            <p className="port-text mb-3">{description}</p>
                            {multipleImages && (
                              <div className="payd-countys">
                                {multipleImages.map((ele, mindex) => {
                                  const { images } = ele;
                                  return (
                                    <div key={mindex}>
                                      <img src={images} alt="Country Icon" />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            <div className="port-available">
                              <p>Available on</p>
                              {icons &&
                                icons.map((ele, oindex) => {
                                  const { store } = ele;
                                  return (
                                    <div key={oindex}>
                                      <img src={store} alt="www" />
                                    </div>
                                  );
                                })}
                            </div>

                            <div className="port-reting">
                              {(visitorsNumber || visitorsName) && (
                                <div className="port-visitor">
                                  <h2>{visitorsNumber}</h2>
                                  <p>{visitorsName}</p>
                                </div>
                              )}
                              {(ratingNumber || ratingName) && (
                                <div className="port-visitor">
                                  <h2>{ratingNumber}</h2>
                                  <p>{ratingName}</p>
                                </div>
                              )}
                              {(usersNumber || userName) && (
                                <div className="port-visitor">
                                  <h2>{usersNumber}</h2>
                                  <p>{userName}</p>
                                </div>
                              )}
                            </div>
                            <button
                              className="btn port-btn"
                              onClick={handleClick}>
                              {btnTitle}
                            </button>
                          </div>
                          <div className="col-sm-6 port-img">
                            <img src={images} alt="paydPortfolio" />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;
