import React from "react";
import "./HirePhpDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HirePhpDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Engage Skilled PHP Developers with Confidence",
      subTitle:
        "Empower your digital initiatives with our certified PHP developers. Partner with our team and harness the expertise of our PHP professionals to gain a competitive edge. Whether it's crafting robust eCommerce solutions, enterprise-grade websites, content management systems, or dynamic web applications, our PHP developers are equipped to deliver excellence.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Unlock Exceptional PHP Solutions with Us",
      title: "Engage Our Dedicated Developers",
      text: "Welcome to our platform, your go-to destination for top-tier PHP development services. As a prominent PHP development entity, we offer access to a talented pool of dedicated PHP developers, meticulously chosen for their qualifications, experience, and proficiency in PHP technology.Our PHP developers are adept at crafting high-performing and intricate solutions, perfectly aligned with your project requirements. Whether you need support part-time, full-time, or on an ad-hoc basis, our versatile team seamlessly integrates into your workflow, ensuring seamless collaboration.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire PHP Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our PHP Developers",
      subTitle: "Unlocking the Potential Expertise of Our PHP Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "PHP Application Development",
          icon: icons.learningIcon,
          benefitdescription:
            "Partner with us to harness the expertise of our PHP developers in building robust, high-performance, and scalable PHP applications. Our developers are equipped with the necessary skills and experience to deliver top-notch quality in PHP application development, tailored to your specific requirements.",
        },
        {
          id: 2,
          benefitTitle: "PHP eCommerce Development",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Experience seamless eCommerce solutions with our dedicated PHP developers. Specializing in PHP eCommerce platforms including WooCommerce, Magento, ZenCart, PrestaShop, and OpenCart, our team ensures a smooth customer shopping experience that drives conversions and growth for your online business.",
        },
        {
          id: 3,
          benefitTitle: "CakePHP Development",
          icon: icons.holidayIcon,
          benefitdescription:
            "Discover the expertise of our developers in CakePHP framework, known for its reliability and scalability. Whether you need robust and scalable websites or custom web applications, our top-notch developers have the skills and experience to meet your CakePHP development needs.",
        },
        {
          id: 4,
          benefitTitle: "Yii Development",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Leverage the advantages of Yii framework with our skilled PHP developers. Known for developing reliable and efficient web apps, our developers possess adept knowledge and experience in utilizing Yii framework to deliver exceptional results for your web projects.",
        },
        {
          id: 5,
          benefitTitle: "Portal Development Solutions",
          icon: icons.salaryIcon,
          benefitdescription:
            "Explore our core PHP development services with a focus on portal development solutions. Our PHP experts specialize in developing comprehensive, user-friendly, and highly functional web portals tailored for startups and enterprises, enabling seamless deployment in a large network.",
        },
        {
          id: 6,
          benefitTitle: "PHP Migration Services",
          icon: icons.environmentIcon,
          benefitdescription:
            "Ensure seamless migration of your web applications with our dedicated PHP developers. Whether migrating from parallel technologies like Python, Java, or .Net, our PHP developers ensure a cost-efficient and hassle-free migration to robust and dynamic PHP platforms, empowering your business for future growth and scalability.",
        },
        {
          id: 7,
          benefitTitle: "Symfony Development",
          icon: icons.professionalIcon,
          benefitdescription:
            "Partner with our PHP developers proficient in Symfony framework to create modern, high-performance web applications. With expertise in Symfony's best practices and components, our team delivers tailored solutions that meet your business requirements, ensuring scalability, security, and maintainability.",
        },
        {
          id: 8,
          benefitTitle: "Laravel Development",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Harness the capabilities of Laravel framework with our experienced PHP developers. From building custom web applications to API development and backend systems, our team leverages Laravel's elegant syntax and robust features to deliver efficient, scalable, and maintainable solutions that drive your business forward.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of PHP Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our PHP developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "Tailored Solutions for Your Business Needs",
          description:
            "Looking for robust and scalable applications? Hire our PHP developers equipped with a high level of expertise to bring your digital ideas to life. Choose from a variety of services tailored to meet your specific requirements:",
          number: "01",
        },
        {
          id: 2,
          title: "PHP Application Development",
          description:
            "Embark on a journey of innovation with our PHP application development services. Our skilled developers craft robust and scalable applications that cater to your unique business needs, ensuring seamless functionality and user experience.",
          number: "02",
        },
        {
          id: 3,
          title: "PHP Extension Development",
          description:
            "Experience enhanced performance and functionality with our PHP extension development services. Our team delivers high-performance applications and websites, leveraging the latest technologies and best practices to meet your project requirements.",
          number: "03",
        },
        {
          id: 4,
          title: "PHP Integrations & Upgradation",
          description:
            "Stay ahead of the curve with seamless integrations and upgrades. Our experienced PHP developers specialize in integrating and upgrading existing applications with PHP, ensuring compatibility, security, and optimal performance.",
          number: "04",
        },
        {
          id: 5,
          title: "Enterprise Web Solution",
          description:
            "Empower your enterprise with scalable and technically sound solutions. Hire our dedicated PHP developers to build economically viable and technically scalable enterprise solutions, designed to meet the demands of a growing business environment.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Streamlined PHP Development Process",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "We embark on a journey of exploration, delving deep into your needs and aspirations. Through thorough research and analysis, we uncover insights to shape a roadmap that aligns perfectly with your vision.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "Creativity knows no bounds as we design solutions that push the boundaries. Our design philosophy is to blend creativity with usability, crafting interfaces that are both innovative and intuitive for your end-users.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            " Harnessing modern technologies and cutting-edge tools, we bring your ideas to life. Our development process focuses on building customized solutions that are not only reliable and scalable but also of the highest quality, ensuring your success in the digital realm.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            " Excellence is our hallmark as we follow an iterative approach to our work. With a commitment to continuous improvement, we strive to deliver excellence with every project, ensuring your satisfaction and success.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "What experience do your PHP developers have with PHP frameworks and CMS platforms?",
          subtitle:
            "Our PHP developers have extensive experience working with popular PHP frameworks such as Laravel, Symfony, CodeIgniter, and Yii, as well as CMS platforms like WordPress, Drupal, and Joomla. They are proficient in building robust web applications, APIs, and content management systems using these frameworks and platforms.",
        },
        {
          id: 2,
          title:
            "Can you describe a challenging PHP project your developers have successfully completed?",
          subtitle:
            "Our PHP developers have successfully completed various challenging projects, including developing custom web applications, e-commerce platforms, and enterprise-level systems. They excel in architecting scalable and maintainable solutions, handling complex business logic, and integrating with third-party services and APIs.",
        },
        {
          id: 3,
          title:
            "How do your PHP developers approach security in PHP application development?",
          subtitle:
            "Our PHP developers follow best practices for PHP security, including input validation, output escaping, parameterized queries, secure session management, and protection against SQL injection, XSS, CSRF, and other common vulnerabilities. They also stay updated with PHP security advisories and patches to ensure the integrity and confidentiality of web applications.",
        },
        {
          id: 4,
          title:
            "What strategies do your PHP developers employ for database interaction and optimization?",
          subtitle:
            "Our PHP developers are proficient in database interaction using MySQL, PostgreSQL, SQLite, and other relational databases, as well as NoSQL databases like MongoDB. They utilize ORM (Object-Relational Mapping) libraries, database indexing, query optimization, and caching techniques to ensure efficient data storage, retrieval, and manipulation.",
        },
        {
          id: 5,
          title:
            "How do your PHP developers handle authentication and authorization in web applications?",
          subtitle:
            "Our PHP developers implement secure authentication and authorization mechanisms using PHP authentication libraries, session management, and role-based access control (RBAC). They ensure user authentication with strong password hashing and encryption techniques, protecting sensitive data and resources from unauthorized access.",
        },
        {
          id: 6,
          title:
            "What testing methodologies and tools do your PHP developers use to ensure the quality of PHP applications?",
          subtitle:
            "Our PHP developers follow a comprehensive testing approach, including unit testing, integration testing, and end-to-end testing, to ensure the quality and reliability of PHP applications. They utilize testing frameworks like PHPUnit, Behat, and Codeception for automated testing and conduct manual testing to validate functionality, performance, and user experience.",
        },
        {
          id: 7,
          title:
            "How do your PHP developers stay updated with the latest PHP releases, frameworks, and best practices?",
          subtitle:
            "Our PHP developers are active members of the PHP community and stay updated with the latest PHP releases, frameworks, and best practices. They participate in PHP conferences, workshops, online forums, and contribute to open-source projects to enhance their skills and expertise in PHP development.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-Php-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HirePhpDeveloper;
