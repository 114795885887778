import icons from "utils/constants/icons";
import { NavLink } from "react-router-dom";
import "./Contactbtns.scss";

function ContactBtns() {
    return (
        <div className="contact-btns">
            <NavLink to="tel:7862091636" target="_blank">
                <div className="btn-wrraper call-wrraper">
                    <button className="btn call-btn">
                        <img src={icons.Callicon} alt="Call Icon" />
                    </button>
                    <p>Let's Connect</p>
                </div>
            </NavLink>
            <NavLink to="https://api.whatsapp.com/send?phone=+7862091636&text=Hello Sourrcecube Team" target="_blank"> 
                <div className="btn-wrraper">
                    <button className="btn whatsapp-btn">
                        <img src={icons.Whatsappicon} alt="Whatsapp Icon" />
                    </button>
                    <p>Chat With US</p>
                </div>
            </NavLink>
        </div>
    );
}

export default ContactBtns;
