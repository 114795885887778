import React, { useEffect, useRef, useState } from "react";
import icons from "utils/constants/icons";
import { Modal, Spinner } from "react-bootstrap";
import TextInput from "components/common/TextInput";
import TextArea from "components/common/TextArea";
import {
    applyJob,
    fetchJobDetail,
    setIsVacancy,
    throwSuccess,
} from "store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import Dropdown from "components/common/Dropdown";
import { objectToFormData } from "utils/helpers";
import "./JobDetails.scss";

const JobDetails = () => {
    const dispatch = useDispatch();
    const { singleJobData } = useSelector((state) => ({
        singleJobData: state.global.singleJobData,
    }));
    const [isLoading, setIsLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [applyPosition, setApplyPosition] = useState(false);
    const [jobDetail, setJobDetail] = useState({});
    const formRef = useRef();

    const singleJob = async () => {
        const queryParams = new URLSearchParams({
            id: singleJobData?.id,
        }).toString();
        const response = await dispatch(fetchJobDetail(queryParams));
        if (response?.status === 200) {
            setJobDetail(response?.data || []);
        }
    };

    const handleClick = () => {
        dispatch(setIsVacancy(false));
        setApplyPosition(false);
    };

    const optionsList = [
        { id: "freshers", label: "Freshers" },
        { id: "juniorDeveloper", label: "Junior Developer" },
        { id: "senoirDeveloper", label: "Senoir Developer" },
    ];

    const initialValues = {
        name: "",
        email: "",
        phone: "",
        position: "",
        resume: "",
        cover_letter: "",
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required."),
        email: Yup.string()
            .required("Email is required.")
            .email("Email must be a valid email"),
        phone: Yup.string()
            .required("Phone number is required.")
            .min(10, "Phone number must be minimum 10 digit."),
        position: Yup.string().required("Position is required."),
        resume: Yup.mixed()
            .required("Resume is required.")
            .test("fileType", "Invalid file type.", (value) => {
                if (value) {
                    return (
                        value.type === "image/jpeg" ||
                        value.type === "image/jpg" ||
                        value.type === "image/docx" ||
                        value.type === "application/pdf" ||
                        value.type === "application/doc"
                    );
                }
            }),
    });

    const handleSave = async (values) => {
        setIsLoading(true);
        const payload = { job: jobDetail?.id, ...values };
        const formData = objectToFormData(payload);
        const response = await dispatch(applyJob(formData));

        if (response?.status === 200) {
            dispatch(throwSuccess(response.message));
            if (formRef.current) {
                formRef.current.resetForm();
            }
            handleClick();
        }
        setIsLoading(false);
    };

    useEffect(() => {
        singleJob();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isJobDetailsValue =
        jobDetail?.title ||
        jobDetail?.years_of_experience ||
        jobDetail?.location ||
        jobDetail?.eligibility ||
        jobDetail?.job_type ||
        jobDetail?.start_date ||
        jobDetail?.end_date ||
        jobDetail?.working_days ||
        jobDetail?.description;

    return (
        <Modal show={true} size="lg" centered>
            {!jobDetail?.id ? (
                <div className="d-flex align-items-center justify-content-center mt-5 mb-5 spinner-m">
                    <Spinner
                        animation="border"
                        size="lg"
                        className="ms-2"
                        variant="success"
                    />
                </div>
            ) : (
                <div
                    className="row gx-0 flex-lg-row flex-column-reverse"
                    id="userDetails">
                    <div className="col-12">
                        <div className="main-sec">
                            {!applyPosition ? (
                                <>
                                    <div
                                        className={
                                            isJobDetailsValue
                                                ? "d-flex align-items-center justify-content-between"
                                                : "d-flex align-items-center justify-content-end"
                                        }>
                                        {jobDetail?.title && (
                                            <div className="heading-sec">
                                                {jobDetail?.title}
                                            </div>
                                        )}
                                        <div className="touch-right-part">
                                            <button
                                                type="button"
                                                className="touch-cancel"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={handleClick}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none">
                                                    <path
                                                        d="M13.0306 11.9695C13.1715 12.1104 13.2506 12.3015 13.2506 12.5007C13.2506 12.7 13.1715 12.8911 13.0306 13.032C12.8897 13.1729 12.6986 13.252 12.4993 13.252C12.3001 13.252 12.109 13.1729 11.9681 13.032L7.99997 9.06261L4.0306 13.0307C3.8897 13.1716 3.69861 13.2508 3.49935 13.2508C3.30009 13.2508 3.10899 13.1716 2.9681 13.0307C2.8272 12.8898 2.74805 12.6987 2.74805 12.4995C2.74805 12.3002 2.8272 12.1091 2.9681 11.9682L6.93747 8.00011L2.96935 4.03073C2.82845 3.88984 2.7493 3.69874 2.7493 3.49948C2.7493 3.30023 2.82845 3.10913 2.96935 2.96823C3.11024 2.82734 3.30134 2.74818 3.5006 2.74818C3.69986 2.74818 3.89095 2.82734 4.03185 2.96823L7.99997 6.93761L11.9693 2.96761C12.1102 2.82671 12.3013 2.74756 12.5006 2.74756C12.6999 2.74756 12.891 2.82671 13.0318 2.96761C13.1727 3.10851 13.2519 3.2996 13.2519 3.49886C13.2519 3.69812 13.1727 3.88921 13.0318 4.03011L9.06247 8.00011L13.0306 11.9695Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    {isJobDetailsValue && (
                                        <div className="details-sec row">
                                            {jobDetail?.position && (
                                                <div className="details-data col-auto">
                                                    <img
                                                        src={icons.detail8}
                                                        alt="experience"
                                                    />
                                                    <div className="content">
                                                        {jobDetail?.position}{" "}
                                                        Candidate
                                                    </div>
                                                </div>
                                            )}

                                            {jobDetail?.branch && (
                                                <div className="details-data col-auto">
                                                    <img
                                                        src={icons.detail2}
                                                        alt="location"
                                                    />
                                                    <div className="content">
                                                        {jobDetail?.branch}
                                                    </div>
                                                </div>
                                            )}

                                            {jobDetail?.skill && (
                                                <div className="details-data col-auto">
                                                    <img
                                                        src={icons.detail5}
                                                        alt="graduate"
                                                    />
                                                    <div className="content">
                                                        {jobDetail?.skill}
                                                    </div>
                                                </div>
                                            )}

                                            {jobDetail?.start_date &&
                                                jobDetail?.end_date && (
                                                    <div className="details-data col-auto">
                                                        <img
                                                            src={icons.detail3}
                                                            alt="companytiming"
                                                        />
                                                        <div className="content">
                                                            {
                                                                jobDetail?.start_date
                                                            }{" "}
                                                            /{" "}
                                                            {
                                                                jobDetail?.end_date
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            {jobDetail?.description && (
                                                <div className="col-12">
                                                    <p
                                                        className="job-description"
                                                        dangerouslySetInnerHTML={{
                                                            __html: jobDetail?.description,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="d-flex align-items-center justify-content-center mt-3">
                                        <button
                                            className="btn apply-btn"
                                            onClick={() => {
                                                setApplyPosition(true);
                                            }}>
                                            Apply Now
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="touch-right-part">
                                        <button
                                            type="button"
                                            className="touch-cancel"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={handleClick}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none">
                                                <path
                                                    d="M13.0306 11.9695C13.1715 12.1104 13.2506 12.3015 13.2506 12.5007C13.2506 12.7 13.1715 12.8911 13.0306 13.032C12.8897 13.1729 12.6986 13.252 12.4993 13.252C12.3001 13.252 12.109 13.1729 11.9681 13.032L7.99997 9.06261L4.0306 13.0307C3.8897 13.1716 3.69861 13.2508 3.49935 13.2508C3.30009 13.2508 3.10899 13.1716 2.9681 13.0307C2.8272 12.8898 2.74805 12.6987 2.74805 12.4995C2.74805 12.3002 2.8272 12.1091 2.9681 11.9682L6.93747 8.00011L2.96935 4.03073C2.82845 3.88984 2.7493 3.69874 2.7493 3.49948C2.7493 3.30023 2.82845 3.10913 2.96935 2.96823C3.11024 2.82734 3.30134 2.74818 3.5006 2.74818C3.69986 2.74818 3.89095 2.82734 4.03185 2.96823L7.99997 6.93761L11.9693 2.96761C12.1102 2.82671 12.3013 2.74756 12.5006 2.74756C12.6999 2.74756 12.891 2.82671 13.0318 2.96761C13.1727 3.10851 13.2519 3.2996 13.2519 3.49886C13.2519 3.69812 13.1727 3.88921 13.0318 4.03011L9.06247 8.00011L13.0306 11.9695Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="heading-2">Apply Now</div>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSave}
                                        innerRef={formRef}>
                                        {(props) => {
                                            const {
                                                values,
                                                errors,
                                                handleChange,
                                                handleSubmit,
                                                setFieldValue,
                                            } = props;

                                            return (
                                                <form
                                                    className="row"
                                                    onKeyUp={() => {}}>
                                                    <div className="mb-3 col-lg-6">
                                                        <TextInput
                                                            placeholder="Name"
                                                            id="name"
                                                            value={values?.name}
                                                            error={errors.name}
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-lg-6">
                                                        <TextInput
                                                            placeholder="Phone"
                                                            id="phone"
                                                            value={
                                                                values?.phone
                                                            }
                                                            error={errors.phone}
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-lg-6 ">
                                                        <TextInput
                                                            placeholder="Email"
                                                            id="email"
                                                            value={
                                                                values?.email
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            error={errors.email}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-lg-6">
                                                        <Dropdown
                                                            id="position"
                                                            options={
                                                                optionsList
                                                            }
                                                            placeholder="Position"
                                                            optionKey="id"
                                                            optionValue="label"
                                                            value={
                                                                values?.position
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            error={
                                                                errors.position
                                                            }
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-lg-6">
                                                        <TextArea
                                                            id="cover_letter"
                                                            placeholder="Cover Letter!"
                                                            value={
                                                                values?.cover_letter
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-lg-6">
                                                        <div className="resume-sec position-relative">
                                                            <label
                                                                htmlFor="resume-upload"
                                                                className="resume-label">
                                                                <input
                                                                    type="file"
                                                                    id="resume"
                                                                    className="resumeInput"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        const files =
                                                                            e
                                                                                ?.target
                                                                                ?.files[0];
                                                                        setFieldValue(
                                                                            "resume",
                                                                            files
                                                                        );
                                                                        if (
                                                                            files
                                                                        ) {
                                                                            setSelectedFile(
                                                                                files
                                                                            );
                                                                        } else {
                                                                            setSelectedFile(
                                                                                null
                                                                            );
                                                                        }
                                                                    }}
                                                                    accept=".pdf, .doc, .docx, .jpg, .jpeg"
                                                                />
                                                                <img
                                                                    src={
                                                                        selectedFile
                                                                            ? icons.resumeUploadSuccess
                                                                            : icons.resumeUpload
                                                                    }
                                                                    alt="logo"
                                                                />
                                                            </label>
                                                            <div className="d-flex justify-content-center align-items-center text-name">
                                                                <div
                                                                    className={`cv-value ${
                                                                        selectedFile
                                                                            ? "real-cv-value"
                                                                            : ""
                                                                    }`}>
                                                                    {selectedFile
                                                                        ? selectedFile?.name
                                                                        : "Upload CV/Resume"}
                                                                </div>
                                                                {selectedFile && (
                                                                    <div
                                                                        className="dlt-resume"
                                                                        onClick={() => {
                                                                            setSelectedFile(
                                                                                null
                                                                            );
                                                                            setFieldValue(
                                                                                "resume",
                                                                                ""
                                                                            );
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                icons.trashIcon
                                                                            }
                                                                            alt="Trash icon"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {errors.resume && (
                                                                <div className="text-13-500 mt-1 text-start">
                                                                    <span className="dropdown-error-text">
                                                                        {
                                                                            errors.resume
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-end">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={
                                                                handleSubmit
                                                            }>
                                                            Submit
                                                            {isLoading && (
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    className="ms-2"
                                                                />
                                                            )}
                                                        </button>
                                                    </div>
                                                </form>
                                            );
                                        }}
                                    </Formik>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default JobDetails;
