export function getHeaderData() {
    let header = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': ' GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Max-Age': '1728000',
        // "Access-Control-Allow-Headers":
        //   "Origin, Content-Type, X-Auth-Token, Authorization, X-Requested-With, X-CSRF-Token, ip, Content-Length, Content-Range, Content-Disposition, Content-Description",
        Accept: 'application/json',
    };
    if (getDataFromLocalStorage('token')) {
        header = {
            ...header,
            ...{ Authorization: `Bearer ${getDataFromLocalStorage('token')}` },
        };
    }
    return header;
}

export const storeLocalStorageData = (newData) => {
    // const decryptData = decrypt(localStorage.sliikeData || {});
    // localStorage.sliikeData = encrypt({ ...decryptData, ...newData });
    const decryptData = localStorage?.sliikeData
        ? JSON.parse(localStorage?.sliikeData)
        : {};
    localStorage.sliikeData = JSON.stringify({ ...decryptData, ...newData });
};

export const getDataFromLocalStorage = (key = '') => {
    let returnValue = '';
    if (localStorage?.sliikeData) {
        // const localObjectData = decrypt(localStorage?.sliikeData);
        const localObjectData = JSON.parse(localStorage?.sliikeData);
        if (key) {
            returnValue = localObjectData[key] ? localObjectData[key] : '';
        } else {
            returnValue = localObjectData;
        }
    }
    return returnValue;
};

export const titleCaseString = (value) => {
    if (typeof value !== 'string') return '';
    return value.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const objectToFormData = (obj) => {
    let formData = new FormData();
    for (let key in obj) {
        formData.append(key, obj[key]);
    }
    return formData;
};

export const objectToQueryParams = (object) => {
    return new URLSearchParams(object).toString();
};

// Trim all left space
export const trimLeftSpace = (value) => value.replace(/^\s+/g, '');
export const trimAllSpace = (value) => value.replace(/ /g, '');
