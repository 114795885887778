import icons from "utils/constants/icons";
import "./CommandCenter.scss";

const CommandCenter = () => {
    const commandList = [
        {
            image: icons?.manageImg,
            title: "Seamless Management",
            description:
                "Get full control on your business and manage users, staff, services, etc. from a single window",
        },
        {
            image: icons?.realtimeImg,
            title: "Real-time Insights",
            description:
                "Get smart analytics, accounting details & generate sales report to take better business decisions",
        },
        {
            image: icons?.discountImg,
            title: "Offers & Discounts",
            description:
                "Create promo codes, discounts, and offers to engage customers; track their usage & performance",
        },
        {
            image: icons?.referralImg,
            title: "Referrals and Invites",
            description:
                "Flexible referral system to invite friends & relatives for increasing your customer base",
        },
        {
            image: icons?.performanceImg,
            title: "Performance Monitoring",
            description:
                "Schedule appointments & evaluate your staff's performance in an easy and quick way",
        },
    ];

    return (
        <section className="command-container">
            <div className="container">
                <h3 className="command-title">Admin Dashboard</h3>
                <h1 className="command-sub-title">Your Command Center</h1>
                <p className="command-pra">
                    Navigate through key metrics and site performance at a
                    glance. The Admin Dashboard provides you with real-time
                    data, quick shortcuts, and an overview of your site’s
                    status, making it easier to manage and oversee operations.
                </p>

                <div className="row gy-4">
                    <div className="col-lg-4">
                        <div className="left-div">
                            {commandList?.map((ele) => {
                                const { image, description, title } = ele;
                                return (
                                    <div className="main-card">
                                        <div className="main-img">
                                            <img
                                                src={image}
                                                alt="command-icons"
                                                loading="lazy"
                                            />
                                        </div>
                                        <div>
                                            <h5 className="card-title">
                                                {title}
                                            </h5>
                                            <p className="card-pra">
                                                {description}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="right-div">
                            <img
                                src={icons?.dashbordImg}
                                alt="dashbordI-img"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CommandCenter;
