import icons from "utils/constants/icons";
import "./DiscoverCard.scss";
const DiscoverCard = () => {
  return (
    <>
      <div className="container-fluid DiscoverCardmain-container">
        <div className="container DiscoverCard-container">
          <div className="row text-center  mb-5 justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 mx-auto ">
              <div className="DiscoverCard-header">
                <h1 className="display-4 mb-3 text-center  mx-auto">
                  Discover the Features of Our Innovative Mobile App
                </h1>
                <p className="lead text-muted">
                  Our real estate app is designed to be the ultimate tool for
                  both clients and professionals, offering a seamless and
                  dynamic experience from the palm of your hand. Here’s what
                  makes our app an essential part of your real estate toolkit
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center  mb-5 justify-content-center Discover-card">
            <div className="col-md-5 col-lg-6 col-sm-12 p-3">
              
              <img
                src={icons.DiscoverMacbook}
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="col-md-7 col-lg-6 col-sm-12 ">
                <div className=" d-flex flex-column card-scrollbar">
                {Array.from({ length: 10 }).map((_, index) => ( 
                     <div className="border card-Discover mx-auto shadow-lg p-3 mb-3 bg-white rounded">
                     <div className="d-flex justify-content-start align-items-center ">
                       <div className="Discovercard-img ">
                         <img
                           src={icons.Discovercardimg}
                           alt="img"
                           className="img-fluid"
                         />
                       </div>
                       <div>
                         <h1 className="card-title">Seamless Management</h1>
                       </div>
                     </div>
                     <div>
                       <p className="text-start card-desc">
                         Get full control on your business and manage users, staff,
                         services, etc. from a single window Get full control on your
                         business and manage users, staff, services, etc. from a
                         single window Get full control on your business and manage
                         users, staff, services, etc. from a single window
                       </p>
                     </div>
                   </div>
                 ))}
          
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverCard;
