import React from "react";
import "./HireWordpressDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireWordpressDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Elevate Your Digital Presence with Expert WordPress Developers",
      subTitle:
        "Empower your online presence with our seasoned WordPress developers. Collaborate with us to harness the expertise of our WordPress professionals and elevate your digital projects. Whether it's crafting custom themes, optimizing performance, or implementing intricate functionalities, our WordPress developers are poised to deliver excellence.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Unlock Outstanding WordPress Solutions with Us",
      title: "Engage Our Dedicated Developers",
      text: "Welcome to our platform, your ultimate destination for top-tier WordPress development services. As a distinguished WordPress development entity, we provide access to a talented pool of dedicated WordPress developers meticulously selected for their qualifications, experience, and mastery in WordPress development principles.Our WordPress developers excel in creating dynamic and user-friendly solutions tailored precisely to your project requisites. Whether you require part-time assistance, full-time collaboration, or specialized ad-hoc support, our adaptable team seamlessly integrates into your workflow, ensuring smooth collaboration and superior outcomes.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire Wordpress Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our Wordpress Developers",
      subTitle: "Unlocking the Potential Expertise of Our Wordpress Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Custom Theme Development",
          icon: icons.learningIcon,
          benefitdescription:
            "Partner with us to leverage the expertise of our WordPress developers in crafting custom themes that reflect your brand identity and resonate with your audience. Our developers possess the requisite skills and experience to deliver top-notch quality in WordPress theme development, tailored to your specific requirements.",
        },
        {
          id: 2,
          benefitTitle: "Performance Optimization",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Elevate the performance of your WordPress website with our dedicated WordPress developers specializing in performance optimization. From caching techniques to image compression, our team ensures seamless user experiences that drive engagement and satisfaction for your audience.",
        },
        {
          id: 3,
          benefitTitle: "Plugin Development & Integration",
          icon: icons.holidayIcon,
          benefitdescription:
            "Discover the power of custom plugin development and seamless integration with our WordPress developers. Whether you need to extend existing functionalities or implement new features, our top-notch developers have the skills and experience to meet your WordPress plugin development needs.",
        },
        {
          id: 4,
          benefitTitle: "WordPress Maintenance & Support",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Ensure the continued success of your WordPress website with our comprehensive maintenance and support services. Our dedicated WordPress developers are committed to providing hassle-free maintenance and support, ensuring smooth operation and uninterrupted business continuity.",
        },
        {
          id: 5,
          benefitTitle: "E-commerce Solutions",
          icon: icons.salaryIcon,
          benefitdescription:
            "Harness the capabilities of WordPress for e-commerce with our developers' expertise. Whether you need to set up an online store, customize WooCommerce functionalities, or optimize your e-commerce website for conversions, our team delivers tailored solutions to meet your business goals.",
        },
        {
          id: 6,
          benefitTitle: "Multilingual Website Development",
          icon: icons.environmentIcon,
          benefitdescription:
            "Expand your reach and cater to a global audience with multilingual website development by our WordPress developers. We specialize in implementing multilingual solutions using plugins like WPML or Polylang, ensuring seamless translation and localization of content for diverse language preferences.",
        },
        {
          id: 7,
          benefitTitle: "Accessibility Compliance",
          icon: icons.professionalIcon,
          benefitdescription:
            "Ensure inclusivity and compliance with web accessibility standards with our WordPress developers' expertise. We design and develop accessible websites that adhere to WCAG guidelines, making your content usable and accessible to users with disabilities, thereby enhancing user experience and inclusivity.",
        },
        {
          id: 8,
          benefitTitle: "Content Strategy and SEO",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Maximize the visibility and reach of your WordPress website with our developers' expertise in content strategy and SEO. We help you create engaging and SEO-friendly content, optimize metadata, and implement on-page SEO techniques to improve search engine rankings and drive organic traffic to your website.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of Wordpress Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our Wordpress developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "E-commerce Solutions",
          description:
            "Leverage WordPress for e-commerce with our tailored solutions. We specialize in setting up and customizing e-commerce platforms using WooCommerce or other plugins, enabling you to sell products or services online with ease. From product catalog management to secure payment gateways, we ensure a seamless shopping experience for your customers.",
          number: "01",
        },
        {
          id: 2,
          title: "Multisite Development",
          description:
            "Explore the scalability and flexibility of WordPress multisite development with our expertise. Whether you need to manage multiple websites from a single WordPress installation or create a network of interconnected sites, we provide comprehensive solutions tailored to your multisite needs, including centralized management, user roles, and content sharing.",
          number: "02",
        },
        {
          id: 3,
          title: "Content Migration and Data Import",
          description:
            "Seamlessly migrate your existing website or import content from other platforms to WordPress with our expertise. We handle the migration process efficiently, ensuring data integrity, SEO preservation, and minimal downtime. Whether it's transferring content, media files, or user data, we ensure a smooth transition to your new WordPress website.",
          number: "03",
        },
        {
          id: 4,
          title: "Custom API Development",
          description:
            "Extend the capabilities of your WordPress website with custom API development. Our developers specialize in designing and implementing RESTful APIs or GraphQL endpoints to facilitate seamless communication between your WordPress site and external systems, such as mobile apps, third-party services, or internal applications.",
          number: "04",
        },
        {
          id: 5,
          title: "Accessibility Audits and Compliance",
          description:
            "Ensure your WordPress website is accessible to all users, including those with disabilities, with our accessibility audit and compliance services. We conduct thorough assessments to identify accessibility barriers and provide recommendations for remediation, ensuring compliance with WCAG guidelines and enhancing the inclusivity of your website.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for Wordpress Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Embark on a journey of discovery as we delve into your business objectives and website requirements. Through in-depth research and analysis, we gain valuable insights to inform the development process.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "Unleash creativity as we design and develop custom WordPress solutions tailored to your brand identity and user needs. Our development philosophy blends creativity with functionality, ensuring seamless user experiences across all touchpoints.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Harnessing the latest WordPress technologies and best practices, we bring your vision to life. Our development process focuses on creating responsive, scalable, and secure WordPress websites that meet the highest standards of quality and performance.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "Excellence is our standard as we follow an iterative approach to development. With a commitment to continuous improvement, we strive to deliver WordPress solutions that exceed expectations and drive business success.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      "mainTitle": "FAQ",
      "description": "Frequently Asked Questions",
      "question": [
        {
          "id": 1,
          "title": "What are the advantages of hiring a WordPress developer for my project?",
          "subtitle": "Our WordPress developers bring expertise in crafting custom themes, optimizing performance, and integrating complex functionalities tailored to your specific needs."
        },
        {
          "id": 2,
          "title": "Can you provide examples of successful WordPress projects your team has delivered?",
          "subtitle": "We have successfully developed and deployed numerous WordPress websites, ranging from corporate blogs to e-commerce platforms, each designed to meet our clients' unique requirements and goals."
        },
        {
          "id": 3,
          "title": "How do you ensure the security and privacy of WordPress websites you develop?",
          "subtitle": "We prioritize security by implementing the latest security protocols, conducting regular security audits, and ensuring strict adherence to data privacy regulations such as GDPR."
        },
        {
          "id": 4,
          "title": "What measures do you take to optimize WordPress websites for performance?",
          "subtitle": "Our team utilizes performance optimization techniques such as caching, image optimization, and code minification to ensure fast loading times and smooth user experiences for WordPress websites."
        },
        {
          "id": 5,
          "title": "Can you customize WordPress plugins to fit my specific business requirements?",
          "subtitle": "Yes, we specialize in customizing WordPress plugins to add new features, integrate third-party services, and enhance the functionality of WordPress websites according to your unique business needs."
        },
        {
          "id": 6,
          "title": "How do you stay updated with the latest WordPress trends and technologies?",
          "subtitle": "Our team actively participates in WordPress communities, attends industry conferences, and undergoes continuous training to stay abreast of the latest WordPress developments and best practices."
        },
        {
          "id": 7,
          "title": "What is your approach to collaborating with clients during the WordPress development process?",
          "subtitle": "We believe in transparent communication and close collaboration with clients throughout the development lifecycle, ensuring that their vision is realized and their feedback is incorporated at every stage."
        }
      ]
    },
  ];
  return (
    <div id="Hire-Wordpress-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireWordpressDeveloper;
