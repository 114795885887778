import "./Page3Work.scss";
import work1 from "assets/images/businessmodel/page3/work1.png";
import work2 from "assets/images/businessmodel/page3/work2.png";
import work3 from "assets/images/businessmodel/page3/work3.png";

const Page3Work = () => {
  return (
    <section className="page3-work ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <h2 className="text-black">How's App works?</h2>
            <p className="d-none d-sm-block my-3 mt-sm-4 mb-sm-5 sub-title-page-3">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
              obcaecati dignissimos quae quo ad iste ipsum officiis deleniti
              asperiores sit.
            </p>

            <p className="d-block d-sm-none my-3 sub-title-page-3">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
              obcaecati.
            </p>
          </div>

          <div className="row">
            <div className="col-12 col-md-4">
              <div className="work-container text-center p-3">
                <img src={work1} alt="work-section" className="work-icon" />
                <h3 className="text-black py-3">Install the App</h3>
                <p className="c-7085 pb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius
                  saepe, voluptates quis enim incidunt obcaecati?
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="work-container text-center p-3">
                <img src={work2} alt="work-section" className="work-icon" />
                <h3 className="text-black py-3">Setup your profile</h3>
                <p className="c-7085 pb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius
                  saepe, voluptates quis enim incidunt obcaecati?
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="work-container text-center p-3">
                <img src={work3} alt="work-section" className="work-icon" />
                <h3 className="text-black py-3">Enjoy the features!</h3>
                <p className="c-7085 pb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius
                  saepe, voluptates quis enim incidunt obcaecati?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Page3Work;
