import "./Button.scss";

const Button = ({
    btnText,
    btnStyle,
    btnType,
    onClick,
    className,
    rightIcon,
    leftIcon,
    disabled,
    btnLoading,
}) => {
    return (
        <button
            className={`btn-block ${btnStyle ? btnStyle : ""} ${
                className ? className : ""
            }`}
            onClick={onClick}
            type={btnType || "button"}
            disabled={disabled}>
            {leftIcon && (
                <img
                    src={leftIcon}
                    alt="left-icon"
                    className="h-20"
                    loading="lazy"
                />
            )}
            {btnText && <div className="button-texts"> {btnText}</div>}
            {rightIcon && (
                <img
                    src={rightIcon}
                    alt="right-icon"
                    className="h-20"
                    loading="lazy"
                />
            )}
        </button>
    );
};

export default Button;
