import "./HealthcareFeatures.scss";
import Icon1 from "assets/images/businessmodel/healthcare/maintenance.png";
import Icon2 from "assets/images/businessmodel/healthcare/bullseye.png";
import Icon3 from "assets/images/businessmodel/healthcare/folder.png";
import Icon4 from "assets/images/businessmodel/healthcare/home.png";
import Icon5 from "assets/images/businessmodel/healthcare/digital-marketing.png";
import Icon6 from "assets/images/businessmodel/healthcare/paper.png";
import icons from "utils/constants/icons";
import Button from "components/Button";
const HealthcareFeatures = () => {
    const chooseList = [
        {
            image: icons?.medicalBagIcons,
            title: "Easy Customize",
            description:
                "Tiled way blind lived whose new. The  for fully had she there leave .",
        },
        {
            image: icons?.medicalIcons,
            title: " Super Fast",
            description:
                "Tiled way blind lived whose new. The for fully had she there leave .",
        },
        {
            image: icons?.fileIcons,
            title: "  Cloud Upload",
            description:
                "Tiled way blind lived whose new. The  for fully had she there leave .",
        },
        {
            image: icons?.groupImg,
            title: "Multi Control",
            description:
                "Tiled way blind lived whose new. The  for fully had she there leave .",
        },
        {
            image: icons?.doctorIcons,
            title: "Fast Integrations",
            description:
                "Tiled way blind lived whose new. The  for fully had she there leave .",
        },
        {
            image: icons?.secureShieldIcons,
            title: "100% Secure",
            description:
                "Tiled way blind lived whose new. The  for fully had she there leave .",
        },
    ];

    return (
        <div className="features-seaction-healthcare">
            <div className="feature-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="block-feature-item">
                                <div className="row gy-lg-0 gy-md-5 gy-4">
                                    <div className="col-lg-6 ">
                                        <div className="bg-image-div">
                                            <img src={icons?.PhoneImg} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="content-block">
                                            <a
                                                href="#"
                                                className="text-decoration-none">
                                                <h4
                                                    className="feature-title-tex"
                                                    data-aos="fade-up"
                                                    data-aos-duration="800"
                                                    data-aos-mirror="false"
                                                    data-aos-once="true">
                                                    Manage to balance workloads
                                                    &amp; reso
                                                </h4>
                                            </a>
                                            <div className="content-area">
                                                <div className="text-content">
                                                    Humain tenter blemir palais
                                                    devenu veilla ere ame.
                                                    Remettre la sifflent de
                                                    criaient prennent mourants
                                                    batterie. Halte annee eu le
                                                    subit breve passa parut.
                                                    Longues rit laisses touffes
                                                    malheur deroule couleur mes.
                                                    Peu divergent ans...
                                                </div>
                                                {/* <div
                                                    className="btn-container mt-4"
                                                    data-aos="fade-down"
                                                    data-aos-duration="800"
                                                    data-aos-mirror="false"
                                                    data-aos-once="true">
                                                    <a
                                                        href="#"
                                                        className="button-item text-decoration-none">
                                                        Read More
                                                    </a>
                                                </div> */}
                                                <Button
                                                    btnStyle="btn-light-green-m"
                                                    btnText="READ MORE"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="feature-container">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="block-feature-item">
                                <div className="row  gy-lg-0 gy-md-5 gy-4">
                                    <div className="col-lg-5 order-lg-1 order-2">
                                        <div className="content-block-2">
                                            <a
                                                href="#"
                                                className="text-decoration-none">
                                                <h4
                                                    className="feature-title-tex"
                                                    data-aos="fade-up"
                                                    data-aos-duration="800"
                                                    data-aos-mirror="false"
                                                    data-aos-once="true">
                                                    Best way to connect
                                                    customers &amp; lead
                                                </h4>
                                            </a>
                                            <div className="content-area">
                                                <div className="text-content">
                                                    Humain tenter blemir palais
                                                    devenu veilla ere ame.
                                                    Remettre la sifflent de
                                                    criaient prennent mourants
                                                    batterie. Halte annee eu le
                                                    subit breve passa parut.
                                                    Longues rit laisses touffes
                                                    malheur deroule couleur mes.
                                                    Peu divergent ans...
                                                </div>
                                                {/* <div
                                                    className="btn-container mt-4"
                                                    data-aos="fade-down"
                                                    data-aos-duration="800"
                                                    data-aos-mirror="false"
                                                    data-aos-once="true">
                                                    <a
                                                        href="#"
                                                        className="button-item text-decoration-none">
                                                        Read More
                                                    </a>
                                                </div> */}
                                                <Button
                                                    btnStyle="btn-light-green-m"
                                                    btnText="READ MORE"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 offset-lg-1 offset-0 order-lg-2 order-1">
                                        <div className="bg-image-div-1">
                                            <img
                                                src={icons.MedicalDashboardImg}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="why-chose-us">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="section-title-div text-center">
                                    <h2 className="section-title">
                                        Why Choose Us
                                    </h2>
                                    <p className="section-content">
                                        We bring our diverse background in
                                        advertising, design, branding, public
                                        relations, research, and strategic
                                        planning to work for your company. Not
                                        only will your materials look great –
                                        they will get results.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {chooseList?.map((ele, index) => {
                                const { title, description, image } = ele;
                                return (
                                    <div className="col-lg-4 col-md-6">
                                        <div className="why-us-box-01 margin-bottom-30">
                                            <div className="icon">
                                                <img
                                                    src={image}
                                                    className="flaticon-repair"></img>
                                            </div>
                                            <div className="content">
                                                <h4 className="why-us-box-title">
                                                    {title}
                                                </h4>
                                                <p className="whay-us-box-content">
                                                    {description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {/* <div className="col-lg-4 col-md-6">
                                <div className="why-us-box-01 margin-bottom-30">
                                    <div className="icon">
                                        <img
                                            src={Icon2}
                                            className="flaticon-bullseye"></img>
                                    </div>
                                    <div className="content">
                                        <h4 className="why-us-box-title">
                                            Super Fast
                                        </h4>
                                        <p className="whay-us-box-content">
                                            Tiled way blind lived whose new. The
                                            for fully had she there leave .
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="why-us-box-01 margin-bottom-30">
                                    <div className="icon">
                                        <img
                                            src={Icon3}
                                            className="flaticon-folder"></img>
                                    </div>
                                    <div className="content">
                                        <h4 className="why-us-box-title">
                                            Cloud Upload
                                        </h4>
                                        <p className="whay-us-box-content">
                                            Tiled way blind lived whose new. The
                                            for fully had she there leave .
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="why-us-box-01 margin-bottom-30">
                                    <div className="icon">
                                        <img
                                            src={Icon4}
                                            className="flaticon-sketch"></img>
                                    </div>
                                    <div className="content">
                                        <h4 className="why-us-box-title">
                                            Multi Control
                                        </h4>
                                        <p className="whay-us-box-content">
                                            Tiled way blind lived whose new. The
                                            for fully had she there leave .
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="why-us-box-01 margin-bottom-30">
                                    <div className="icon">
                                        <img
                                            src={Icon5}
                                            className="flaticon-tool"></img>
                                    </div>
                                    <div className="content">
                                        <h4 className="why-us-box-title">
                                            Fast Integrations
                                        </h4>
                                        <p>
                                            Tiled way blind lived whose new. The
                                            for fully had she there leave .
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="why-us-box-01 margin-bottom-30">
                                    <div className="icon">
                                        <img
                                            src={Icon6}
                                            className="flaticon-paper"></img>
                                    </div>
                                    <div className="content">
                                        <h4 className="why-us-box-title">
                                            100% Secure
                                        </h4>
                                        <p>
                                            Tiled way blind lived whose new. The
                                            for fully had she there leave .
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default HealthcareFeatures;
