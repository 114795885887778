import icons from "utils/constants/icons";
import "./Services.scss";
import { FaGithub } from "react-icons/fa";
import { FaRegComments } from "react-icons/fa";
import { FaRegLifeRing } from "react-icons/fa";
import { IoIosDesktop } from "react-icons/io";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    return (
        <>
            <div className="mainServices-container">
                <div className="container">
                    <h5 className="main-title">Amazing Features</h5>
                    <h1 className="main-sub-title">
                        Provide even better <br />
                        customer service.
                    </h1>
                    <p className="main-dec">
                        Consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua.
                    </p>
                    <div className="card-div">
                        <div className="row gy-3">
                            <div className="col-xl-4 col-md-12   d-flex flex-xl-column flex-md-row flex-column justify-content-around ">
                                <div
                                    className="d-flex flex-column justify-content-around  h-100 section-container mb-3 me-md-3 me-xl-0 mx-auto"
                                    data-aos="fade-up"
                                    data-aos-duration="3000">
                                    <div className="icon-container">
                                        <img
                                            src={icons?.settingIcons}
                                            alt="setting-icons"
                                            loading="lazy"
                                        />
                                    </div>
                                    <h1 className="section-title">
                                        Profile Management
                                    </h1>
                                    <p className="section-sub-title">
                                        Messenger communication consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        labore.
                                    </p>
                                </div>
                                <div
                                    className="d-flex flex-column justify-content-around  h-100 section-container mx-auto"
                                    data-aos="fade-up"
                                    data-aos-duration="3000">
                                    <div className="icon-container">
                                        <img
                                            src={icons?.bookIcons}
                                            alt="book-icons"
                                            loading="lazy"
                                        />
                                    </div>
                                    <h1 className="section-title">
                                        Product Catalog
                                    </h1>
                                    <p className="section-sub-title">
                                        Messenger communication consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        labore.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-4 text-center mx-auto">
                                <div className="center-img">
                                    <img
                                        src={icons.iphoneIcons}
                                        alt="image1"
                                        className="imag-container"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-4  col-md-12   d-flex flex-xl-column flex-md-row flex-column justify-content-around">
                                <div
                                    className="d-flex flex-column justify-content-around  h-100  section-container  mb-3  me-md-3 me-xl-0 mx-auto"
                                    data-aos="fade-up"
                                    data-aos-duration="3000">
                                    <div className="icon-container">
                                        <img
                                            src={icons?.supportIcons}
                                            alt="supports-icons"
                                            loading="lazy"
                                        />
                                    </div>
                                    <h1 className="section-title">
                                        Chat Support
                                    </h1>
                                    <p className="section-sub-title">
                                        Messenger communication consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        labore.
                                    </p>
                                </div>
                                <div
                                    className="d-flex flex-column justify-content-around  h-100 section-container  mx-auto"
                                    data-aos="fade-up"
                                    data-aos-duration="3000">
                                    <div className="icon-container">
                                        <img
                                            src={icons?.calenderIcons}
                                            alt="calender-icons"
                                            loading="lazy"
                                        />
                                    </div>
                                    <h1 className="section-title">
                                        Appointment Booking
                                    </h1>
                                    <p className="section-sub-title">
                                        Messenger communication consectetur
                                        adipiscing elit, sed do eiusmod tempor
                                        labore.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Services;
