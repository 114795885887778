import React from "react";
import "./HireAndroidDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireAndroidDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Engage Talented Android Developers with Confidence",
      subTitle:
        "Drive forward your digital aspirations with our certified Android developers. Collaborate with Citrusbug to leverage the expertise of our Android professionals, empowering your projects with a competitive edge in the ever-evolving mobile landscape. Whether it's crafting intuitive user interfaces, robust backend systems, or dynamic mobile applications, our Android developers are primed to deliver excellence.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: " Unlock Exceptional Android Solutions with Us",
      title: "Engage Our Dedicated Developers",
      text: "Welcome to our platform, your trusted destination for top-tier Android development services. As a prominent development entity, we provide access to a skilled pool of dedicated Android developers, meticulously selected for their qualifications, experience, and proficiency in Android technology.Our Android developers excel in crafting high-performing and innovative solutions tailored precisely to your project requirements. Whether you need support on a part-time, full-time, or ad-hoc basis, our versatile team seamlessly integrates into your workflow, ensuring smooth collaboration and outstanding results.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire Android Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our Android Developers",
      subTitle: "Unlocking the Potential Expertise of Our Android Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Android Application Development",
          icon: icons.learningIcon,
          benefitdescription:
            "Partner with us to harness the expertise of our Android developers in building robust, high-performance, and scalable mobile applications tailored to your specific requirements.",
        },
        {
          id: 2,
          benefitTitle: "UI/UX Design for Android",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Experience intuitive and visually appealing user interfaces with our dedicated Android developers, ensuring seamless user experiences that drive engagement and retention.",
        },
        {
          id: 3,
          benefitTitle: "Backend Development for Android Apps",
          icon: icons.holidayIcon,
          benefitdescription:
            "Discover the expertise of our developers in building robust backend systems to support your Android applications, ensuring seamless functionality and data management.",
        },
        {
          id: 4,
          benefitTitle: "Cross-platform Development",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Leverage the advantages of cross-platform development frameworks like Flutter and React Native with our skilled Android developers, enabling efficient development and deployment across multiple platforms.",
        },
        {
          id: 5,
          benefitTitle: "Mobile Game Development",
          icon: icons.salaryIcon,
          benefitdescription:
            "Explore our capabilities in mobile game development with our dedicated Android developers, crafting immersive and entertaining gaming experiences for your audience.",
        },
        {
          id: 6,
          benefitTitle: "Emerging Technologies Integration",
          icon: icons.environmentIcon,
          benefitdescription:
            "Stay ahead of the curve by tapping into the proficiency of our Android developers in integrating cutting-edge technologies such as augmented reality (AR), virtual reality (VR), machine learning (ML), and artificial intelligence (AI) into your Android applications, enhancing user experiences and functionality.",
        },
        {
          id: 7,
          benefitTitle: "Performance Optimization and Maintenance",
          icon: icons.professionalIcon,
          benefitdescription:
            "Ensure the smooth performance and longevity of your Android applications with our team's expertise in performance optimization techniques and ongoing maintenance services. Benefit from continuous monitoring, updates, and improvements to keep your app running efficiently and effectively.",
        },
        {
          id: 8,
          benefitTitle: "Enterprise Solutions Development",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Collaborate with our Android developers to create customized enterprise solutions tailored to your business needs. From employee management systems to customer relationship management (CRM) tools, we provide end-to-end development services to streamline your business processes and boost productivity.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of Android Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our Android developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "Quality Assurance & Testing",
          description:
            "Ensure the reliability, functionality, and performance of your Android applications with our comprehensive quality assurance and testing services. Our skilled QA engineers meticulously test every aspect of your app, from user interface responsiveness to backend functionality, across various devices and scenarios. Through thorough testing methodologies and industry-standard tools, we identify and rectify any issues, ensuring your app delivers a seamless and error-free experience to users.",
          number: "01",
        },
        {
          id: 2,
          title: "Continuous Integration and Deployment (CI/CD) Pipeline Setup",
          description:
            "Streamline your Android app development process and accelerate time-to-market with our CI/CD pipeline setup services. Our expert developers configure automated build, test, and deployment pipelines, integrating seamlessly with your version control system. By automating the entire software delivery process, we ensure faster iterations, improved code quality, and more reliable releases, enabling you to deliver updates and features to your users with confidence and efficiency.",
          number: "02",
        },
        {
          id: 3,
          title: "Performance Optimization",
          description:
            "Enhance the performance and efficiency of your Android applications with our performance optimization services. Our experienced developers conduct in-depth analysis and profiling of your app to identify bottlenecks, memory leaks, and other performance issues. Leveraging industry best practices and optimization techniques, we fine-tune your app's codebase, database queries, and resource utilization to deliver optimal responsiveness and speed, providing users with a seamless and satisfying experience.",
          number: "03",
        },
        {
          id: 4,
          title: "Security Auditing and Enhancements",
          description:
            "Embark on a journey of innovation with our Android app development services. Our skilled developers craft dynamic and responsive web applications that cater to your unique business needs, ensuring seamless functionality and user experienceSafeguard your Android applications and user data against potential security threats with our security auditing and enhancement services. Our security experts conduct comprehensive audits of your app's architecture, codebase, and network interactions to identify vulnerabilities and potential attack vectors. We then implement robust security measures, such as encryption, authentication mechanisms, and secure data storage practices, to fortify your app against cyber threats and ensure compliance with industry standards and regulations.",
          number: "04",
        },
        {
          id: 5,
          title: "Post-launch Maintenance and Support",
          description:
            "Ensure the long-term success and stability of your Android applications with our post-launch maintenance and support services. Our dedicated support team provides ongoing monitoring, troubleshooting, and updates to address any issues, bugs, or performance concerns that may arise post-launch. By proactively managing your app's maintenance needs and providing timely support to users, we help you maintain high levels of customer satisfaction, retention, and engagement, ensuring your app continues to deliver value and meet evolving user expectations over time.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for Android Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Delve deep into your project requirements and objectives, uncovering insights to shape a roadmap aligned with your vision.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "Blend creativity with usability, crafting intuitive user interfaces and robust backend systems that elevate your Android applications.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Bring your ideas to life with modern technologies and industry best practices, ensuring high-performance and scalable Android solutions.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "Commit to continuous improvement, delivering excellence with every iteration and ensuring your satisfaction and success in the digital realm.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "What programming languages and frameworks do your Android developers specialize in?",
          subtitle:
            "Our Android developers are proficient in Java, Kotlin, and popular frameworks like Android Studio, Flutter, and React Native.",
        },
        {
          id: 2,
          title:
            "How do you ensure compatibility and optimal performance across various Android devices?",
          subtitle:
            "We conduct thorough testing across multiple devices and screen sizes, adhering to best practices for Android app development and performance optimization.",
        },
        {
          id: 3,
          title:
            "Can you provide examples of successful Android app projects you've completed?",
          subtitle:
            "Certainly! We've delivered a range of Android applications across various industries, including e-commerce, healthcare, finance, and more. Feel free to explore our portfolio for detailed case studies.",
        },
        {
          id: 4,
          title:
            "How do you stay updated with the latest trends and technologies in Android development?",
          subtitle:
            "Our team regularly participates in workshops, conferences, and continuous learning programs to stay abreast of the latest advancements in Android development. Additionally, we actively contribute to the Android developer community through knowledge sharing and open-source contributions.",
        },
        {
          id: 5,
          title:
            "What is your approach to handling security and data privacy in Android app development?",
          subtitle:
            "Security and data privacy are paramount in our development process. We implement robust encryption protocols, adhere to industry standards such as OWASP guidelines, and conduct regular security audits to ensure the safety of user data.",
        },
        {
          id: 6,
          title:
            "How do you ensure seamless integration of backend services with Android applications?",
          subtitle:
            "We leverage RESTful APIs, GraphQL, and other integration technologies to establish seamless communication between Android apps and backend services. Our developers follow best practices in API design and integration to ensure reliability and efficiency.",
        },
        {
          id: 7,
          title:
            "Can you elaborate on your team's approach to UI/UX design for Android apps?",
          subtitle:
            "Our UI/UX designers collaborate closely with developers to create visually appealing and intuitive interfaces for Android applications. We conduct user research, create wireframes and prototypes, and iterate based on user feedback to deliver exceptional user experiences.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-Android-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireAndroidDeveloper;
