import React from "react";
import "./BenefitSection.scss";
const BenefitSection = ({ data }) => {
  return (
    <div id="Benefit-Section-id">
      {data?.map((ele, index) => {
        const { mainTitle, subTitle, benefit } = ele;
        return (
          <div className="container" key={index}>
            <h4 className="title section-line">{mainTitle}</h4>
            <h3 className="sub-title mb-0 pb-1">{subTitle}</h3>
            <div className="joining-content">
              <div className="row g-0">
                {benefit?.map((elm, bindex) => {
                  const { benefitTitle, benefitdescription, icon } = elm;
                  return (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 col-12"
                      key={bindex}>
                      <div
                        className={`border-dotted-end-1 border-dotted-bottom-1 benefit-cards-1 box-${
                          bindex + 1
                        }`}>
                        <img
                          src={icon}
                          alt="learning"
                          className="joining-content-icon"
                        />
                        <h4 className="joining-content-title-1">
                          {benefitTitle}
                        </h4>
                        <p className="joining-content-description-1">
                          {benefitdescription}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BenefitSection;
