import "./Real_estate.scss";
import RealEstateHero from "./Real_estateHero";
import LaunchBus from "./LaunchBus";
import Comprehensive from "./Comprehensive";
import DServices from "./D_Services";
import Discover from "./Discover";
import Solution from "./Solution";
import DiscoverCard from "./DiscoverCard";
import Choose from "./Choose";
import ContactUs from "components/ContactUs";
const RealEstate = () => {
  return (
    <>
      <div className="Real_estate-page">
        <RealEstateHero />
        <LaunchBus />
        <Comprehensive />
        <DServices/>
        <Discover/>
        <DiscoverCard/>
        <Solution/>
        <Choose/>
      </div>
      <ContactUs/>
    </>
  );
};
export default RealEstate;
