import React, { useState } from "react";
import icons from "utils/constants/icons";
import RadioButton from "components/common/RadioButton";
import TouchBtn from "components/common/TouchBtn";
import ExploreBtn from "components/common/ExploreBtn";
import "animate.css";
import "./Service.scss";

const Service = () => {
    const [selectedImages, setSelectedImages] = useState("radio-one");
    const handleRadioChange = (e) => {
        setSelectedImages(e.target.value);
    };
    return (
        <div className="container">
            <h4 className="title">SERVICES</h4>
            <h3 className="sub-title mb-1">
                Custom Software Solutions to Drive Your Niche Strategic Business
            </h3>
            <div className="service-sub-heading pb-sm-5 pb-2 mb-2">
                With our extensive history of developing and delivering
                tailor-made web applications, we excel at assisting you in
                adopting the most appropriate technologies for enhancing your
                business's value.
            </div>
            <div className="d-flex flex-wrap row">
                <div className="col-lg-7 col-12">
                    <div className="radio-one pb-md-5 pb-3 mb-2">
                        <RadioButton
                            label="Web Development"
                            labelClassName={
                                selectedImages === "radio-one"
                                    ? "radio-label-active"
                                    : "radio-label"
                            }
                            radioClassName="radio-button mt-sm-1"
                            id="one"
                            value="radio-one"
                            checked={selectedImages === "radio-one"}
                            onChange={handleRadioChange}
                        />
                        {selectedImages === "radio-one" && (
                            <div className="service-tab-pera animate__animated animate__fadeIn">
                                <div className="description-container">
                                    <div className="description pb-4">
                                        Our team of skilled web developers can
                                        design and deliver custom web solutions
                                        to meet any requirements.
                                    </div>
                                    <div className="icons-container">
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services1}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services2}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services3}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services4}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services5}
                                                alt="divLogos"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src={icons.radioOne}
                                    alt="one"
                                    className="service-tab-img"
                                />
                            </div>
                        )}
                    </div>
                    <div className="radio-two pb-md-5 pb-3 mb-2">
                        <RadioButton
                            label="Mobile App Solutions"
                            labelClassName={
                                selectedImages === "radio-two"
                                    ? "radio-label-active"
                                    : "radio-label"
                            }
                            radioClassName="radio-button"
                            id="two"
                            value="radio-two"
                            checked={selectedImages === "radio-two"}
                            onChange={handleRadioChange}
                        />
                        {selectedImages === "radio-two" && (
                            <div className="service-tab-pera animate__animated animate__fadeIn">
                                <div className="description-container">
                                    <div className="description pb-4">
                                        At Sourrcecube, we understand the
                                        transformative power of mobile
                                        applications in today's dynamic digital
                                        landscape. Our commitment is to empower
                                        businesses and individuals with
                                        innovative, tailor-made mobile app
                                        solutions that redefine the way you
                                        engage with your audience.
                                    </div>
                                    <div className="icons-container">
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services21}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services22}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services23}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services24}
                                                alt="divLogos"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src={icons.radioFour}
                                    alt="two"
                                    className="service-tab-img"
                                />
                            </div>
                        )}
                    </div>
                    <div className="radio-three pb-md-5 pb-3 mb-2">
                        <RadioButton
                            label="JS / Cloud Solutions"
                            labelClassName={
                                selectedImages === "radio-three"
                                    ? "radio-label-active"
                                    : "radio-label"
                            }
                            radioClassName="radio-button"
                            id="three"
                            value="radio-three"
                            checked={selectedImages === "radio-three"}
                            onChange={handleRadioChange}
                        />
                        {selectedImages === "radio-three" && (
                            <div className="service-tab-pera animate__animated animate__fadeIn">
                                <div className="description-container">
                                    <div className="description pb-4">
                                        JS Technologies gain popularity due to
                                        their affordable open source Frameworks
                                        , we are ahead of the game in developing
                                        JS Apps and Web apps. We build In all
                                        Major types of Js Frameworks, React,
                                        Node, Angular, Vue, Next.
                                    </div>
                                    <div className="icons-container">
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services31}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services32}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services33}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services34}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services35}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services36}
                                                alt="divLogos"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src={icons.radioThree}
                                    alt="two"
                                    className="service-tab-img"
                                />
                            </div>
                        )}
                    </div>
                    <div className="radio-four pb-md-5 pb-3 mb-2">
                        <RadioButton
                            label="E-commerce Solutions"
                            labelClassName={
                                selectedImages === "radio-four"
                                    ? "radio-label-active"
                                    : "radio-label"
                            }
                            radioClassName="radio-button"
                            id="four"
                            value="radio-four"
                            checked={selectedImages === "radio-four"}
                            onChange={handleRadioChange}
                        />
                        {selectedImages === "radio-four" && (
                            <div className="service-tab-pera animate__animated animate__fadeIn">
                                <div className="description-container">
                                    <div className="description pb-4">
                                        We offer e-Commerce web & app
                                        development services designed to enhance
                                        your online business presence. Our
                                        full-service team of e-Commerce
                                        developers will understand your business
                                        idea, identify the competitive
                                        roadblocks, and provide you with
                                        solutions dedicated to your business
                                        requirements.
                                    </div>
                                    <div className="icons-container">
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services1}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services5}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services31}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services32}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services35}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.Services36}
                                                alt="divLogos"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src={icons.radioTwo}
                                    alt="two"
                                    className="service-tab-img"
                                />
                            </div>
                        )}
                    </div>
                    <div className="radio-five pb-md-5 pb-3 mb-2">
                        <RadioButton
                            label="Devops Solutions"
                            labelClassName={
                                selectedImages === "radio-five"
                                    ? "radio-label-active"
                                    : "radio-label"
                            }
                            radioClassName="radio-button"
                            id="five"
                            value="radio-five"
                            checked={selectedImages === "radio-five"}
                            onChange={handleRadioChange}
                        />
                        {selectedImages === "radio-five" && (
                            <div className="service-tab-pera animate__animated animate__fadeIn">
                                <div className="description-container">
                                    <div className="description pb-4">
                                        As a DevOps development company, we
                                        combine skills in software development
                                        and automation to create a unified and
                                        better approach to both.
                                    </div>
                                    <div className="icons-container">
                                        <div className="services-icon">
                                            <img
                                                src={icons.servicesDevop1}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.servicesDevop2}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.servicesDevop3}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.servicesDevop4}
                                                alt="divLogos"
                                            />
                                        </div>
                                        <div className="services-icon">
                                            <img
                                                src={icons.servicesDevop5}
                                                alt="divLogos"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src={icons.radioFive}
                                    alt="two"
                                    className="service-tab-img"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-5 images-container">
                    {selectedImages === "radio-one" && (
                        <div className="radio-one">
                            <img
                                src={icons.radioOne}
                                alt="one"
                                className="animate__animated animate__flipInY"
                            />
                        </div>
                    )}
                    {selectedImages === "radio-two" && (
                        <div className="radio-two">
                            <img
                                src={icons.radioFour}
                                alt="two"
                                className="animate__animated animate__flipInY"
                            />
                        </div>
                    )}
                    {selectedImages === "radio-three" && (
                        <div>
                            <img
                                src={icons.radioThree}
                                alt="three"
                                className="animate__animated animate__flipInY"
                            />
                        </div>
                    )}
                    {selectedImages === "radio-four" && (
                        <div>
                            <img
                                src={icons.radioTwo}
                                alt="four"
                                className="animate__animated animate__flipInY"
                            />
                        </div>
                    )}
                    {selectedImages === "radio-five" && (
                        <div>
                            <img
                                src={icons.radioFive}
                                alt="five"
                                className="animate__animated animate__flipInY"
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="service-footer-image">
                <div className="">
                    <h4>
                        Embracing Excellence in Global IT Services and
                        Solutions, Pioneering Future-Centric Platforms Across
                        Diverse Industries.
                    </h4>
                    <div className="d-flex gap-sm-3 gap-2 flex-wrap">
                        <TouchBtn title="Let's Talk" />
                        <ExploreBtn title="Explore Our Services" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;
