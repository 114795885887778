import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import icons from "utils/constants/icons";
import TextInput from "components/common/TextInput";
import TextArea from "components/common/TextArea";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { sendMail, setIsOpen, throwSuccess } from "store/globalSlice";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import { objectToFormData } from "utils/helpers";
import "./GetInTochModal.scss";

function TochModal() {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef();
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(setIsOpen(false));
    };

    const handleSave = async (values) => {
        setIsLoading(true);
        const formData = objectToFormData(values);
        const response = await dispatch(sendMail(formData));
        if (response?.status === 200) {
            dispatch(throwSuccess(response?.message));
            handleClick();
            if (formRef.current) {
                formRef.current.resetForm();
            }
        }

        setIsLoading(false);
    };
    const initialValues = {
        name: "",
        email: "",
        phone: "",
        message: "",
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string()
            .email("Email must be a valid email")
            .required("Email is required."),
        phone: Yup.string()
            .required("Mobile is required.")
            .matches(/^[0-9]{10}$/, "Only 10 digits allowed "),
        message: Yup.string().required("Description is required"),
    });
    return (
        <Modal show={true} onHide={handleClick} size="lg" centered>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSave}
                innerRef={formRef}>
                {(props) => {
                    const { values, errors, handleChange, handleSubmit } =
                        props;
                    const { name, email, phone, message } = values;
                    return (
                        <form>
                            <div className="row gx-0 flex-lg-row flex-column-reverse">
                                <div className="col-lg-6">
                                    <div className="modal-detail mt-0 mt-lg-4 pt-lg-1">
                                        <h1 className="modal-heading">
                                            Let’s Talk Business!
                                        </h1>
                                        <div className="mb-3">
                                            <TextInput
                                                placeholder="Name"
                                                id="name"
                                                value={name}
                                                onChange={handleChange}
                                                error={errors?.name}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <TextInput
                                                placeholder="Email"
                                                id="email"
                                                value={email}
                                                onChange={handleChange}
                                                error={errors?.email}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <TextInput
                                                placeholder="Phone"
                                                id="phone"
                                                value={phone}
                                                onChange={handleChange}
                                                error={errors?.phone}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <TextArea
                                                placeholder="What’s your project about?"
                                                id="message"
                                                value={message}
                                                onChange={handleChange}
                                                error={errors?.message}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-primary w-100 mt-auto"
                                            onClick={handleSubmit}>
                                            Get in Touch
                                            {isLoading && (
                                                <Spinner
                                                    animation="border"
                                                    size="sm"
                                                    className="ms-2"
                                                />
                                            )}
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="touch-right-part">
                                        <button
                                            type="button"
                                            className="touch-cancel"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={handleClick}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none">
                                                <path
                                                    d="M13.0306 11.9695C13.1715 12.1104 13.2506 12.3015 13.2506 12.5007C13.2506 12.7 13.1715 12.8911 13.0306 13.032C12.8897 13.1729 12.6986 13.252 12.4993 13.252C12.3001 13.252 12.109 13.1729 11.9681 13.032L7.99997 9.06261L4.0306 13.0307C3.8897 13.1716 3.69861 13.2508 3.49935 13.2508C3.30009 13.2508 3.10899 13.1716 2.9681 13.0307C2.8272 12.8898 2.74805 12.6987 2.74805 12.4995C2.74805 12.3002 2.8272 12.1091 2.9681 11.9682L6.93747 8.00011L2.96935 4.03073C2.82845 3.88984 2.7493 3.69874 2.7493 3.49948C2.7493 3.30023 2.82845 3.10913 2.96935 2.96823C3.11024 2.82734 3.30134 2.74818 3.5006 2.74818C3.69986 2.74818 3.89095 2.82734 4.03185 2.96823L7.99997 6.93761L11.9693 2.96761C12.1102 2.82671 12.3013 2.74756 12.5006 2.74756C12.6999 2.74756 12.891 2.82671 13.0318 2.96761C13.1727 3.10851 13.2519 3.2996 13.2519 3.49886C13.2519 3.69812 13.1727 3.88921 13.0318 4.03011L9.06247 8.00011L13.0306 11.9695Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </button>
                                        <img
                                            src={icons.TouchImage}
                                            className="d-none d-lg-block"
                                            alt="Touch Modal"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
}

export default TochModal;
