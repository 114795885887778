import "./LoveSection.scss";
import icons from "utils/constants/icons";
import { IoPlay } from "react-icons/io5";

const LoveSection = () => {
    return (
        <>
            <div className="main-love-container">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="position-img">
                                <img
                                    src={icons.sectionBgImg}
                                    alt="Background Illustration"
                                    className="img-fluid"
                                />
                                <div className="play-btn">
                                    <div className="video-play-btn">
                                        <img
                                            src={icons.playIcons}
                                            alt="play-icons"
                                            className="icon"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="left-div">
                                <h4 className="left-title">
                                    WHY LOVE THIS APP?
                                </h4>
                                <h1 className="left-desc">
                                    Dozens of chatbots and automations, Ready to
                                    use faster than ever
                                </h1>
                                <p className="left-para">
                                    Innovative solutions with the best.
                                    Incididunt dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolor tempor
                                    incididunt ut labore et dolore Built purse
                                    maids cease her ham new seven among and.
                                    Pulled coming wooded tended it answer remain
                                    me be.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoveSection;
