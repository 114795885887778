import icons from "utils/constants/icons";
import "./D_Services.scss"
const DServices =() =>{
    return (
        <div className="container-fluid main-D_services ">
        <div className="container D_services">
          <div className="row row-container">
            <div className="col-lg-6 col-12 header-container mx-auto d-flex flex-column justify-content-center  justify-content-center ">
              <div className="d-flex text-center text-lg-start align-items-center">
                <div className="mx-auto">
                  <h1 className="hero-title text-center text-lg-start">
                  Become The Next Leading Name In Real Estate World With Our Real Estate App Development Services
                  </h1>
                  <div className="d-flex justify-content-lg-start justify-content-center  btn-container">
                    <button className="btn">Talk to Experts</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-lg-end  justify-content-center D_Services-img ">
              <div className="">
              <img
                src={icons.D_Services}
                alt="Background Illustration"
                className="img-fluid"
              />
              </div>
            </div>
          
          </div>
        </div>
      </div>
    )
}

export default DServices;