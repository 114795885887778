import ContactUs from 'components/ContactUs';
import Gallery from 'pages/Career/Gallery';
import Fun from 'pages/Fun';
import Technologies from 'pages/Home/Technologies';
import Learning from 'pages/Learning';
import OurCulture from 'pages/OurCulture';
import React from 'react';
import { Helmet } from "react-helmet";
import "./Culture.scss";

const Culture = () => {
  return (
    <>
      <Helmet>
        <title>
            Sourcecube Life
        </title>
        <meta
            name="description"
            content="Sourcecube Life."
        />
        <meta
            property="og:title"
            content="Join Our Team at Sourrcecube Technologies | Sourcecube Life"
        />
        <meta
            property="og:description"
            content="Sourcecube Life."
        />
      </Helmet>
      <div id="culture-id" className="mb-5">
        <OurCulture />
        <Learning />
        <Gallery />
        <Fun /> 
      </div>
      <div className="home-technologies">
          <Technologies />
      </div>
      <ContactUs />
    </>
  )
}

export default Culture;