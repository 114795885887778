import React from "react";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
// import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";
import "./HireDedicatedDeveloper.scss";

function HireDedicatedDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Dedicated Developer Hiring",
      subTitle:
        "Considering hiring developers for your business? Worry not. Our developers excel in efficiently building interactive, dynamic, scalable, and feature-rich front-end applications. With Sourrcecube Technologies, you can hire developers hourly or full-time, based on your business needs, and enjoy up to 40% cost savings.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Engage Dedicated Developers",
      subTitle: "Engage Dedicated Developers from Sourrcecube Technologies",
      title: "",
      text: "As an Indian development company, our developers excel in working with all versions. Hire developers from Sourrcecube Technologies to craft fully-functional, visually appealing software tailored to your business needs. Our developers bring robust expertise in Php, Laravel, ReactJS, NordJS, Flutter, Android, WordPress, and other cutting-edge tools.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire Dedicated Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  // const BenefitData = [
  //   {
  //     id: 1,
  //     mainTitle: "Expertise of Our Developers",
  //     subTitle: "Unlocking the Potential Expertise of Our Developers",
  //     benefit: [
  //       {
  //         id: 1,
  //         benefitTitle: "Application Development",
  //         icon: icons.learningIcon,
  //         benefitdescription:
  //           "Harness the expertise of our developers in building robust, scalable, and high-performance applications tailored to your specifications.",
  //       },
  //       {
  //         id: 2,
  //         benefitTitle: "eCommerce Development",
  //         icon: icons.motovationalIcon,
  //         benefitdescription:
  //           "Experience seamless eCommerce solutions with our developers specializing in various platforms such as WooCommerce, Magento, Shopify, and more.",
  //       },
  //       {
  //         id: 3,
  //         benefitTitle: "Web Development",
  //         icon: icons.holidayIcon,
  //         benefitdescription:
  //           "Explore the capabilities of our developers in crafting websites and web applications using frameworks like Django, React, Angular, and Vue.js",
  //       },
  //       {
  //         id: 4,
  //         benefitTitle: "Mobile App Development",
  //         icon: icons.celebrationIcon,
  //         benefitdescription:
  //           "Leverage the advantages of our developers in developing native or cross-platform mobile applications for iOS and Android.",
  //       },
  //       {
  //         id: 5,
  //         benefitTitle: "Database Development",
  //         icon: icons.salaryIcon,
  //         benefitdescription:
  //           "Discover our core database development services focusing on designing, implementing, and optimizing databases using MySQL, PostgreSQL, MongoDB, and others.",
  //       },
  //       {
  //         id: 6,
  //         benefitTitle: "Cloud Development",
  //         icon: icons.environmentIcon,
  //         benefitdescription:
  //           "Ensure seamless deployment and scalability with our developers proficient in cloud platforms such as AWS, Azure, Google Cloud, and Heroku.",
  //       },
  //       {
  //         id: 7,
  //         benefitTitle: "Quality Assurance and Testing",
  //         icon: icons.professionalIcon,
  //         benefitdescription:
  //           "Rely on our QA engineers to thoroughly test your applications and websites across various devices, browsers, and operating systems. With expertise in automated and manual testing methodologies, we ensure the highest level of quality and reliability for your software products.",
  //       },
  //       {
  //         id: 8,
  //         benefitTitle: "UI/UX Design",
  //         icon: icons.loyaltyIcon,
  //         benefitdescription:
  //           "Elevate user experiences with our designers who excel in creating intuitive and visually appealing interfaces. From wireframing to prototyping, our team ensures your application or website is not only functional but also aesthetically pleasing.",
  //       },
  //     ],
  //   },
  // ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our ReactJS developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "Expert Developers",
          description:
            "Sourrcecube Technologies boasts a top-notch team of designers and developers for hire, ready to elevate your software to new heights.",
          number: "01",
        },
        {
          id: 2,
          title: "Rapid Time-to-Market",
          description:
            "Dependable Expertise for Timely Software Delivery. Our hired developers consistently deliver high-quality solutions to meet your business needs, even under tight deadlines.",
          number: "02",
        },
        {
          id: 3,
          title: "Enhancing Team Capabilities",
          description:
            "Swiftly scale your expert development to adapt to changing scenarios as per your needs. Our hired developers are always ready to build large-scale software, ensuring business success with best-in-class development",
          number: "03",
        },
        {
          id: 4,
          title: "Technical Proficiency",
          description:
            "Empower your business with IT expertise by hiring developers from Sourrcecube Technologies. Our hired developers specialize in crafting high-quality, bug-free, and flawless software that exceeds client expectations.",
          number: "04",
        },
        {
          id: 5,
          title: "Economical and Time-Efficient Solutions",
          description:
            "Optimize your time and costs by hiring developers, bypassing the expenses of permanent staff, overheads, and recruitment efforts. Our hired developers expertly manage the software development lifecycle, ensuring optimal solutions for your business requirements.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "How To Hire",
      sectionDescription: "Unlocking Growth With Some Step",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Grasping Core Needs",
          description:
            "Provide us with your project details, and we'll conduct a thorough analysis. Our hired developers will begin by comprehending your core objectives, technical needs, and team dynamics",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Shortlisting Profiles",
          description:
            "Aligning with your project needs, we will furnish you with the names and CVs of curated developers. Feel free to take the time to review their credentials and choose the developers best suited for your business.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Conducting Interviews",
          description:
            "After this stage, conduct interviews with the chosen hired developers via a phone or video call. If you require additional options, we will send more developers along with their profiles for your consideration.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Developer Hiring",
          description:
            "After completing the interview process, choose the hired developers who align with your requirements. we will proceed to finalize the terms of engagement and sign a NDA to ensure the utmost security for your app idea.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "How do you prioritize user security and privacy in your web development projects?",
          subtitle:
            "Security is a top priority; we implement robust encryption and adhere to best practices for data privacy.",
        },
        {
          id: 2,
          title:
            "What steps does your team take to ensure seamless integration of mobile apps across different platforms?",
          subtitle:
            "We leverage cross-platform frameworks such as React Native and Flutter for seamless integration.",
        },
        {
          id: 3,
          title:
            "Can you provide examples of successful collaborations with clients in the finance sector?",
          subtitle:
            "Certainly, our expertise in the finance sector is evident in successful projects like [Project Name].",
        },
        {
          id: 4,
          title:
            "How does your team approach the customization of IT solutions for specific industries?",
          subtitle:
            "We tailor solutions by understanding industry-specific needs and challenges.",
        },
        {
          id: 5,
          title:
            "Can you share insights into the technologies and frameworks utilized for recent e-commerce projects?",
          subtitle:
            "We've successfully utilized technologies like [Technology Name] and frameworks like [Framework Name] for e-commerce projects.",
        },
        {
          id: 6,
          title:
            "How does your team adapt to changing technology trends in the ever-evolving IT landscape?",
          subtitle:
            "We stay updated through continuous learning, attending conferences, and fostering a culture of innovation within our team.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-Dedicated-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      {/* <BenefitSection data={BenefitData} /> */}
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireDedicatedDeveloper;
