import React from "react";
import icons from "utils/constants/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./OurJourney2.scss";

function OurJourney2() {
    return (
        <>
            <div className="container">
                <h4 className="title">Our Journey</h4>
                <h3 className="sub-title mb-0">
                    We’re A Growing Team Of Innovative Minds,Passionate About
                    Your Success
                </h3>
            </div>
            <div className="gallery pb-md-5 journey-slider">
                <Swiper
                    modules={[Pagination, Navigation, Autoplay]}
                    spaceBetween={16}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: true,
                    }}
                    navigation={true}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                        dynamicMainBullets: 2,
                    }}
                    className="mySwiper"
                    breakpoints={{
                        280: {
                            slidesPerView: 1,
                        },
                        576: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 4,
                        },
                    }}>
                    <SwiperSlide>
                        <img src={icons.st1} alt="st-1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={icons.st2} alt="st-2" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={icons.st4} alt="st-4" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={icons.st10} alt="st-10" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={icons.st15} alt="st-15" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={icons.st14} alt="st-14" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={icons.st12} alt="st-12" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={icons.st16} alt="st-16" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={icons.st24} alt="st-24" />
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    );
}

export default OurJourney2;
