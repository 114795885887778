import React from "react";
import "./HireAngularJsDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireAngularJSDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Engage Talented AngularJS Developers for Your Next Project",
      subTitle:
        "Take your digital initiatives to new heights with our skilled AngularJS developers. Collaborate with us to tap into the expertise of our AngularJS professionals and gain a strategic advantage. Whether you're aiming for dynamic web applications, immersive user interfaces, or robust single-page applications, our AngularJS developers are poised to deliver outstanding results.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Unleash Exceptional AngularJS Solutions with Our Team",
      title: "Engage Our Dedicated Developers",
      text: "Welcome to our platform, your go-to destination for premium AngularJS development services. As a leading AngularJS development firm, we offer access to a handpicked team of dedicated AngularJS developers chosen for their expertise, experience, and mastery of AngularJS technology.Our AngularJS developers excel in crafting innovative and high-performance solutions tailored precisely to your project's needs. Whether you require full-time collaboration, part-time support, or specialized assistance, our adaptable team seamlessly integrates into your workflow, ensuring smooth collaboration and superior outcomes.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire AngularJS Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our AngularJS Developers",
      subTitle: "Unlocking the Potential Expertise of Our AngularJS Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "AngularJS Application Development",
          icon: icons.learningIcon,
          benefitdescription:
            "Partner with us to leverage the expertise of our AngularJS developers in building dynamic and responsive web applications. Our developers have the necessary skills and experience to deliver top-tier quality in AngularJS app development, tailored to your specific requirements.",
        },
        {
          id: 2,
          benefitTitle: "AngularJS UI/UX Design",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Immerse yourself in captivating user interfaces crafted by our dedicated AngularJS developers. Specializing in AngularJS UI/UX design, our team ensures intuitive and seamless user experiences that drive engagement and satisfaction for your audience.",
        },
        {
          id: 3,
          benefitTitle: "AngularJS Integration & Enhancement",
          icon: icons.holidayIcon,
          benefitdescription:
            "Stay ahead of the curve with seamless integrations and enhancements. Our experienced AngularJS developers specialize in integrating and upgrading existing applications with AngularJS, ensuring compatibility, security, and optimal performance.",
        },
        {
          id: 4,
          benefitTitle: "AngularJS Custom Component Development",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Our AngularJS developers excel in creating custom components to extend the functionality and visual appeal of your AngularJS applications. Whether you need custom UI components, interactive widgets, or reusable modules, our developers can design and implement tailored solutions that enhance the usability and versatility of your applications.",
        },
        {
          id: 5,
          benefitTitle: "AngularJS Maintenance & Support",
          icon: icons.salaryIcon,
          benefitdescription:
            "Ensure the seamless operation of your AngularJS applications with our round-the-clock maintenance and support services. Our dedicated AngularJS developers are committed to providing hassle-free maintenance and support, ensuring your peace of mind and uninterrupted business operations.",
        },
        {
          id: 6,
          benefitTitle: "AngularJS Version Migration",
          icon: icons.environmentIcon,
          benefitdescription:
            "Our AngularJS developers offer expertise in migrating your applications to newer versions of AngularJS or Angular (Angular 2+). Whether you're looking to upgrade from AngularJS to Angular or migrate between different versions of Angular, our developers ensure a smooth transition while maintaining the functionality and performance of your applications.",
        },
        {
          id: 7,
          benefitTitle: "AngularJS Performance Optimization",
          icon: icons.professionalIcon,
          benefitdescription:
            "With a focus on performance, our AngularJS developers employ optimization techniques to enhance the speed, responsiveness, and efficiency of your web applications. From optimizing code structure and reducing loading times to implementing lazy loading and caching strategies, we strive to improve the overall performance of your AngularJS applications.",
        },
        {
          id: 8,
          benefitTitle: "Responsive Web Design Implementation",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Our AngularJS developers specialize in implementing responsive web design principles to ensure that your applications are accessible and user-friendly across various devices and screen sizes. By utilizing flexible layouts, fluid grids, and media queries, we create responsive web interfaces that adapt seamlessly to desktops, tablets, and smartphones, providing an optimal user experience for all users.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of AngularJS Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our AngularJS developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "AngularJS Code Audit and Refactoring",
          description:
            "Optimize the performance, maintainability, and scalability of your AngularJS codebase with our code audit and refactoring services. Our experienced developers conduct in-depth assessments of your AngularJS applications, identifying areas for improvement, such as code complexity, architectural bottlenecks, and deprecated features.",
          number: "01",
        },
        {
          id: 2,
          title: "AngularJS Performance Monitoring and Optimization",
          description:
            "Maximize the performance and reliability of your AngularJS applications with our performance monitoring and optimization services. Our developers implement robust monitoring tools and analytics to track key performance metrics, identify performance bottlenecks, and proactively address issues impacting user experience. ",
          number: "02",
        },
        {
          id: 3,
          title: "AngularJS Integration & Enhancement",
          description:
            "AngularJS integration and enhancement involve integrating user interface design with backend systems, third-party APIs, or external services to create seamless and cohesive user experiences. It also includes ongoing refinement and optimization of AngularJS elements based on user feedback and analytics.",
          number: "03",
        },
        {
          id: 4,
          title: "AngularJS Application Development",
          description:
            "Embark on a journey of innovation with our AngularJS app development services. Our skilled developers craft dynamic and responsive web applications that cater to your unique business needs, ensuring seamless functionality and user experience.",
          number: "04",
        },
        {
          id: 5,
          title: "AngularJS UI/UX Design",
          description:
            "Experience captivating user interfaces with our dedicated AngularJS developers. Specializing in AngularJS UI/UX design, our team ensures intuitive and seamless user experiences that drive engagement and satisfaction for your audience.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for AngularJS Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Embark on a journey of discovery as we delve into your needs and aspirations. Through comprehensive research and analysis, we unearth insights to shape a roadmap that aligns seamlessly with your vision.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "Unleash creativity as we design solutions that push boundaries. Our design ethos blends creativity with usability, crafting interfaces that are both innovative and intuitive for your end-users.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Leveraging modern technologies and cutting-edge tools, we breathe life into your ideas. Our development process focuses on building customized solutions that are not only reliable and scalable but also of the highest quality, ensuring your success in the digital realm.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "Excellence is our hallmark as we adopt an iterative approach to our work. With a dedication to continuous improvement, we strive to deliver excellence with every project, ensuring your satisfaction and success.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "What experience do your Angular developers have?",
          subtitle:
            "Our Angular developers possess extensive experience in developing dynamic and responsive web applications using Angular framework. They are proficient in AngularJS, Angular 2+, and Angular CLI, with a track record of delivering high-quality solutions for various industries.",
        },
        {
          id: 2,
          title:
            "How do you ensure the proficiency of your Angular developers?",
          subtitle:
            "We have a rigorous screening process in place to assess the technical skills and expertise of our Angular developers. Additionally, our developers undergo continuous training and professional development to stay updated with the latest trends and best practices in Angular development.",
        },
        {
          id: 3,
          title:
            "Can I review the portfolios of your Angular developers?",
          subtitle:
            "Yes, we provide access to the portfolios and profiles of our Angular developers, allowing you to review their past projects, expertise, and achievements. This transparency enables you to make informed decisions and select developers who best match your project requirements.",
        },
        {
          id: 4,
          title:
            "Will I have direct communication with the Angular developer assigned to my project?",
          subtitle:
            "Absolutely. We believe in fostering open communication and collaboration between clients and developers. You will have direct access to your assigned Angular developer(s) via various communication channels, ensuring seamless coordination and feedback exchange throughout the project.",
        },
        {
          id: 5,
          title:
            "Do your Angular developers follow best practices and coding standards?",
          subtitle:
            "Yes, our Angular developers adhere to industry-standard coding practices and follow Angular style guides to ensure consistency, maintainability, and scalability of the codebase. They also emphasize writing clean, efficient, and well-documented code to facilitate easier maintenance and future enhancements.",
        },
        {
          id: 6,
          title:
            "What support do you offer during the development process?",
          subtitle:
            "We provide comprehensive support throughout the development lifecycle, including assistance with project planning, requirements analysis, design, development, testing, deployment, and maintenance. Our dedicated project managers and support team ensure that your project progresses smoothly and meets your expectations.",
        },
        {
          id: 7,
          title:
            "How do you handle project scalability and future updates with Angular developers?",
          subtitle:
            "Our Angular developers are experienced in building scalable and flexible applications that can easily accommodate future updates and enhancements. They follow modular and component-based architecture principles, making it simpler to extend functionality or integrate new features as your project evolves. Additionally, we offer ongoing support and maintenance services to address any updates or changes post-launch.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-AngularJS-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireAngularJSDeveloper;
