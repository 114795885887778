import Gallery from "pages/Career/Gallery";
import BenefitsJoining from "pages/Career/BenefitsJoining";
import TouchBtn from "components/common/TouchBtn";
import icons from "utils/constants/icons";
// import Vacancy from "./Vacancy";
import Technologies from "pages/Home/Technologies";
import ContactUs from "components/ContactUs";
import Celebration from "./Celebration";
import { Helmet } from "react-helmet";
import "swiper/css";
import "./Career.scss";
import VacancyDetails from "./VacancyDetails";

const Career = () => {
  return (
    <>
      <Helmet>
        <title>Join Our Team at Sourrcecube Technologies | Careers</title>
        <meta
          name="description"
          content="Explore exciting career opportunities at Sourrcecube Technologies. We are on the lookout for talented individuals to join our team and contribute to the future of technology. Discover our current job openings and take the next step in your career with us."
        />
        <meta
          property="og:title"
          content="Join Our Team at Sourrcecube Technologies | Careers"
        />
        <meta
          property="og:description"
          content="Elevate your carrer with sourececube technologies where your growth knows no limits"
        />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Career" />

        <meta
          property="og:url"
          content="https://www.sourcecubeindia.com/career"
        />
        <meta property="og:site_name" content="Sourrcecube technologies" />

        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link rel="canonical" href="https:www.sourcecubeindia.com/career" />
      </Helmet>

      <div className="career-page">
        <div className="career-section">
          <div className="container-main">
            <div className="career-hero">
              <img
                src={icons.CareerHeroText}
                alt=""
                className="careerHeroText"
              />
              <div className="container">
                <div className="row align-items-center flex-lg-row flex-column-reverse">
                  <div className="col-lg-6">
                    <h1 className="career-hero-heading">
                      Elevate Your Career with Sourrcecube Technologies: Where
                      Your Growth Knows No Limits.
                    </h1>
                    <p className="career-hero-text">
                      Are you a skilled developer, creative web designer, or
                      someone deeply passionate about providing innovative
                      solutions? If so, Sourrcecube Technologies invites you to
                      be a part of our dynamic team and foster your career
                      growth. We extend a warm welcome to talented professionals
                      from around the globe to join us in shaping the future.
                    </p>
                    <TouchBtn title="Get in Touch" />
                  </div>
                  <div className="col-lg-6">
                    <div className="career-hero-bg">
                      <img src={icons.CareerHeroBg} alt="" />
                      <img
                        src={icons.CareerSmallRing}
                        alt=""
                        className="career-small-ring"
                      />
                      <img
                        src={icons.CareerBigRing}
                        alt=""
                        className="career-big-ring"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Vacancy /> */}
        <VacancyDetails />
        <Celebration />
        <BenefitsJoining />
        <Gallery />
        <Technologies />
        <ContactUs />
      </div>
    </>
  );
};

export default Career;
