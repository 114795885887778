import React from "react";
import "./HireNodeJSDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireNodeJSDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Elevate Your Digital Presence with Expert Node.js Developers",
      subTitle:
        "Experience exceptional web solutions with our seasoned Node.js developers. Collaborate with us to leverage the expertise of our Node.js professionals and elevate your digital projects. Whether it's crafting robust backend systems, real-time applications, or scalable APIs, our Node.js developers are ready to deliver excellence.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Unlock Outstanding Node.js Solutions with Us",
      title: "Engage Our Dedicated Developers",
      text: "Welcome to our platform, your ultimate destination for top-tier Node.js development services. As a distinguished Node.js development entity, we provide access to a talented pool of dedicated Node.js developers meticulously selected for their qualifications, experience, and mastery in JavaScript and server-side development.Our Node.js developers excel in creating high-performance and scalable solutions tailored precisely to your project requisites. Whether you require part-time assistance, full-time collaboration, or specialized ad-hoc support, our adaptable team seamlessly integrates into your workflow, ensuring smooth collaboration and superior outcomes.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire NodeJS Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our NodeJS Developers",
      subTitle: "Unlocking the Potential Expertise of Our NodeJS Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Backend Development with Node.js",
          icon: icons.learningIcon,
          benefitdescription:
            "Partner with us to harness the expertise of our Node.js developers in building robust backend systems that power your web applications. Our developers possess the requisite skills and experience to deliver top-notch quality in Node.js development, tailored to your specific requirements.",
        },
        {
          id: 2,
          benefitTitle: "Real-time Application Development",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Elevate user engagement with our dedicated Node.js developers specializing in real-time application development. Whether you're building a chat application, a live streaming platform, or a collaborative tool, our team ensures seamless and responsive experiences for your users.",
        },
        {
          id: 3,
          benefitTitle: "Scalable API Development",
          icon: icons.holidayIcon,
          benefitdescription:
            "Discover the power of scalable API development with our Node.js developers. Whether you're building APIs for web applications, mobile apps, or IoT devices, our top-notch developers have the skills and experience to design and implement APIs that meet your business needs.",
        },
        {
          id: 4,
          benefitTitle: "Node.js Integration & Enhancement",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Stay ahead of the curve with seamless integration and enhancement of your existing applications. Our experienced Node.js developers specialize in integrating and enhancing backend systems, ensuring scalability, performance, and optimal functionality.",
        },
        {
          id: 5,
          benefitTitle: "Node.js Maintenance & Support",
          icon: icons.salaryIcon,
          benefitdescription:
            "Ensure the continued success of your Node.js solutions with our comprehensive maintenance and support services. Our dedicated Node.js developers are committed to providing hassle-free maintenance and support, ensuring smooth operation and uninterrupted business continuity.",
        },
        {
          id: 6,
          benefitTitle: "Performance Optimization",
          icon: icons.environmentIcon,
          benefitdescription:
            "Maximize the performance of your Node.js applications with our developers' proficiency in performance optimization techniques. From optimizing code execution and memory usage to fine-tuning database queries and server configurations, we ensure that your Node.js applications deliver optimal speed, responsiveness, and scalability to meet the demands of your users and business operations.",
        },
        {
          id: 7,
          benefitTitle: "Real-time Data Processing",
          icon: icons.professionalIcon,
          benefitdescription:
            "Harness the real-time data processing capabilities of Node.js with our expert developers. Whether you're dealing with streaming data, real-time analytics, or live monitoring dashboards, our team leverages Node.js's event-driven architecture and asynchronous processing to handle large volumes of data in real-time, ensuring timely insights and actionable intelligence.",
        },
        {
          id: 8,
          benefitTitle: "Microservices Architecture",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Our Node.js developers excel in implementing microservices architecture, breaking down complex applications into smaller, independent services that are easier to develop, deploy, and scale. Leveraging Node.js's non-blocking I/O model and lightweight footprint, we design resilient and highly scalable microservices architectures that support your business growth.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of NodeJS Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our NodeJS developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "Single Page Application (SPA) Development",
          description:
            "Our Node.js developers specialize in building dynamic and interactive single-page applications (SPAs) using frameworks like React, Angular, or Vue.js. Leveraging Node.js for the backend, we create SPAs that offer a seamless user experience with fast performance and smooth navigation.",
          number: "01",
        },
        {
          id: 2,
          title: "Real-time Data Analytics",
          description:
            "Harness the power of real-time data analytics with our Node.js expertise. We develop custom solutions for collecting, processing, and analyzing streaming data in real-time, enabling you to gain valuable insights into user behavior, system performance, and business trends.",
          number: "02",
        },
        {
          id: 3,
          title: "Microservices Architecture Implementation",
          description:
            "Embrace microservices architecture with our Node.js developers, who excel in designing and implementing scalable and resilient microservices-based systems. By decomposing monolithic applications into smaller, independent services, we enable flexibility, maintainability, and agility in your software architecture.",
          number: "03",
        },
        {
          id: 4,
          title: "Serverless Application Development",
          description:
            "Take advantage of serverless computing with our Node.js expertise. We design and develop serverless applications using platforms like AWS Lambda or Azure Functions, allowing you to build and deploy applications without managing servers, infrastructure, or scalability concerns.",
          number: "04",
        },
        {
          id: 5,
          title: "DevOps Automation and CI/CD Pipeline Setup",
          description:
            "Streamline your development processes with our Node.js developers' expertise in DevOps automation and continuous integration/continuous deployment (CI/CD) pipeline setup. We automate infrastructure provisioning, configuration management, and deployment workflows, ensuring faster time-to-market and higher software quality.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for NodeJS Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Embark on a journey of discovery as we delve into your business objectives and technical requirements. Through in-depth analysis and planning, we gain valuable insights to inform the development process.  ",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "Unleash creativity as we craft robust backend solutions with Node.js. Our development philosophy blends functionality with scalability, ensuring reliable performance and seamless integration with frontend interfaces.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Harnessing the power of Node.js, we bring your vision to life. Our development process focuses on creating scalable and asynchronous backend systems that meet the highest standards of quality and reliability.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "Excellence is our standard as we follow an iterative approach to development. With a commitment to continuous improvement, we strive to deliver Node.js solutions that exceed expectations and drive business success.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "What experience do you have with Node.js development?",
          subtitle:
            "Our Node.js developers have extensive experience working with Node.js and building scalable backend systems using frameworks like Express.js.",
        },
        {
          id: 2,
          title:
            "Can you provide examples of Node.js projects you've worked on?",
          subtitle:
            "Certainly, we have worked on various projects ranging from real-time applications, RESTful APIs, to serverless architectures, all powered by Node.js.",
        },
        {
          id: 3,
          title:
            "How do you ensure the security of Node.js applications?",
          subtitle:
            "Security is paramount in our development process. We implement best practices for secure coding, input validation, and authentication mechanisms to safeguard Node.js applications against common vulnerabilities.",
        },
        {
          id: 4,
          title:
            "What is your approach to optimizing the performance of Node.js applications?",
          subtitle:
            "We optimize Node.js applications for speed and efficiency by leveraging caching mechanisms, asynchronous programming, and performance monitoring tools to identify and address bottlenecks.",
        },
        {
          id: 5,
          title:
            "How do you stay updated with the latest trends and developments in the Node.js ecosystem?",
          subtitle:
            "Our developers actively participate in the Node.js community, attend conferences, and continuously explore new tools and libraries to stay ahead of the curve in Node.js development.",
        },
        {
          id: 6,
          title:
            "Can you describe your experience with deploying Node.js applications?",
          subtitle:
            "We have expertise in deploying Node.js applications on various platforms, including cloud providers like AWS, Azure, and Google Cloud Platform, ensuring scalability, reliability, and efficient resource utilization.",
        },
        {
          id: 7,
          title:
            "How do you approach troubleshooting and debugging Node.js applications?",
          subtitle:
            "We employ a systematic approach to troubleshooting, utilizing logging, monitoring tools, and debugging techniques to identify and resolve issues quickly, minimizing downtime and ensuring smooth operation.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-NodeJS-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireNodeJSDeveloper;
