import NumberCounter from "number-counter";
import icons from "utils/constants/icons";
import "./TeamCard.scss";

function TeamCard() {
    return (
        <section className="section-line">
            <div className="container">
                <div className="team-cards">
                    <div className="team-card">
                        <div className="team-detail">
                            <h3 className="team-name">Experience</h3>
                            <h2 className="team-total">
                                <NumberCounter end={5} delay={1} />
                                <span>+</span>
                            </h2>
                            <p>Years Of Experience</p>
                        </div>
                        <div className="team-imgs">
                            <div className="team-img">
                                <img src={icons.TeamImg1} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.TeamImg2} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.TeamImg3} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.TeamImg4} alt="Team" />
                            </div>
                            <div className="team-plus-img">
                                <img src={icons.TeamPlusImg} alt="Team" />
                            </div>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-detail">
                            <h3 className="team-name">Professionals</h3>
                            <h2 className="team-total">
                                <NumberCounter end={75} delay={1} />
                                <span>+</span>
                            </h2>
                            <p>Talented IT Professionals</p>
                        </div>
                        <div className="team-imgs">
                            <div className="team-img">
                                <img src={icons.TeamImg3} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.TeamImg5} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.TeamImg7} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.TeamImg8} alt="Team" />
                            </div>
                            <div className="team-plus-img">
                                <img src={icons.TeamPlusImg} alt="Team" />
                            </div>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-detail">
                            <h3 className="team-name">Projects</h3>
                            <h2 className="team-total">
                                <NumberCounter end={3264} delay={1} />
                                <span>+</span>
                            </h2>
                            <p>Success Projects</p>
                        </div>
                        <div className="team-imgs">
                            <div className="team-img">
                                <img src={icons.project_icon1} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.project_icon2} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.project_icon3} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.project_icon4} alt="Team" />
                            </div>
                            <div className="team-plus-img">
                                <img src={icons.TeamPlusImg} alt="Team" />
                            </div>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-detail">
                            <h3 className="team-name">Countries</h3>
                            <h2 className="team-total">
                                <NumberCounter end={10} delay={1} />
                                <span>+</span>
                            </h2>
                            <p>Countries Served</p>
                        </div>
                        <div className="team-imgs">
                            <div className="team-img">
                                <img src={icons.countries_icon2} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.countries_icon3} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.countries_icon1} alt="Team" />
                            </div>
                            <div className="team-img">
                                <img src={icons.countries_icon4} alt="Team" />
                            </div>
                            <div className="team-plus-img">
                                <img src={icons.TeamPlusImg} alt="Team" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TeamCard;
