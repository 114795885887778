import { useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import ExploreBtn from "components/common/ExploreBtn";
import TextAreaFile from "components/common/TextAreaFile";
import TextInput from "components/common/TextInput";
import { trimLeftSpace } from "utils/helpers";
import { addInquiry, throwError, throwSuccess } from "store/globalSlice";
import "./HelpSection.scss";

const HelpSection = ({ data }) => {
    const dispatch = useDispatch();
    const formRef = useRef();
    const [btnLoading, setBtnLoading] = useState(false);
    const handleSave = async (values) => {
        setBtnLoading(true);
        let formData = new FormData();
        for (let key in values) {
            if (values[key]) {
                formData.append(key, values[key]);
            }
        }
        const response = await dispatch(addInquiry(formData));
        if (response?.status === 200) {
            dispatch(throwSuccess(response?.message));
            if (formRef.current) {
                formRef.current.resetForm();
            }
        }
        setBtnLoading(false);
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required."),
        desired_role: Yup.string().required("Desired role is required."),
        country: Yup.string().required("Country is required."),
        state: Yup.string().required("State is required."),
        email: Yup.string()
            .email("Email must be a valid email")
            .required("Email is required."),
        phone_number: Yup.string()
            .required("Phone number is required.")
            .min(10, "Phone number must be 10 digit.")
            .max(10, "Phone number must be 10 digit."),
        details: Yup.lazy((_, obj) => {
            const { description, attachment } = obj?.parent;
            if (!description && !attachment) {
                return Yup.string().required("Inquiry details is required.");
            } else {
                return Yup.string();
            }
        }),
    });
    const initialValues = {
        name: "",
        email: "",
        phone_number: "",
        desired_role: "",
        country: "",
        state: "",
        description: "",
        attachment: "",
    };
    const { mainTitle, subTitle, text, description, images } = data?.[0] || {};

    return (
        <div id="help-section-id">
            <div className="container">
                <div>
                    <h4 className="title section-line">{mainTitle}</h4>
                    <h3 className="sub-title mb-0 pb-1">{subTitle}</h3>
                    <div className="help-main-div">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="help-div">
                                    <img
                                        src={images}
                                        alt="hireResourcesFormImg"
                                        className="help-img"
                                    />
                                    <h1 className="help-main-title">{text}</h1>
                                    <p className="help-sub-title">
                                        {description}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-div">
                                    <Formik
                                        innerRef={formRef}
                                        onSubmit={handleSave}
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}>
                                        {(props) => {
                                            const {
                                                values,
                                                errors,
                                                handleSubmit,
                                                setFieldValue,
                                            } = props;
                                            const {
                                                name,
                                                email,
                                                phone_number,
                                                desired_role,
                                                country,
                                                state,
                                                description,
                                                attachment,
                                            } = values;
                                            const {
                                                name: err_name,
                                                email: err_email,
                                                phone_number: err_phone_number,
                                                desired_role: err_desired_role,
                                                country: err_country,
                                                state: err_state,
                                                details,
                                            } = errors;
                                            return (
                                                <form className="row gy-3">
                                                    <div className="col-sm-6 col-12">
                                                        <TextInput
                                                            placeholder="Name"
                                                            value={name}
                                                            error={err_name}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "name",
                                                                    trimLeftSpace(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-12">
                                                        <TextInput
                                                            placeholder="Email"
                                                            value={email}
                                                            error={err_email}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "email",
                                                                    trimLeftSpace(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-12">
                                                        <TextInput
                                                            placeholder="Phone number"
                                                            value={phone_number}
                                                            error={
                                                                err_phone_number
                                                            }
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "phone_number",
                                                                    trimLeftSpace(
                                                                        e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        )
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-12">
                                                        <TextInput
                                                            placeholder="Desired Role?"
                                                            value={desired_role}
                                                            error={
                                                                err_desired_role
                                                            }
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "desired_role",
                                                                    trimLeftSpace(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-12">
                                                        <TextInput
                                                            placeholder="Country"
                                                            value={country}
                                                            error={err_country}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "country",
                                                                    trimLeftSpace(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-12">
                                                        <TextInput
                                                            placeholder="State"
                                                            value={state}
                                                            error={err_state}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "state",
                                                                    trimLeftSpace(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <TextAreaFile
                                                            placeholder="Provide further details here..."
                                                            value={description}
                                                            attachment={
                                                                attachment
                                                            }
                                                            error={details}
                                                            onfileChange={(
                                                                e
                                                            ) => {
                                                                const fileType =
                                                                    e.target
                                                                        .files[0]
                                                                        ?.type;
                                                                if (
                                                                    [
                                                                        "application/pdf",
                                                                        "application/msword",
                                                                        "image/png",
                                                                        "image/jpg",
                                                                        "image/jpeg",
                                                                    ].includes(
                                                                        fileType
                                                                    )
                                                                ) {
                                                                    setFieldValue(
                                                                        "description",
                                                                        ""
                                                                    );
                                                                    setFieldValue(
                                                                        "attachment",
                                                                        e.target
                                                                            .files[0]
                                                                    );
                                                                } else {
                                                                    dispatch(
                                                                        throwError(
                                                                            {
                                                                                message:
                                                                                    "Invalid file selected.",
                                                                            }
                                                                        )
                                                                    );
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "attachment",
                                                                    ""
                                                                );
                                                                setFieldValue(
                                                                    "description",
                                                                    trimLeftSpace(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <ExploreBtn
                                                            title="Submit"
                                                            className="btn-4bbb8b"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                            isLoading={
                                                                btnLoading
                                                            }
                                                            disabled={
                                                                btnLoading
                                                            }
                                                        />
                                                    </div>
                                                </form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpSection;
