import React from "react";
import "./HireWebDesigner.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireWebDesigner() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Elevate Your Online Presence with Skilled Web Designers",
      subTitle:
        "Experience exceptional web designs with our seasoned team of web designers. Collaborate with us to harness the expertise of our professionals and elevate your digital projects. Whether it's crafting visually appealing layouts, intuitive navigation, or engaging user interfaces, our web designers are ready to deliver excellence.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Unlock Outstanding Web Design Solutions with Us",
      title: "Engage Our Dedicated Developers",
      text: "Welcome to our platform, your ultimate destination for top-tier web design services. As a distinguished web design entity, we provide access to a talented pool of dedicated designers meticulously selected for their qualifications, experience, and mastery in creating captivating digital experiences.Our web designers excel in crafting visually stunning and user-friendly websites tailored precisely to your project requisites. Whether you require part-time assistance, full-time collaboration, or specialized ad-hoc support, our adaptable team seamlessly integrates into your workflow, ensuring smooth collaboration and superior outcomes.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire Web Designers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our Web Designers",
      subTitle: "Unlocking the Potential Expertise of Our Web Designers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Creative Web Design Solutions",
          icon: icons.learningIcon,
          benefitdescription:
            "Partner with us to harness the expertise of our web designers in crafting creative and visually appealing websites that resonate with your audience. Our designers possess the requisite skills and experience to deliver top-notch quality in web design, tailored to your specific requirements.",
        },
        {
          id: 2,
          benefitTitle: "Intuitive User Interface Design",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Elevate user engagement with our dedicated web designers specializing in intuitive user interface design. Our team ensures seamless and delightful user experiences that drive engagement and satisfaction for your audience.",
        },
        {
          id: 3,
          benefitTitle: "Responsive Layouts and Mobile Design",
          icon: icons.holidayIcon,
          benefitdescription:
            "Discover the power of responsive web design with our web designers. Whether you need websites optimized for desktops, tablets, or mobile devices, our top-notch designers have the skills and experience to create responsive layouts that adapt to different screen sizes and resolutions.",
        },
        {
          id: 4,
          benefitTitle: "Website Maintenance & Support",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Ensure the continued success of your website with our comprehensive maintenance and support services. Our dedicated web designers are committed to providing hassle-free maintenance and support, ensuring smooth operation and uninterrupted business continuity.",
        },
        {
          id: 5,
          benefitTitle: "SEO-Friendly Design",
          icon: icons.salaryIcon,
          benefitdescription:
            "Benefit from our web designers' expertise in creating SEO-friendly websites. Our team understands the importance of optimizing your site's structure, content, and performance to improve search engine visibility and drive organic traffic to your website.",
        },
        {
          id: 6,
          benefitTitle: "E-commerce Design and Integration",
          icon: icons.environmentIcon,
          benefitdescription:
            "Leverage our web designers' proficiency in e-commerce design and integration to establish a robust online presence for your business. From designing visually appealing product pages to seamless integration of payment gateways, our team ensures a smooth and secure online shopping experience for your customers.",
        },
        {
          id: 7,
          benefitTitle: "Custom Web Development",
          icon: icons.professionalIcon,
          benefitdescription:
            "Harness the capabilities of our web designers in custom web development to address unique business needs and requirements. Whether you need a custom-built CMS, interactive features, or complex functionalities, our team can translate your vision into reality with tailored web solutions.",
        },
        {
          id: 8,
          benefitTitle: "Accessibility Compliance",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Ensure inclusivity and accessibility for all users with our web designers' expertise in accessibility compliance. Our team follows best practices and guidelines to ensure that your website is accessible to users with disabilities, providing equal access to information and services for everyone.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of Web Designing Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our Web Designers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "UI/UX Optimization",
          description:
            "Enhance user satisfaction and engagement with our UI/UX optimization services. Our web designers meticulously analyze user behavior and preferences to optimize the interface design, resulting in intuitive navigation, seamless interactions, and ultimately, higher conversion rates.",
          number: "01",
        },
        {
          id: 2,
          title: "Landing Page Design",
          description:
            "Maximize conversions and lead generation with captivating landing page designs. Our web designers specialize in creating visually appealing and persuasive landing pages tailored to your marketing goals, driving traffic and encouraging action from your target audience.",
          number: "02",
        },
        {
          id: 3,
          title: "Content Management System (CMS) Integration",
          description:
            "Simplify content management and website administration with seamless CMS integration. Our web designers are proficient in integrating popular CMS platforms such as WordPress, Drupal, or Joomla, empowering you to easily update and manage website content without technical expertise..",
          number: "03",
        },
        {
          id: 4,
          title: "Branding and Identity Design",
          description:
            "Establish a strong brand presence and identity online with our branding and identity design services. Our web designers collaborate closely with you to develop visual elements such as logos, color schemes, and typography that reflect your brand identity and resonate with your target audience.",
          number: "04",
        },
        {
          id: 5,
          title: "E-commerce Development",
          description:
            "Unlock the potential of online selling with our e-commerce development services. Our web designers leverage cutting-edge technologies and industry best practices to create secure, scalable, and user-friendly e-commerce websites that drive sales, enhance customer experiences, and foster long-term relationships with your customers.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for Web Designing",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Embark on a journey of discovery as we delve into your needs and aspirations. Through comprehensive research and analysis, we unearth insights to shape a roadmap that aligns seamlessly with your vision.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "Unleash creativity as we design solutions that push boundaries. Our design ethos blends creativity with usability, crafting interfaces that are both innovative and intuitive for your end-users.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Leveraging modern technologies and cutting-edge tools, we breathe life into your ideas. Our development process focuses on building customized solutions that are not only reliable and scalable but also of the highest quality, ensuring your success in the digital realm.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "Excellence is our hallmark as we adopt an iterative approach to our work. With a dedication to continuous improvement, we strive to deliver excellence with every project, ensuring your satisfaction and success.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          "id": 1,
          "title": "How do you approach website design to ensure a visually appealing and user-friendly experience?",
          "subtitle": "We prioritize user-centric design principles, focusing on creating intuitive interfaces, engaging visuals, and seamless navigation to enhance user satisfaction and interaction."
        },
        {
          "id": 2,
          "title": "Can you provide examples of websites you've designed in the past?",
          "subtitle": "Certainly, we have designed a diverse range of websites, including portfolio sites, corporate websites, e-commerce platforms, and more. Each design showcases our creativity, attention to detail, and ability to tailor solutions to meet client needs."
        },
        {
          "id": 3,
          "title": "How do you ensure your website designs are responsive and optimized for various devices?",
          "subtitle": "We employ responsive design techniques and conduct thorough testing across different devices and screen sizes to ensure optimal performance and usability. Our goal is to deliver a consistent and seamless experience across desktops, tablets, and smartphones."
        },
        {
          "id": 4,
          "title": "What role does user research play in your web design process?",
          "subtitle": "User research is integral to our design process. We conduct user surveys, interviews, and usability testing to gain insights into user behaviors, preferences, and pain points. This informs our design decisions and ensures that our solutions meet user needs effectively."
        },
        {
          "id": 5,
          "title": "How do you stay updated with the latest design trends and technologies?",
          "subtitle": "We stay abreast of industry trends and innovations by regularly attending design conferences, workshops, and webinars, as well as actively participating in online design communities. This allows us to incorporate cutting-edge techniques and technologies into our designs, keeping them fresh and relevant."
        },
        {
          "id": 6,
          "title": "Can you discuss your approach to collaboration and communication during the design process?",
          "subtitle": "We believe in open and transparent communication throughout the design process. We work closely with clients to understand their vision, provide regular updates and iterations, and solicit feedback to ensure that the final product meets their expectations and objectives."
        },
        {
          "id": 7,
          "title": "How do you handle revisions and feedback during the design process?",
          "subtitle": "We welcome feedback and revisions as part of the iterative design process. Our goal is to collaborate closely with clients to refine and perfect the design until it aligns with their vision and goals. We prioritize client satisfaction and strive to exceed expectations with each iteration."
        },
      ],
    },
  ];
  return (
    <div id="Hire-Web-Designer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireWebDesigner;
