import React from "react";
import { useState } from "react";
import icons from "utils/constants/icons";
import ImagePopup from "./ImagePopup/ImagePopup";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";

const Gallery = () => {
    const [showImagePopup, setShowImagePopup] = useState(null);
    const [imageUrl, setImageUrl] = useState("");

    const sliderOneData = [
        { slideImageOne: icons.st1 },
        { slideImageOne: icons.st2 },
        { slideImageOne: icons.st3 },
        { slideImageOne: icons.st4 },
        { slideImageOne: icons.st5 },
        { slideImageOne: icons.st6 },
        { slideImageOne: icons.st7 },
        { slideImageOne: icons.st8 },
        { slideImageOne: icons.st9 },
        { slideImageOne: icons.gImage6 },
        { slideImageOne: icons.st10 },
        { slideImageOne: icons.st11 },
        { slideImageOne: icons.st12 },
    ];

    const sliderTwoData = [
        { slideImageTwo: icons.st13 },
        { slideImageTwo: icons.st14 },
        { slideImageTwo: icons.st15 },
        { slideImageTwo: icons.st16 },
        { slideImageTwo: icons.st17 },
        { slideImageTwo: icons.st18 },
        { slideImageTwo: icons.st19 },
        { slideImageTwo: icons.gallery10 },
        { slideImageTwo: icons.st20 },
        { slideImageTwo: icons.st21 },
        { slideImageTwo: icons.st22 },
        { slideImageTwo: icons.st23 },
        { slideImageTwo: icons.st24 },
    ];

    return (
        <div id="career-container">
            <div id="gallery-container" className="container">
                <h4 className="title">GALLERY</h4>
                <h3 className="sub-title">Inside Stories</h3>
            </div>
            <div className="container-main">
                <div className="gallery pb-md-5">
                    {showImagePopup && (
                        <ImagePopup
                            onHide={() => {
                                setShowImagePopup(null);
                            }}
                            imageUrl={imageUrl}
                        />
                    )}
                    <Splide
                        options={{
                            type: "loop",
                            gap: "10px",
                            drag: "free",
                            perPage: 5,
                            pagination: false,
                            arrows: false,
                            autoStart: true,
                            breakpoints: {
                                1200: {
                                    perPage: 4,
                                },
                                992: {
                                    perPage: 3,
                                },
                                768: {
                                    perPage: 2,
                                },
                            },
                        }}
                        extensions={{ AutoScroll }}>
                        {sliderOneData?.map((e, i) => {
                            return (
                                <SplideSlide className="slide" key={i}>
                                    <img
                                        src={e?.slideImageOne}
                                        alt="Gallery Images"
                                        onClick={() => {
                                            setImageUrl(e);
                                            setShowImagePopup(true);
                                        }}
                                    />
                                </SplideSlide>
                            );
                        })}
                    </Splide>

                    <Splide
                        options={{
                            gap: "10px",
                            type: "loop",
                            drag: "free",
                            perPage: 5,
                            pagination: false,
                            arrows: false,
                            direction: "rtl",
                            autoStart: true,

                            breakpoints: {
                                1200: {
                                    perPage: 4,
                                },
                                992: {
                                    perPage: 3,
                                },
                                768: {
                                    perPage: 2,
                                },
                            },
                        }}
                        extensions={{ AutoScroll }}>
                        {sliderTwoData?.map((e, i) => {
                            return (
                                <SplideSlide className="slide" key={i}>
                                    <img
                                        src={e?.slideImageTwo}
                                        alt="Gallery Images"
                                        onClick={() => {
                                            setImageUrl(e);
                                            setShowImagePopup(true);
                                        }}
                                    />
                                </SplideSlide>
                            );
                        })}
                    </Splide>
                </div>
            </div>
        </div>
    );
};

export default Gallery;
