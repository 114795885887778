import React from "react";
import "./OurCulture.scss";
import TouchBtn from "components/common/TouchBtn";
const OurCulture = () => {
  return (
    <div id="our-culture-Section-id">
        <section className="our-culture-section">
        <div className="our-culture-content">
            <div className="container">
            <h1 className="our-culture-title">Our Culture</h1>
            <p className="our-culture-paragraph">SourceCube Technologies fosters a culture of collaboration, innovation, and continuous learning, empowering every individual to thrive and contribute to our collective success. We cultivate an environment where diversity, creativity, and integrity are valued, fueling our drive to push boundaries and deliver exceptional solutions</p>
            <TouchBtn title="Get in Touch" />
            </div>
        </div>
        </section>
    </div>
  );
};

export default OurCulture;
