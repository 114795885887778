import React from "react";
import "./HireVueJSDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireVueJSDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Hire Skilled VueJS Developers for Your Next Project",
      subTitle:
        "Elevate your web development projects with our talented VueJS developers. At our VueJS development company, we boast a team of seasoned professionals with extensive experience, top-tier skills, and deep expertise in VueJS development and related technologies. Whether you're aiming to create highly customized and scalable solutions, our diverse VueJS development services are here to meet your needs.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Unlock Outstanding VueJS Solutions with Our Team",
      title: "Engage Our Dedicated Developers",
      text: "Welcome to our platform, your ultimate destination for top-tier VueJS development services. As a prominent VueJS development entity, we provide access to a skilled pool of dedicated VueJS developers carefully selected for their qualifications, experience, and proficiency in VueJS technology.Our VueJS developers excel in crafting high-performing and innovative solutions tailored to your project requirements. Whether you need part-time support, full-time collaboration, or specialized ad-hoc assistance, our adaptable team seamlessly integrates into your workflow, ensuring seamless collaboration and superior outcomes.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire VueJS Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our VueJS Developers",
      subTitle: "Unlocking the Potential Expertise of Our VueJS Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Single Page App Development",
          icon: icons.learningIcon,
          benefitdescription:
            "Build high-performing single-page applications with our experienced VueJS developers, utilizing modern tools and libraries for optimal results.",
        },
        {
          id: 2,
          benefitTitle: "Custom VueJS Web Solutions",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Our developers have a knack for crafting customized web solutions tailored to the unique needs of enterprises, SMEs, and startups, ensuring robustness, scalability, and cost-effectiveness.",
        },
        {
          id: 3,
          benefitTitle: "Real-Time App Development",
          icon: icons.holidayIcon,
          benefitdescription:
            "Harness the power of VueJS for real-time applications such as cloud solutions, chat apps, and messengers, leveraging our expertise and proficiency in VueJS development.",
        },
        {
          id: 4,
          benefitTitle: "Enterprise VueJS Application",
          icon: icons.celebrationIcon,
          benefitdescription:
            "For enterprises seeking advanced applications, our VueJS developers excel in building reliable, scalable, and secure enterprise-level solutions.",
        },
        {
          id: 5,
          benefitTitle: "Native Mobile App Development",
          icon: icons.salaryIcon,
          benefitdescription:
            "Build cross-platform native mobile applications with our dedicated VueJS developers, utilizing Vue Native CLI and Vue Native Command Line Interface for highly interactive experiences.",
        },
        {
          id: 6,
          benefitTitle: "VueJS Version Migration",
          icon: icons.environmentIcon,
          benefitdescription:
            "Our VueJS developers offer expertise in migrating your applications to newer versions of VueJS or Angular (Angular 2+). Whether you're looking to upgrade from VueJS to Angular or migrate between different versions of Angular, our developers ensure a smooth transition while maintaining the functionality and performance of your applications.",
        },
        {
          id: 7,
          benefitTitle: "Data Analysis Tools",
          icon: icons.professionalIcon,
          benefitdescription:
            "Our developers are adept at developing advanced data analysis tools, empowering your product to handle massive volumes of unstructured and structured data effectively.",
        },
        {
          id: 8,
          benefitTitle: "Responsive Web Design Implementation",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Our VueJS developers specialize in implementing responsive web design principles to ensure that your applications are accessible and user-friendly across various devices and screen sizes. By utilizing flexible layouts, fluid grids, and media queries, we create responsive web interfaces that adapt seamlessly to desktops, tablets, and smartphones, providing an optimal user experience for all users.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of VueJS Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our VueJS developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "VueJS Code Audit and Refactoring",
          description:
            "Optimize the performance, maintainability, and scalability of your VueJS codebase with our code audit and refactoring services. Our experienced developers conduct in-depth assessments of your VueJS applications, identifying areas for improvement, such as code complexity, architectural bottlenecks, and deprecated features.",
          number: "01",
        },
        {
          id: 2,
          title: "VueJS Performance Monitoring and Optimization",
          description:
            "Maximize the performance and reliability of your VueJS applications with our performance monitoring and optimization services. Our developers implement robust monitoring tools and analytics to track key performance metrics, identify performance bottlenecks, and proactively address issues impacting user experience. ",
          number: "02",
        },
        {
          id: 3,
          title: "VueJS Integration & Enhancement",
          description:
            "VueJS integration and enhancement involve integrating user interface design with backend systems, third-party APIs, or external services to create seamless and cohesive user experiences. It also includes ongoing refinement and optimization of VueJS elements based on user feedback and analytics.",
          number: "03",
        },
        {
          id: 4,
          title: "VueJS Application Development",
          description:
            "Embark on a journey of innovation with our VueJS app development services. Our skilled developers craft dynamic and responsive web applications that cater to your unique business needs, ensuring seamless functionality and user experience.",
          number: "04",
        },
        {
          id: 5,
          title: "VueJS UI/UX Design",
          description:
            "Experience captivating user interfaces with our dedicated VueJS developers. Specializing in VueJS UI/UX design, our team ensures intuitive and seamless user experiences that drive engagement and satisfaction for your audience.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for VueJS Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Embark on a journey of discovery as we delve into your needs and aspirations. Through comprehensive research and analysis, we unearth insights to shape a roadmap that aligns seamlessly with your vision.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "Unleash creativity as we design solutions that push boundaries. Our design ethos blends creativity with usability, crafting interfaces that are both innovative and intuitive for your end-users.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Leveraging modern technologies and cutting-edge tools, we breathe life into your ideas. Our development process focuses on building customized solutions that are not only reliable and scalable but also of the highest quality, ensuring your success in the digital realm.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "Excellence is our hallmark as we adopt an iterative approach to our work. With a dedication to continuous improvement, we strive to deliver excellence with every project, ensuring your satisfaction and success.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      "mainTitle": "FAQ",
      "description": "Frequently Asked Questions",
      "question": [
        {
          "id": 1,
          "title": "What experience do your Vue.js developers have in building progressive web applications (PWAs) and reactive user interfaces?",
          "subtitle": "Our Vue.js developers possess extensive experience in crafting PWAs and reactive user interfaces using Vue.js and related tools such as Vuex, Vue Router, and Vuetify. They specialize in developing scalable and responsive front-end solutions that offer enhanced user experiences across various devices and browsers."
        },
        {
          "id": 2,
          "title": "Can you describe a challenging Vue.js project your developers have successfully completed?",
          "subtitle": "Our Vue.js developers have successfully delivered a variety of challenging projects, including developing real-time collaboration tools, dynamic content management systems, and interactive data visualization platforms. They excel in leveraging Vue.js's reactive data binding, component-based architecture, and VueX for state management to build complex and high-performance applications."
        },
        {
          "id": 3,
          "title": "How do your Vue.js developers manage state in large-scale applications?",
          "subtitle": "Our Vue.js developers employ state management solutions such as Vuex or Vue Composition API to effectively manage complex application states in large-scale applications. They follow best practices for state normalization, modularization, and asynchronous data handling to ensure maintainability and scalability."
        },
        {
          "id": 4,
          "title": "What strategies do your Vue.js developers utilize for optimizing performance in Vue.js applications?",
          "subtitle": "Our Vue.js developers utilize a variety of performance optimization techniques, including code splitting, lazy loading, memoization, server-side rendering (SSR), and efficient DOM manipulation, to enhance the performance of Vue.js applications and ensure fast load times and smooth interactions. They also leverage performance profiling tools to identify and address performance bottlenecks."
        },
        {
          "id": 5,
          "title": "How do your Vue.js developers handle client-side routing and navigation in Vue.js applications?",
          "subtitle": "Our Vue.js developers utilize Vue Router for client-side routing and navigation in Vue.js applications. They design efficient route structures, implement nested routes, and utilize route guards and navigation hooks to manage navigation flows and ensure seamless user experiences across different views and components."
        },
        {
          "id": 6,
          "title": "What testing methodologies and tools do your Vue.js developers use to ensure the quality of Vue.js applications?",
          "subtitle": "Our Vue.js developers follow a comprehensive testing approach, including unit testing, integration testing, and end-to-end testing, to ensure the quality and reliability of Vue.js applications. They leverage testing frameworks like Jest, Vue Test Utils, and Cypress for automated testing and conduct manual testing to validate UI/UX and functionality."
        },
        {
          "id": 7,
          "title": "How do your Vue.js developers stay updated with the latest Vue.js releases, libraries, and best practices?",
          "subtitle": "Our Vue.js developers are committed to continuous learning and professional development. They stay updated with the latest Vue.js releases, libraries, and best practices by attending conferences, workshops, online courses, and actively participating in online communities and forums dedicated to Vue.js development. They also contribute to open-source projects and experiment with new tools and libraries to enhance their expertise in Vue.js development."
        }
      ]
    }
  ];
  return (
    <div id="Hire-VueJS-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireVueJSDeveloper;
