import React from "react";
import HeroSection from "./HeroSection";
import AboutSection from "./AboutSection";
import LoveSection from "./LoveSection";
import CounterUp from "./CounterUp";
import Services from "./Services";
import ScreenShort from "./ScreenShort";

import WorkSection from "./WorkSection";
import Testimonial from "./Testimonial";
import ContactUs from "components/ContactUs";
import CommandCenter from "./CommandCenter";
const BeautySalon = () => {
    return (
        <div className="on-demand-app-container">
            <HeroSection />
            <AboutSection />
            <LoveSection />
            <CounterUp />
            <Services />
            <WorkSection />
            <ScreenShort />
            <CommandCenter />
            {/* <Testimonial /> */}
            <ContactUs />
        </div>
    );
};

export default BeautySalon;
