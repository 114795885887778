import React from "react";
import "./HireFullstackDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireFullstackDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Empower Your Business with Full Stack Developers",
      subTitle:
        "Unlock the potential of your digital ventures by hiring full stack developers. At our development hub, we offer seasoned professionals skilled in crafting robust and scalable solutions for diverse business needs. Whether you're aiming for prototype development or enterprise-level applications, our full stack developers are ready to transform your vision into reality with rapid development cycles.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Premier Full Stack Development Solutions",
      title: "Engage Our Dedicated Developers",
      text: "Embark on your digital journey with our leading full stack development solutions. As a prominent full stack development company, we deliver top-notch services tailored to meet your business objectives. Our adept developers possess extensive expertise across various industry domains, ensuring the success of your ventures.Our dedicated team of full stack developers adopts an agile approach to work, swiftly transforming your ideas into reality. From custom applications to web and enterprise solutions, we excel in deploying innovative solutions that meet your unique requirements.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire Fullstack Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our Fullstack Developers",
      subTitle: "Unlocking the Potential Expertise of Our Fullstack Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Full Stack Enterprise Solution Development",
          icon: icons.learningIcon,
          benefitdescription:
            "Dive into the world of highly efficient enterprise applications crafted by our expert team. We specialize in developing solutions that not only meet your business needs but also provide seamless user experiences. With our full stack enterprise solution development services, you can expect scalable, reliable, and cost-effective applications that propel your business forward.",
        },
        {
          id: 2,
          benefitTitle: "Full Stack Web Hosting",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Entrust your web hosting needs to our seasoned full stack developers and experience unparalleled reliability. We understand the importance of dependable web hosting services for the success of your online ventures. Our experts ensure that your website is hosted on robust servers, guaranteeing optimal performance, uptime, and security. With our full stack web hosting solutions, you can focus on growing your business while we take care of the technical aspects.",
        },
        {
          id: 3,
          benefitTitle: "Automated Testing",
          icon: icons.holidayIcon,
          benefitdescription:
            "Embrace the efficiency of automated testing with our comprehensive suite of services. Our skilled QA engineers leverage cutting-edge tools and technologies to automate various testing processes, ensuring thorough coverage and faster feedback cycles. By harnessing the power of automation, we help you identify bugs early, streamline testing workflows, and deliver high-quality software products on time and within budget.",
        },
        {
          id: 4,
          benefitTitle: "Full Stack Integration",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Seamlessly connect your applications and systems to provide a cohesive user experience with our full stack integration services. Our experienced developers specialize in integrating diverse technologies, platforms, and databases, ensuring smooth data flow and communication across your entire IT infrastructure. With our expertise in full stack integration, you can enhance collaboration, improve efficiency, and drive business growth.",
        },
        {
          id: 5,
          benefitTitle: "Database Programming",
          icon: icons.salaryIcon,
          benefitdescription:
            "Unlock the full potential of your databases with our dedicated team of full stack developers. Whether you need to optimize existing databases or develop new ones from scratch, our experts have the skills and experience to deliver efficient, scalable, and secure solutions. From designing data models to implementing complex queries, we ensure that your databases perform optimally and support your business objectives.",
        },
        {
          id: 6,
          benefitTitle: "Cloud Migration",
          icon: icons.environmentIcon,
          benefitdescription:
            "Embark on a seamless journey to the cloud with our proficient cloud migration services. Our experienced team helps you migrate your existing solutions to public, private, or hybrid cloud environments, ensuring scalability, flexibility, and cost-effectiveness. Leveraging our expertise in cloud platforms, we handle the migration process with precision, minimizing downtime and disruptions to your business operations.",
        },
        {
          id: 7,
          benefitTitle: "Continuous Integration and Deployment (CI/CD) Pipeline Setup",
          icon: icons.professionalIcon,
          benefitdescription:
            "Streamline your software development lifecycle with our CI/CD pipeline setup services. Our experienced full stack developers design and implement automated CI/CD pipelines tailored to your project requirements, enabling rapid and reliable delivery of software updates and enhancements. By automating build, test, and deployment processes, we help you accelerate time-to-market, improve code quality, and reduce manual errors, fostering a culture of continuous integration and delivery within your organization.",
        },
        {
          id: 8,
          benefitTitle: "Real-time Data Analytics and Visualization",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Harness the power of real-time data analytics and visualization with our full stack development expertise. Our skilled team designs and implements custom solutions for capturing, processing, and analyzing data in real-time, enabling you to gain actionable insights and make informed business decisions. Whether you need interactive dashboards, predictive analytics models, or data-driven visualizations, we deliver cutting-edge solutions that empower you to stay ahead of the competition and drive business growth.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Full Stack Development Services We Offer",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our Fullstack developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "Full Stack Enterprise Solution Development",
          description:
            "Unlock the potential of your enterprise with our full stack enterprise solution development services. From conceptualization to deployment, we specialize in creating comprehensive solutions tailored to meet the unique needs of your business. Our expertise encompasses front-end and back-end development, ensuring seamless integration and robust functionality across your organization.",
          number: "01",
        },
        {
          id: 2,
          title: "Full Stack Web Hosting",
          description:
            "Experience reliable and efficient web hosting solutions with our full stack web hosting services. Whether you're launching a new website or migrating an existing one, our team ensures optimal performance, scalability, and security for your online presence. We leverage cutting-edge technologies to provide seamless hosting solutions tailored to your specific requirements.",
          number: "02",
        },
        {
          id: 3,
          title: "Full Stack E-commerce Development",
          description:
            "Elevate your online business with our full stack e-commerce development services. From building custom e-commerce platforms to optimizing existing ones, we deliver solutions that are tailored to enhance user experience, streamline operations, and drive sales. Our expertise in front-end and back-end technologies ensures a robust and scalable e-commerce solution that meets your business objectives.",
          number: "03",
        },
        {
          id: 4,
          title: "Support and Maintenance Services",
          description:
            "Ensure the smooth operation of your applications with our support and maintenance services. From troubleshooting issues to implementing updates and enhancements, our dedicated support team is available round-the-clock to address your needs and keep your systems running smoothly. With our proactive approach to maintenance, you can minimize downtime and maximize productivity.",
          number: "04",
        },
        {
          id: 5,
          title: "Data Migration and Conversion Services",
          description:
            "Efficiently transfer and convert your data between different systems and formats with our data migration and conversion services. Whether you're migrating to a new platform, consolidating data from multiple sources, or upgrading your database systems, our experienced full stack developers ensure seamless data migration while preserving data integrity and security. We handle the entire process, from data analysis and mapping to execution and validation, ensuring a smooth transition with minimal disruption to your operations.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for Fullstack Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Uncover and shape brands that stand the test of time. We conduct extensive research and analysis to provide a roadmap for your solution that ensures success.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "We excel in creating innovative designs that are both creative and user-friendly. Our design style is imaginative, intricate, yet intuitive for end-users.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Utilizing modern technologies and advanced tools, we construct customized solutions that are not only reliable and scalable but also of the highest quality.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "We uphold excellence by employing an iterative approach to our work. Continuously striving for improvement, we ensure the delivery of exceptional results with every project.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "What experience do your full-stack developers have in both frontend and backend technologies?",
          subtitle:
            "Our full-stack developers possess extensive experience in frontend and backend development, with proficiency in a wide range of technologies such as HTML, CSS, JavaScript, TypeScript, React.js, Node.js, Express.js, Python, Django, and more. They excel in building end-to-end solutions that encompass both client-side and server-side components.",
        },
        {
          id: 2,
          title:
            "Can you describe a complex full-stack project your developers have successfully delivered?",
          subtitle:
            "Our full-stack developers have successfully delivered various complex projects, such as developing comprehensive e-commerce platforms, social networking applications, and enterprise-grade web applications. They have the expertise to architect scalable and robust solutions, handling everything from database design and API development to frontend UI/UX and deployment.",
        },
        {
          id: 3,
          title:
            "How do your full-stack developers handle communication and collaboration between frontend and backend components?",
          subtitle:
            "Our full-stack developers emphasize seamless communication and collaboration between frontend and backend components using RESTful APIs, GraphQL, or other communication protocols. They ensure data consistency, security, and performance by implementing clear data transfer mechanisms and proper validation between client and server.",
        },
        {
          id: 4,
          title:
            "What strategies do your full-stack developers employ for database design and optimization?",
          subtitle:
            "Our full-stack developers are skilled in database design and optimization, utilizing relational and NoSQL databases such as MySQL, PostgreSQL, MongoDB, and Firebase. They employ normalization techniques, indexing, query optimization, and caching strategies to ensure efficient data storage, retrieval, and management.",
        },
        {
          id: 5,
          title:
            "How do your full-stack developers approach authentication and authorization in web applications?",
          subtitle:
            "Our full-stack developers implement robust authentication and authorization mechanisms using industry-standard protocols such as OAuth, JWT (JSON Web Tokens), and session management. They ensure secure access control to resources and sensitive data, preventing unauthorized access and protecting user privacy.",
        },
        {
          id: 6,
          title:
            "What DevOps practices and tools do your full-stack developers utilize for CI/CD and deployment?",
          subtitle:
            "Our full-stack developers embrace DevOps practices for continuous integration, continuous deployment (CI/CD), and automated testing. They utilize tools such as Jenkins, GitLab CI/CD, Docker, Kubernetes, and AWS/GCP/Azure services to automate build, test, and deployment pipelines, ensuring rapid and reliable delivery of software updates.",
        },
        {
          id: 7,
          title:
            "How do your full-stack developers handle scalability and performance optimization in web applications?",
          subtitle:
            "Our full-stack developers design scalable architectures and employ performance optimization techniques such as load balancing, caching, horizontal scaling, and asynchronous processing to ensure web applications can handle increasing user loads and maintain optimal performance under heavy traffic. They continuously monitor application performance and make necessary adjustments to optimize resource utilization and response times.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-Fullstack-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireFullstackDeveloper;
