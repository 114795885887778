import Progress from "components/inputs/Progress";
import "./Impact.scss";
import icons from "utils/constants/icons";
import Button from "components/Button";

const Impact = () => {
    return (
        <section className="impact-section">
            <div className="container">
                <h3 className="impact-title">Impact</h3>
                <div className="impact-div">
                    <div className="row gy-5">
                        <div className="col-lg-5 col-md-12">
                            <Progress
                                now="72"
                                label="72%"
                                text="Quarterly Rise in Number of Freelance Projects"
                            />
                        </div>
                        <div className="col-lg-5 offset-lg-2 col-md-12">
                            <Progress
                                now="89"
                                label="89%"
                                text="Quarterly Rise in Project Bids"
                            />
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <Progress
                                now="45"
                                label="45%"
                                text="Quarterly Jump in Overall Business Revenue"
                            />
                        </div>
                        <div className="col-lg-5 offset-lg-2  col-md-12">
                            <Progress
                                now="68"
                                label="68%"
                                text="Quarterly Increase in Total User Base"
                            />
                        </div>
                    </div>
                    <div className="last-div">
                        <h3 className="last-text">
                            Build An Upwork Like App Today to Contribute to the
                            Massive $750B Economy
                        </h3>
                        <div className="btn-div">
                            <Button
                                btnStyle="btn-purple"
                                btnText="Get Started"
                                rightIcon={icons?.rightNavigateIcons}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Impact;
