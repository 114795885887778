import "./ScreenShort.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icons from "utils/constants/icons";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import { useEffect, useRef, useState } from "react";
const ScreenShort = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const swiperRef = useRef(null);
    const screenShortList = [
        {
            id: 1,
            image: icons?.slideImg,
        },
        {
            id: 2,
            image: icons?.slideImg,
        },
        {
            id: 3,
            image: icons?.slideImg,
        },
        {
            id: 4,
            image: icons?.slideImg,
        },
    ];
    const handleNext = () => {
        if (swiperRef?.current) {
            swiperRef?.current?.slideNext();
        }
    };

    const handlePrev = () => {
        if (swiperRef?.current) {
            swiperRef?.current?.slidePrev();
        }
    };
    useEffect(() => {
        console.log("Swiper reference:", swiperRef.current);
    }, [swiperRef.current]);
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     arrows: true,
    //     responsive: [
    //         {
    //             breakpoint: 576, // Small screens (portrait phones, less than 576px)
    //             settings: {
    //                 slidesToShow: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 768, // Medium screens (tablets, 576px and up)
    //             settings: {
    //                 slidesToShow: 2,
    //             },
    //         },
    //         {
    //             breakpoint: 992, // Large screens (desktops, 768px and up)
    //             settings: {
    //                 slidesToShow: 3,
    //             },
    //         },
    //         {
    //             breakpoint: 1200, // Extra large screens (larger desktops)
    //             settings: {
    //                 slidesToShow: 4,
    //             },
    //         },
    //     ],
    // };
    return (
        <div className="ScreenShort-section">
            <div className="container">
                <h4 className="screen-title">SCREENSHOTS</h4>
                <h1 className="screen-sub-title">Amazing interface</h1>
                <p className="screen-sub-pra">
                    Consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolor tempor incididunt ut labore
                    dolore magna.
                </p>

                <div className="div-max">
                    <Swiper
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        spaceBetween={20}
                        slidesPerView={1}
                        navigation={false}
                        pagination={{
                            clickable: true,
                        }}
                        onSlideChange={(swiper) =>
                            setCurrentSlide(swiper.activeIndex)
                        }
                        // autoplay={{
                        //     delay: 2000,
                        //     disableOnInteraction: false,
                        // }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper">
                        {screenShortList?.map((ele, index) => {
                            const { image } = ele;
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="screen-card">
                                    <img
                                        src={image}
                                        alt="screen-short-img"
                                        loading="lazy"
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div className="custom-navigation">
                        <button
                            onClick={handlePrev}
                            className={`btn-prev ${
                                currentSlide === 0 ? "first-slide" : ""
                            }`}>
                            <img
                                src={icons?.leftImg}
                                alt="left-img"
                                loading="lazy"
                            />
                        </button>
                        <button
                            onClick={handleNext}
                            className={`btn-next ${
                                currentSlide === screenShortList.length - 1
                                    ? "last-slide"
                                    : ""
                            }`}>
                            <img
                                src={icons?.rightImg}
                                alt="right-img"
                                loading="lazy"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ScreenShort;
