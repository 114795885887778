import React from "react";
import "./EngageSection.scss";
import TouchLightBtn from "components/common/TouchLightBtn";
import ExploreBtn from "components/common/ExploreBtn";
const EngageSection = ({ data }) => {
  return (
    <div id="Engage-Section-id">
      {data?.map((ele, index) => {
        const {
          mainTitle,
          subTitle,
          title,
          text,
          images,
          btnTwotitle,
          businessTitle,
          businessText,
          btnOnetitle,
          tags,
          reverse,
        } = ele;
        return (
          <React.Fragment key={index}>
            <div className="container">
              <h4 className="title section-line">{mainTitle}</h4>
              <h3 className="sub-title mb-0 pb-1">{subTitle}</h3>
              <div className="main-engage-div">
                <div className="row">
                  <div
                    className={`col-lg-6 ${reverse === "true" ? "order-lg-1" : ""
                      }`}>
                    <div className="text-div">
                      <h3 className="title-sub">{title}</h3>
                      <p className="text-w-s">{text}</p>
                    </div>
                    {tags && (
                      <div className="filed-div">
                        {tags?.map((elm, Tindex) => {
                          const { image, text } = elm;
                          return (
                            <div className="sub-filed" key={Tindex}>
                              <div className="check-icon-bg">
                                <img
                                  src={image}
                                  width={24}
                                  height={24}
                                  alt="filedImages"
                                />
                              </div>
                              <div className="sub-filed-text">{text}</div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <div className="hire-btn">
                      <a href="#help-section-id">
                        <ExploreBtn
                          title={btnOnetitle}
                          className="btn-4bbb8b mb-3"
                        />
                      </a>
                    </div>{" "}
                  </div>
                  <div
                    className={`col-lg-6 ${reverse === "true" ? "" : "order-lg-1"
                      }`}>
                    <div className="developerImg-div">
                      <img
                        src={images}
                        alt="hireResourcesDeveloperImg"
                        className="developerImg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="engage-hire-section">
              <div className="container">
                <div className="main-hire-section">
                  <div className="row">
                    <div className="col-lg-7">
                      <h1 className="hire-section-title"> {businessTitle}</h1>
                      <p className="hire-section-text">{businessText}</p>
                    </div>
                    <div className="col-lg-5">
                      <div className="engage-hire-btn">
                        <TouchLightBtn title={btnTwotitle} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default EngageSection;
