import { Modal } from 'react-bootstrap';
import './ImagePopup.scss';

const ImagePopup = ({ onHide, imageUrl }) => {
    return (
        <Modal
            show
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
            className="image-popup gallery-img-prev"
            animation
        >
            <img
                src={
                    imageUrl?.slideImageOne
                        ? imageUrl?.slideImageOne
                        : imageUrl?.slideImageTwo
                }
                alt="slide"
                className="rounded"
            />
        </Modal>
    );
};

export default ImagePopup;
