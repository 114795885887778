import "./Page3HeroSection.scss";
import thumb1 from "assets/images/businessmodel/page3/thumb-1.png";
import heroBg from "assets/images/businessmodel/page3/hero-bg.png";

const Page3HeroSection = () => {
  return (
    <section className="page3-hero">
      <div className="page3-hero-div ">
        <div className="shapes-container">
          <div className="bg-shape"></div>
        </div>
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-12 col-md-7">
              <div className="hero-section-text">
                <h1 className="text-header">
                  Creative way to <span className="m-light">Showcase</span> your
                  App
                </h1>
                <p className="my-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Temporibus commodi, voluptate quaerat iure quidem expedita eos
                  a blanditiis sint modi est error veniam facere eum at
                  doloribus amet, nobis ut.
                </p>
                <div className="btn">Get Started</div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div
                className="hero-thumb"
                data-aos="fade-right"
                data-aos-delay="500"
                data-aos-duration="1000"
              >
                <img src={thumb1} alt="thumbnail" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-bg">
        <img src={heroBg} alt="bg" />
      </div>
    </section>
  );
};

export default Page3HeroSection;
