import icons from "utils/constants/icons";
import "./Comprehensive.scss"
const Comprehensive =() =>{
    return (
        <>
             <div className="container-fluid Comprehensivemain-container">
      <div className="container Comprehensive-container">
        <div className="row text-center  mb-5 justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 mx-auto ">
            <div className="Comprehensive-header">
            <h1 className="display-4 mb-3">
            Unlock the Full Potential of Real Estate
            with Our Comprehensive Suite
            </h1>
            <p className="lead text-muted">
            Our real estate platform offers a seamless blend of mobile, desktop, and administrative tools, designed to 
            streamline your operations and enhance user experiences. Here’s an overview of how each component works together to elevate your business
            </p>
            </div>
          </div>
          <div className='col-lg-12  Comprehensive-img d-flex '>
            <img src={icons.Comprehensiveimg} alt="Background Illustration" className="img-fluid img" />
          </div>
        </div>

      </div>
    </div>
        </>
    )
}

export default Comprehensive;