import icons from "utils/constants/icons";
import "./AdditionalFeatures.scss";

const AdditionalFeatures = () => {
  const featuresList = [
    {
      images: icons?.profileImg,
      title: "Simple Profile Creation",
      description:
        "Better opportunity for freelancers & recruiters to get noticed with dynamic profiles which are easy to update & manage",
    },
    {
      images: icons?.paymentImg,
      title: "Multiple Payment Options",
      description:
        "Users enjoy a secure and uninterrupted payment process with endless number of payment gateways",
    },
    {
      images: icons?.mobileImgs,
      title: "Mobile-Friendly",
      description:
        "Mobile friendly freelance website & powerful apps to provide your customers an intuitive experience",
    },
    {
      images: icons?.webImg,
      title: "Responsive Web Panel",
      description:
        "Highly responsive & fully functional web panel to make user registration & bidding a hassle-free process",
    },
    {
      images: icons?.chatImgs,
      title: "Real Time Chat",
      description:
        "Give your users freedom to contact sellers on your Upwork clone instantly through a web widget & in-app chat",
    },
    {
      images: icons?.customzableImg,
      title: "Fully Customizable",
      description:
        "Get the complete flexibility to customize your Upwork like app with your own logo, colors and domain",
    },
    {
      images: icons?.rattingImg,
      title: "Reviews & Ratings",
      description:
        "With a built-in rating & review system let your users & service providers leave their honest feedback",
    },
    {
      images: icons?.socialImg,
      title: "Social Login",
      description:
        "Let your users register & sign-in on your custom Upwork clone app using their social media accounts",
    },
  ];

  return (
    <section className="features-container">
      <h1 className="features-title">Additional Features</h1>
      <div className="container">
        <div className="row">
          {featuresList?.map((ele, index) => {
            const { description, images, title } = ele;
            return (
              <div className="col-xl-3  col-md-4 col-sm-6" key={index}>
                <div className="features-card">
                  <img src={images} alt="features-icons" loading="lazy" />
                  <h3 className="features-title-text">{title}</h3>
                  <p className="features-pra">{description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AdditionalFeatures;
