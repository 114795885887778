import icons from "utils/constants/icons";
import "./Technologies.scss";

function Technologies() {
    return (
        <div className="container section-line">
            <h4 className="title">TECHNOLOGIES</h4>
            <h3 className="sub-title">Technologies and Platforms We Use</h3>
            <div className="technology-group">
                <div className="technology">
                    <img src={icons.Technology1} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology2} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology3} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology4} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology5} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology6} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology7} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology8} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology9} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology10} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology11} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology12} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology13} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology14} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology15} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology16} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology17} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology18} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology19} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology20} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology21} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology22} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology23} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology24} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology25} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology26} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology27} alt="Technology" />
                </div>
                <div className="technology">
                    <img src={icons.Technology28} alt="Technology" />
                </div>
            </div>
        </div>
    );
}

export default Technologies;
