import React from "react";
import "./HireFlutterDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";

function HireFlutterDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Empower Your Digital Initiatives with Skilled Flutter Engineers",
      subTitle:
        "Elevate your digital projects with our proficient Flutter engineers. Join forces with us to tap into the expertise of our Flutter professionals and gain a competitive edge. Whether you're in need of dynamic mobile app development, captivating user interfaces, or robust cross-platform solutions, our Flutter engineers are primed to deliver exceptional results.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Unlock Exceptional Flutter Solutions with Our Team",
      title: "Engage Our Dedicated Developers",
      text: "Welcome to our platform, your premier destination for top-notch Flutter development services. As a leading Flutter development entity, we offer access to a talented pool of dedicated Flutter engineers meticulously chosen for their qualifications, experience, and adeptness in Flutter technology.Our Flutter engineers excel in crafting high-performing and innovative solutions tailored precisely to your project requirements. Whether you require part-time assistance, full-time collaboration, or specialized ad-hoc support, our adaptable team seamlessly integrates into your workflow, ensuring seamless collaboration and superior outcomes.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire Flutter Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our Flutter Developers",
      subTitle: "Unlocking the Potential Expertise of Our Flutter Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "Flutter Application Development",
          icon: icons.learningIcon,
          benefitdescription:
            "Collaborate with us to leverage the expertise of our Flutter engineers in crafting dynamic and responsive mobile applications. Our engineers possess the requisite skills and experience to deliver top-tier quality in Flutter app development, tailored to your specific needs.",
        },
        {
          id: 2,
          benefitTitle: "Flutter User Interface/User Experience Design",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Immerse yourself in engaging user interfaces crafted by our dedicated Flutter engineers. Specializing in Flutter UI/UX design, our team ensures intuitive and seamless user experiences that foster engagement and satisfaction for your target audience.",
        },
        {
          id: 3,
          benefitTitle: "Cross-Platform Development using Flutter",
          icon: icons.holidayIcon,
          benefitdescription:
            "Uncover the potential of Flutter for building robust cross-platform solutions. Whether you require scalable and interactive mobile apps or custom solutions for multiple platforms, our top-tier engineers possess the skills and experience to fulfill your Flutter development needs.",
        },
        {
          id: 4,
          benefitTitle: "Flutter Integration & Enhancement",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Stay ahead of the curve with seamless integrations and enhancements. Our experienced Flutter engineers specialize in integrating and enhancing existing applications with Flutter, ensuring compatibility, security, and optimal performance.",
        },
        {
          id: 5,
          benefitTitle: "Flutter Maintenance & Support",
          icon: icons.salaryIcon,
          benefitdescription:
            "Ensure the uninterrupted operation of your Flutter applications with our round-the-clock maintenance and support services. Our dedicated Flutter engineers are committed to providing hassle-free maintenance and support, guaranteeing your peace of mind and seamless business operations.",
        },
        {
          id: 6,
          benefitTitle: "Flutter Migration Services",
          icon: icons.environmentIcon,
          benefitdescription:
            "Our Flutter engineers offer expertise in migrating existing mobile applications to Flutter, enabling you to leverage the benefits of Flutter's cross-platform development capabilities. Whether you're transitioning from native platforms or other cross-platform frameworks, our engineers guide you through the migration process, ensuring a smooth and seamless transition while preserving existing features and functionality.",
        },
        {
          id: 7,
          benefitTitle: "Performance Optimization",
          icon: icons.professionalIcon,
          benefitdescription:
            "With a keen focus on performance, our Flutter engineers employ best practices and optimization techniques to ensure that your applications deliver superior performance across devices and platforms. From optimizing code structure and minimizing resource consumption to implementing efficient rendering techniques, we strive to enhance the speed, responsiveness, and overall user experience of your Flutter applications.",
        },
        {
          id: 8,
          benefitTitle: "Flutter Plugin Development",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Our Flutter engineers excel in developing custom plugins to extend the functionality of your Flutter applications. Whether you need to integrate native device features, access platform-specific APIs, or connect to third-party services, our engineers can create tailored plugins that seamlessly integrate with your Flutter projects, enhancing their capabilities and versatility.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of Flutter Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our Flutter developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "Customized Solutions for Your Business Requirements",
          description:
            "Seeking innovative and dynamic solutions? Collaborate with our Flutter engineers equipped with a high level of expertise to bring your digital visions to fruition. Choose from a variety of services tailored to meet your specific needs.",
          number: "01",
        },
        {
          id: 2,
          title: "Flutter Application Development",
          description:
            "Embark on an innovation journey with our Flutter app development services. Our skilled engineers craft dynamic and responsive mobile applications tailored to your unique business requirements, ensuring seamless functionality and user experience.",
          number: "02",
        },
        {
          id: 3,
          title: "Flutter User Interface/User Experience Design",
          description:
            "Experience captivating user interfaces with our dedicated Flutter engineers. Specializing in Flutter UI/UX design, our team ensures intuitive and seamless user experiences that drive engagement and satisfaction for your audience.",
          number: "03",
        },
        {
          id: 4,
          title: "Cross-Platform Development using Flutter",
          description:
            "Unlock the power of Flutter for building robust cross-platform solutions. Whether you require scalable and interactive mobile apps or custom solutions for multiple platforms, our top-tier engineers possess the skills and experience to meet your Flutter development needs.",
          number: "04",
        },
        {
          id: 5,
          title: "Flutter Integration & Enhancement",
          description:
            "Stay abreast of technological advancements with seamless integrations and enhancements. Our experienced Flutter engineers specialize in integrating and enhancing existing applications with Flutter, ensuring compatibility, security, and optimal performance.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for Flutter Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Embark on a journey of discovery as we delve into your needs and aspirations. Through comprehensive research and analysis, we unearth insights to shape a roadmap that aligns seamlessly with your vision.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "Unleash creativity as we design solutions that push boundaries. Our design ethos blends creativity with usability, crafting interfaces that are both innovative and intuitive for your end-users.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Leveraging modern technologies and cutting-edge tools, we breathe life into your ideas. Our development process focuses on building customized solutions that are not only reliable and scalable but also of the highest quality, ensuring your success in the digital realm.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "Excellence is our hallmark as we adopt an iterative approach to our work. With a dedication to continuous improvement, we strive to deliver excellence with every project, ensuring your satisfaction and success.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "What experience do your Flutter developers have in cross-platform mobile app development?",
          subtitle:
            "Our Flutter developers have extensive experience in developing cross-platform mobile applications using the Flutter framework. They are proficient in building high-performance, visually appealing apps that run seamlessly on both iOS and Android platforms.",
        },
        {
          id: 2,
          title:
            "Can you describe a complex mobile app project your Flutter developers have successfully delivered?",
          subtitle:
            "Our Flutter developers have successfully delivered various complex mobile app projects, including e-commerce platforms, social networking apps, and on-demand service applications. They excel in leveraging Flutter's rich widget library and native performance to create feature-rich and user-friendly applications.",
        },
        {
          id: 3,
          title:
            "How do your Flutter developers handle state management in Flutter applications?",
          subtitle:
            "Our Flutter developers are proficient in various state management techniques, including Provider, Bloc, Redux, and MobX. They evaluate the specific requirements of each project and select the most suitable state management approach to ensure maintainability, scalability, and performance of the application.",
        },
        {
          id: 4,
          title:
            "What testing strategies do your Flutter developers employ to ensure app quality?",
          subtitle:
            "Our Flutter developers follow a comprehensive testing approach, including unit testing, integration testing, and widget testing, to ensure the quality and reliability of Flutter applications. They utilize testing frameworks like Flutter's built-in testing library, Mockito, and Flutter Driver to automate testing processes and identify and fix bugs early in the development lifecycle.",
        },
        {
          id: 5,
          title:
            "How do your Flutter developers handle platform-specific integrations and device features?",
          subtitle:
            "Our Flutter developers leverage platform channels and native plugins to integrate platform-specific features and APIs into Flutter applications seamlessly. Whether it's accessing device sensors, utilizing platform-specific UI components, or integrating with native SDKs, our developers ensure consistent performance and behavior across iOS and Android platforms.",
        },
        {
          id: 6,
          title:
            "What steps do your Flutter developers take to optimize app performance and minimize app size?",
          subtitle:
            "Our Flutter developers employ various optimization techniques, including code splitting, tree shaking, and AOT (Ahead-Of-Time) compilation, to reduce app size and improve performance. They also utilize Flutter's hot reload feature for rapid iteration and performance profiling tools to identify and address performance bottlenecks.",
        },
        {
          id: 7,
          title:
            "How do your Flutter developers stay updated with the latest Flutter releases and best practices?",
          subtitle:
            "Our Flutter developers are active members of the Flutter community and stay updated with the latest releases, updates, and best practices. They participate in Flutter meetups, forums, and online communities, contribute to open-source projects, and engage in continuous learning to enhance their skills and expertise in Flutter development.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-Flutter-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireFlutterDeveloper;
