import "./Page3Features.scss";
import feat1 from "assets/images/businessmodel/page3/f1.png";
import feat2 from "assets/images/businessmodel/page3/f2.png";
import feat3 from "assets/images/businessmodel/page3/f3.png";
import feat4 from "assets/images/businessmodel/page3/f4.png";
import feat5 from "assets/images/businessmodel/page3/f5.png";
import feat6 from "assets/images/businessmodel/page3/f6.png";

const FeaturesBox = () => {
  let data = [
    {
      image: feat1,
      title: "On-Demand Car Wash",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: "0",
    },
    {
      image: feat2,
      title: "On-Demand Beauty Services ",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: "200",
    },
    {
      image: feat3,
      title: "On-Demand Healthcare",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: "400",
    },
    {
      image: feat4,
      title: "On-Demand Food Delevery",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: "600",
    },
    {
      image: feat5,
      title: "On-Demand Laundry Service",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: "800",
    },
    {
      image: feat6,
      title: "On-Demand Pickup Delevery",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.et dolore magna aliqua. Ut enim ad minim veniam.",
      delay: "1000",
    },
  ];
  return (
    <>
      {data.length &&
        data.map((val, index) => (
          <div className="col-12 col-md-6 col-lg-4" key={index}>
            <div
              className="icon-box text-center p-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-mirror="false"
              data-aos-once="true"
              data-aos-delay={val.delay}
            >
              <img src={val.image} alt="feat-1" className="mb-3" height={250} />
              <h3 className="mb-2">{val.title}</h3>
              <p>{val.description}</p>
            </div>
          </div>
        ))}
    </>
  );
};

export default FeaturesBox;
