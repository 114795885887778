import LabelText from "../LabelText";
import "./TextArea.scss";

const TextArea = ({
    label,
    placeholder,
    error,
    onChange,
    id,
    value,
    isRequired,
    maxLength,
}) => {
    return (
        <div id="text-area-container">
            {label && <LabelText label={label} required={isRequired} />}
            <textarea
                rows={3}
                className="text-input-area form-control"
                onChange={onChange}
                id={id}
                value={value}
                placeholder={placeholder}
            />
            {error && (
                <span className="text-13-500 pt-1">
                    <span className="dropdown-error-text">{error}</span>
                </span>
            )}
        </div>
    );
};

export default TextArea;
