import React from 'react';
import Technologies from 'pages/Home/Technologies';
import ContactUs from 'components/ContactUs';
import icons from 'utils/constants/icons';
import GetInTouchForm from 'pages/GetInTouchForm/GetInTouchForm';
import { useNavigate } from 'react-router-dom';
import { BiLogoFacebook } from "react-icons/bi";
import { BsTwitter } from "react-icons/bs";
import { BiLogoLinkedin } from "react-icons/bi";
import "./BlogDetail.scss";

const BlogDetail = () => {
  const navigate = useNavigate();

  const blogData = [
    {
        id: 1,
        name: "How To Build An App Like Bolt?",
        date: '11 August, 2023',
        by: 'Shilpi Chhabra',
        image: icons.st1
    },
    {
        id: 2,
        name: "How To Build An App Like Bolt?",
        date: '11 August, 2023',
        by: 'Shilpi Chhabra',
        image: icons.st2
    },
    {
        id: 3,
        name: "How To Build An App Like Bolt?",
        date: '11 August, 2023',
        by: 'Shilpi Chhabra',
        image: icons.st3
    },
];

  return (
    <div id="blog-detail-id">
      <section className="blog-detail-section">
        <div className="blog-detail-content">
          <div className="container blog-short">
            <h1 className="blog-detail-title">Twitter Killer ‘Meta Threads App’ Launching Soon – Everything You Need to Know -Mark Zuckerberg</h1>
            <div className="d-flex justify-content-between blog-detail-date">
              <div>Date : February 21, 2024</div>
              <div className="d-flex">
                <div className="profile me-2"><img src={icons.gallery1} alt="img" className="w-100 h-100 profile-image" /></div>
                <div className="listed">
                  <div className="listed-by">Listed By:</div>
                  <div className="listed-by-name">Shilpi Chhabra</div>
                </div>
              </div>
            </div>
            {/* <TouchBtn title="Get in Touch" /> */}
          </div>
        </div>
      </section>

      <div className='details-section container d-flex flex-wrap mb-5'>
        <div className="col-lg-4 col-md-5 left-block">
          <div className="sticky1">
            <div className="fixer-form sticky">
              <div className="setting-on-blog">
                <div className="row" style={{ padding: '0px' }}> 
                  <div id="subsciberform-2" className="register-form"> 
                    <GetInTouchForm />
                  </div> 
                </div>
                <div className="social-share">
                    <div className="share-article-label">Share article on: </div>
                    <div className="social-share-image">
                      <div><BiLogoFacebook size="18px" color="#4bbb8b" /></div>
                      <div><BsTwitter color="#4bbb8b" /></div>
                      <div><BiLogoLinkedin color="#4bbb8b" /></div>
                    </div>
                  </div> 
              </div>
            </div>
            <div className="table-of-content">
              <img src={icons.gallery10} alt="img" className="w-100 h-100 sample-image" />
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-7 right-block">
          <div>
              <div className="big-image">
                <img alt="img" src={icons.gallery1} className='big-img w-100 h-100' />
              </div>

              <div className='mt-5'>
                <div className="blog-detail-sub-title">Understanding the Market</div>
                <div className="blog-detail-desc mt-2">To get success, it is important to conduct thorough research on your competitors and the industry. This will help you understand the industry dynamics and identify any potential opportunities. It is recommended to use both primary and secondary research methods to enrich your business plan and adopt effective strategies. You should also study your target audience, their preferences, pain points, and the existing competition. Analyzing ride-hailing apps, including their features, user experience, and monetization strategies, is a crucial step in developing your own app. </div>
              </div>

              <div className='mt-5'>
                <div className="blog-detail-sub-title">Understanding the Market</div>
                <div className="blog-detail-desc mt-2">To get success, it is important to conduct thorough research on your competitors and the industry. This will help you understand the industry dynamics and identify any potential opportunities. It is recommended to use both primary and secondary research methods to enrich your business plan and adopt effective strategies. You should also study your target audience, their preferences, pain points, and the existing competition. Analyzing ride-hailing apps, including their features, user experience, and monetization strategies, is a crucial step in developing your own app. </div>
              </div>

              <div className='mt-5'>
                <div className="blog-detail-sub-title">Understanding the Market</div>
                <div className="blog-detail-desc mt-2">To get success, it is important to conduct thorough research on your competitors and the industry. This will help you understand the industry dynamics and identify any potential opportunities. It is recommended to use both primary and secondary research methods to enrich your business plan and adopt effective strategies. You should also study your target audience, their preferences, pain points, and the existing competition. Analyzing ride-hailing apps, including their features, user experience, and monetization strategies, is a crucial step in developing your own app. </div>
              </div>

              <div className='mt-5'>
                <div className="blog-detail-sub-title">Understanding the Market</div>
                <div className="blog-detail-desc mt-2">To get success, it is important to conduct thorough research on your competitors and the industry. This will help you understand the industry dynamics and identify any potential opportunities. It is recommended to use both primary and secondary research methods to enrich your business plan and adopt effective strategies. You should also study your target audience, their preferences, pain points, and the existing competition. Analyzing ride-hailing apps, including their features, user experience, and monetization strategies, is a crucial step in developing your own app. </div>
              </div>

              <div className='mt-5'>
                <div className="blog-detail-sub-title">Understanding the Market</div>
                <div className="blog-detail-desc mt-2">To get success, it is important to conduct thorough research on your competitors and the industry. This will help you understand the industry dynamics and identify any potential opportunities. It is recommended to use both primary and secondary research methods to enrich your business plan and adopt effective strategies. You should also study your target audience, their preferences, pain points, and the existing competition. Analyzing ride-hailing apps, including their features, user experience, and monetization strategies, is a crucial step in developing your own app. </div>
              </div>

              <div className='mt-5'>
                <div className="blog-detail-sub-title">Understanding the Market</div>
                <div className="blog-detail-desc mt-2">To get success, it is important to conduct thorough research on your competitors and the industry. This will help you understand the industry dynamics and identify any potential opportunities. It is recommended to use both primary and secondary research methods to enrich your business plan and adopt effective strategies. You should also study your target audience, their preferences, pain points, and the existing competition. Analyzing ride-hailing apps, including their features, user experience, and monetization strategies, is a crucial step in developing your own app. </div>
              </div>

              <div className='mt-5'>
                <div className="blog-detail-sub-title">Wrapping Up</div>
                <div className="blog-detail-desc mt-2">To get success, it is important to conduct thorough research on your competitors and the industry. This will help you understand the industry dynamics and identify any potential opportunities. It is recommended to use both primary and secondary research methods to enrich your business plan and adopt effective strategies. You should also study your target audience, their preferences, pain points, and the existing competition. Analyzing ride-hailing apps, including their features, user experience, and monetization strategies, is a crucial step in developing your own app. </div>
              </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row recent_bar_blog">
          <div className="col-md-12">
            <div className="recent-blogs">
              <div className="recent-blog-heading">
                <div className="other-blogs">Other Blogs</div>
                <div className="explore-more" onClick={() => navigate(`/blog`)}>Explore More</div>
              </div>
              <div className="blog-list row">
              {blogData?.map((blog, i) => {
                return (
                  <div className='col-md-4 '>
                    <div className="blog">
                      <div className="blog-card mb-4" key={i}>
                        <div className="image-section w-100"><img src={blog?.image} alt="img" className="w-100 h-100 img-section" /></div>
                        <div className='mt-3 blog-title' onClick={() => navigate(`/blog/blog-details/${blog?.id}`)}>{blog?.name}</div>
                        <div className='d-flex justify-content-between  mt-3 mb-3 blog-name'>
                          <div>{blog?.date}</div>
                          <div>By: {blog?.by}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

        <Technologies />
        <ContactUs />
    </div>
  )
}

export default BlogDetail;