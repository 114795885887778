import "./RadioButton.scss";

const RadioButton = ({
    id,
    label,
    checked,
    value,
    onChange,
    defaultChecked,
    labelClassName,
    radioClassName,
    error,
    name,
}) => {
    return (
        <div id="round-radio-box-container">
            <div className="form-check d-flex ps-0">
                {label && (
                    <label className={labelClassName}>
                        <input
                            id={id}
                            className={
                                radioClassName
                                    ? radioClassName
                                    : "form-check-input"
                            }
                            type="radio"
                            checked={checked}
                            value={value}
                            // checked={checked === value}
                            onChange={onChange}
                            name={name}
                            // onChange={() => setter(value)}
                        />
                        {label}
                    </label>
                )}
                {error && (
                    <span className="text-13-500 pt-1">
                        <span className="dropdown-error-text">{error}</span>
                    </span>
                )}
            </div>
        </div>
    );
};

export default RadioButton;
