import icons from "utils/constants/icons";
import "./FreelanceService.scss";
import Button from "components/Button";

const FreelanceService = () => {
    return (
        <section className="freelance-service-container">
            <div className="bg-top" />

            <div className="container">
                <div className="center-div">
                    <div className="row gy-md-0 gy-5">
                        <div className="col-lg-5">
                            <div className="left-div">
                                <h1 className="service-title">
                                    Create Your Own Upwork Like{" "}
                                    <span> Freelance Services</span> Marketplace
                                </h1>
                                <p className="service-sub-title">
                                    Launch Your Customized Marketplace Like
                                    Fiverr or Upwork For Freelance Services
                                </p>
                                <div className="btn-div">
                                    <Button
                                        btnStyle="btn-black"
                                        btnText="Get Started"
                                        rightIcon={icons?.rightNavigateIcons}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="right-div">
                                <img
                                    src={icons?.MacBookImg}
                                    alt="mack-book-img"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="last-div" />
            </div>
        </section>
    );
};

export default FreelanceService;
