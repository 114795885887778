import React from "react";
import icons from "utils/constants/icons";
import "./Blog.scss";
// import TouchBtn from 'components/common/TouchBtn';
import ContactUs from "components/ContactUs";
import Technologies from "pages/Home/Technologies";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const navigate = useNavigate();
  const blogData = [
    {
      id: 1,
      name: "How To Build An App Like Bolt?",
      date: "11 August, 2023",
      by: "Shilpi Chhabra",
      image: icons.st1,
    },
    {
      id: 2,
      name: "How To Build An App Like Bolt?",
      date: "11 August, 2023",
      by: "Shilpi Chhabra",
      image: icons.st2,
    },
    {
      id: 3,
      name: "How To Build An App Like Bolt?",
      date: "11 August, 2023",
      by: "Shilpi Chhabra",
      image: icons.st3,
    },
    {
      id: 4,
      name: "How To Build An App Like Bolt?",
      date: "11 August, 2023",
      by: "Shilpi Chhabra",
      image: icons.st4,
    },
    {
      id: 5,
      name: "How To Build An App Like Bolt?",
      date: "11 August, 2023",
      by: "Shilpi Chhabra",
      image: icons.st5,
    },
    {
      id: 6,
      name: "How To Build An App Like Bolt?",
      date: "11 August, 2023",
      by: "Shilpi Chhabra",
      image: icons.st6,
    },
    {
      id: 7,
      name: "How To Build An App Like Bolt?",
      date: "11 August, 2023",
      by: "Shilpi Chhabra",
      image: icons.st7,
    },
    {
      id: 8,
      name: "How To Build An App Like Bolt?",
      date: "11 August, 2023",
      by: "Shilpi Chhabra",
      image: icons.st8,
    },
  ];
  return (
    <>
      <div id="blog-section" className="container mb-5">
        <div className="row">
          {blogData?.map((blog, i) => {
            return (
              <div className="col-md-4">
                <div className="blog-card mb-4" key={i}>
                  <div className="image-section w-100">
                    <img
                      src={blog?.image}
                      alt="img"
                      className="w-100 h-100 img-section"
                    />
                  </div>
                  <div className="d-flex justify-content-between ms-4 me-4 mt-3 mb-3 blog-name">
                    <div>{blog?.date}</div>
                    <div>By: {blog?.by}</div>
                  </div>
                  <div
                    className="ms-2 me-2 blog-title"
                    onClick={() => navigate(`/blog/blog-details/${blog?.id}`)}
                  >
                    {blog?.name}
                  </div>
                  <div className="ms-2 mt-4 mb-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate(`/blog/blog-details/${blog?.id}`)}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="home-technologies">
        <Technologies />
      </div>
      <ContactUs />
    </>
  );
};

export default Blog;
