import "./HealthcarePlayer.scss";
import IntroImage from "assets/images/businessmodel/healthcare/player-pc.png";
import NewVideoModal from "components/modals/NewVideoModal";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import { setIsVideo, setVideoURL } from "store/globalSlice";
import icons from "utils/constants/icons";
const HealthcarePlayer = () => {
    const dispatch = useDispatch();
    return (
        <div className="health-care-player-container">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="intro-video-container d-flex justify-content-center">
                            <div className="intro-image z-0">
                                <img src={icons?.SpaceIcons} alt="Intro" />
                                <div className="intro-image-player">
                                    <ReactPlayer
                                        light={
                                            <img
                                                src={icons?.thumbImg}
                                                alt="Thumbnail"
                                            />
                                        }
                                        width="100%"
                                        height="100%"
                                        url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                        controls={true}
                                        t
                                        // playing={isPlaying}
                                        // onPlay={() => setPlaying(true)}
                                        // onPause={() => setPlaying(false)}
                                    />
                                </div>
                                <div
                                    className="play-btn"
                                    onClick={() => {
                                        dispatch(setIsVideo(true));
                                        dispatch(
                                            setVideoURL({
                                                video: "https://www.youtube.com/watch?v=-0ovL_iYIiI",
                                            })
                                        );
                                    }}>
                                    <a
                                        // href="https://www.youtube.com/watch?v=-0ovL_iYIiI"
                                        className="video-play-btn mfp-iframe">
                                        <img
                                            src={icons?.pauseImg}
                                            alt="Intro"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <NewVideoModal isClose />
            </div>
        </div>
    );
};

export default HealthcarePlayer;
