import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import icons from "utils/constants/icons";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useDispatch } from "react-redux";
import { setIsOpen } from "../../../store/globalSlice";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "./Portfolio.scss";

const Portfolio = () => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(setIsOpen(true));
    };

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (
        <div className="portfolio-container">
            <div className="container">
                <h4 className="title section-line">PORTFOLIO</h4>
                <h3 className="sub-title mb-0 pb-1">
                    The Successfully Served Businesses
                </h3>
                <div className="portfolio-sub-heading">
                    We take pride in catering their growing business needs.
                </div>
            </div>
            <div className="macbook-container">
                <div className="pilse-pera container-main">
                    <div className="pilse-pera-2">
                        <div className="pulse-image port-cart-img">
                            <img
                                src={icons.cartIcon}
                                alt="ellipse"
                                className="ellipse pulse"
                            />
                        </div>
                        <div className="pulse-image port-doctor-img">
                            <img
                                src={icons.portDoctor}
                                alt="ellipse"
                                className="ellipse pulse"
                            />
                        </div>
                        <div className="pulse-image port-food-img">
                            <img
                                src={icons.portFood}
                                alt="ellipse"
                                className="ellipse pulse"
                            />
                        </div>
                        <div className="pulse-image port-mobile-img">
                            <img
                                src={icons.portMobile}
                                alt="ellipse"
                                className="ellipse pulse"
                            />
                        </div>
                        <div className="pulse-image port-progres-img">
                            <img
                                src={icons.portProgres}
                                alt="ellipse"
                                className="ellipse pulse"
                            />
                        </div>
                    </div>
                </div>
                <div className="laptop-container">
                    <img
                        src={icons.macBook}
                        alt="macbook"
                        className="responsive portfolio-laptop"
                    />
                    <div className="portfolio-slider-pera">
                        <div className="portfolio-slider-btns">
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                loop={false}
                                slidesPerView={"auto"}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="mySwiper">
                                <SwiperSlide>
                                    <img src={icons.iferp} alt="iferp" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img
                                        src={icons.udenzLogo}
                                        alt="udenzLogo"
                                    />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img src={icons.paydLogo} alt="paydLogo" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img
                                        src={icons.citronLogo}
                                        alt="citronLogo"
                                    />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img
                                        src={icons.quickDrive}
                                        alt="quickDrive"
                                    />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img
                                        src={icons.mohamedLogo}
                                        alt="mohamedLogo"
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="portfolio-slider-imgs">
                            <Swiper
                                loop={true}
                                thumbs={{
                                    swiper:
                                        thumbsSwiper && !thumbsSwiper.destroyed
                                            ? thumbsSwiper
                                            : null,
                                }}
                                navigation={true}
                                breakpoints={{
                                    576: {
                                        navigation: false,
                                    },
                                }}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="mySwiper2">
                                <SwiperSlide>
                                    <div className="port-main iferp-port">
                                        <div className="row gx-0">
                                            <div className="col-sm-6">
                                                <p className="port-text">
                                                    The Institute for
                                                    Educational Research and
                                                    Publication (IFERP) is a
                                                    professional association
                                                    devoted to the advancement
                                                    of the fields of
                                                    engineering, science, and
                                                    technology through the
                                                    funding of research
                                                    activities, propagation of
                                                    the latest research
                                                    insights, furtherance of
                                                    industry trends, and other
                                                    related ventures. IFERP aims
                                                    to digitalize this entire
                                                    process of innovation,
                                                    collaboration, and
                                                    knowledge-sharing through
                                                    the fostering of a unified
                                                    virtual scientific community
                                                    worldwide. Everything from
                                                    networking and joint
                                                    ventures to learning,
                                                    research assistance,
                                                    publication, and more, will
                                                    be carried out as part of
                                                    this objective
                                                </p>
                                                <div className="port-available">
                                                    <p>Available on</p>
                                                    <img
                                                        src={icons.www}
                                                        alt="www"
                                                    />
                                                </div>

                                                <div className="port-reting">
                                                    <div className="port-visitor">
                                                        <h2>2M+</h2>
                                                        <p>Visitors</p>
                                                    </div>
                                                    <div className="port-visitor">
                                                        <h2>4.5</h2>
                                                        <p>Rating</p>
                                                    </div>
                                                    <div className="port-visitor">
                                                        <h2>64k+</h2>
                                                        <p>Users</p>
                                                    </div>
                                                </div>

                                                <button
                                                    className="btn port-btn"
                                                    onClick={handleClick}>
                                                    Get in Touch
                                                </button>
                                            </div>
                                            <div className="col-sm-6 port-img">
                                                <img
                                                    src={icons.iferpPortfolio}
                                                    alt="iferpPortfolio"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="port-main udenz-port">
                                        <img
                                            className="top-100-logo"
                                            src={icons.top100Logo}
                                            alt="top100Logo"
                                        />
                                        <div className="row gx-0">
                                            <div className="col-sm-6">
                                                <div className="port-special-text">
                                                    <h2>
                                                        Selected under 100 in
                                                        UAE
                                                    </h2>
                                                </div>
                                                <p className="port-text">
                                                    UDENZ has been honored as
                                                    part of the Future 100 list,
                                                    an initiative by the UAE
                                                    Ministry of Economy and the
                                                    Minister of State for
                                                    Government Development and
                                                    the Future. Add this image
                                                </p>
                                                <div className="port-available">
                                                    <p>Available on</p>
                                                    <img
                                                        src={icons.www}
                                                        alt="www"
                                                    />
                                                    <img
                                                        src={icons.playStore}
                                                        alt="www"
                                                        className="playstore-img"
                                                    />
                                                </div>

                                                <div className="port-reting">
                                                    <div className="port-visitor">
                                                        <h2>4.6</h2>
                                                        <p>Rating</p>
                                                    </div>
                                                </div>

                                                <button
                                                    className="btn port-btn"
                                                    onClick={handleClick}>
                                                    Get in Touch
                                                </button>
                                            </div>
                                            <div className="col-sm-6 port-img">
                                                <img
                                                    src={icons.udenzPortfolio}
                                                    alt="udenzPortfolio"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="port-main payd-port">
                                        <div className="row gx-0">
                                            <div className="col-sm-6">
                                                <p className="port-text mb-3">
                                                    Streamline payment
                                                    processes, Small payment
                                                    gateway straightforward,
                                                    secure and cutting-edge
                                                    services solution Available
                                                    for businesses all over menu
                                                </p>
                                                <div className="payd-countys">
                                                    <img
                                                        src={icons.country1}
                                                        alt="Country Icon"
                                                    />
                                                    <img
                                                        src={icons.country2}
                                                        alt="Country Icon"
                                                    />
                                                    <img
                                                        src={icons.country3}
                                                        alt="Country Icon"
                                                    />
                                                    <img
                                                        src={icons.country4}
                                                        alt="Country Icon"
                                                    />
                                                    <img
                                                        src={icons.country5}
                                                        alt="Country Icon"
                                                    />
                                                    <img
                                                        src={icons.country6}
                                                        alt="Country Icon"
                                                    />
                                                    <img
                                                        src={icons.country7}
                                                        alt="Country Icon"
                                                    />
                                                    <img
                                                        src={icons.country8}
                                                        alt="Country Icon"
                                                    />
                                                    <img
                                                        src={icons.country9}
                                                        alt="Country Icon"
                                                    />
                                                </div>
                                                <div className="port-available">
                                                    <p>Available on</p>
                                                    <img
                                                        src={icons.www}
                                                        alt="www"
                                                    />
                                                </div>

                                                <div className="port-reting">
                                                    <div className="port-visitor">
                                                        <h2>2M+</h2>
                                                        <p>Visitors</p>
                                                    </div>
                                                    <div className="port-visitor">
                                                        <h2>4.9</h2>
                                                        <p>Rating</p>
                                                    </div>
                                                    <div className="port-visitor">
                                                        <h2>80k+</h2>
                                                        <p>Users</p>
                                                    </div>
                                                </div>

                                                <button
                                                    className="btn port-btn"
                                                    onClick={handleClick}>
                                                    Get in Touch
                                                </button>
                                            </div>
                                            <div className="col-sm-6 port-img">
                                                <img
                                                    src={icons.paydPortfolio}
                                                    alt="paydPortfolio"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="port-main citron-port">
                                        <div className="row gx-0">
                                            <div className="col-sm-6">
                                                <p className="port-text">
                                                    Tours and Travel packages
                                                    Booking Portal for UAE based
                                                    business,They will show you
                                                    the unique and unmissable
                                                    things to do in your
                                                    destination. With Citron
                                                    Tourism on your side (and in
                                                    your pocket)
                                                </p>
                                                <div className="port-available">
                                                    <p>Available on</p>
                                                    <img
                                                        src={icons.playStore}
                                                        alt="www"
                                                        className="playstore-img"
                                                    />
                                                </div>

                                                <div className="port-reting">
                                                    <div className="port-visitor">
                                                        <h2>5k</h2>
                                                        <p>Visitors</p>
                                                    </div>
                                                    <div className="port-visitor">
                                                        <h2>4.2/5</h2>
                                                        <p>Rating</p>
                                                    </div>
                                                    <div className="port-visitor">
                                                        <h2>1k+</h2>
                                                        <p>Users</p>
                                                    </div>
                                                </div>

                                                <button
                                                    className="btn port-btn"
                                                    onClick={handleClick}>
                                                    Get in Touch
                                                </button>
                                            </div>
                                            <div className="col-sm-6 port-img">
                                                <img
                                                    src={icons.citronPortfolio}
                                                    alt="citronPortfolio"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="port-main quick-drive-port">
                                        <div className="row gx-0">
                                            <div className="col-sm-6">
                                                <p className="port-text">
                                                    Welcome to QuickDrive.ae – a
                                                    project that holds a special
                                                    place in our portfolio,
                                                    showcasing skills and
                                                    dedication to creating
                                                    innovative and user-centric
                                                    digital experiences. As the
                                                    driving force behind the
                                                    development of
                                                    QuickDrive.ae, aimed to
                                                    redefine the online car
                                                    rental landscape by
                                                    seamlessly blending
                                                    cutting-edge technology with
                                                    an intuitive user interface.
                                                </p>
                                                <div className="port-available">
                                                    <p>Available on</p>
                                                    <img
                                                        src={icons.www}
                                                        alt="www"
                                                    />
                                                </div>

                                                <div className="port-reting">
                                                    <div className="port-visitor">
                                                        <h2>1M+</h2>
                                                        <p>Visitors</p>
                                                    </div>
                                                    <div className="port-visitor">
                                                        <h2>4.3/5</h2>
                                                        <p>Rating</p>
                                                    </div>
                                                    <div className="port-visitor">
                                                        <h2>20k+</h2>
                                                        <p>Users</p>
                                                    </div>
                                                </div>

                                                <button
                                                    className="btn port-btn"
                                                    onClick={handleClick}>
                                                    Get in Touch
                                                </button>
                                            </div>
                                            <div className="col-sm-6 port-img">
                                                <img
                                                    src={
                                                        icons.quickDrivePortfolio
                                                    }
                                                    alt="quickDrivePortfolio"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="port-main mohamed-port">
                                        <div className="row gx-0">
                                            <div className="col-sm-6">
                                                <p className="port-text">
                                                    Contrary to popular belief
                                                    is not simply text. It has
                                                    roots in a piece of
                                                    classical literature from
                                                    making it over years old.
                                                </p>
                                                <div className="port-available">
                                                    <p>Available on</p>
                                                    <img
                                                        src={icons.www}
                                                        alt="www"
                                                    />
                                                </div>

                                                <div className="port-reting">
                                                    <div className="port-visitor">
                                                        <h2>10M+</h2>
                                                        <p>Visitors</p>
                                                    </div>
                                                    <div className="port-visitor">
                                                        <h2>4.2/5</h2>
                                                        <p>Rating</p>
                                                    </div>
                                                    <div className="port-visitor">
                                                        <h2>5M+</h2>
                                                        <p>Users</p>
                                                    </div>
                                                </div>

                                                <button
                                                    className="btn port-btn"
                                                    onClick={handleClick}>
                                                    Get in Touch
                                                </button>
                                            </div>
                                            <div className="col-sm-6 port-img">
                                                <img
                                                    src={icons.mohamedPortfolio}
                                                    alt="mohamedPortfolio"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
