import React from "react";
import "./HireUIUXDeveloper.scss";
import HeroSection from "components/HeroSection";
import icons from "utils/constants/icons";
import EngageSection from "components/EngageSection";
import BenefitSection from "components/BenefitSection";
import ServiceSection from "components/ServiceSection";
import GrowthSection from "components/GrowthSection";
import HelpSection from "components/HelpSection";
import PortfolioSection from "components/PortfolioSection";
import TestimonialSection from "components/TestimonialSection";
import ContactUs from "components/ContactUs";
import FaqSection from "components/FaqSection";
// import Portfolio from "pages/Home/Portfolio";

function HireUIUXDeveloper() {
  const heroSectionData = [
    {
      id: 1,
      mainTitle: "Elevate Your Digital Presence with Expert UI/UX Developers",
      subTitle:
        "Experience exceptional user experiences with our seasoned UI/UX developers. Collaborate with us to leverage the expertise of our UI/UX professionals and elevate your digital projects. Whether it's crafting intuitive interfaces, seamless interactions, or visually stunning designs, our UI/UX developers are ready to deliver excellence.",
      tags: [
        {
          name: "Time Zone Efficiency",
        },
        {
          name: "Cultural Compatibility",
        },
        {
          name: "Skilled Engineer Pool",
        },
        {
          name: "Swift Onboarding",
        },
        {
          name: "Flexible Scalability",
        },
      ],
      images: icons.hireResourcesText,
    },
  ];
  const EngageData = [
    {
      id: 1,
      mainTitle: "Elevate Your Projects",
      subTitle: "  Unlock Outstanding UI/UX Solutions with Us",
      title: "Engage Our Dedicated Developers",
      text: "Welcome to our platform, your ultimate destination for top-tier UI/UX development services. As a distinguished UI/UX development entity, we provide access to a talented pool of dedicated UI/UX developers meticulously selected for their qualifications, experience, and mastery in user-centric design principles.Our UI/UX developers excel in creating captivating and user-friendly solutions tailored precisely to your project requisites. Whether you require part-time assistance, full-time collaboration, or specialized ad-hoc support, our adaptable team seamlessly integrates into your workflow, ensuring smooth collaboration and superior outcomes.",
      tags: [
        {
          id: 1,
          text: "Flexible Developer Hiring: Full-Time or Hourly Basis",
          image: icons.checkedIcon,
        },
        {
          id: 2,
          text: "Agile Approach",
          image: icons.checkedIcon,
        },
        {
          id: 3,
          text: "Experienced Developers with 5+ Years",
          image: icons.checkedIcon,
        },
        {
          id: 4,
          text: "Constant Oversight & Reporting",
          image: icons.checkedIcon,
        },
        {
          id: 5,
          text: "Stringent NDA Policy",
          image: icons.checkedIcon,
        },
      ],
      businessTitle:
        "Considering Dedicated Developers for Your Business Software?",
      businessText:
        "Sourrcecube Technologies boasts a highly qualified team of designers and developers for hire, ready to elevate your application to the next level.",
      images: icons.hireResourcesDeveloperImg,
      btnOnetitle: "Hire UI/UX Developers",
      btnTwotitle: "Hire Now",
      reverse: "true",
    },
  ];
  const BenefitData = [
    {
      id: 1,
      mainTitle: "Expertise of Our UI/UX Developers",
      subTitle: "Unlocking the Potential Expertise of Our UI/UX Developers",
      benefit: [
        {
          id: 1,
          benefitTitle: "User-Centric Interface Design",
          icon: icons.learningIcon,
          benefitdescription:
            "Partner with us to harness the expertise of our UI/UX developers in crafting user-centric interfaces that resonate with your audience. Our developers possess the requisite skills and experience to deliver top-notch quality in UI/UX design, tailored to your specific requirements.",
        },
        {
          id: 2,
          benefitTitle: "Intuitive Interaction Design",
          icon: icons.motovationalIcon,
          benefitdescription:
            "Elevate user engagement with our dedicated UI/UX developers specializing in intuitive interaction design. Our team ensures seamless and delightful user experiences that drive engagement and satisfaction for your audience.",
        },
        {
          id: 3,
          benefitTitle: "Visually Striking Design Solutions",
          icon: icons.holidayIcon,
          benefitdescription:
            "Discover the power of impactful visual design with our UI/UX developers. Whether you need stunning graphics, appealing layouts, or compelling visual elements, our top-notch developers have the skills and experience to meet your design needs.",
        },
        {
          id: 4,
          benefitTitle: "UI/UX Integration & Enhancement",
          icon: icons.celebrationIcon,
          benefitdescription:
            "Stay ahead of the curve with seamless integration and enhancement of your existing applications. Our experienced UI/UX developers specialize in integrating and enhancing user interfaces, ensuring consistency, usability, and optimal performance.",
        },
        {
          id: 5,
          benefitTitle: "UI/UX Maintenance & Support",
          icon: icons.salaryIcon,
          benefitdescription:
            "Ensure the continued success of your UI/UX solutions with our comprehensive maintenance and support services. Our dedicated UI/UX developers are committed to providing hassle-free maintenance and support, ensuring smooth operation and uninterrupted business continuity.",
        },
        {
          id: 6,
          benefitTitle: "Accessibility-focused Design",
          icon: icons.environmentIcon,
          benefitdescription:
            "Our UI/UX developers prioritize accessibility in design, ensuring that your digital products are inclusive and accessible to all users, including those with disabilities. From implementing keyboard navigation to optimizing for screen readers, we ensure compliance with accessibility standards to enhance usability and reach a broader audience.",
        },
        {
          id: 7,
          benefitTitle: "Responsive Design Implementation",
          icon: icons.professionalIcon,
          benefitdescription:
            "With expertise in responsive design principles, our UI/UX developers ensure that your applications adapt seamlessly to various screen sizes and devices. Whether it's desktops, tablets, or smartphones, we create fluid and responsive designs that provide optimal viewing and interaction experiences for users across all devices.",
        },
        {
          id: 8,
          benefitTitle: "Usability Testing and Optimization",
          icon: icons.loyaltyIcon,
          benefitdescription:
            "Our UI/UX developers conduct rigorous usability testing to identify pain points and opportunities for improvement in your interfaces. Through user testing, feedback analysis, and iterative design enhancements, we optimize the usability of your digital products to enhance user satisfaction and drive better business outcomes.",
        },
      ],
    },
  ];
  const servicesData = [
    {
      sectionTitle: "Why Our Developers Stand Out",
      sectionDescription: "Why Opt for Our Dedicated Development Services?",
      mainTitle: "Our Range of UI/UX Development Services",
      subTitle:
        "Seeking innovative and captivating design solutions? Partner with our UI/UX developers renowned for their expertise in crafting exceptional digital experiences. Choose from a diverse array of services meticulously tailored to meet your specific requirements, including:",
      Services: [
        {
          id: 1,
          title: "Design System Development",
          description:
            "Our UI/UX developers specialize in developing comprehensive design systems that ensure consistency and scalability across your digital products. From creating reusable components and design patterns to establishing guidelines for typography, color schemes, and interaction behaviors, we help streamline the design and development process while maintaining brand coherence and efficiency.",
          number: "01",
        },
        {
          id: 2,
          title: "Visual Design Excellence",
          description:
            "Visual design excellence focuses on creating visually appealing and impactful designs that align with the brand identity and resonate with the target audience. It involves leveraging principles of graphic design, typography, color theory, and composition to create visually striking interfaces.",
          number: "02",
        },
        {
          id: 3,
          title: "UI/UX Integration & Enhancement",
          description:
            "UI/UX integration and enhancement involve integrating user interface design with backend systems, third-party APIs, or external services to create seamless and cohesive user experiences. It also includes ongoing refinement and optimization of UI/UX elements based on user feedback and analytics.",
          number: "03",
        },
        {
          id: 4,
          title: "UI/UX Maintenance & Support",
          description:
            "UI/UX maintenance and support involve ongoing monitoring, troubleshooting, and updates to ensure the continued effectiveness and relevance of the user interface and user experience. It includes tasks such as bug fixes, performance optimization, content updates, and security patches.",
          number: "04",
        },
        {
          id: 5,
          title: "Customized Design Workshops",
          description:
            "Our UI/UX developers offer customized design workshops tailored to your business needs. These workshops provide a collaborative environment where stakeholders can ideate, prototype, and refine design concepts under the guidance of our experts. Through hands-on activities and design exercises, we empower your team to contribute to the creation of innovative and effective design solutions.",
          number: "05",
        },
      ],
      sProjects: "3K",
      itProfessionals: "75",
      experience: "5",
      sProjectsTitle: "Successful Projects",
      itProfessionalsTitle: "IT professionals",
      experienceTitle: "Years of experience",
    },
  ];
  const growthStep = [
    {
      sectionTitle: "Our Streamlined Process for UI/UX Development",
      sectionDescription: "Efficient Hiring and Tailored Project Execution",
      mainTitle: "",
      subTitle: "",
      step: [
        {
          id: 1,
          title: "Discover",
          description:
            "Embark on a journey of discovery as we delve into your business objectives and user needs. Through in-depth research and analysis, we gain valuable insights to inform the design process.",
          number: "1",
          icon: icons?.hireResourcesStap1,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 2,
          title: "Design",
          description:
            "Unleash creativity as we craft visually stunning and intuitive design solutions. Our design philosophy blends creativity with functionality, ensuring delightful user experiences across all touchpoints.",
          number: "2",
          icon: icons?.hireResourcesStap2,
          stepArrow: icons?.stepDownArrow,
        },
        {
          id: 3,
          title: "Build",
          description:
            "Harnessing the latest design tools and technologies, we bring your vision to life. Our development process focuses on creating user-centric interfaces that are not only visually appealing but also highly functional and responsive.",
          number: "3",
          icon: icons?.hireResourcesStap3,
          stepArrow: icons?.stepUpArrow,
        },
        {
          id: 4,
          title: "Deliver",
          description:
            "Excellence is our standard as we follow an iterative approach to design. With a commitment to continuous improvement, we strive to deliver UI/UX solutions that exceed expectations and drive business success.",
          number: "4",
          icon: icons?.hireResourcesStap4,
        },
      ],
    },
  ];
  const HelpStep = [
    {
      mainTitle: "WE ARE HERE TO HELP YOU",
      subTitle: "Let’s Transform Your Vision Into Value",
      text: "Start Project Conversation",
      images: icons.hireResourcesFormImg,
      description:
        "Drop your project requirements here. Share your details with us. Let’s build a strategic solution for your business together!",
    },
  ];
  const portfolioData = [
    {
      id: 1,
      images: icons.iferp,
      name: "iferp",
    },
    {
      id: 2,
      images: icons.udenzLogo,
      name: "udenzLogo",
    },
    {
      id: 3,
      images: icons.paydLogo,
      name: "paydLogo",
    },
    {
      id: 4,
      images: icons.citronLogo,
      name: "citronLogo",
    },
    {
      id: 5,
      images: icons.quickDrive,
      name: "quickDrive",
    },
    {
      id: 6,
      images: icons.mohamedLogo,
      name: "mohamedLogo",
    },
  ];
  const portfolioSliderData = [
    {
      id: 1,
      description:
        "The Institute for Educational Research and Publication (IFERP) is a professional association devoted to the advancement of the fields of engineering, science, and technology through the funding of research activities, propagation of the latest research insights, furtherance of industry trends, and other related ventures. IFERP aims to digitalize this entire process of innovation, collaboration, and knowledge-sharing through the fostering of a unified virtual scientific community worldwide. Everything from networking and joint ventures to learning, research assistance, publication, and more, will be carried out as part of this objective",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.5",
      ratingName: "Rating",
      usersNumber: "64k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.udenzPortfolio,
      Available: "Available on",
      classNameweb: "iferp-port",
    },
    {
      id: 2,
      title: "Selected under 100 in UAE",
      description:
        "     UDENZ has been honored as part of the Future 100 list, an initiative by the UAE Ministry of Economy and the Minister of State for Government Development and the Future. Add this image",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
        {
          id: 2,
          store: icons.playStore,
        },
      ],
      ratingNumber: "4.6",
      ratingName: "Rating",
      btnTitle: " Get in Touch",
      images: icons.iferpPortfolio,
      Available: "Available on",
      classNameweb: "udenz-port",
      topLogo: icons.top100Logo,
    },
    {
      id: 3,
      description:
        "Streamline payment processes, Small payment gateway straightforward, secure and cutting-edge services solution Available for businesses all over menu",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      multipleImages: [
        {
          id: 1,
          images: icons.country1,
        },
        {
          id: 2,
          images: icons.country2,
        },
        {
          id: 3,
          images: icons.country3,
        },
        {
          id: 4,
          images: icons.country4,
        },
        {
          id: 5,
          images: icons.country5,
        },
        {
          id: 6,
          images: icons.country6,
        },
        {
          id: 7,
          images: icons.country7,
        },
        {
          id: 8,
          images: icons.country8,
        },
        {
          id: 9,
          images: icons.country9,
        },
      ],
      visitorsNumber: "2M+",
      visitorsName: "Visitors",
      ratingNumber: "4.9",
      ratingName: "Rating",
      usersNumber: "80k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.paydPortfolio,
      Available: "Available on",
      classNameweb: "payd-port",
    },
    {
      id: 4,
      description:
        "Tours and Travel packages Booking Portal for UAE based  business,They will show you the unique and unmissable  things to do in your destination. With Citron Tourism  on your side (and in your pocket)",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "5k+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "1k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.citronPortfolio,
      Available: "Available on",
      classNameweb: "citron-port",
    },
    {
      id: 5,
      description:
        " Welcome to QuickDrive.ae – a project that holds a  special place in our portfolio, showcasing skills and  dedication to creating innovative and user-centric  digital experiences. As the driving force behind the  development of QuickDrive.ae, aimed to redefine the  online car rental landscape by seamlessly blending  cutting-edge technology with an intuitive user  interface.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "1M+",
      visitorsName: "Visitors",
      ratingNumber: "4.3/5",
      ratingName: "Rating",
      usersNumber: "20k+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.quickDrivePortfolio,
      Available: "Available on",
      classNameweb: "quick-drive-port",
    },
    {
      id: 6,
      description:
        "Contrary to popular belief is not simply text. It has  roots in a piece of classical literature from making  it over years old.",
      icons: [
        {
          id: 1,
          store: icons.www,
        },
      ],
      visitorsNumber: "10M+",
      visitorsName: "Visitors",
      ratingNumber: "4.2/5",
      ratingName: "Rating",
      usersNumber: "5M+",
      userName: "Users",
      btnTitle: " Get in Touch",
      images: icons.mohamedPortfolio,
      Available: "Available on",
      classNameweb: "mohamed-port",
    },
  ];
  const TestimonialData = [
    {
      title: "TESTIMONIALS",
      description: "Their Taste-Statements Make Us Proud ",
    },
  ];
  const FaqData = [
    {
      mainTitle: "FAQ",
      description: "Frequently Asked Questions",
      question: [
        {
          id: 1,
          title:
            "What experience do your UI/UX developers have in designing user-centric interfaces and experiences?",
          subtitle:
            "Our UI/UX developers possess extensive experience in crafting user-centric interfaces and experiences across various platforms and devices. They specialize in conducting user research, creating wireframes and prototypes, and designing intuitive and visually appealing interfaces that enhance user satisfaction and engagement.",
        },
        {
          id: 2,
          title:
            "Can you describe a challenging UI/UX project your developers have successfully completed?",
          subtitle:
            "Our UI/UX developers have successfully delivered a variety of challenging projects, including designing complex web applications, mobile apps, and e-commerce platforms. They excel in conducting usability testing, implementing accessibility standards, and incorporating user feedback to iterate and refine designs for optimal user experiences.",
        },
        {
          id: 3,
          title:
            "How do your UI/UX developers approach user research and usability testing in the design process?",
          subtitle:
            "Our UI/UX developers conduct thorough user research to understand user needs, behaviors, and pain points. They utilize techniques such as user interviews, surveys, and usability testing to gather insights and validate design decisions. By prioritizing user feedback and iterative design, they ensure that the final product meets user expectations and delivers exceptional experiences.",
        },
        {
          id: 4,
          title:
            "What design tools and software do your UI/UX developers utilize in their design process?",
          subtitle:
            "Our UI/UX developers are proficient in using a variety of design tools and software, including Adobe XD, Sketch, Figma, and InVision, to create wireframes, prototypes, and high-fidelity designs. They leverage these tools to collaborate effectively with stakeholders, iterate designs rapidly, and ensure seamless handoff to development teams.",
        },
        {
          id: 5,
          title:
            "How do your UI/UX developers ensure accessibility and inclusivity in their designs?",
          subtitle:
            "Our UI/UX developers prioritize accessibility and inclusivity in their designs by adhering to Web Content Accessibility Guidelines (WCAG) and conducting accessibility audits. They ensure that designs are perceivable, operable, understandable, and robust for users with disabilities, thus providing an inclusive experience for all users.",
        },
        {
          id: 6,
          title:
            "What collaboration and communication practices do your UI/UX developers follow with cross-functional teams?",
          subtitle:
            "Our UI/UX developers collaborate closely with cross-functional teams, including designers, developers, product managers, and stakeholders. They engage in regular communication, conduct design reviews, and embrace feedback to ensure alignment with project goals and objectives. By fostering a collaborative environment, they deliver cohesive and impactful design solutions.",
        },
        {
          id: 7,
          title:
            "How do your UI/UX developers stay updated with the latest design trends, tools, and best practices?",
          subtitle:
            "Our UI/UX developers are dedicated to continuous learning and professional development. They stay updated with the latest design trends, tools, and best practices by attending design conferences, workshops, online courses, and actively participating in design communities and forums. They also conduct design experiments and case studies to explore new techniques and enhance their expertise in UI/UX design.",
        },
      ],
    },
  ];
  return (
    <div id="Hire-UIUX-Developer-id">
      <HeroSection Data={heroSectionData} />
      <EngageSection data={EngageData} />
      <BenefitSection data={BenefitData} />
      <ServiceSection data={servicesData} isTrue={true} />
      <GrowthSection data={growthStep} />
      <HelpSection data={HelpStep} />
      <PortfolioSection data={portfolioData} sliderData={portfolioSliderData} />
      <TestimonialSection data={TestimonialData} />
      <FaqSection data={FaqData} />
      <ContactUs />
    </div>
  );
}

export default HireUIUXDeveloper;
